import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { PageWrapper } from "../../components/PageWrapper";
import { TableHeader } from "../../components/TableHeader";

import { TableBodyRow } from "../../components/TableBodyRow";
import { TableBodyCell } from "../../components/TableBodyCell";
import { TableHeadCell } from "../../components/TableHeadCell";
import { formatNumber } from "../../utils/format-number";
import { Table } from "../../components/Table";
import { formatDate } from "../../utils/format-date";
import { LoadingState } from "../../components/LoadingState";
import { TickerAvatar } from "../../components/TickerAvatar";
import { CusipTagList } from "../../components/CusipTagList";
import { AlertIcon } from "../../components/AlertIcon";
import { useTasks } from "./useTasks";
import { SecurityProspectusPackage } from "src/api/adapters/nexus/types";
import { Box } from "src/components/atoms/Box";

export const MissingExpired = (): JSX.Element | null => {
  const navigate = useNavigate();
  const {
    missingExpiredQuery: query,
    setMissingExpiredQuery: setQuery,
    missingExpiredProspectuses,
  } = useTasks();

  const handleOrderingClick = useCallback(
    (ordering: string) => {
      setQuery({ ...query, ordering });
    },
    [query]
  );

  const { ordering, pageSize, page } = query;
  const { count, isLoading, isError, prospectuses } =
    missingExpiredProspectuses;

  if (isLoading || !prospectuses)
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );

  if (isError) {
    return <PageWrapper>Error...</PageWrapper>;
  }

  const handleRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    prospectus: SecurityProspectusPackage
  ) => {
    const {
      currentProxyEvents,
      security: { id },
      prospectusPackage,
    } = prospectus;

    let url = `/prospectuses/security/${id}?targetPackage=${prospectusPackage.id}`;
    if (currentProxyEvents.length > 0) {
      url = `/proxy/${String(currentProxyEvents[0])}`;
    }

    if (e.metaKey) {
      window.open(url, "_blank");
      return;
    }
    navigate(url);
  };

  return (
    <PageWrapper>
      <TableHeader
        count={count || 0}
        page={page}
        pageSize={pageSize}
        itemType="fund"
        onPageChange={(page) => setQuery({ ...query, page })}
        onPageSizeChange={(pageSize) => setQuery({ ...query, pageSize })}
      />
      <Table>
        <thead className="bg-white">
          <tr>
            <TableHeadCell name="Ticker" />
            <TableHeadCell name="Security" />
            <TableHeadCell name="CUSIP" />
            <TableHeadCell
              name="Recent Trades"
              align="right"
              orderingKey="recent_trades"
              currentOrdering={ordering}
              onOrderingClick={handleOrderingClick}
            />
            <TableHeadCell
              name="Effective Date"
              align="right"
              orderingKey="effective_start_date"
              currentOrdering={ordering}
              onOrderingClick={handleOrderingClick}
            />
            <TableHeadCell
              name="Expect Date"
              align="right"
              orderingKey="expected_end_date"
              currentOrdering={ordering}
              onOrderingClick={handleOrderingClick}
            />
            <TableHeadCell name="Filing ID" />
          </tr>
        </thead>
        <tbody>
          {prospectuses.map((prospectus: SecurityProspectusPackage) => {
            const {
              currentProxyEvents,
              tradeStats: { recentTrades },
              prospectusPackage: {
                id: prospectusId,
                effectiveStartDate,
                expectedEndDate,
              },
              security: { issuerName, cusip, ticker },
            } = prospectus;
            return (
              <TableBodyRow
                key={prospectusId}
                onClick={(e) => handleRowClick(e, prospectus)}
              >
                <TableBodyCell>
                  <TickerAvatar tickers={[ticker]} />
                </TableBodyCell>
                <TableBodyCell>
                  <Box component="span" sx={{ color: "black" }}>
                    {issuerName}
                  </Box>
                </TableBodyCell>
                <TableBodyCell>
                  <CusipTagList cusips={[String(cusip)]} />
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-primary-text font-medium text-right">
                    {formatNumber(recentTrades)}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-right">
                    {effectiveStartDate
                      ? formatDate(new Date(effectiveStartDate))
                      : "--"}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-red text-right">
                    {expectedEndDate ? (
                      formatDate(new Date(expectedEndDate))
                    ) : (
                      <div className="flex items-center justify-end">
                        -- <AlertIcon className="ml-2" />
                      </div>
                    )}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-primary-text">
                    {currentProxyEvents.length > 0
                      ? String(currentProxyEvents[0])
                      : "--"}
                  </div>
                </TableBodyCell>
              </TableBodyRow>
            );
          })}
        </tbody>
      </Table>
    </PageWrapper>
  );
};
