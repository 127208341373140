import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

import {
  createAdditionalMailingDocument,
  deleteAdditionalMailingDocument,
  updateAdditionalMailingDocument,
} from "src/api/adapters";
import { AdditionalMailing } from "src/types/additionalMailing";
import { WarrenGDocument } from "src/types/warreng";
import { capitalize } from "src/utils/capitalize";
import { Dropzone } from "../atoms/Dropzone";
import { FormSection } from "../molecules/FormSection";
import {
  WarrenGDocumentDialog,
  WarrenGDocumentForm,
} from "../WarrenGDocumentDialog";
import { DocumentRow } from "./DocumentRow";

interface IAdditionalMailingDocumentListProps {
  additionalMailing: AdditionalMailing;
  onAdditionalMailingUpdate: () => void;
  proxyEventId: string;
}

export function AdditionalMailingDocumentList({
  additionalMailing,
  onAdditionalMailingUpdate,
  proxyEventId,
}: IAdditionalMailingDocumentListProps) {
  const [documentToAdd, setDocumentToAdd] = useState<File | null>(null);
  const [isCreatingDocument, setIsCreatingDocument] = useState(false);

  const handleAddDocument = async (values: WarrenGDocumentForm) => {
    const { type, name } = values;
    if (documentToAdd && additionalMailing && type) {
      const payload = new FormData();
      payload.append("attachment", documentToAdd, name);
      payload.append("type", type);
      payload.append("name", name);
      try {
        setIsCreatingDocument(true);
        await createAdditionalMailingDocument(
          proxyEventId,
          additionalMailing.id,
          payload
        );
      } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.data?.length > 0) {
          const errorMessage = e?.response?.data[0];
          toast.error(capitalize(errorMessage));
        } else {
          toast.error("An error occured when trying to add the document.");
        }
      } finally {
        // Setting null to close dialog
        onAdditionalMailingUpdate();
        setDocumentToAdd(null);
        setIsCreatingDocument(false);
      }
    }
  };

  const handleDeleteDocument = (doc: WarrenGDocument) => {
    return deleteAdditionalMailingDocument(
      proxyEventId,
      additionalMailing.id,
      doc.id
    );
  };

  const handleUpdateDocument = async (
    doc: WarrenGDocument,
    type: string,
    name: string
  ) => {
    return updateAdditionalMailingDocument(
      proxyEventId,
      additionalMailing.id,
      doc.id,
      { name, type }
    );
  };

  const handleOnDrop = (files: File[]) => {
    setDocumentToAdd(files[0]);
  };

  const handleRemoveDocumentToAdd = () => {
    setDocumentToAdd(null);
  };

  return (
    <FormSection title="Documents">
      {additionalMailing.documents.map((doc) => {
        return (
          <DocumentRow
            key={doc.id}
            document={doc}
            onDeleteDocument={handleDeleteDocument}
            onEditDocument={handleUpdateDocument}
            showProxyDocumentOptions={true}
            updateDocuments={onAdditionalMailingUpdate}
          />
        );
      })}
      <Dropzone multiple={false} onDrop={handleOnDrop} pdfOnly={true} />
      <WarrenGDocumentDialog
        isLoading={isCreatingDocument}
        isOpen={!!documentToAdd}
        onClose={handleRemoveDocumentToAdd}
        onCreateUpdate={handleAddDocument}
        showProxyDocumentOptions={true}
      />
    </FormSection>
  );
}
