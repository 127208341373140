import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { PageHeader } from "src/components/molecules/PageHeader";
import { ReactComponent as VCAIcon } from "src/assets/icons/vca.svg";
import { Tabs } from "src/components/atoms/Tabs";
import { getTabFromUrl } from "src/utils/get-tab-from-url";
import { LoadingState } from "src/components/LoadingState";
import { useCorporateActionEvent } from "src/api/adapters/nexus/hooks/useCorporateActionEvent";
import { Box } from "src/components/atoms/Box";
import { VCAStatusBadge } from "src/components/molecules/VCAStatusBadge";
import { DeleteVcaFilingDialog } from "src/components/DeleteVcaFilingDialog";
import { LoadingButton } from "@mui/lab";
import { Dialog } from "src/components/atoms/dialog/Dialog";
import { toast } from "react-toastify";
import { updateVoluntaryCorporateActionStatus } from "src/api/adapters";

type DetailsPageParams = {
  id: string;
};

export const VcaDetailPage = () => {
  const { id } = useParams() as DetailsPageParams;
  const location = useLocation();
  const { corporateActionEvent, isLoading, mutate } =
    useCorporateActionEvent(id);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isApprovingFiling, setIsApprovingFiling] = useState(false);

  function onTabChange(value: string) {
    navigate(`/vca/${id}/${value}`);
  }

  const handleDeleteClick = () => {
    setAnchorEl(null);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleOpenApproveDialog = () => {
    setIsApproveDialogOpen(true);
  };

  const handleCloseApproveDialog = () => {
    setIsApproveDialogOpen(false);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleApproveFiling = async () => {
    setIsApprovingFiling(true);
    try {
      await updateVoluntaryCorporateActionStatus(id, {
        operationsStatus: "approved",
      });
      mutate();
    } catch (e) {
      toast.error("Unable to mark as approved.");
    } finally {
      setIsApprovingFiling(false);
      handleCloseApproveDialog();
    }
  };

  if (isLoading || !corporateActionEvent) {
    return <LoadingState />;
  }

  const tabs = [
    {
      label: `Filing details`,
      value: "",
      testId: "CorporateActionsFilingDetailsTab",
    },
    {
      label: `Broker email`,
      value: "broker-email",
      testId: "CorporateActionsBrokerEmailTab",
    },
  ];

  const { electionCutoff, operationsStatus } = corporateActionEvent;
  const { issuer } = corporateActionEvent.securities[0];
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <PageHeader icon={<VCAIcon />} title={issuer?.name || ""}>
        <VCAStatusBadge
          sx={{ ml: 4 }}
          status={operationsStatus}
          electionCutoff={electionCutoff}
        />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Box>
            {operationsStatus !== "approved" && (
              <LoadingButton
                color="primary"
                data-testid="save-button"
                onClick={handleOpenApproveDialog}
                size="small"
                sx={{ mr: 2 }}
                variant="contained"
              >
                Approve
              </LoadingButton>
            )}
            <IconButton
              id="vca-filing-more"
              size="small"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="vca-filing-more"
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  width: "20ch",
                },
              }}
            >
              <MenuItem key="vca-filing-delete" onClick={handleDeleteClick}>
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </PageHeader>
      <Tabs
        sx={{ borderBottom: "1px solid #e5e5e5", px: 6 }}
        onChange={onTabChange}
        value={getTabFromUrl(location)}
        tabs={tabs}
      />
      <Outlet />
      <DeleteVcaFilingDialog
        id={id}
        isOpen={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
      />
      <Dialog
        open={isApproveDialogOpen}
        title="Approve Corporate Action"
        primaryButtonText="Approve"
        secondaryButtonText="Cancel"
        onClose={handleCloseApproveDialog}
        onPrimaryClick={handleApproveFiling}
        onSecondaryClick={handleCloseApproveDialog}
        isPrimaryLoading={isApprovingFiling}
      >
        Are you sure you want to approve this corporate action? Approved events
        will send emails to shareholders.
      </Dialog>
    </Box>
  );
};
