// TBD: expand on this and use for other tables not just review;
import { TableCell } from "@mui/material";
import { OrderingKey as ProxyOrderingKey } from "../../features/Proxy/useTasks";
import { tableHeadCell } from "../../features/Proxy/styles/tableStyles";
import { TableSortLabel } from "../TableSortLabel";

export interface HeaderDataType {
  queryValue: ProxyOrderingKey | null;
  label: string;
}

interface Props {
  headerData: HeaderDataType[];
  activeQueryField: string;
  direction: "asc" | "desc";
  clickHandler(queryValue: string): void;
}

const TableColumnHeaders = ({
  headerData, // array of data to render headers
  activeQueryField, // currently active column query
  direction, // asc or desc
  clickHandler,
}: Props): JSX.Element => {
  /**
   * renders table column headers
   * needs to be expanded to support additional variations so it can render them all
   * currently, basic and sortable are the two it can render
   */
  const renderTableColumnHeaders = () => {
    return headerData.map((itm) => {
      const { queryValue, label } = itm;
      if (queryValue !== null) {
        const isActive = activeQueryField === queryValue;

        return (
          <TableCell key={label} sx={tableHeadCell}>
            <TableSortLabel
              active={isActive}
              direction={isActive ? direction : "asc"}
              onClick={() => clickHandler(queryValue)}
            >
              {label}
            </TableSortLabel>
          </TableCell>
        );
      }
      return (
        <TableCell key={label} sx={tableHeadCell}>
          {label}
        </TableCell>
      );
    });
  };

  return <>{renderTableColumnHeaders()}</>;
};

export default TableColumnHeaders;
