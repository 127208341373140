import { Autocomplete as MUIAutocomplete, TextField } from "@mui/material";
import get from "lodash.get";

interface IAutocompleteProps<T> {
  multiple?: boolean;
  onChange: (option: T | T[] | null) => void;
  optionLabelPath: string;
  optionValuePath: string;
  options: T[];
  placeholder: string;
  value: T[];
}

export function Autocomplete<T>({
  placeholder,
  options,
  multiple,
  onChange,
  value,
  optionLabelPath,
  optionValuePath,
}: IAutocompleteProps<T>) {
  return (
    <MUIAutocomplete
      ChipProps={{ size: "small" }}
      getOptionLabel={(option) => get(option, optionLabelPath)}
      onChange={(e, options) => {
        onChange(options);
      }}
      filterSelectedOptions={true}
      isOptionEqualToValue={(option, value) =>
        get(option, optionValuePath) === get(value, optionValuePath)
      }
      options={options}
      multiple={multiple}
      renderInput={(props) => (
        <TextField {...props} placeholder={placeholder} size="small" />
      )}
      value={value}
    />
  );
}
