import { CorporateActionType } from "../types";

const actionTypeModelNameToVerboseNameMapping = {
  ConsentSolicitation: "Consent Solicitation",
  DividendOption: "Dividend Option",
  DividendReinvestmentPlan: "Dividend Reinvestment Plan",
  ExchangeOffer: "Exchange Offer",
  MergerConsiderationElection: "Merger with Election",
  OddLotOffer: "Odd Lot Offer",
  OfferToPurchaseBuyBack: "Buyback",
  OfferToPurchaseThirdPartyOffer: "Offer to Purchase",
  PreferentialOffer: "Preferential Offer",
  RightsIssue: "Rights Issue",
};

function getActionTypeVerboseName(actionType: CorporateActionType): string {
  return actionTypeModelNameToVerboseNameMapping[actionType];
}

export { getActionTypeVerboseName };
