import Switch from "@mui/material/Switch";
import axios from "axios";
import { zonedTimeToUtc } from "date-fns-tz";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { CusipTypeahead } from "../../components/CusipTypeahead/CusipTypeahead";
import { Label } from "../../components/Label";
import { Input } from "../../components/Input";
import { FormStatusBar } from "./FormStatusBar";
import {
  deleteVCA,
  updateElection,
  updateVCA,
  updateVCAStatus,
} from "../../api/adapters/proxy";
import { FieldError } from "../../components/FieldError/FieldError";
import {
  CorporateActionType,
  DetailsFormValues,
  OperationsStatus,
} from "./types";
import { editValidationSchema } from "./validation-schema";
import { corporateActionTypeOptions, frequencyOptions } from "./select-options";
import { createFormPayload, getElectionChoices } from "./helpers";
import { DetailsPageDocuments } from "./DetailsPageDocuments";
import { useCorporateAction } from "../../api/adapters/proxy/hooks/useCorporateAction";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";
import { formatErrors } from "./formatErrors";
import { formatUpdateStatusError } from "../../utils/format-update-status-error";
import { TextareaAutosize } from "./TextareaAutosize";
import { Select } from "src/components/Select";
import { DatePicker } from "src/components/atoms/DatePicker";
import { Box } from "src/components/atoms/Box";
import { Button } from "src/components/atoms/Button";

const NY_TIME_ZONE_STRING = "America/New_York";

type FilingDetailsParams = {
  id: string;
};

export function FilingDetails(): JSX.Element | null {
  const { id } = useParams() as FilingDetailsParams;
  const { corporateAction, mutate } = useCorporateAction(id);
  const navigate = useNavigate();
  const [showReport, setShowReport] = React.useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    React.useState(false); // delete filings

  const vcaReport = React.useMemo(() => {
    if (corporateAction) {
      return corporateAction.documents.find(
        (document) => document.type === "corporate_action_details"
      );
    }
  }, [corporateAction]);

  const isEDIVCA: boolean = vcaReport ? true : false;
  const handleFormSubmit = async (
    values: DetailsFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (values.issuer && values.securities.length && id) {
      try {
        setSubmitting(true);
        const payload = createFormPayload(values);
        const electionPayload = {
          title: values.electionTitle,
          detail: values.electionDetails,
          choices: getElectionChoices(values.type as CorporateActionType),
          corporateActionId: id,
        };
        const response = await updateVCA(id, payload);
        if (values.operationsStatus === "new") {
          const payload: { operationsStatus: OperationsStatus } = {
            operationsStatus: "active",
          };
          await updateVCAStatus(id, payload);
        }

        await updateElection(response.data.election.id, electionPayload);
        setSubmitting(false);
        mutate();
      } catch (error) {
        setSubmitting(false);
        if (axios.isAxiosError(error) && error?.response?.data) {
          toast.error(formatErrors(error.response.data), { autoClose: false });
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    }
  };

  async function handleMarkReadyForReview() {
    if (corporateAction && id) {
      const status = corporateAction.operationsStatus;

      if (status === "new") {
        toast.error(
          "Status must be active in order to mark it ready for review."
        );
        return;
      }

      if (
        status === "ready_for_review" ||
        status === "approved" ||
        status === "closed"
      ) {
        navigate(`/corporate-actions/${id}/broker-email`);
        return;
      }

      if (status === "active") {
        try {
          const payload: { operationsStatus: OperationsStatus } = {
            operationsStatus: "ready_for_review",
          };
          await updateVCAStatus(id, payload);
          mutate();
          navigate(`/corporate-actions/${id}/broker-email`);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            toast.error(formatUpdateStatusError(error));
          } else {
            toast.error(
              "An error occurred. Unable to mark it as ready for review."
            );
          }
        }
      }
    }
  }

  async function handleDeleteVca() {
    if (id) {
      try {
        await deleteVCA(id);
        navigate(`/corporate-actions/tasks/new`);
      } catch (error) {
        if (axios.isAxiosError(error) && error?.response?.data) {
          toast.error(JSON.stringify(error.response.data, null, 2));
        } else {
          toast.error(
            "An error occurred while trying to delete this corporate action."
          );
        }
      }
    }
  }

  if (corporateAction) {
    const initialValues: DetailsFormValues = {
      securities: corporateAction.securities
        ? corporateAction.securities.map((security) => ({
            cusip: security.cusip,
            id: security.id,
          }))
        : [],
      issuer: corporateAction.issuer
        ? {
            id: corporateAction.issuer.id,
            name: corporateAction.issuer.companyName,
          }
        : null,
      announcementDate:
        zonedTimeToUtc(corporateAction.announcementDate, NY_TIME_ZONE_STRING) ||
        null,
      recordDate: corporateAction.recordDate
        ? zonedTimeToUtc(corporateAction.recordDate, NY_TIME_ZONE_STRING)
        : null,
      electionCutoffDate: corporateAction.electionCutoffDatetime
        ? zonedTimeToUtc(
            corporateAction.electionCutoffDatetime,
            NY_TIME_ZONE_STRING
          )
        : null,
      dtcExpirationDate: corporateAction.dtcExpirationDate
        ? zonedTimeToUtc(corporateAction.dtcExpirationDate, NY_TIME_ZONE_STRING)
        : null,
      type: (corporateAction.type as CorporateActionType) || "",
      operationsStatus: corporateAction.operationsStatus || "active",
      electionDetails: corporateAction.election?.detail || "",
      electionTitle: corporateAction.election?.title || "",
      bidIncrement: corporateAction.bidIncrement || "",
      consentPayment: corporateAction.consentPayment || "",
      contraCusip: corporateAction.contraCusip || "",
      dripReinvestmentPrice: corporateAction.dripReinvestmentPrice || "",
      frequency: corporateAction.frequency || "",
      grossDividend: corporateAction.grossDividend || "",
      maximumBidPrice: corporateAction.maximumBidPrice || "",
      maximumQualifyingQuantity:
        corporateAction.maximumQualifyingQuantity || "",
      minimumBidPrice: corporateAction.minimumBidPrice || "",
      minimumQualifyingQuantity:
        corporateAction.minimumQualifyingQuantity || "",
      offererParty: corporateAction.offererParty || "",
      outTurn: corporateAction.outTurn || "",
      price: corporateAction.price || "",
      ratio: corporateAction.ratio || "",
      tradeSecurity: corporateAction.tradeSecurity || "",
      documents: corporateAction.documents
        ? corporateAction.documents.map((doc) => ({
            id: doc.id,
            name: doc.name,
            type: doc.type,
            attachment: doc.attachment,
          }))
        : [],
    };

    if (!id) return null;

    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, formikBag) =>
            handleFormSubmit(values, formikBag.setSubmitting)
          }
          validationSchema={editValidationSchema}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            isValid,
            dirty,
            setSubmitting,
          }: FormikProps<DetailsFormValues>) => (
            <Form>
              <FormStatusBar
                electionCutoff={values.electionCutoffDate?.toString() || null}
                status={corporateAction.operationsStatus}
                sx={{
                  py: 4,
                  px: 8,
                }}
              >
                <Button
                  sx={{ mr: 2 }}
                  color="primary"
                  data-testid="save-button"
                  disabled={!isValid}
                  size="small"
                  type="submit"
                  variant="outlined"
                >
                  {isSubmitting ? "Saving..." : "Save"}
                </Button>
                <Button
                  color="primary"
                  data-testid="review-broker-email-button"
                  disabled={!isValid}
                  onClick={async () => {
                    try {
                      if (dirty) {
                        await handleFormSubmit(values, setSubmitting);
                      }
                      await handleMarkReadyForReview();
                    } catch (error) {
                      toast.error("Unable to mark VCA ready for review.");
                    }
                  }}
                  size="small"
                  variant="contained"
                >
                  Review broker emails
                </Button>
              </FormStatusBar>
              <div className="grid grid-cols-2 gap-8 py-4 px-8">
                <div>
                  <div className="py-4 w-full border-b border-gray flex items-center justify-between">
                    <h2 className="text-secondary-text font-medium text-xs uppercase">
                      Filing Details {isEDIVCA ? "(Auto Created)" : ""}
                    </h2>
                    {isEDIVCA && (
                      <div className="flex items-center">
                        <span className="text-secondary-text font-medium text-xs uppercase">
                          Report
                        </span>

                        <Switch
                          checked={showReport}
                          name="report"
                          onChange={() => setShowReport(!showReport)}
                          color="primary"
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col mt-4">
                    <Field name="securities">
                      {({ field, form }: FieldProps<DetailsFormValues>) => (
                        <InputGroup>
                          <Label required={true}>CUSIP(s)</Label>
                          <CusipTypeahead
                            selectedItems={field.value}
                            onSelectItem={(security) => {
                              form.setFieldValue("securities", [
                                ...field.value,
                                security,
                              ]);
                              form.setFieldValue("issuer", {
                                id: security.issuer.id,
                                name: security.issuer.companyName,
                              });
                            }}
                            onClear={() => {
                              form.setFieldValue("securities", []);
                              form.setFieldValue("issuer", {
                                id: 0,
                                name: "",
                              });
                            }}
                            onRemoveItem={(index) => {
                              if (field.value.length === 1) {
                                form.setFieldValue("issuer", {
                                  id: 0,
                                  name: "",
                                });
                              }

                              form.setFieldValue(
                                "securities",
                                field.value.filter(
                                  (_: unknown, i: number) => i !== index
                                )
                              );
                            }}
                            issuerId={
                              values.issuer ? values.issuer.id : undefined
                            }
                            disabled={values.operationsStatus === "closed"}
                          />
                        </InputGroup>
                      )}
                    </Field>
                    <Field name="issuer">
                      {({ field }: FieldProps<DetailsFormValues>) => (
                        <InputGroup>
                          <Label required={true}>Issuer</Label>
                          <Input
                            disabled={true}
                            type="text"
                            value={field.value ? field.value.name : ""}
                          />
                        </InputGroup>
                      )}
                    </Field>
                    <div className="grid grid-cols-2 gap-4">
                      <Field name="announcementDate">
                        {({ form, field }: FieldProps<DetailsFormValues>) => (
                          <InputGroup>
                            <Label required={true}>Announcement Date</Label>
                            <DatePicker
                              fullWidth={true}
                              name="announcementDate"
                              onChange={(date) => {
                                form.setFieldValue("announcementDate", date);
                              }}
                              selected={field.value}
                              dateFormat="MM/dd/yyyy"
                              placeholder="MM/DD/YYYY"
                            />
                          </InputGroup>
                        )}
                      </Field>
                      <Field name="recordDate">
                        {({ field, form }: FieldProps<DetailsFormValues>) => (
                          <InputGroup>
                            <Label showOptional={true}>Record Date</Label>
                            <DatePicker
                              fullWidth={true}
                              name="recordDate"
                              onChange={(date) => {
                                form.setFieldValue("recordDate", date);
                              }}
                              selected={field.value}
                              placeholder="MM/DD/YYYY"
                            />
                          </InputGroup>
                        )}
                      </Field>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <Field name="electionCutoffDate">
                        {({ field, form }: FieldProps<DetailsFormValues>) => (
                          <InputGroup>
                            <Label required={true}>Election Cutoff Date</Label>
                            <DatePicker
                              fullWidth={true}
                              name="electionCutoffDate"
                              onChange={(date) => {
                                form.setFieldValue("electionCutoffDate", date);
                              }}
                              selected={field.value}
                              dateFormat="MM/dd/yyyy"
                              placeholder="MM/DD/YYYY"
                            />
                          </InputGroup>
                        )}
                      </Field>
                      <Field name="dtcExpirationDate">
                        {({ field, form }: FieldProps<DetailsFormValues>) => (
                          <InputGroup>
                            <Label required={true}>DTC Expiration Date</Label>
                            <DatePicker
                              fullWidth={true}
                              name="dtcExpirationDate"
                              onChange={(date) => {
                                form.setFieldValue("dtcExpirationDate", date);
                              }}
                              selected={field.value}
                              dateFormat="MM/dd/yyyy"
                              placeholder="MM/DD/YYYY"
                            />
                          </InputGroup>
                        )}
                      </Field>
                    </div>
                    <Field name="type">
                      {({ field }: FieldProps<DetailsFormValues>) => (
                        <InputGroup>
                          <Label>Voluntary Corporate Action Type</Label>
                          <Input
                            disabled={true}
                            type="text"
                            value={
                              corporateActionTypeOptions.filter(
                                (i) => i.value === field.value
                              )[0]?.label
                            }
                          />
                        </InputGroup>
                      )}
                    </Field>
                    {values.type && (
                      <div className="border-t border-light-gray pt-2 mt-4">
                        {values.type === "OfferToPurchaseThirdPartyOffer" ? (
                          <Field name="offererParty">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>offerer Party</Label>
                                <Input type="text" {...field} />
                                {errors.offererParty && touched.offererParty ? (
                                  <FieldError>{errors.offererParty}</FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {(values.type === "OfferToPurchaseBuyBack" ||
                          values.type === "OfferToPurchaseThirdPartyOffer") &&
                        !values.price ? (
                          <Field name="bidIncrement">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Bid Increment</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {(values.type === "OfferToPurchaseBuyBack" ||
                          values.type === "OfferToPurchaseThirdPartyOffer") &&
                        !values.price ? (
                          <Field name="minimumBidPrice">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Minimum Bid Price</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {(values.type === "OfferToPurchaseBuyBack" ||
                          values.type === "OfferToPurchaseThirdPartyOffer") &&
                        !values.price ? (
                          <Field name="maximumBidPrice">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Maximum Bid Price</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {values.type === "ExchangeOffer" ? (
                          <Field name="contraCusip">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Contra Cusip</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {values.type === "ExchangeOffer" ? (
                          <Field name="ratio">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Ratio</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {values.type === "MergerConsiderationElection" ||
                        values.type === "ConsentSolicitation" ? (
                          <Field name="consentPayment">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>Consent Payment</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {values.type === "RightsIssue" ? (
                          <Field name="tradeSecurity">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>Trade Security</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {values.type === "RightsIssue" ? (
                          <Field name="outTurn">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>Out Turn</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {values.type === "OddLotOffer" ? (
                          <Field name="minimumQualifyingQuantity">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>
                                  Minimum Qualifying Quantity
                                </Label>
                                <Input type="text" {...field} />
                                {errors.minimumQualifyingQuantity &&
                                touched.minimumQualifyingQuantity ? (
                                  <FieldError>
                                    {errors.minimumQualifyingQuantity}
                                  </FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {values.type === "OddLotOffer" ? (
                          <Field name="maximumQualifyingQuantity">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>
                                  Maximum Qualifying Quantity
                                </Label>
                                <Input type="text" {...field} />
                                {errors.maximumQualifyingQuantity &&
                                touched.maximumQualifyingQuantity ? (
                                  <FieldError>
                                    {errors.maximumQualifyingQuantity}
                                  </FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {values.type === "OddLotOffer" ||
                        values.type === "OfferToPurchaseBuyBack" ||
                        values.type === "OfferToPurchaseThirdPartyOffer" ? (
                          <Field name="price">
                            {({
                              field,
                              form,
                            }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={values.type === "OddLotOffer"}>
                                  Price
                                </Label>
                                <Input
                                  type="text"
                                  {...field}
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    if (
                                      values.type ===
                                        "OfferToPurchaseBuyBack" ||
                                      values.type ===
                                        "OfferToPurchaseThirdPartyOffer"
                                    ) {
                                      form.setFieldValue("bidIncrement", "");
                                      form.setFieldValue("minimumBidPrice", "");
                                      form.setFieldValue("maximumBidPrice", "");
                                    }

                                    form.setFieldValue("price", value);
                                  }}
                                />
                                {errors.price && touched.price ? (
                                  <FieldError>{errors.price}</FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {values.type === "DividendOption" ? (
                          <Field name="frequency">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>Frequency</Label>
                                <Select options={frequencyOptions} {...field} />
                                {errors.frequency && touched.frequency ? (
                                  <FieldError>{errors.frequency}</FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {values.type === "DividendOption" ? (
                          <Field name="grossDividend">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label required={true}>Gross Dividend</Label>
                                <Input type="text" {...field} />
                                {errors.grossDividend &&
                                touched.grossDividend ? (
                                  <FieldError>
                                    {errors.grossDividend}
                                  </FieldError>
                                ) : null}
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        {values.type === "DividendReinvestmentPlan" ? (
                          <Field name="dripReinvestmentPrice">
                            {({ field }: FieldProps<DetailsFormValues>) => (
                              <InputGroup>
                                <Label>DRIP Reinvestment Price</Label>
                                <Input type="text" {...field} />
                              </InputGroup>
                            )}
                          </Field>
                        ) : null}
                        <Field name="electionTitle">
                          {({ field }: FieldProps<DetailsFormValues>) => (
                            <InputGroup>
                              <Label required={true}>Election Title</Label>
                              <Input type="text" {...field} />
                              {errors.electionTitle && touched.electionTitle ? (
                                <FieldError>{errors.electionTitle}</FieldError>
                              ) : null}
                            </InputGroup>
                          )}
                        </Field>
                        <Field name="electionDetails">
                          {({ field }: FieldProps<DetailsFormValues>) => (
                            <InputGroup>
                              <Label required={true}>Election Details</Label>
                              <TextareaAutosize minRows={3} {...field} />
                              {errors.electionDetails &&
                              touched.electionDetails ? (
                                <FieldError>
                                  {errors.electionDetails}
                                </FieldError>
                              ) : null}
                            </InputGroup>
                          )}
                        </Field>
                      </div>
                    )}
                    <Box display="flex" alignItems="start" marginTop={2}>
                      <Button
                        className="bg-periwinkle"
                        disableElevation={true}
                        disableRipple={true}
                        onClick={() => setIsConfirmDeleteDialogOpen(true)}
                        disabled={
                          corporateAction.operationsStatus === "approved" ||
                          corporateAction.operationsStatus === "closed"
                        }
                      >
                        Delete Filing
                      </Button>
                    </Box>
                  </div>
                </div>
                <div>
                  <div className="rounded border border-light-gray">
                    {showReport && vcaReport ? (
                      <React.Fragment>
                        <div className="py-4 px-8 block w-full border-b border-light-gray">
                          <h2 className="text-secondary-text font-medium text-xs uppercase">
                            Report
                          </h2>
                        </div>
                        <iframe
                          src={vcaReport.attachment}
                          className="h-screen w-full"
                          key={vcaReport.id}
                        />
                      </React.Fragment>
                    ) : (
                      <DetailsPageDocuments corporateActionId={id} />
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {isConfirmDeleteDialogOpen && (
          <ConfirmDeleteDialog
            onClose={() => setIsConfirmDeleteDialogOpen(false)}
            onConfirm={handleDeleteVca}
          />
        )}
      </>
    );
  }

  return null;
}

function InputGroup({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-col mt-2">{children}</div>;
}
