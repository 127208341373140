import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { useSolicitors } from "src/api/adapters/nexus/hooks/useSolicitors";
import { Solicitor } from "src/types/voteTransmission";

interface ISolicitorFieldProps {
  hasError?: boolean;
  label: string;
  isNoboEligible?: boolean;
  setSolicitor: (solicitor: Solicitor | null) => void;
  value: Solicitor | null;
}

export function SolicitorField({
  hasError,
  isNoboEligible,
  label,
  setSolicitor,
  value,
}: ISolicitorFieldProps) {
  const [searchTerm, setSearchTerm] = useState("");

  const { solicitors, isLoading } = useSolicitors(isNoboEligible);

  return (
    <Autocomplete
      multiple={false}
      options={solicitors || []}
      loading={isLoading}
      inputValue={searchTerm}
      getOptionLabel={(option) => option.name}
      onChange={(_, changeValue) => {
        return setSolicitor(changeValue);
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === "clear") {
          setSolicitor(null);
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ my: 2 }}
          error={hasError}
          InputLabelProps={{ shrink: true }}
          label={label}
          size="small"
        />
      )}
      value={value}
    />
  );
}
