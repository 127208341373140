import { COLORS } from "@asayinc/component-library";
import { SxProps } from "@mui/material";

import { OperationsStatus } from "src/types/nobo";
import { Chip } from "../atoms/Chip";

interface INoboStatusBadgeProps {
  status: OperationsStatus;
  sx?: SxProps;
}

export function NoboStatusBadge({ status, sx }: INoboStatusBadgeProps) {
  const getBorderColor = () => {
    switch (status) {
      case "new":
        return COLORS.MUTED_BLUE;
      case "approved":
        return COLORS.MUTED_GREEN;
      case "closed":
        return COLORS.MUTED_RED;
      case "converted_to_vote_transmission":
        return COLORS.MUTED_PURPLE;
    }
  };

  const getLabel = () => {
    switch (status) {
      case "new":
        return "New";
      case "approved":
        return "Approved";
      case "closed":
        return "Deleted";
      case "converted_to_vote_transmission":
        return "Converted to Vote Transmission";
    }
  };

  return (
    <Chip
      label={getLabel()}
      variant="outlined"
      size="small"
      sx={{ border: `1px solid ${getBorderColor()}`, ...sx }}
    />
  );
}
