import { toast } from "react-toastify";
import { sendTestVCAEmailWarrenG } from "src/api/adapters";
import { useVcaEmailPreview } from "src/api/adapters/nexus/hooks/useVcaEmailPreview";
import { VcaBroker } from "src/types/vca";
import { EmailPreviewPane } from "./EmailPreviewPane";

interface IVcaEmailPreviewPaneProps {
  broker: VcaBroker | null;
  vcaId: string;
}

export function VcaEmailPreviewPane({
  broker,
  vcaId,
}: IVcaEmailPreviewPaneProps) {
  const {
    preview,
    isLoading,
    isError: isEmailError,
  } = useVcaEmailPreview(vcaId, broker?.id || null);

  const handleSendTestEmail = async (emails: string[]) => {
    if (broker) {
      try {
        await sendTestVCAEmailWarrenG(vcaId, broker.id, { emails });
        toast.success("Test email sent!");
      } catch (e) {
        toast.error("An error occurred when trying to send a test email.");
      }
    }
  };

  return (
    <EmailPreviewPane
      brokerName={broker?.name}
      isLoading={isLoading}
      hasError={isEmailError}
      onSendTestEmail={handleSendTestEmail}
      preview={preview}
    />
  );
}
