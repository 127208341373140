import useSWR from "swr";

import { nexus } from "..";
import { Nobo } from "src/types/nobo";

const fetcher = (url: string) => nexus.get<Nobo>(url);

export function useNobo(id: string) {
  const url = `/nobo-events/${id}/`;
  const { data, error, mutate } = useSWR(url, fetcher);
  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    nobo: data?.data,
  };
}
