import { SxProps } from "@mui/material";

import { Box } from "../atoms/Box";
import { LoadingState } from "../LoadingState";

interface ILoadingCardProps {
  sx?: SxProps;
}

const LoadingCard = ({ sx }: ILoadingCardProps): JSX.Element => {
  return (
    <Box sx={{ display: "flex", flexGrow: 1, ...sx }}>
      <Box
        sx={{
          flexGrow: 1,
          border: "#e5e5e5 solid 1px",
          background: "#f8f8f8",
          px: 8,
          py: 64,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LoadingState />
      </Box>
    </Box>
  );
};

export { LoadingCard };
