const AlertIcon = ({ className }: { className?: string }): JSX.Element => (
  <div
    className={`bg-red text-white font-medium text-xxs h-3 w-3 rounded-full flex items-center justify-center ${
      className || ""
    }`}
  >
    !
  </div>
);

export { AlertIcon };
