import {
  TableSortLabel as MUITableSortLabel,
  TableSortLabelProps,
} from "@mui/material";

export const TableSortLabel = (props: TableSortLabelProps) => {
  return (
    <MUITableSortLabel
      {...props}
      sx={{
        flexDirection: "row-reverse",
        marginLeft: "-26px",
      }}
    >
      {props.children}
    </MUITableSortLabel>
  );
};
