import { TextField } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";

import { CreateCorporateActionForm } from "src/types/vca";

interface IExchangeOfferConditionsProps {
  form: UseFormReturn<CreateCorporateActionForm>;
}

export const ExchangeOfferConditions = ({
  form,
}: IExchangeOfferConditionsProps) => {
  const { control } = form;
  return (
    <Controller
      control={control}
      name="offer.conditions.contraCusip"
      rules={{
        required: true,
        maxLength: { value: 9, message: "Contra Cusip must be 9 digits" },
        minLength: { value: 9, message: "Contra Cusip must be 9 digits" },
      }}
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            sx={{ mb: 2 }}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            InputLabelProps={{ shrink: true }}
            fullWidth={true}
            label="Contra Cusip*"
            size="small"
          />
        );
      }}
    />
  );
};
