import { COLORS } from "@asayinc/component-library";
import { Checkbox, Link, TableCell, TableRow, Tooltip } from "@mui/material";
import { useCallback, useMemo } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box } from "src/components/atoms/Box";

import { CusipTagList } from "src/components/CusipTagList";
import { formatDate } from "src/utils/format-date";
import { formatNumber } from "src/utils/format-number";
import {
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from "../../batch-filings-context";
import { checkIfFilingHasTradeAlert } from "../../checkIfFilingHasTradeAlert";
import {
  tableBodyRow,
  tableBodyCellCheckbox,
  tableBodyCell,
} from "../../styles/tableStyles";
import { TradeAlertIndicator } from "../../TradeAlertIndicator";
import { FilingListFiling } from "../../types";
import { useTasks } from "../../useTasks";
import { getEventTypeLabel } from "../../utils";

interface IOpenTableRowProps {
  filing: FilingListFiling;
}

export function OpenTableRow({ filing }: IOpenTableRowProps) {
  const navigate = useNavigate();
  const { openProxyEvents: proxyEvents } = useTasks();
  const batchFilings = useBatchFilingsState();
  const dispatch = useBatchFilingsDispatch();

  const {
    id,
    legacyId,
    issuer,
    meetingDate,
    notes,
    recordDate,
    securities,
    stats,
    type,
  } = filing;

  const hasProxyEvent = useMemo(() => {
    return proxyEvents?.data?.data?.results
      ? checkIfFilingHasTradeAlert(String(id), proxyEvents.data.data.results)
      : false;
  }, [id, proxyEvents]);

  const handleRowClick = useCallback(() => {
    navigate(`/proxy/${legacyId}/email`);
  }, []);

  const handleSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
      if (event.target.checked) {
        dispatch({
          type: "SELECT_FILING",
          filing: id,
          path: location.pathname,
        });
      } else {
        dispatch({ type: "DESELECT_FILING", filing: id });
      }
    },
    []
  );

  return (
    <TableRow
      key={id}
      selected={batchFilings.filings.includes(id)}
      onClick={handleRowClick}
      hover={true}
      sx={tableBodyRow}
    >
      <TableCell
        sx={{
          ...tableBodyCellCheckbox,
          position: "sticky",
          left: 0,
          zIndex: 20,
          overflow: "visible",
          background: "white",
        }}
      >
        <Checkbox
          onChange={(event) => handleSelect(event, id)}
          checked={batchFilings.filings.includes(id)}
          onClick={(event) => event.stopPropagation()}
        />
      </TableCell>
      <TableCell
        sx={{
          ...tableBodyCell,
          position: "sticky",
          borderRight: `1px solid`,
          borderRightColor: "divider",
        }}
      >
        <Tooltip arrow title={issuer?.name || ""}>
          <Link
            to={`/issuers/${issuer?.id}`}
            component={RouterLink}
            underline="hover"
          >
            {issuer?.name}
          </Link>
        </Tooltip>
      </TableCell>
      <TableCell sx={tableBodyCell}>
        <CusipTagList cusips={securities.map((security) => security.cusip)} />
      </TableCell>
      <TableCell sx={tableBodyCell}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {hasProxyEvent && <TradeAlertIndicator />}
          {getEventTypeLabel(type)}
        </Box>
      </TableCell>
      <TableCell sx={tableBodyCell}>
        {recordDate ? formatDate(new Date(recordDate)) : ""}
      </TableCell>
      <TableCell sx={tableBodyCell}>
        {stats && stats.totalPositions
          ? formatNumber(stats.totalPositions)
          : "--"}
      </TableCell>
      <TableCell sx={tableBodyCell}>
        {meetingDate ? formatDate(new Date(meetingDate)) : ""}
      </TableCell>
      <TableCell sx={{ ...tableBodyCell }} align="right">
        <Box
          sx={{
            ":hover": { background: COLORS.ICE_BLUE },
            margin: "1px 13px 1px 11px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {notes}
        </Box>
      </TableCell>
    </TableRow>
  );
}
