import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useNobos } from "src/api/adapters/nexus/hooks/useNobos";
import { Avatar } from "src/components/atoms/Avatar";
import { Box } from "src/components/atoms/Box";
import { Tabs } from "src/components/atoms/Tabs";
import { PageHeader } from "src/components/molecules/PageHeader";
import { formatNumber } from "src/utils/format-number";
import { getTabFromUrl } from "src/utils/get-tab-from-url";

const DEFAULT_QUERY_PARAMS = {
  limit: 25,
  ordering: "-date_received",
  offset: 0,
};

export function TaskListContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { count: newCount } = useNobos("new", DEFAULT_QUERY_PARAMS);
  const { count: approvedCount } = useNobos("approved", DEFAULT_QUERY_PARAMS);
  const { count: deliveredCount } = useNobos("delivered", DEFAULT_QUERY_PARAMS);

  function onTabChange(value: string) {
    navigate(`/nobo/tasks/${value}`);
  }

  return (
    <>
      <PageHeader icon={<Avatar name="no" />} title="NOBO Request" />
      <Box
        id="NoboTabs"
        sx={{ pl: 6, borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs
          onChange={onTabChange}
          value={getTabFromUrl(location)}
          tabs={[
            {
              label: `New (${formatNumber(newCount || 0)})`,
              value: "new",
              testId: "NoboNewTab",
            },
            {
              label: `Approved (${formatNumber(approvedCount || 0)})`,
              value: "approved",
              testId: "NoboApprovedTab",
            },
            {
              label: `Delivered (${formatNumber(deliveredCount || 0)})`,
              value: "delivered",
              testId: "NoboDeliveredTab",
            },
          ]}
        />
      </Box>
      <Outlet />
    </>
  );
}
