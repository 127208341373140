import {
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  TextField,
} from "@mui/material";
import { FormikProps } from "formik";
import { FilingDetailsFormValues } from "../../../../types";

interface Props {
  formikBag: FormikProps<FilingDetailsFormValues>;
}

const CanShareholdersAttend = ({ formikBag }: Props) => {
  return (
    <>
      <Typography fontSize={14}>
        <b>Can shareholders attend this meeting?</b>
      </Typography>
      <RadioGroup
        aria-label="canAttendMeeting.enabled"
        name="canAttendMeeting.enabled"
        value={formikBag.values.canAttendMeeting.enabled}
      >
        {formikBag.errors.canAttendMeeting?.enabled ? (
          <Box
            sx={{
              color: "#d33f33",
              fontSize: ".75rem",
              margin: "3px 0 0 14px",
            }}
          >
            {formikBag.errors.canAttendMeeting?.enabled}
          </Box>
        ) : null}
        <div>
          <FormControlLabel
            value={"yes"}
            onChange={formikBag.handleChange}
            control={<Radio size="small" />}
            label={
              <Typography fontSize={14}>
                Yes, shareholders can attend
              </Typography>
            }
          />
        </div>
        <div>
          <FormControlLabel
            value={"no"}
            onChange={formikBag.handleChange}
            control={<Radio size="small" />}
            label={
              <Typography fontSize={14}>
                No, shareholders cannot attend
              </Typography>
            }
          />
        </div>
      </RadioGroup>
      {formikBag.values.canAttendMeeting.enabled === "no" && (
        <TextField
          size="small"
          fullWidth
          multiline
          rows={3}
          name={`canAttendMeeting.value`}
          id="cannot-attend-address"
          data-testid="cannot-attend-address"
          label="Meeting Location"
          inputProps={{ maxLength: 120 }}
          value={formikBag.values.canAttendMeeting.value}
          onChange={formikBag.handleChange}
          error={
            formikBag.touched.canAttendMeeting?.value &&
            Boolean(formikBag.errors.canAttendMeeting?.value)
          }
          helperText={
            formikBag.touched.canAttendMeeting?.value &&
            formikBag.errors.canAttendMeeting?.value
          }
        />
      )}
    </>
  );
};

export default CanShareholdersAttend;
