import { Box } from "src/components/atoms/Box";
import { formatDate } from "src/utils/format-date";
import { FilingListFiling } from "./types";

export const getMeetingDate = (filing: FilingListFiling) => {
  switch (filing.type) {
    case "MeetingContest":
    case "FundMeeting":
    case "FirmMeeting":
      return filing.meetingDate ? formatDate(new Date(filing.meetingDate)) : "";
    default:
      return "N/A";
  }
};

export const formatDeadline = (internalDigitalSendDeadline: string) => {
  const date = new Date(internalDigitalSendDeadline);
  const deadlineTime = date.getTime();
  const currentTime = new Date().getTime();
  return (
    <Box color={deadlineTime <= currentTime ? "error.main" : ""}>
      {formatDate(date)}
    </Box>
  );
};

export const getEventTypeLabel = (type: string) => {
  switch (type) {
    case "contested_annual_meeting":
      return "Contested Annual Meeting";
    case "contested_special_meeting":
      return "Contested Special Meeting";
    case "annual_meeting":
      return "Annual Meeting";
    case "special_meeting":
      return "Special Meeting";
    default:
      return "-";
  }
};
