import { FaTimesCircle } from "react-icons/fa";

type Props = {
  onClose: (value: unknown) => void;
  onConfirm: (value: unknown) => void;
  document?: boolean;
};

function ConfirmDeleteDialog({
  onClose,
  onConfirm,
  document = false,
}: Props): JSX.Element {
  return (
    <div
      className="bg-overlay fixed top-0 right-0 left-0 bottom-0 flex items-start justify-center"
      style={{ zIndex: 10000 }}
    >
      <div className="bg-white rounded shadow overflow-hidden mt-32">
        <header className="bg-light-gray relative px-8 py-6">
          <span className="font-medium text-primary-text text-center block">
            Delete {document ? "document" : "filing"}
          </span>
          <button
            className="absolute text-gray"
            style={{ right: "1rem", top: "1rem" }}
            onClick={onClose}
          >
            <FaTimesCircle />
          </button>
        </header>

        <div className="p-8 flex flex-col items-center">
          <p className="text-primary-text text-center m-0">
            Are you sure you want to delete this{" "}
            {document ? "document" : "voluntary corporate action"}?
          </p>

          <button
            className="mt-8 h-10 rounded-full border border-gray text-primary-text font-medium text-sm w-32 hover:bg-primary-text hover:border-primary-text hover:text-white duration-300 transition-color"
            onClick={onConfirm}
          >
            Yes
          </button>

          <button
            className="mt-4 text-sm font-medium text-primary-text"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}

export { ConfirmDeleteDialog };
