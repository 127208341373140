import { Location } from "history";

function getTabFromUrl(location: Location): string {
  if (location.pathname.includes("tasks")) {
    const urlParts = location.pathname.split("/");
    const tab = urlParts[urlParts.length - 1];

    return tab;
  } else {
    const urlParts = location.pathname.split("/").filter((part) => part !== "");

    // check to see if the url is /proxy/:id
    // if it is, return empty string to match tab
    if (urlParts.length === 2) {
      return "";
    } else {
      return urlParts[urlParts.length - 1];
    }
  }
}
export { getTabFromUrl };
