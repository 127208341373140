import { camelize } from "humps";
import { IApiMeetingFormat, IMeetingFormatType } from "../../../../types";

/**
 * Convert api response to a format the formikBag can understand
 */
export const initiateMeetingFormat = (formats?: IApiMeetingFormat[]) => {
  // Default all data empty
  const meetingFormats = {
    inPerson: {
      canVote: "",
      enabled: false,
      value: "",
    },
    virtual: {
      canVote: "",
      enabled: false,
      value: "",
    },
    telephone: {
      canVote: "",
      enabled: false,
      value: "",
    },
  };
  const canAttendMeeting = {
    enabled: "",
    value: "",
  };

  if (formats && formats.length) {
    // if api returns formats, reduce the data into a format the formikBag can process
    const updatedMeetingData = formats.reduce(
      (acc, curr) => {
        if (curr.format === "private") {
          acc.canAttendMeeting.enabled = "no";
          acc.canAttendMeeting.value = curr.value;
          return acc;
        }
        acc.meetingFormats[camelize(curr.format) as IMeetingFormatType] = {
          canVote: curr.canVote ? "yes" : "no", // radio buttons need to be strings
          enabled: true,
          value: curr.value,
        };
        acc.canAttendMeeting.enabled = "yes";
        return acc;
      },
      {
        meetingFormats,
        canAttendMeeting,
      }
    );
    return updatedMeetingData;
  }

  return { meetingFormats, canAttendMeeting };
};
