import React from "react";
import { OrderingKey } from "./useTasks";

type UseTableOrderingReturnType = {
  direction: "asc" | "desc";
  getIsActive: (orderingKey: OrderingKey) => boolean;
};

function useTableOrdering(
  currentOrdering: OrderingKey
): UseTableOrderingReturnType {
  const direction: "asc" | "desc" = React.useMemo(() => {
    if (currentOrdering.startsWith("-")) {
      return "desc";
    }

    return "asc";
  }, [currentOrdering]);

  function getIsActive(orderingKey: OrderingKey) {
    return currentOrdering.includes(orderingKey);
  }

  return {
    direction,
    getIsActive,
  };
}

export { useTableOrdering };
