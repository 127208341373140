import { createTheme, adaptV4Theme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Chip from "@mui/material/Chip";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  ThemeProvider,
  StyledEngineProvider,
  PaperProps,
  TableRowProps,
  TableCellProps,
  TableProps,
  Box,
} from "@mui/material";
import { COLORS } from "@asayinc/component-library";
import React from "react";

import {
  CorporateAction,
  CorporateActionFiling,
  // MeetingResult,
  Proxy,
  ProxyFiling,
  Search,
  Prospectus,
} from "src/types/search";
import { ReactComponent as CorporateActionIcon } from "src/assets/icons/corporate-action.svg";
import { ReactComponent as ProxyIcon } from "src/assets/icons/proxy.svg";
import { ReactComponent as ProspectusIcon } from "src/assets/icons/prospectus.svg";
// import { ReactComponent as ResultsIcon } from './results.svg';
import { ReactComponent as IssuerIcon } from "src/assets/icons/issuers.svg";
import { useNavigate } from "react-router-dom";
import { humanize } from "src/utils/humanize";
import { formatDate } from "src/utils/format-date";

type SearchResultsProps = {
  className?: string;
  style?: React.CSSProperties;
  isLoading: boolean;
  isError: boolean;
  results: Search | null;
  onClear: () => void;
};

const theme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiTableCell: {
        root: {
          padding: "16px 14px",
        },
        sizeSmall: {
          padding: "6px 12px",
        },
      },
    },
  })
);

type Product =
  | "filings"
  // | 'meetingResults'
  | "tasks"
  | "corporateActions"
  | "issuers";

const PaperContainer = (props: PaperProps) => (
  <Paper
    elevation={0}
    sx={{
      padding: "16px 80px",
      backgroundColor: "#FFFFFF",
      borderBottom: "1px solid #e5e5e5",
      boxShadow:
        "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);0 5px 4px -4px rgba(0,0,0,0.5)",
    }}
    {...props}
  >
    {props.children}
  </Paper>
);

const Row = (props: TableRowProps) => (
  <TableRow
    sx={{
      "&:hover": {
        background: "#e5e5e5",
        cursor: "pointer",
      },
    }}
    {...props}
  >
    {props.children}
  </TableRow>
);

const BlankCell = (props: TableCellProps) => (
  <TableCell
    sx={{
      border: 0,
      width: "12px",
    }}
  >
    {props.children}
  </TableCell>
);

const ResultsTable = (props: TableProps) => (
  <Table
    sx={{
      marginLeft: "128px",
      overflow: "auto",
      borderTop: "1px solid #e5e5e5",
      width: "calc(100% - 128px)",
    }}
    {...props}
  >
    {props.children}
  </Table>
);

const SearchResults = ({
  isLoading,
  isError,
  results,
  className = "",
  style = {},
  onClear,
}: SearchResultsProps): JSX.Element => {
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(
    null
  );
  const handleSelectProduct = (product: Product) => setSelectedProduct(product);
  const handleBackClick = () => setSelectedProduct(null);
  const handleClose = () => {
    onClear();
    setSelectedProduct(null);
  };
  function handleTableRowClick(path: string) {
    return function (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
      if (e.metaKey) {
        window.open(path, "_blank");
        return;
      }
      navigate(path);
      handleClose();
    };
  }

  if (isLoading) return <PaperContainer>Loading...</PaperContainer>;

  if (isError) return <PaperContainer>Error</PaperContainer>;

  if (results && !selectedProduct) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Table
            id="SearchResultContainer"
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow:
                "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);0 5px 4px -4px rgba(0,0,0,0.5)",
            }}
            size="small"
          >
            <TableBody>
              <Row
                id={"ProxyResult"}
                onClick={() =>
                  results.filings.reduce(
                    (acc, curr) => acc + curr.results.length,
                    0
                  ) > 0
                    ? handleSelectProduct("filings")
                    : null
                }
              >
                <BlankCell>
                  <IconButton disabled size="large">
                    <ProxyIcon />{" "}
                  </IconButton>
                </BlankCell>
                <TableCell>
                  {results.filings.reduce(
                    (acc, curr) => acc + curr.results.length,
                    0
                  )}{" "}
                  filings
                </TableCell>
              </Row>

              <Row
                id={"ProspectusResult"}
                onClick={() =>
                  results.tasks.length ? handleSelectProduct("tasks") : null
                }
              >
                <BlankCell>
                  <IconButton disabled size="large">
                    <ProspectusIcon />
                  </IconButton>
                </BlankCell>
                <TableCell>{results.tasks.length} tasks</TableCell>
              </Row>
              <Row
                onClick={() =>
                  results.corporateActions.reduce(
                    (acc, curr) => acc + curr.results.length,
                    0
                  )
                    ? handleSelectProduct("corporateActions")
                    : null
                }
              >
                <BlankCell>
                  <IconButton
                    disabled
                    id={"CorporateActionResult"}
                    size="large"
                  >
                    <CorporateActionIcon />
                  </IconButton>
                </BlankCell>
                <TableCell>
                  {results.corporateActions.reduce(
                    (acc, curr) => acc + curr.results.length,
                    0
                  )}{" "}
                  filings
                </TableCell>
              </Row>
              {/* hiding meeting results routes until it can be restyled */}
              {/* <TableRow
                id={'MeetingResult'}
                className={classes.rowStyle}
                onClick={() =>
                  results.meetingResults.reduce(
                    (acc, curr) => acc + curr.results.length,
                    0,
                  )
                    ? handleSelectProduct('meetingResults')
                    : null
                }
              >
                <TableCell className={classes.blankCell}>
                  <IconButton disabled>
                    <ResultsIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {results.meetingResults.reduce(
                    (acc, curr) => acc + curr.results.length,
                    0,
                  )}{' '}
                  meeting results
                </TableCell>
              </TableRow> */}
              <Row
                id={"IssuersResult"}
                onClick={() =>
                  results.issuers.length ? handleSelectProduct("issuers") : null
                }
              >
                <BlankCell>
                  <IconButton disabled size="large">
                    <IssuerIcon />
                  </IconButton>
                </BlankCell>
                <TableCell style={{ border: 0 }}>
                  {results.issuers.length} issuers
                </TableCell>
              </Row>
            </TableBody>
          </Table>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  if (results && selectedProduct) {
    switch (selectedProduct) {
      case "filings":
        return (
          <ProxyIssuers
            issuers={results.filings}
            className={className}
            style={style}
            onBackClick={handleBackClick}
            onClose={handleClose}
          />
        );
      case "corporateActions":
        return (
          <CorporateActionIssuers
            issuers={results.corporateActions}
            className={className}
            style={style}
            onBackClick={handleBackClick}
            onClose={handleClose}
          />
        );
      // case 'meetingResults':
      //   return (
      //     <MeetingResults
      //       issuers={results.meetingResults}
      //       className={className}
      //       style={style}
      //       onBackClick={handleBackClick}
      //       onClose={handleClose}
      //     />
      //   );
      case "issuers":
        return (
          <SelectedView
            onBackClick={handleBackClick}
            className={className}
            style={style}
            icon={<IssuerIcon />}
            title={`${results.issuers.length} issuers`}
            onClose={handleClose}
          >
            <ResultsTable id="SelectedResults">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className={tableHeadTitleStyles}>Issuers</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.issuers.map((issuer) => (
                  <Row
                    key={issuer.id}
                    onClick={handleTableRowClick(`/issuers/${issuer.id}`)}
                  >
                    <TableCell>
                      <span>{issuer.name}</span>
                    </TableCell>
                  </Row>
                ))}
                <TableRow>
                  <TableCell
                    style={{ maxHeight: "20px", border: 0 }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </ResultsTable>
          </SelectedView>
        );
      case "tasks":
        type parsedTask = {
          cusip: string;
          minDate: string;
        };

        const parseTasks = (prospectus: Prospectus): parsedTask => {
          let cusip = "";
          let minDate = "9999";
          for (let j = 0; j < prospectus["statuses"].length; j++) {
            if (prospectus["statuses"][j] === "missing") {
              cusip = prospectus["cusips"][j];
              break;
            } else if (minDate > prospectus["expectedDates"][j]) {
              minDate = prospectus["expectedDates"][j];
              cusip = prospectus["cusips"][j];
            }
          }
          return { cusip, minDate };
        };

        return (
          <SelectedView
            onBackClick={handleBackClick}
            className={className}
            style={style}
            icon={<ProspectusIcon />}
            title={`${results.tasks.length} tasks`}
            onClose={handleClose}
          >
            <ResultsTable id="SelectedResults">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className={tableHeadTitleStyles}>Issuer</span>
                  </TableCell>
                  <TableCell>
                    <span className={tableHeadTitleStyles}>CUSIP</span>
                  </TableCell>
                  <TableCell>
                    <span className={tableHeadTitleStyles}>Expected Date</span>
                  </TableCell>
                  <TableCell>
                    <span className={tableHeadTitleStyles}>Status</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.tasks.map((prospectus, index) => (
                  <Row
                    key={`${prospectus.issuerId}-${index}`}
                    onClick={handleTableRowClick(
                      `/prospectuses/missing-expired?id=${prospectus.issuerId}`
                    )}
                  >
                    <TableCell>{prospectus.issuerName}</TableCell>
                    <TableCell>{parseTasks(prospectus).cusip}</TableCell>
                    <TableCell>
                      {!prospectus.statuses.includes("missing")
                        ? formatDate(new Date(parseTasks(prospectus).minDate))
                        : null}
                    </TableCell>
                    <TableCell>
                      <span className="capitalize">
                        {prospectus.statuses.includes("missing")
                          ? "Missing"
                          : null}
                      </span>
                    </TableCell>
                  </Row>
                ))}
                <TableRow>
                  <TableCell
                    style={{ maxHeight: "20px", border: 0 }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </ResultsTable>
          </SelectedView>
        );
    }
  }

  return (
    <PaperContainer>Unable to retrieve results for your query.</PaperContainer>
  );
};

const tableHeadTitleStyles = `uppercase text-xxs text-secondary-text font-medium`;

function ProxyIssuers({
  issuers,
  onBackClick,
  className,
  style,
  onClose,
}: {
  issuers: Proxy[];
  onBackClick: () => void;
  className: string;
  style: React.CSSProperties;
  onClose: () => void;
}) {
  const [selectedIssuer, setSelectedIssuer] = React.useState<string>("");
  const navigate = useNavigate();
  const handleSelectIssuer = (id: string) => setSelectedIssuer(id);
  const handleBackToIssuersClick = () => setSelectedIssuer("");

  function handleTableRowClick(path: string) {
    return function (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
      if (e.metaKey) {
        window.open(path, "_blank");
        return;
      }
      navigate(path);
      onClose();
    };
  }

  const issuer = React.useMemo(
    () => issuers.find((issuer) => issuer.id === selectedIssuer),
    [selectedIssuer, issuers]
  );

  const totalNumberOfFilings = issuers.reduce(
    (acc, curr) => acc + curr.results.length,
    0
  );

  const newFiling = issuer ? filterAndSortByStatus(issuer.results, "new") : [];
  const draft = issuer ? filterAndSortByStatus(issuer.results, "draft") : [];
  const active = issuer ? filterAndSortByStatus(issuer.results, "active") : [];
  const materialsRequested = issuer
    ? filterAndSortByStatus(issuer.results, "materials_requested")
    : [];
  const readyForReview = issuer
    ? filterAndSortByStatus(issuer.results, "ready_for_review")
    : [];
  const approved = issuer
    ? filterAndSortByStatus(issuer.results, "approved")
    : [];
  const closed = issuer ? filterAndSortByStatus(issuer.results, "closed") : [];

  const filings: ProxyFiling[] = [
    ...newFiling,
    ...draft,
    ...active,
    ...materialsRequested,
    ...readyForReview,
    ...approved,
    ...closed,
  ];

  if (selectedIssuer && issuer) {
    return (
      <SelectedView
        onBackClick={handleBackToIssuersClick}
        className={className}
        style={style}
        icon={<ProxyIcon />}
        title={issuer.name}
        onClose={onClose}
      >
        <ResultsTable id="SelectedResults">
          <TableHead>
            <TableRow>
              <TableCell>
                <span className={tableHeadTitleStyles}>State</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Filing Type</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Record Date</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Status</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filings.map((filing) => (
              <Row
                key={filing.id}
                onClick={handleTableRowClick(`/proxy/${filing.id}`)}
              >
                <TableCell>
                  <StateTag status={filing.status} />
                </TableCell>
                <TableCell>{filing.type}</TableCell>

                <TableCell>{formatDate(new Date(filing.recordDate))}</TableCell>

                <TableCell>{filing.status}</TableCell>
              </Row>
            ))}
            <TableRow>
              <TableCell style={{ maxHeight: "20px", border: 0 }}></TableCell>
            </TableRow>
          </TableBody>
        </ResultsTable>
      </SelectedView>
    );
  }
  return (
    <SelectedView
      onBackClick={onBackClick}
      className={className}
      style={style}
      icon={<ProxyIcon />}
      title={`${totalNumberOfFilings} filings`}
      onClose={onClose}
    >
      <ResultsTable id="SelectedResults">
        <TableHead>
          <TableRow>
            <TableCell>
              <span className={tableHeadTitleStyles}>Issuers</span>
            </TableCell>
            <TableCell>
              <span className={tableHeadTitleStyles}>Total Filings</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {issuers.map((issuer) =>
            issuer.results.length ? (
              <Row
                key={issuer.id}
                onClick={() => handleSelectIssuer(issuer.id)}
              >
                <TableCell>{issuer.name}</TableCell>
                <TableCell>{issuer.results.length}</TableCell>
              </Row>
            ) : null
          )}
          <TableRow>
            <TableCell style={{ maxHeight: "20px", border: 0 }}></TableCell>
          </TableRow>
        </TableBody>
      </ResultsTable>
    </SelectedView>
  );
}

function CorporateActionIssuers({
  issuers,
  onBackClick,
  className,
  style,
  onClose,
}: {
  issuers: CorporateAction[];
  onBackClick: () => void;
  className: string;
  style: React.CSSProperties;
  onClose: () => void;
}) {
  const [selectedIssuer, setSelectedIssuer] = React.useState<string>("");
  const navigate = useNavigate();
  const handleSelectIssuer = (id: string) => setSelectedIssuer(id);
  const handleBackToIssuersClick = () => setSelectedIssuer("");
  function handleTableRowClick(path: string) {
    return function (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
      if (e.metaKey) {
        window.open(path, "_blank");
        return;
      }
      navigate(path);
      onClose();
    };
  }

  const issuer = React.useMemo(
    () => issuers.find((issuer) => issuer.id === selectedIssuer),
    [selectedIssuer, issuers]
  );

  const totalNumberOfFilings = issuers.reduce(
    (acc, curr) => acc + curr.results.length,
    0
  );

  const newFiling = issuer ? filterAndSortByStatus(issuer.results, "new") : [];
  const open = issuer ? filterAndSortByStatus(issuer.results, "open") : [];
  const active = issuer ? filterAndSortByStatus(issuer.results, "active") : [];
  const materialsRequested = issuer
    ? filterAndSortByStatus(issuer.results, "materials_requested")
    : [];
  const readyForReview = issuer
    ? filterAndSortByStatus(issuer.results, "ready_for_review")
    : [];
  const approved = issuer
    ? filterAndSortByStatus(issuer.results, "approved")
    : [];
  const closed = issuer ? filterAndSortByStatus(issuer.results, "closed") : [];

  const filings: CorporateActionFiling[] = [
    ...newFiling,
    ...open,
    ...active,
    ...materialsRequested,
    ...readyForReview,
    ...approved,
    ...closed,
  ];

  if (selectedIssuer && issuer) {
    return (
      <SelectedView
        onBackClick={handleBackToIssuersClick}
        className={className}
        style={style}
        icon={<CorporateActionIcon />}
        title={issuer.name}
        onClose={onClose}
      >
        <ResultsTable id="SelectedResults">
          <TableHead>
            <TableRow>
              <TableCell>
                <span className={tableHeadTitleStyles}>State</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Filing Type</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Record Date</span>
              </TableCell>
              <TableCell>
                <span className={tableHeadTitleStyles}>Status</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filings.map((filing) => (
              <Row
                key={filing.id}
                onClick={handleTableRowClick(`/corporate-actions/${filing.id}`)}
              >
                <TableCell>
                  <StateTag status={filing.status} />
                </TableCell>
                <TableCell>{filing.type}</TableCell>
                <TableCell>{formatDate(new Date(filing.recordDate))}</TableCell>
                <TableCell>{humanize(filing.status)}</TableCell>
              </Row>
            ))}

            <TableRow>
              <TableCell style={{ maxHeight: "20px", border: 0 }}></TableCell>
            </TableRow>
          </TableBody>
        </ResultsTable>
      </SelectedView>
    );
  }

  return (
    <SelectedView
      onBackClick={onBackClick}
      className={className}
      style={style}
      icon={<CorporateActionIcon />}
      title={`${totalNumberOfFilings} filings`}
      onClose={onClose}
    >
      <ResultsTable id="SelectedResults">
        <TableHead>
          <TableRow>
            <TableCell>
              <span className={tableHeadTitleStyles}>Issuers</span>
            </TableCell>
            <TableCell>
              <span className={tableHeadTitleStyles}>Total Filings</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {issuers.map((issuer) =>
            issuer.results.length ? (
              <Row
                key={issuer.id}
                onClick={() => handleSelectIssuer(issuer.id)}
              >
                <TableCell>{issuer.name}</TableCell>
                <TableCell>{issuer.results.length}</TableCell>
              </Row>
            ) : null
          )}
          <TableRow>
            <TableCell style={{ maxHeight: "20px", border: 0 }}></TableCell>
          </TableRow>
        </TableBody>
      </ResultsTable>
    </SelectedView>
  );
}

// function MeetingResults({
//   issuers,
//   onBackClick,
//   className,
//   style,
//   onClose,
// }: {
//   issuers: MeetingResult[];
//   onBackClick: () => void;
//   className: string;
//   style: React.CSSProperties;
//   onClose: () => void;
// }) {
//   const [selectedIssuer, setSelectedIssuer] = React.useState<string>('');
//   const navigate = useNavigate();
//   const handleSelectIssuer = (id: string) => setSelectedIssuer(id);
//   const handleBackToIssuersClick = () => setSelectedIssuer('');

//   function handleTableRowClick(path: string) {
//     return function (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
//       if (e.metaKey) {
//         window.open(path, '_blank');
//         return;
//       }
//       navigate(path);
//       onClose();
//     };
//   }

//   const issuer = React.useMemo(
//     () => issuers.find((issuer) => issuer.id === selectedIssuer),
//     [selectedIssuer, issuers],
//   );

//   const totalNumberOfFilings = issuers.reduce(
//     (acc, curr) => acc + curr.results.length,
//     0,
//   );

//   if (selectedIssuer && issuer) {
//     return (
//       <SelectedView
//         onBackClick={handleBackToIssuersClick}
//         className={className}
//         style={style}
//         icon={<ResultsIcon />}
//         title={issuer.name}
//         onClose={onClose}
//       >
//         <Table id="SelectedResults" className={classes.resultsTable}>
//           <TableHead>
//             <TableRow>
//               <TableCell>
//                 {' '}
//                 <span className={tableHeadTitleStyles}>Status</span>
//               </TableCell>
//               <TableCell>
//                 {' '}
//                 <span className={tableHeadTitleStyles}>Filing Date</span>
//               </TableCell>
//             </TableRow>
//           </TableHead>{' '}
//           <TableBody>
//             {issuer.results.map((filing) => (
//               <TableRow
//                 key={filing.id}
//                 className={classes.rowStyle}
//                 onClick={handleTableRowClick(`/old/results/all/${filing.id}`)}
//               >
//                 <TableCell>{humanize(filing.status)}</TableCell>
//                 <TableCell>{formatDate(new Date(filing.filingDate))}</TableCell>
//               </TableRow>
//             ))}
//             <TableCell></TableCell>
//             <TableRow>
//               <TableCell style={{ maxHeight: '20px', border: 0 }}></TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//       </SelectedView>
//     );
//   }

//   return (
//     <SelectedView
//       onBackClick={onBackClick}
//       className={className}
//       style={style}
//       icon={<ResultsIcon />}
//       title={`${totalNumberOfFilings} meeting results`}
//       onClose={onClose}
//     >
//       <Table id="SelectedResults" className={classes.resultsTable}>
//         <TableHead>
//           <TableRow>
//             <TableCell>
//               {' '}
//               <span className={tableHeadTitleStyles}>Issuers</span>
//             </TableCell>
//             <TableCell>
//               {' '}
//               <span className={tableHeadTitleStyles}>Total Filings</span>
//             </TableCell>
//           </TableRow>
//         </TableHead>{' '}
//         <TableBody>
//           {issuers.map((issuer) =>
//             issuer.results.length ? (
//               <TableRow
//                 key={issuer.id}
//                 className={classes.rowStyle}
//                 onClick={() => handleSelectIssuer(issuer.id)}
//               >
//                 <TableCell>{issuer.name}</TableCell>
//                 <TableCell>{issuer.results.length}</TableCell>
//               </TableRow>
//             ) : null,
//           )}
//           <TableRow>
//             <TableCell style={{ maxHeight: '20px', border: 0 }}></TableCell>
//           </TableRow>
//         </TableBody>
//       </Table>
//     </SelectedView>
//   );
// }

function SelectedView({
  onBackClick,
  children,
  icon,
  title,
}: {
  onBackClick: () => void;
  children: React.ReactNode;
  style: React.CSSProperties;
  className: string;
  icon: React.ReactNode;
  title: string;
  onClose: () => void;
}) {
  return (
    <Paper
      sx={{
        backgroundColor: "#FFFFFF",
        boxShadow:
          "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);0 5px 4px -4px rgba(0,0,0,0.5)",
      }}
    >
      <Box
        id="SearchSelectedTitle"
        sx={{
          py: 1,
          px: 3,
          background: "#FFF",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          size="medium"
          id="ResultBackButton"
          onClick={onBackClick}
          edge="start"
          style={{ marginRight: "20px" }}
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          edge="start"
          disabled
          style={{ marginRight: "20px" }}
          size="large"
        >
          {icon}
        </IconButton>
        {title}
      </Box>
      {children}
    </Paper>
  );
}

function StateTag({ status }: { status: string }) {
  switch (status) {
    case "approved":
    case "closed":
      return (
        <Chip
          style={{ backgroundColor: "#e5e5e5" }}
          size="small"
          label="Closed"
        />
      );
    default:
      return (
        <Chip
          style={{
            border: `1px solid ${COLORS.BRIGHT_GREEN}`,
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          size="small"
          label="Open"
        />
      );
  }
}

function filterAndSortByStatus(
  filings: ProxyFiling[] | CorporateActionFiling[],
  status: string
) {
  return filings
    .filter((filing) => filing.status === status)
    .sort(
      (a, b) =>
        new Date(b.recordDate).getTime() - new Date(a.recordDate).getTime()
    );
}

export { SearchResults };
