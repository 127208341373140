import { useParams } from "react-router-dom";
import { useState } from "react";

import { LoadingState } from "../../../components/LoadingState";
import { PageWrapper } from "../../../components/PageWrapper";
import { Prospectus, Security } from "../../../types/common";
import { useIssuer } from "src/api/adapters/hub/hooks/useIssuer";
import { InvoicingContactDialog } from "src/components/InvoicingContactDialog/InvoicingContactDialog";
import { IssuerInvocingDetails } from "src/components/organisms/IssuerInvoicingDetails";

export interface Issuer {
  cik: null;
  fiscalYearEnd: Date;
  fundOwner: null;
  id: string;
  invoicingAddress: InvoicingAddress;
  issuerType: string;
  name: string;
  prospectuses: Prospectuses;
  securities: Security[];
  stats: Stats;
}

export type InvoicingAddress = {
  address: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  notes: string;
  email: string;
};

type Prospectuses = {
  active: Prospectus[];
  upcoming: Prospectus[];
  archived: Prospectus[];
};

type Stats = {
  monthlyTrades: number;
};

type InvoicingPage = {
  id: string;
};

export function Invoicing() {
  const { id } = useParams() as InvoicingPage;
  const { issuer, isError, isLoading, mutate } = useIssuer(id);
  const [isEditContactDialogOpen, setIsEditContactDialogOpen] = useState(false);

  const handleOpenContactDialog = () => {
    setIsEditContactDialogOpen(true);
  };

  const handleCloseContactDialog = () => {
    setIsEditContactDialogOpen(false);
  };

  const handleOnUpdate = () => {
    mutate();
  };

  if (isLoading) {
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );
  }

  if (isError || !issuer) {
    return <PageWrapper>Error...</PageWrapper>;
  }

  return (
    <PageWrapper>
      <IssuerInvocingDetails
        issuer={issuer}
        onContactEdit={handleOpenContactDialog}
      />
      <InvoicingContactDialog
        isOpen={isEditContactDialogOpen}
        issuer={issuer}
        onClose={handleCloseContactDialog}
        onUpdate={handleOnUpdate}
      />
    </PageWrapper>
  );
}
