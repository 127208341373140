import { Route, Routes } from "react-router-dom";
import { Redirect } from "src/components/Redirect";
import { NoboDetails } from "src/pages/nobo/details";
import { TaskListContainer } from "src/pages/nobo/tasks";
import { NoboTable } from "src/pages/nobo/tasks/NoboTable";

export function Nobo() {
  return (
    <Routes>
      <Route path=":id/*" element={<NoboDetails />} />
      <Route path="tasks" element={<TaskListContainer />}>
        <Route
          path="new"
          element={<NoboTable key="nobo-new-table" listType="new" />}
        />
        <Route
          path="approved"
          element={
            <NoboTable
              key="nobo-approved-table"
              listType="approved"
              defaultOrdering="-record_date"
            />
          }
        />
        <Route
          path="delivered"
          element={
            <NoboTable
              key="nobo-delivered-table"
              listType="delivered"
              defaultOrdering="-nobo_file_last_delivered"
            />
          }
        />
      </Route>
      <Route path="/" element={<Redirect to="tasks/new" />} />
    </Routes>
  );
}
