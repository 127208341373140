import { AxiosRequestConfig } from "axios";
import useSWR from "swr";

import { nexus } from "..";
import { IQueryParams } from "../../types";
import { PaginatedResponse } from "src/types/PaginatedResponse";
import { AdditionalMailingBroker } from "src/types/additionalMailing";

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) =>
  nexus.get<PaginatedResponse<AdditionalMailingBroker>>(url, { params });

export function useAdditionalMailingEligibleBrokers(
  proxyEventId: string,
  additionalMailingId: string
) {
  const url = `proxy-events/${proxyEventId}/additional-mailings/${additionalMailingId}/eligible-brokers/`;
  const { data, error } = useSWR(
    proxyEventId && additionalMailingId ? url : null,
    fetcher
  );

  return {
    count: data?.data?.count,
    isError: error,
    isLoading: !error && !data,
    eligibleBrokers: data?.data?.results,
  };
}
