import * as Yup from "yup";
import { OperationsStatus, FilingType } from "./types";

const draftValidationSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  issuer: Yup.object().required("Issuer is required"),
  cusips: Yup.array()
    .required("At least one CUSIP is required")
    .min(1, "At least one CUSIP is required"),
  brokerSearchReceivedDate: Yup.string()
    .required("Broker Search Date cannot be blank")
    .nullable(),
  recordDate: Yup.string().required("Record Date cannot be blank").nullable(),
  solicitor: Yup.object().required("You must include a solicitor"),
  replyTo: Yup.array()
    .of(Yup.object())
    .min(1, "At least one contact is required"),
  deliveryMethod: Yup.string().required("Delivery Method cannot be blank"),
  contestingEntity: Yup.string().when("type", {
    is: (val: string) => val === "MeetingContest",
    then: Yup.string().required("Please include the the contesting entity"),
    otherwise: Yup.string().nullable(),
  }),
  issuerFiling: Yup.string().when("type", {
    is: (val: string) => val === "MeetingContest",
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(),
  }),
  minimumShares: Yup.number().when("type", {
    is: (val: string) => val === "MeetingContest",
    then: Yup.number().required().min(0, "Minimum shares cannot be negative"),
    otherwise: Yup.number().nullable(),
  }),
  canAttendMeeting: Yup.object({
    value: Yup.string().when("enabled", {
      is: "no",
      then: Yup.string()
        .required("Meeting location is required")
        .max(120, "Max of 120 characters"),
    }),
    enabled: Yup.string(),
  }),
  // If and only when shareholders can attend the meeting, run validation on meetingFormat fields
  meetingFormats: Yup.object().when("canAttendMeeting.enabled", {
    is: "yes",
    then: Yup.object({
      inPerson: Yup.object({
        value: Yup.string().when("enabled", {
          is: true,
          then: Yup.string()
            .required("Address is required")
            .max(256, "Max of 256 characters"),
        }),
        canVote: Yup.string().when("enabled", {
          is: true,
          then: Yup.string().required("Must select if shareholders can vote"),
        }),
        enabled: Yup.boolean(),
      }),
      virtual: Yup.object({
        value: Yup.string().when("enabled", {
          is: true,
          then: Yup.string()
            .required("URL Address is required")
            .max(256, "Max of 256 characters"),
        }),
        canVote: Yup.string().when("enabled", {
          is: true,
          then: Yup.string().required("Must select if shareholders can vote"),
        }),
        enabled: Yup.boolean(),
      }),
      telephone: Yup.object({
        value: Yup.string().when("enabled", {
          is: true,
          then: Yup.string()
            .required("Telephone number is required")
            .max(120, "Max of 120 characters"),
        }),
        canVote: Yup.string().when("enabled", {
          is: true,
          then: Yup.string().required("Must select if shareholders can vote"),
        }),
        enabled: Yup.boolean(),
      }),
    }).test(
      // must select at least one meeting format
      "must-select-format",
      "Must select at least one meeting format",
      (value) => {
        return (
          Boolean(value.inPerson.enabled) ||
          Boolean(value.telephone.enabled) ||
          Boolean(value.virtual.enabled)
        );
      }
    ),
  }),
});

export const createValidationSchema = draftValidationSchema.shape({
  contestingEntity: Yup.string().when("type", {
    is: (val: string) => val === "MeetingContest",
    then: Yup.string().required("Please include the the contesting entity"),
    otherwise: Yup.string().nullable(),
  }),
  issuerFiling: Yup.string().when("type", {
    is: (val: string) => val === "MeetingContest",
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(),
  }),
  minimumShares: Yup.number().when("type", {
    is: (val: string) => val === "MeetingContest",
    then: Yup.number().required().min(0, "Minimum shares cannot be negative"),
    otherwise: Yup.number().nullable(),
  }),
});

const baseValidationSchema = draftValidationSchema.shape({
  fundOwner: Yup.string().when("type", {
    is: (val: string) =>
      val === "FundReport" ||
      val === "FundInformationStatement" ||
      val === "FundMeeting",
    then: Yup.string().required("Fund Owner is required"),
    otherwise: Yup.string().nullable(),
  }),
  reportType: Yup.string().when("type", {
    is: (val: string) => val === "FundReport",
    then: Yup.string().required("Report type is required"),
    otherwise: Yup.string().nullable(),
  }),
  digitalMaterialsReceivedDate: Yup.string()
    .required("Digital Received Date is required")
    .nullable(),
});

const votingValidationSchema = baseValidationSchema.shape({
  tabulator: Yup.object().required("You must include a tabulator"),
  sendTo: Yup.array()
    .of(Yup.object())
    .min(1, "At least one contact is required"),
  voteCutoffDate: Yup.string()
    .required("Vote Cutoff Date cannot be blank")
    .nullable(),
  voteCutoffTime: Yup.string().required("Vote Cutoff Time cannot be blank"),
  voteCutoffTimezone: Yup.string().required("Vote Cutoff Date cannot be blank"),
});

const meetingValidationSchema = votingValidationSchema.shape({
  meetingType: Yup.string().required("Meeting Type cannot be blank"),
  meetingDate: Yup.string().required("Meeting Date cannot be blank").nullable(),
  meetingTime: Yup.string().required("Meeting Time cannot be blank").nullable(),
  meetingTimezone: Yup.string().required("Meeting Timezone cannot be blank"),
  canAttendMeeting: Yup.object({
    enabled: Yup.string().required(
      "Must select if shareholders can attend the meeting"
    ),
    value: Yup.string(),
  }),
  /*
  // If and only when shareholders can attend the meeting, run validation on meetingFormat fields
  meetingFormats: Yup.object().when("canAttendMeeting.enabled", {
    is: "yes",
    then: Yup.object({
      inPerson: Yup.object({
        value: Yup.string().when("enabled", {
          is: true,
          then: Yup.string()
            .required("Address is required")
            .max(120, "Max of 120 characters"),
        }),
        canVote: Yup.string().when("enabled", {
          is: true,
          then: Yup.string().required("Must select if shareholders can vote"),
        }),
        enabled: Yup.boolean(),
      }),
      virtual: Yup.object({
        value: Yup.string().when("enabled", {
          is: true,
          then: Yup.string()
            .required("URL Address is required")
            .max(120, "Max of 120 characters"),
        }),
        canVote: Yup.string().when("enabled", {
          is: true,
          then: Yup.string().required("Must select if shareholders can vote"),
        }),
        enabled: Yup.boolean(),
      }),
      telephone: Yup.object({
        value: Yup.string().when("enabled", {
          is: true,
          then: Yup.string()
            .required("Telephone number is required")
            .max(120, "Max of 120 characters"),
        }),
        canVote: Yup.string().when("enabled", {
          is: true,
          then: Yup.string().required("Must select if shareholders can vote"),
        }),
        enabled: Yup.boolean(),
      }),
    }).test(
      // must select at least one meeting format
      "must-select-format",
      "Must select at least one meeting format",
      (value) => {
        return (
          Boolean(value.inPerson.enabled) ||
          Boolean(value.telephone.enabled) ||
          Boolean(value.virtual.enabled)
        );
      }
    ),
  }),*/
});

const closedStatusValidationSchema = Yup.object().shape({
  cusips: Yup.array()
    .min(1, "At least one CUSIP is required")
    .required("At least one CUSIP is required"),
});

export const getValidationSchemaByStatus = (
  filingType: FilingType,
  status: OperationsStatus
): Yup.AnyObjectSchema | undefined => {
  const mapTypeToSchema = new Map([
    [FilingType.FirmConsentSolicitation, votingValidationSchema],
    [FilingType.FirmInformationStatement, baseValidationSchema],
    [FilingType.FirmMeeting, meetingValidationSchema],
    [FilingType.FundInformationStatement, baseValidationSchema],
    [FilingType.FundMeeting, meetingValidationSchema],
    [FilingType.FundReport, baseValidationSchema],
    [FilingType.MeetingContest, meetingValidationSchema],
  ]);
  switch (status) {
    case OperationsStatus.Draft:
      return draftValidationSchema;
    case OperationsStatus.ReadyForReview:
    case OperationsStatus.Approved:
      return mapTypeToSchema.get(filingType);
    case OperationsStatus.Closed:
      return closedStatusValidationSchema;
    default:
      return draftValidationSchema;
  }
};

export const documentValidationSchema = Yup.object().shape({
  securities: Yup.array().required(),
  document: Yup.mixed().required(),
  documentType: Yup.string().required(),
  documentTitle: Yup.string().required(),
  shouldDistribute: Yup.bool().required(),
});

export const editDocumentValidationSchema = Yup.object().shape({
  securities: Yup.array().required(),
  documentType: Yup.string().required(),
  documentTitle: Yup.string().required(),
  shouldDistribute: Yup.bool().required(),
});
