import { CircularProgress } from "@mui/material";
import useSWR from "swr";

import { swrDisablePolling } from "../../constants/configs";
import { getCommunicationApi } from "../../features/Proxy/ReviewEmail/endpointGenerators";

interface IBallotViewProps {
  filingId: string;
}

export const BallotView = ({ filingId }: IBallotViewProps): JSX.Element => {
  const communicationApi = getCommunicationApi({
    filingId,
    broker: "TE",
  });
  const { data: communication } = useSWR(communicationApi, swrDisablePolling);

  return (
    <>
      {communication && communication.status === 200 && (
        <iframe
          src={communication.data.sampleBallotUrl}
          style={{
            minHeight: "100%",
            width: "100%",
          }}
          title="Ballot Sample"
        />
      )}
      {!communication && (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};
