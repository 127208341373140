import { useEffect, useState } from "react";

import { useAdditionalMailings } from "src/api/adapters/nexus/hooks/useAdditionalMailings";
import { Box } from "src/components/atoms/Box";
import { Button } from "src/components/atoms/Button";
import { EmptyCard } from "src/components/EmptyCard";
import { LoadingState } from "src/components/LoadingState";
import { SplitPane } from "src/components/molecules/SplitPane";
import { AdditionalMailing } from "src/types/additionalMailing";
import { AdditionalMailingDetailPanel } from "./AdditionalMailingDetailPanel/AdditionalMailingDetailPanel";
import { AdditionalMailingDialog } from "./AdditionalMailingDialog";
import { AdditionalMailingRow } from "./AdditionalMailingRow";

interface IAdditionalMailingsProps {
  proxyEventId: string;
}

export function AdditionalMailings({ proxyEventId }: IAdditionalMailingsProps) {
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [mailingInFocus, setMailingInFocus] =
    useState<AdditionalMailing | null>(null);
  const { additionalMailings, isLoading, isError, mutate } =
    useAdditionalMailings(proxyEventId);

  useEffect(() => {
    // Since this state is not updated when we call handleAdditionalMailingsUpdate
    // we have this hook to refresh the mailingInFocus.
    // Another option is to pass the mailingInFocus ID and call the useAdditionalMailing
    // hook in the child but this comes tradeoffs.
    if (mailingInFocus) {
      const updatedMailing = additionalMailings?.find(
        (mailing) => mailing.id === mailingInFocus.id
      );
      if (updatedMailing) {
        setMailingInFocus(updatedMailing);
      }
    }
  }, [additionalMailings]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (isError || !additionalMailings) {
    return <Box>Uh-oh we encountered an error</Box>;
  }

  const handleOpenCreationModal = () => {
    setIsCreateOpen(true);
  };

  const handleCloseCreationModal = () => {
    setIsCreateOpen(false);
  };

  const handleAdditionalMailingsUpdate = () => mutate();

  const handleSelectAdditionalMailing = (mailing: AdditionalMailing) => {
    setMailingInFocus(mailing);
  };

  const renderPrimaryPanel = () => {
    return (
      <Box
        data-testid="additonalMailing"
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          px: 4,
        }}
      >
        <Box
          sx={{
            py: 3,
            mx: -4,
            pl: 8,
            pr: 4,
            mb: 4,
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #e5e5e5",
            fontWeight: 500,
            height: "48px",
          }}
        >
          Additional Mailings
          {additionalMailings.every(
            (mailing) =>
              mailing.operationsStatus === "approved" ||
              mailing.operationsStatus === "closed"
          ) && (
            <Button
              onClick={handleOpenCreationModal}
              size="small"
              variant="contained"
            >
              Create
            </Button>
          )}
        </Box>
        <Box sx={{ overflowY: "auto", height: "calc(100vh - 16rem)" }}>
          {additionalMailings.map((mailing) => {
            const { id } = mailing;
            return (
              <AdditionalMailingRow
                key={id}
                isInFocus={id === mailingInFocus?.id}
                mailing={mailing}
                onClick={handleSelectAdditionalMailing}
              />
            );
          })}
        </Box>
      </Box>
    );
  };

  const renderSecondaryPanel = () => {
    return (
      <AdditionalMailingDetailPanel
        additionalMailing={mailingInFocus}
        onAdditionalMailingUpdate={handleAdditionalMailingsUpdate}
        proxyEventId={proxyEventId}
      />
    );
  };

  return (
    <>
      {additionalMailings.length === 0 ? (
        <EmptyCard
          data-testid="addtional_mail_button"
          body="We don't have any additional mailings related to this filing."
          buttonLabel="Create additional mailing"
          onClick={handleOpenCreationModal}
          title="No additional mailings to review."
          sx={{ p: 6 }}
        />
      ) : (
        <SplitPane
          primaryPane={renderPrimaryPanel()}
          primaryWidth={25}
          secondaryPane={renderSecondaryPanel()}
          secondarySx={{ display: "flex" }}
        />
      )}
      <AdditionalMailingDialog
        open={isCreateOpen}
        onClose={handleCloseCreationModal}
        onUpdate={handleAdditionalMailingsUpdate}
        proxyEventId={proxyEventId}
      />
    </>
  );
}
