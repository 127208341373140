/**
 * This function will take  a timezone string and return a formatted string
 * For exmaple:
 * - "America/New_York" => "America New York (EDT)"
 * - "America/Los_Angeles" => "America Los Angeles (PDT)"
 * - "America/Chicago" => "America Chicago (CDT)"
 */

export const formatTimezoneLabel = (timeZone: string) => {
  if (timeZone) {
    // Example using US/Eastern
    // const abbreviatedTimeZone = new Intl.DateTimeFormat("en-US", { timeZone: "US/Eastern, timeZoneName: "short" }) // This returns a DateTimeFormat
    // .format() // returns 9/28/2023, EDT
    // .split(", ") returns ["9/28/2023", "EDT"]
    // .pop() returns "EDT"

    const abbreviatedTimeZone = new Intl.DateTimeFormat("en-US", {
      timeZone,
      timeZoneName: "short",
    })
      .format()
      .split(", ")
      .pop();

    return `${timeZone.split("_").join(" ")} (${abbreviatedTimeZone})`;
  }
  return timeZone;
};
