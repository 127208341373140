import axios, {
  AxiosRequestConfig,
  AxiosRequestTransformer,
  AxiosResponseTransformer,
} from "axios";
import humps from "humps";
import { refreshTokenIfExpired } from "../../utils/refreshToken";
import { getTokenFromCookies } from "../../utils/getToken";

const getConfig = (
  baseURL: string,
  transformRequest: boolean,
  transformResponse: boolean
) => {
  const config: AxiosRequestConfig = {
    baseURL,
  };

  if (transformRequest) {
    config.transformRequest = [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data: any) => humps.decamelizeKeys(data),
      ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
    ];
  }

  if (transformResponse) {
    config.transformResponse = [
      ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data: any) => humps.camelizeKeys(data),
    ];
  }

  return config;
};

interface IAdapterOptions {
  baseURL: string;
  isMultipartRequest?: boolean;
  isPublic?: boolean;
  transformRequest?: boolean;
  transformResponse?: boolean;
}

const createAdapter = ({
  baseURL,
  isMultipartRequest = false,
  isPublic = false,
  transformRequest = true,
  transformResponse = true,
}: IAdapterOptions) => {
  const axiosConfig = getConfig(baseURL, transformRequest, transformResponse);
  const axiosInstance = axios.create(axiosConfig);

  axiosInstance.interceptors.request.use(
    async (config) => {
      if (!isPublic) {
        await refreshTokenIfExpired();
        if (config.headers) {
          config.headers.Authorization = `JWT ${getTokenFromCookies()}`;

          if (isMultipartRequest) {
            config.headers["Content-Type"] = "multipart/form-data";
          }
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export { createAdapter };
