import { FC, MouseEvent } from "react";

import { FormikProps } from "formik";
import { FilingDetailsFormValues } from "../types";

import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FilingDatePicker } from "./FilingDatePicker";

type AdditionalRecordDates = {
  error: string;
  additionalRecordDates?: string[];
  formikBag: FormikProps<FilingDetailsFormValues>;
};

export const AdditionalRecordDates: FC<AdditionalRecordDates> = ({
  additionalRecordDates,
  error,
  formikBag,
}) => {
  const canAddAnotherField = () => {
    return (
      !additionalRecordDates ||
      additionalRecordDates.every((date: string) => date !== "")
    );
  };

  return (
    <Box>
      {formikBag.values.recordDateCompare &&
        additionalRecordDates &&
        additionalRecordDates.map((additionalRecordDate: string, i: number) => {
          return (
            <Box key={`add-record-date-${i}`} sx={{ mb: 3 }}>
              <FilingDatePicker
                formikBag={formikBag}
                label={`Addtional Record Date ${i + 1}`}
                name={`additionalRecordDates.${i}`}
                required={true}
              />
              {error && i === 0 && (
                <Box
                  component="p"
                  sx={{
                    color: "#d33f33",
                    fontSize: ".75rem",
                    margin: "3px 0 0 14px",
                  }}
                >
                  {error}
                </Box>
              )}
              {i + 1 === additionalRecordDates.length && (
                <Button
                  size="small"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (additionalRecordDates.length === 1) {
                      formikBag.setFieldValue("recordDateCompare", false);
                      formikBag.setFieldValue(
                        "additionalRecordDates",
                        undefined
                      );
                    } else {
                      const filteredRecordDates = [
                        ...additionalRecordDates,
                      ].filter(
                        (recordDate) => recordDate !== additionalRecordDate
                      );
                      formikBag.setFieldValue("additionalRecordDates", [
                        ...filteredRecordDates,
                      ]);
                    }
                  }}
                >
                  Remove
                </Button>
              )}
            </Box>
          );
        })}
      <Box>
        <Button
          size="small"
          startIcon={
            <AddIcon color={!canAddAnotherField() ? "inherit" : "primary"} />
          }
          disabled={!canAddAnotherField()}
          onClick={() => {
            if (!formikBag.values.recordDateCompare) {
              formikBag.setFieldValue("recordDateCompare", true);
              formikBag.setFieldValue("additionalRecordDates", new Array(""));
            } else {
              if (additionalRecordDates?.length) {
                formikBag.setFieldValue("additionalRecordDates", [
                  ...additionalRecordDates,
                  "",
                ]);
              }
            }
          }}
        >
          Record Date
        </Button>
      </Box>
    </Box>
  );
};
