import { COLORS } from "@asayinc/component-library";
import { SxProps } from "@mui/material";

import { OperationsStatus } from "src/features/CorporateActions/types";
import { OperationsStatus as WarrenGOperationsStatus } from "src/types/vca";
import { Chip } from "../atoms/Chip";

interface IVCAStatusBadgeProps {
  electionCutoff: string | null;
  status: OperationsStatus | WarrenGOperationsStatus;
  sx?: SxProps;
}

export function VCAStatusBadge({
  electionCutoff,
  status,
  sx,
}: IVCAStatusBadgeProps) {
  const isPastCutoff = electionCutoff
    ? new Date(electionCutoff).getTime() < new Date().getTime()
    : false;

  const getLabel = () => {
    if (isPastCutoff || status === "closed") {
      return "Closed";
    }
    switch (status) {
      case "new":
        return "New";
      case "active":
      case "materials_requested":
      case "ready_for_review":
      case "in_progress":
        return "In Progress";
      case "approved":
        return "Approved";
    }
  };

  const getBackgroundColor = () => {
    if (isPastCutoff || status === "closed") {
      return COLORS.WHITE;
    }
    switch (status) {
      case "new":
        return COLORS.WHITE;
      case "active":
      case "materials_requested":
      case "ready_for_review":
      case "in_progress":
        return COLORS.LIGHT_BLUE;
      case "approved":
        return COLORS.LIGHT_GREEN;
    }
  };

  const getBorderColor = () => {
    if (isPastCutoff || status === "closed") {
      return COLORS.SHADED_RED;
    }
    switch (status) {
      case "new":
        return COLORS.BLACK;
      case "active":
      case "materials_requested":
      case "ready_for_review":
      case "in_progress":
        return COLORS.LIGHT_BLUE;
      case "approved":
        return COLORS.LIGHT_GREEN;
    }
  };

  return (
    <Chip
      label={getLabel()}
      data-testid="filingStatus"
      variant="outlined"
      size="small"
      sx={{
        backgroundColor: getBackgroundColor(),
        border: `1px solid ${getBorderColor()}`,
        ...sx,
      }}
    />
  );
}
