import { AxiosRequestConfig } from "axios";
import useSWR from "swr";

import { PaginatedResponse } from "src/types/PaginatedResponse";
import { nexus } from "..";
import { IQueryParams } from "../../types";
import { AdditionalMailing } from "src/types/additionalMailing";

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) =>
  nexus.get<PaginatedResponse<AdditionalMailing>>(url, { params });

export function useAdditionalMailings(id: string) {
  const url = `/proxy-events/${id}/additional-mailings/?ordering=-date_received`;
  const { data, error, mutate } = useSWR(url, fetcher);
  return {
    count: data?.data?.count,
    isError: error,
    isLoading: !error && !data,
    mutate,
    additionalMailings: data?.data?.results,
  };
}
