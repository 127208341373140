import {
  Link,
  MenuItem,
  TextField,
  Typography,
  colors,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { createFilings } from "src/api/adapters/proxy";
import { formatCamelCaseString } from "src/utils/format-camel-case";
import { FilingType } from "src/features/Proxy/types";
import { Dropzone } from "src/components/atoms/Dropzone";
import { Dialog } from "src/components/atoms/dialog/Dialog";
import { Box } from "src/components/atoms/Box";
import { FILING_TYPE_TO_LINK } from "./utils";

type BulkUploadForm = {
  filingType: FilingType | null;
  file: File | null;
};

interface IBulkUploadDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function BulkUploadDialog({
  isOpen,
  onClose,
}: IBulkUploadDialogProps): JSX.Element {
  const filingTypes = Object.values(FilingType);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
    setValue,
    watch,
  } = useForm<BulkUploadForm>({
    defaultValues: {
      filingType: null,
      file: null,
    },
  });
  const filingType = watch("filingType");
  const file = watch("file");

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  const handleDrop = (acceptedFiles: File[]) => {
    setValue("file", acceptedFiles[0], { shouldValidate: true });
  };

  const handleRemoveFile = () => {
    setValue("file", null, { shouldValidate: true });
  };

  const handleUpload: SubmitHandler<BulkUploadForm> = async (values) => {
    const { filingType, file } = values;
    if (filingType && file) {
      const formData = new FormData();
      formData.append("file", file as File, (file as File).name);
      formData.append("filing_type", filingType);
      try {
        await createFilings(formData);
        toast.success("Multiple filings were successfully uploaded.");
      } catch (error) {
        toast.error("Unable to upload multiple filings.");
      } finally {
        onClose();
      }
    }
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onClose}
      onPrimaryClick={handleSubmit(handleUpload)}
      onSecondaryClick={onClose}
      secondaryButtonText="Cancel"
      isPrimaryDisabled={!isValid}
      isPrimaryLoading={isSubmitting}
      primaryButtonText="Upload"
      title="Multiple Filings"
    >
      <form id="bulk-upload-form">
        <Controller
          control={control}
          name="filingType"
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                id="filing-type"
                fullWidth
                select
                label="Type"
                size="small"
                sx={{ mt: 2, mb: 4 }}
                InputLabelProps={{ shrink: true }}
              >
                {filingTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {formatCamelCaseString(type)}
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        />
        {filingType && (
          <Box sx={{ pb: 4 }}>
            <Typography variant="h6" component="h3">
              Instructions
            </Typography>
            <Typography variant="body1" component="p" sx={{ mt: 1 }}>
              Click the link below to download the{" "}
              {formatCamelCaseString(filingType)} template. Fill out the fields
              one CUSIP per row. If an issuer has more than 1 CUSIP, the issuer
              should appear multiple times.
            </Typography>
            <Typography variant="body1" component="p" sx={{ mt: 1 }}>
              Please make sure information is entered correctly. Capitalization
              and punctuation will be interpreted exactly as entered.
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Link
                href={FILING_TYPE_TO_LINK[filingType]}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
                sx={{
                  fontWeight: 500,
                  "&:hover": {
                    color: "primary.main",
                    textDecoration: "underline",
                  },
                }}
              >
                {formatCamelCaseString(filingType)} Template
              </Link>
            </Box>
          </Box>
        )}
        {file ? (
          <Box
            p={2}
            bgcolor={colors.grey[100]}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="body1"
              component="p"
              style={{ color: colors.blue[800] }}
            >
              {file.name}
            </Typography>
            <IconButton
              size="small"
              aria-label="delete"
              onClick={handleRemoveFile}
            >
              <Close />
            </IconButton>
          </Box>
        ) : (
          <Controller
            control={control}
            name="file"
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <Dropzone {...field} multiple={false} onDrop={handleDrop} />
              );
            }}
          />
        )}
      </form>
    </Dialog>
  );
}
