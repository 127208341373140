import { useState } from "react";
import { toast } from "react-toastify";

import { approveProspectusPackage } from "src/api/adapters/nexus";
import { ProspectusPackage } from "src/api/adapters/nexus/types";
import { IBaseProps } from "../../types/BaseProps";
import { Dialog } from "../atoms/dialog/Dialog";

interface IApproveProspectusDialog extends IBaseProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
  prospectusPackage: ProspectusPackage;
}

export function ApproveProspectusDialog({
  isOpen,
  onClose,
  onUpdate,
  prospectusPackage,
}: IApproveProspectusDialog) {
  const [isLoading, setIsLoading] = useState(false);

  const handleCompleteFiling = async () => {
    setIsLoading(true);
    try {
      await approveProspectusPackage(prospectusPackage.id);
    } catch (error) {
      toast.error(
        "An unknown error occurred. Please ensure your selections are correct.",
        { autoClose: false }
      );
    } finally {
      onUpdate();
      onClose();
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      isPrimaryLoading={isLoading}
      onClose={onClose}
      onPrimaryClick={handleCompleteFiling}
      onSecondaryClick={onClose}
      primaryButtonText="Ok"
      secondaryButtonText="Cancel"
      title="Are you sure you want to approve this trade alert?"
    >
      This package will be used as the current package immediately.
    </Dialog>
  );
}
