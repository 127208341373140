import React from "react";

type TableProps = {
  children: React.ReactNode;
  isFixed?: boolean;
};

const Table = ({ children, isFixed }: TableProps): JSX.Element => (
  <table
    className="w-full"
    style={{
      borderCollapse: "separate",
      borderSpacing: "0 10px",
      tableLayout: isFixed ? "fixed" : undefined,
    }}
  >
    {children}
  </table>
);

export { Table };
