import { AdditionalMailingType, Audience } from "src/types/additionalMailing";

export const ADDITIONAL_MAILING_TYPE_TO_LABEL: {
  [key in AdditionalMailingType]: string;
} = {
  informational: "Informational",
  vote_reminder: "Vote Reminder",
};

export const ADDITIONAL_MAILING_AUDIENCE_TO_LABEL: {
  [key in Audience]: string;
} = {
  all_shareholders: "All Shareholders",
  not_voted_shareholders: "Not Voted Shareholders",
};
