import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box } from "src/components/atoms/Box";
import { LoadingState } from "src/components/LoadingState";
import { PageWrapper } from "src/components/PageWrapper";
import { TableHeader } from "src/components/TableHeader";
import { TableSortLabel } from "src/components/TableSortLabel";
import {
  deleteFilings,
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from "../batch-filings-context";
import { BatchActionButtons } from "../BatchActionButtons";
import { table, tableHeadCell } from "../styles/tableStyles";
import { useTableOrdering } from "../useTableOrdering";
import { OrderingKey, useTasks } from "../useTasks";
import { OpenTableRow } from "./OpenTableRow";

export function Open() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    open: { data, error, mutate },
    openQuery: query,
    handleUpdateQuery,
  } = useTasks();
  const [isBatchActionVisible, setIsBatchActionsVisible] = useState(false);
  const { getIsActive, direction } = useTableOrdering(query.ordering);
  const batchFilings = useBatchFilingsState();
  const dispatch = useBatchFilingsDispatch();

  const handleOrderingClick = useCallback(
    (orderingKey: OrderingKey) => {
      switch (direction) {
        case "desc":
          return handleUpdateQuery("open", "ordering", orderingKey);
        case "asc":
        default:
          return handleUpdateQuery("open", "ordering", `-${orderingKey}`);
      }
    },
    [query]
  );

  const handlePageChange = (page: number) => {
    handleUpdateQuery("open", "page", page);
  };

  const handlePageSizeChange = (pageSize: number) => {
    handleUpdateQuery("open", "pageSize", pageSize);
  };

  const handleShowBatchActions = () => {
    setIsBatchActionsVisible(true);
  };

  const handleHideBatchActions = () => {
    setIsBatchActionsVisible(false);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!data) return;

    if (event.target.checked) {
      const allFilingIds = data.data.results.map((filing) => filing.id);
      dispatch({
        type: "SELECT_ALL_FILINGS",
        filings: allFilingIds,
        path: location.pathname,
      });
      return;
    }

    dispatch({ type: "DESELECT_ALL_FILINGS" });
  };

  const handleDeleteBatchFilings = () => {
    deleteFilings(dispatch, batchFilings.filings, mutate);
  };

  const handleEditBatchFilings = () => {
    navigate(`/proxy/${batchFilings.filings[0]}`);
  };

  if ((!error && !data) || !data?.data?.results)
    return (
      <Box p={4}>
        <LoadingState />
      </Box>
    );

  if (error) return <Box p={4}>Error...</Box>;

  return (
    <PageWrapper>
      <TableHeader
        count={data.data.count}
        page={query.page}
        pageSize={query.pageSize}
        itemType="filing"
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
      <Box sx={{ overflowX: "auto" }}>
        <Table sx={table}>
          <TableHead
            onMouseEnter={handleShowBatchActions}
            onMouseLeave={handleHideBatchActions}
          >
            <TableRow>
              <TableCell
                padding="checkbox"
                sx={{
                  position: "sticky",
                  background: "white",
                  zIndex: 10,
                }}
              >
                <Checkbox
                  onChange={handleSelectAll}
                  checked={
                    batchFilings.filings.length > 0 &&
                    batchFilings.filings.length === data.data.results.length
                  }
                  indeterminate={
                    batchFilings.filings.length > 0 &&
                    batchFilings.filings.length < data.data.results.length
                  }
                />
              </TableCell>
              <TableCell
                sx={tableHeadCell}
                style={{
                  width: "300px",
                  left: 47,
                  position: "sticky",
                  zIndex: 10,
                }}
              >
                <BatchActionButtons
                  isVisible={
                    isBatchActionVisible && Boolean(batchFilings.filings.length)
                  }
                  onDelete={handleDeleteBatchFilings}
                  onEdit={handleEditBatchFilings}
                />
                <TableSortLabel
                  active={getIsActive("issuer__company_name")}
                  direction={direction}
                  onClick={() => handleOrderingClick("issuer__company_name")}
                >
                  Issuer
                </TableSortLabel>
              </TableCell>
              <TableCell sx={tableHeadCell} id="CUSIP">
                CUSIP
              </TableCell>
              <TableCell sx={tableHeadCell} id="Event">
                <TableSortLabel
                  active={getIsActive("type")}
                  direction={direction}
                  onClick={() => handleOrderingClick("type")}
                >
                  Event
                </TableSortLabel>
              </TableCell>
              <TableCell sx={tableHeadCell} id="Record Date">
                <TableSortLabel
                  active={getIsActive("first_record_date")}
                  direction={direction}
                  onClick={() => handleOrderingClick("first_record_date")}
                >
                  Record Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={tableHeadCell} id="Positions">
                Positions
              </TableCell>
              <TableCell sx={tableHeadCell} id="Meeting Date">
                <TableSortLabel
                  active={getIsActive("meeting_date")}
                  direction={direction}
                  onClick={() => handleOrderingClick("meeting_date")}
                >
                  Meeting Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={tableHeadCell} id="Notes">
                Notes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data.results.map((filing, index) => (
              <OpenTableRow key={index} filing={filing} />
            ))}
          </TableBody>
        </Table>
      </Box>
    </PageWrapper>
  );
}
