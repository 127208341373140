import { TextField } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";

import { CreateCorporateActionForm } from "src/types/vca";

interface IDividendReinvestmentPlanConditionsProps {
  form: UseFormReturn<CreateCorporateActionForm>;
}

export const DividendReinvestmentPlanConditions = ({
  form,
}: IDividendReinvestmentPlanConditionsProps) => {
  const { control } = form;
  return (
    <Controller
      control={control}
      name="offer.conditions.price"
      render={({ field }) => {
        return (
          <TextField
            {...field}
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
            fullWidth={true}
            label="DRIP Reinvestment Price"
            size="small"
          />
        );
      }}
    />
  );
};
