import { useState } from "react";

import { useTradeAlerts } from "src/api/adapters/nexus/hooks/useTradeAlerts";
import { useMissingExpiredProspectuses } from "src/api/adapters/nexus/hooks/useMissingExpiredProspectuses";
import { useUpcomingProspectuses } from "src/api/adapters/nexus/hooks/useUpcomingProspectuses";
import { getPageOffset } from "src/utils/get-page-offset";
import { Query } from "./types";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useTasks() {
  const [tradeAlertQuery, setTradeAlertQuery] = useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: "effective_end_date",
  });
  const [missingExpiredQuery, setMissingExpiredQuery] = useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: "effective_end_date",
  });
  const [upcomingQuery, setUpcomingQuery] = useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: "effective_end_date",
  });

  const tradeAlerts = useTradeAlerts({
    limit: tradeAlertQuery.pageSize,
    ordering: tradeAlertQuery.ordering,
    offset: getPageOffset({
      page: tradeAlertQuery.page,
      pageSize: tradeAlertQuery.pageSize,
    }),
  });
  const missingExpired = useMissingExpiredProspectuses({
    limit: missingExpiredQuery.pageSize,
    ordering: missingExpiredQuery.ordering,
    offset: getPageOffset({
      page: missingExpiredQuery.page,
      pageSize: missingExpiredQuery.pageSize,
    }),
  });
  const upcoming = useUpcomingProspectuses({
    limit: upcomingQuery.pageSize,
    ordering: upcomingQuery.ordering,
    offset: getPageOffset({
      page: upcomingQuery.page,
      pageSize: upcomingQuery.pageSize,
    }),
  });

  return {
    tradeAlertQuery,
    setTradeAlertQuery,
    missingExpiredQuery,
    setMissingExpiredQuery,
    upcomingQuery,
    setUpcomingQuery,
    isLoading:
      tradeAlerts.isLoading || missingExpired.isLoading || upcoming.isLoading,
    tradeAlertsProspectuses: tradeAlerts,
    missingExpiredProspectuses: missingExpired,
    upcomingProspectuses: upcoming,
  };
}
