// TODO(David): This is temporary will remove once we get the proxy tables to use the same tables as other pages
import { SxProps } from "@mui/system";

export const table: SxProps = {
  borderCollapse: "separate",
  borderSpacing: "0px 8px",
  whiteSpace: "nowrap",
  position: "relative",
};

export const tableBodyRow: SxProps = {
  borderRadius: "6px",
  cursor: "pointer",
  "&:hover": {
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  },
  overflowX: "scroll",
};

export const tableHeadCell: SxProps = {
  fontSize: "12px",
  fontWeight: "bold",
  background: "white",
};

export const tableBodyCell: SxProps = {
  padding: "auto",
  maxWidth: "100px",
  borderTop: `1px solid`,
  borderTopColor: "divider",
  borderBottom: `1px solid`,
  borderBottomColor: "divider",
  fontSize: "12px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  background: "white",

  "&:last-of-type": {
    borderRight: `1px solid`,
    borderRightColor: "divider",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
    maxWidth: "236px !important",
  },
  "& > a": {
    color: "#1853b4 !important",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

export const tableBodyCellCheckbox: SxProps = {
  borderTop: `1px solid`,
  borderBottom: `1px solid`,
  borderLeft: `1px solid`,
  borderColor: "divider",
  padding: `0 0 0 4px`,
  borderTopLeftRadius: "6px",
  borderBottomLeftRadius: "6px",
};
