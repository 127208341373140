import { MenuItem, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { documentTypeOptions as vcaDocumentTypeOptions } from "src/features/CorporateActions/select-options";
import { documentTypeOptions as proxyDocumentTypeOptions } from "src/features/Proxy/select-options";
import { NonEdiDocumentType } from "src/features/CorporateActions/types";
import { WarrenGDocument } from "src/types/warreng";
import { Box } from "../atoms/Box";
import { Dialog } from "../atoms/dialog/Dialog";
import { useEffect } from "react";

interface IWarrenGDocumentDialogProps {
  document?: WarrenGDocument; // If provided component will assume we are updating the document
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onCreateUpdate: (values: WarrenGDocumentForm) => void;
  showProxyDocumentOptions: boolean; // Currently we have two different document type lists (proxy and vca). Once we get another list we can revisit this prop
}

export type WarrenGDocumentForm = {
  type: NonEdiDocumentType | null;
  name: string;
};

export function WarrenGDocumentDialog({
  document,
  isOpen,
  isLoading,
  onClose,
  onCreateUpdate,
  showProxyDocumentOptions,
}: IWarrenGDocumentDialogProps) {
  const { control, handleSubmit, reset, setValue } =
    useForm<WarrenGDocumentForm>({
      defaultValues: {
        type: document?.type || null,
        name: document?.name || "",
      },
    });

  useEffect(() => {
    if (!isOpen) {
      reset({
        type: document?.type || null,
        name: document?.name || "",
      });
    }
  }, [isOpen]);

  const handleOnClose = () => {
    // Resetting to original document
    reset();
    onClose();
  };

  const options = showProxyDocumentOptions
    ? proxyDocumentTypeOptions
    : vcaDocumentTypeOptions;

  return (
    <Dialog
      fullWidth
      isPrimaryLoading={isLoading}
      onClose={handleOnClose}
      onPrimaryClick={handleSubmit(onCreateUpdate)}
      onSecondaryClick={handleOnClose}
      open={isOpen}
      primaryButtonText={document?.id ? "Update" : "Create"}
      secondaryButtonText="Cancel"
      title={document?.id ? "Update Document" : "Create Document"}
    >
      <form id="warreng-document-form">
        <Box sx={{ py: 2 }}>
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  sx={{ mb: 4 }}
                  error={!!fieldState.error}
                  onChange={(e) => {
                    const { value } = e.target;
                    field.onChange(e.target.value);
                    const label = options.find(
                      (option) => option.value === value
                    )?.label;
                    setValue("name", value === "other" ? "" : label || "");
                  }}
                  fullWidth
                  label="Document Type*"
                  size="small"
                  select={true}
                >
                  {options.map((type) => (
                    <MenuItem key={type.label} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
          <Controller
            control={control}
            rules={{ required: true }}
            name="name"
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  error={!!fieldState.error}
                  fullWidth
                  label="Document Name*"
                  size="small"
                />
              );
            }}
          />
        </Box>
      </form>
    </Dialog>
  );
}
