import Textarea, { TextareaAutosizeProps } from "react-textarea-autosize";

const TextareaAutosize = (props: TextareaAutosizeProps): JSX.Element => {
  return (
    <Textarea
      className={`border border-light-gray rounded outline-none h-8 p-2 focus:border-primary-text w-full disabled:bg-very-light-gray disabled:cursor-not-allowed resize-none ${props.className}`}
      {...props}
    />
  );
};

export { TextareaAutosize };
