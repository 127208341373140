import { COLORS } from "@asayinc/component-library";
import { IBaseProps } from "src/types/BaseProps";
import { Box } from "../atoms/Box";

interface IPageHeaderProps extends IBaseProps {
  icon?: React.ReactNode;
  details?: string;
  title: string;
}

export const PageHeader = ({
  children,
  details,
  icon,
  title,
}: IPageHeaderProps) => {
  return (
    <Box
      sx={{
        py: 3,
        px: 8,
        borderBottom: "1px solid #e5e5e5",
        display: "flex",
        alignItems: "center",
      }}
    >
      {icon && <Box sx={{ mr: 3 }}>{icon}</Box>}
      <Box>
        <Box
          component="h1"
          sx={{
            fontWeight: 500,
            fontSize: "1.5rem",
          }}
        >
          {title}
        </Box>
        {details && (
          <Box sx={{ color: COLORS.SATURATED_GREY, fontSize: "12px" }}>
            {details}
          </Box>
        )}
      </Box>
      {children}
    </Box>
  );
};
