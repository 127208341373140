import { useState } from "react";
import { toast } from "react-toastify";

import {
  createVoluntaryCorporateActionDocuments,
  deleteVoluntaryCorporateActionDocument,
  updateVoluntaryCorporateActionDocument,
} from "src/api/adapters";
import { NonEdiDocumentType } from "src/features/CorporateActions/types";
import { DisplayType } from "src/features/Proxy/types";
import { Dropzone } from "../atoms/Dropzone";
import { PreviewItem } from "../PreviewPane/PreviewPane";
import { DocumentRow } from "./DocumentRow";
import { FormSection } from "../molecules/FormSection";
import { WarrenGDocument } from "src/types/warreng";
import {
  WarrenGDocumentDialog,
  WarrenGDocumentForm,
} from "../WarrenGDocumentDialog";

interface IVcaDocumentListProps {
  documents: WarrenGDocument[];
  onDocumentsChange: (docs: WarrenGDocument[]) => void;
  onPreviewClick: (doc: PreviewItem) => void;
  updateVca?: () => void;
  vcaId?: string;
}

// TODO: (David) This should be combined w/ AdditionalMailingDocumentlist but...
// Problem is for VcaDocuments we want to be able to upload documents and create the VCA filing at the same time (hence the if statements in the handlers)
// whereas for Additional Mailings the user first creates the additional mailing THEN adds documents to that AM
// We should try to streamline this workflow to create model first THEN add documents to it.
export const VcaDocumentList = ({
  documents,
  onDocumentsChange,
  onPreviewClick,
  updateVca,
  vcaId,
}: IVcaDocumentListProps) => {
  const [documentToAdd, setDocumentToAdd] = useState<File | null>(null);
  const [isCreatingDocument, setIsCreatingDocument] = useState(false);

  const handleAddDocument = async (values: WarrenGDocumentForm) => {
    const { type, name } = values;
    if (documentToAdd && type) {
      if (vcaId) {
        const payload = new FormData();
        payload.append("attachment", documentToAdd, name);
        payload.append("type", type);
        payload.append("name", name);
        try {
          setIsCreatingDocument(true);
          await createVoluntaryCorporateActionDocuments(vcaId, payload);
        } catch (e) {
          toast.error(
            "An error has occurred. Please try adding the document again."
          );
        } finally {
          if (updateVca) {
            updateVca();
          }
          setIsCreatingDocument(false);
        }
      } else {
        onDocumentsChange(
          [...documents].concat({
            id: "",
            name,
            file: documentToAdd,
            type,
            url: URL.createObjectURL(documentToAdd),
          })
        );
      }
      setDocumentToAdd(null);
    }
  };

  const handleUpdateDocument = async (
    doc: WarrenGDocument,
    type: NonEdiDocumentType,
    name: string
  ) => {
    if (vcaId && doc.id) {
      try {
        await updateVoluntaryCorporateActionDocument(vcaId, doc.id, {
          type,
          name,
        });
      } catch (e) {
        toast.error(
          "An error has occurred. Please try updating the document again."
        );
      }
      if (updateVca) {
        updateVca();
      }
    } else {
      const updatedDocuments = documents.map((document) => {
        if (document === doc) {
          return {
            ...document,
            type,
            name,
          };
        }
        return document;
      });

      onDocumentsChange(updatedDocuments);
    }
  };

  const handleDeleteDocument = async (doc: WarrenGDocument) => {
    if (vcaId && doc.id) {
      try {
        await deleteVoluntaryCorporateActionDocument(vcaId, doc.id);
      } catch (e) {
        toast.error(
          "An error has occurred. Please try deleting the document again."
        );
      }
      if (updateVca) {
        updateVca();
      }
    } else {
      const updatedDocuments = documents.filter((document) => document !== doc);
      onDocumentsChange(updatedDocuments);
    }
  };

  const handlePreviewDocument = (doc: WarrenGDocument) => {
    onPreviewClick({
      id: doc.name,
      name: doc.name,
      preview: doc.url,
      type: DisplayType.Document,
    });
  };

  const handleAddDocumentToAdd = (files: File[]) => {
    setDocumentToAdd(files[0]);
  };

  const handleRemoveDocumentToAdd = () => {
    setDocumentToAdd(null);
  };

  return (
    <FormSection isCollapsible={false} title="Documents">
      {documents.map((document, index) => {
        return (
          <DocumentRow
            key={index}
            document={document}
            onDeleteDocument={handleDeleteDocument}
            onEditDocument={handleUpdateDocument}
            onPreviewClick={handlePreviewDocument}
            showProxyDocumentOptions={false}
          />
        );
      })}
      <Dropzone
        isDisabled={!vcaId}
        multiple={false}
        onDrop={handleAddDocumentToAdd}
        pdfOnly={true}
      />
      <WarrenGDocumentDialog
        isLoading={isCreatingDocument}
        isOpen={!!documentToAdd}
        onClose={handleRemoveDocumentToAdd}
        onCreateUpdate={handleAddDocument}
        showProxyDocumentOptions={false}
      />
    </FormSection>
  );
};
