import { Text } from "@asayinc/component-library";

const RadioLabel = ({
  enabled,
  label,
}: {
  enabled?: boolean;
  label: string;
}) => (
  <Text variant="body2" emphasis={enabled ? "medium_high" : "disabled"}>
    {label}
  </Text>
);

export default RadioLabel;
