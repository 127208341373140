import { COLORS } from "@asayinc/component-library";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CreateIcon from "@mui/icons-material/Create";
import { Chip, IconButton, Tooltip } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";

import { deleteAttachment, updateAttachment } from "src/api/adapters";
import { useProxy } from "src/api/adapters/proxy/hooks/useProxy";
import {
  Document as SayDocument,
  FilingDetails,
} from "src/features/Proxy/types";
import { Box } from "../atoms/Box";
import { DeleteConfirmationDialog } from "../Dialog/DeleteConfirmationDialog";
import { getLabel } from "src/features/Proxy/select-options";
import {
  SayDocumentDialog,
  SayDocumentForm,
} from "../SayDocumentDialog/SayDocumentDialog";

interface ISayDocumentRowProps {
  document: SayDocument;
  filing: FilingDetails;
  onDocumentPreviewClick: () => void;
}

// We should be deleting this component eventually since we are going to be
// deprecating sayAPI. This was mostly copy/pasted from the DocumentRow component
// in the Materials.tsx file
export function SayDocumentRow({
  document,
  filing,
  onDocumentPreviewClick,
}: ISayDocumentRowProps) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { mutate } = useProxy(filing.id.toString());

  const handleDeleteDocument = async () => {
    try {
      await deleteAttachment(document.id);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error);
      } else {
        toast.error("An unexpected error occurred. Unable to delete document.");
      }
    } finally {
      mutate();
      setIsDeleteDialogOpen(false);
    }
  };

  const handleEditDocument = async (values: SayDocumentForm) => {
    const { name, securities, type, shouldDistribute, effectiveDate } = values;
    try {
      // Setting effective date to document's effective date if it's null
      // since we don't want to update the effective date for doc types where
      // we don't show the effective date input
      const attachmentPayload = {
        filing: filing.id,
        securities: securities.map((security) => security.id),
        name,
        type,
        shouldDistributeInNextMailing: shouldDistribute,
        effectiveDate: effectiveDate
          ? moment(effectiveDate).format("yyyy-MM-DD")
          : document.effectiveDate,
      };
      await updateAttachment(document.id, attachmentPayload);
      mutate();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error);
      } else {
        toast.error("An error occurred. Unable to save document.");
      }
    } finally {
      mutate();
      setIsEditDialogOpen(false);
    }
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleOpenEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  // find security on filing given a document.security id
  const cusips = useMemo(() => {
    if (document.securities) {
      return document.securities.reduce((acc: string[], curr: number) => {
        if (filing) {
          const foundSecurity = filing.securities.find(
            (filingSecurity) => filingSecurity.id === curr
          );

          if (foundSecurity) {
            acc.push(foundSecurity.cusip);
          }
        }

        return acc;
      }, []);
    }
  }, [document.securities, filing]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "space-between",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        width: "100%",
        marginBottom: "16px",
        borderRadius: "4px",
        padding: "20px",
        "&:hover": {
          backgroundColor: COLORS.ICE_BLUE,
          boxShadow:
            " 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)",
        },
      }}
    >
      <InsertDriveFileIcon
        style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "32px" }}
      />
      <Box
        sx={{
          flexGrow: 2,
          color: "rgba(18, 18, 18, 0.87)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          lineHeight: "1.5",
        }}
      >
        <p>
          <b>Type:</b>
          {` ${getLabel(document.type)} `}| <b>Title: </b>
          <a
            className="text-blue hover:underline hover:text-blue"
            href={document.attachment ? document.attachment : "#"}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              event.stopPropagation()
            }
          >
            {document.name}
          </a>
        </p>
        <Box
          sx={{
            marginTop: "8px",
            "& > *": {
              marginRight: "8px",
            },
          }}
        >
          {cusips && cusips.map((i) => <Chip size="small" label={i} key={i} />)}
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: "right",
          minWidth: "200px",
        }}
      >
        <Tooltip title="Preview">
          <IconButton
            size="small"
            aria-label="preview"
            onClick={onDocumentPreviewClick}
          >
            <VisibilityOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton
            size="small"
            aria-label="edit"
            onClick={handleOpenEditDialog}
          >
            <CreateIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            size="small"
            aria-label="delete"
            onClick={handleOpenDeleteDialog}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <SayDocumentDialog
        document={document}
        filing={filing}
        isOpen={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        onCreateUpdate={handleEditDocument}
      />
      <DeleteConfirmationDialog
        deleteType="document"
        isOpen={isDeleteDialogOpen}
        onConfirmDelete={handleDeleteDocument}
        onRequestClose={handleCloseDeleteDialog}
      />
    </Box>
  );
}
