import { capitalize } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { createUpdateNoboRequest } from "src/api/adapters";
import { Nobo } from "src/types/nobo";
import { Dialog } from "../atoms/dialog/Dialog";
import { NoboForm } from "../NoboForm/NoboForm";

interface INoboDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate?: () => void;
  nobo?: Nobo;
}

export function NoboDialog({
  isOpen,
  onClose,
  onUpdate,
  nobo,
}: INoboDialogProps) {
  const navigate = useNavigate();
  const form = useForm<NoboForm>({
    defaultValues: {
      solicitor: nobo?.solicitor || undefined,
      dateReceived: nobo?.dateReceived || null,
      frontLink: nobo?.frontLink || undefined,
      securities: nobo?.securities || [],
      recordDate: nobo?.recordDate || null,
      notes: nobo?.notes || "",
    },
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
  } = form;

  // Each time we open and close the dialog, we want to reset the dialog values
  useEffect(() => {
    if (isOpen) {
      reset({
        solicitor: nobo?.solicitor || undefined,
        dateReceived: nobo?.dateReceived || null,
        frontLink: nobo?.frontLink || undefined,
        securities: nobo?.securities || [],
        recordDate: nobo?.recordDate || null,
        notes: nobo?.notes || "",
      });
    }
  }, [isOpen]);

  // Nobo might not be initialized on first render since we need to
  // fetch the nobo from the BE. Once we receive the nobo we want to
  // reset the form with the nobo values.
  useEffect(() => {
    if (nobo) {
      reset({
        solicitor: nobo.solicitor,
        dateReceived: nobo.dateReceived,
        frontLink: nobo.frontLink,
        securities: nobo.securities,
        recordDate: nobo.recordDate,
        notes: nobo.notes,
      });
    }
  }, [nobo]);

  const onSubmit: SubmitHandler<NoboForm> = async (values) => {
    const {
      solicitor,
      dateReceived,
      frontLink,
      securities,
      recordDate,
      notes,
    } = values;
    try {
      if (solicitor && dateReceived && frontLink && securities && recordDate) {
        const response: AxiosResponse<Nobo> = await createUpdateNoboRequest(
          nobo?.id,
          {
            securities: securities.map((s) => s.id),
            solicitorId: solicitor.id,
            dateReceived: moment(dateReceived).format("yyyy-MM-DD"),
            recordDate: moment(recordDate).format("yyyy-MM-DD"),
            frontLink,
            notes: notes ? notes : "",
            // Temp hardcoded values here
            type: "proxy_related",
            minimumShares: 0,
            includeGiftedPositions: true,
            includeFractionalPositions: false,
          }
        );
        navigate(`/nobo/${response.data.id}`);
      }
    } catch (e) {
      if (axios.isAxiosError(e) && e?.response?.data?.length > 0) {
        const errorMessage = e?.response?.data[0];
        toast.error(capitalize(errorMessage));
      } else {
        toast.error(
          `An error occured when trying to create the NOBO request. Please try creating the NOBO request again.`
        );
      }
    } finally {
      if (onUpdate) {
        onUpdate();
      }
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth
      isPrimaryLoading={isSubmitting}
      isPrimaryDisabled={!isDirty && !!nobo}
      onClose={onClose}
      open={isOpen}
      onPrimaryClick={handleSubmit(onSubmit)}
      onSecondaryClick={onClose}
      primaryButtonText={nobo ? "Update" : "Create"}
      secondaryButtonText="Cancel"
      title={nobo ? "Update NOBO Request" : "Create NOBO Request"}
    >
      <NoboForm form={form} />
    </Dialog>
  );
}
