import React from "react";
import useSWR from "swr";
import { proxy } from "..";
import { Search } from "src/types/search";
import { useDebounce } from "use-debounce";
import { AxiosResponse } from "axios";

const fetcher = (url: string) => proxy.get<Search>(url);

function useSearch(): {
  data: AxiosResponse<Search> | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  onSetSearch: (value: string) => void;
  searchTerm: string;
} {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 250);

  function onSetSearch(value: string) {
    setSearchTerm(value);
  }

  const { data, error } = useSWR(
    debouncedSearchTerm.length
      ? `/admin/search/?value=${debouncedSearchTerm}`
      : null,
    fetcher
  );

  return { data, error, onSetSearch, searchTerm };
}

export { useSearch };
