import { createAdapter } from "../adapterFactory";
import {
  IUpdateAdminIssuerRequest,
  IUpdateIssuerRequest,
  IUpdateProspectusRequest,
  IUpdateSecurityRequest,
} from "./types";

const hub = createAdapter({
  baseURL: `${process.env.REACT_APP_HUB_URL}/v1`,
});

const hubFormData = createAdapter({
  baseURL: `${process.env.REACT_APP_HUB_URL}/v1`,
  isMultipartRequest: true,
  transformRequest: false,
});

function updateIssuer(issuerId: string, payload: IUpdateIssuerRequest) {
  const url = `/issuers/issuer/${issuerId}`;
  return hub.patch(url, payload);
}

function updateAdminIssuer(
  issuerId: string,
  payload: IUpdateAdminIssuerRequest
) {
  const url = `/admin/issuers/${issuerId}`;
  return hub.patch(url, payload);
}

function createProspectus(payload: FormData) {
  const url = `/securities/prospectuses/`;
  return hubFormData.post(url, payload);
}

function updateProspectus(
  prospectusId: string,
  payload: IUpdateProspectusRequest
) {
  const url = `/securities/prospectuses/${prospectusId}/`;
  return hub.patch(url, payload);
}

function deleteProspectus(prospectusId: string) {
  const url = `/securities/prospectuses/${prospectusId}/`;
  return hub.delete(url);
}

function updateSecurity(securityId: string, payload: IUpdateSecurityRequest) {
  const url = `/admin/securities/${securityId}/`;
  return hub.patch(url, payload);
}

function deleteSecurity(securityId: string) {
  const url = `/securities/${securityId}/`;
  return hub.delete(url);
}

export {
  hub,
  createProspectus,
  deleteProspectus,
  deleteSecurity,
  updateAdminIssuer,
  updateIssuer,
  updateProspectus,
  updateSecurity,
};
