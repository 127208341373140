import classNames from "classnames";
import React from "react";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import { Broker } from "src/api/adapters/proxy/hooks/useCorporateActionBrokers";
import { Box } from "src/components/atoms/Box";

type BrokersListProps = {
  brokers: Broker[];
  selectedBrokerIds: number[];
  onAddBroker: (brokerId: number) => void;
  onRemoveBroker: (brokerId: number) => void;
  onSelectBroker: (broker: Broker) => void;
};

function BrokersList({
  brokers,
  selectedBrokerIds,
  onAddBroker,
  onRemoveBroker,
  onSelectBroker,
}: BrokersListProps): JSX.Element {
  return (
    <Box sx={{ py: 3 }}>
      {brokers.map((broker) => (
        <ParentBroker
          key={broker.id}
          broker={broker}
          onAddBroker={onAddBroker}
          onRemoveBroker={onRemoveBroker}
          onSelectBroker={onSelectBroker}
          selectedBrokerIds={selectedBrokerIds}
        />
      ))}
    </Box>
  );
}

type ParentBrokerProps = {
  broker: Broker;
  onAddBroker: (brokerId: number) => void;
  onRemoveBroker: (brokerId: number) => void;
  onSelectBroker: (broker: Broker) => void;
  selectedBrokerIds: number[];
};

function ParentBroker({
  broker,
  onAddBroker,
  onRemoveBroker,
  onSelectBroker,
  selectedBrokerIds,
}: ParentBrokerProps) {
  const [isShowingChildren, setIsShowingChildren] = React.useState(false);

  return (
    <>
      <Box
        key={broker.id}
        className="flex items-center px-2 py-4 border-b border-light-gray hover:bg-very-light-gray"
      >
        <BrokerField
          broker={broker}
          isChildBroker={false}
          onAddBroker={onAddBroker}
          onRemoveBroker={onRemoveBroker}
          onSelectBroker={onSelectBroker}
          positions={broker.totalPositions}
          selectedBrokerIds={selectedBrokerIds}
        />
        {isShowingChildren ? (
          <FaAngleDown
            className="cursor-pointer"
            onClick={() => setIsShowingChildren(false)}
            size={16}
          />
        ) : (
          <FaAngleRight
            className="cursor-pointer"
            onClick={() => setIsShowingChildren(true)}
            size={16}
          />
        )}
      </Box>
      {isShowingChildren &&
        broker.childBrokers?.map((childBroker) => (
          <Box
            key={childBroker.id}
            className="flex items-center px-2 py-4 border-b border-light-gray hover:bg-very-light-gray"
          >
            <BrokerField
              className="ml-4"
              broker={childBroker}
              isChildBroker={true}
              onAddBroker={onAddBroker}
              onRemoveBroker={onRemoveBroker}
              onSelectBroker={onSelectBroker}
              selectedBrokerIds={selectedBrokerIds}
              positions={childBroker.totalPositions}
            />
          </Box>
        ))}
    </>
  );
}

type BrokerFieldProps = {
  broker: Broker;
  className?: string;
  isChildBroker?: boolean;
  onAddBroker: (brokerId: number) => void;
  onRemoveBroker: (brokerId: number) => void;
  onSelectBroker: (broker: Broker) => void;
  positions: number;
  selectedBrokerIds: number[];
};

function BrokerField({
  broker,
  className,
  isChildBroker,
  onAddBroker,
  onRemoveBroker,
  onSelectBroker,
  positions,
  selectedBrokerIds,
}: BrokerFieldProps) {
  const { id, name } = broker;
  return (
    <Box
      className={className}
      sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}
    >
      <input
        id={String(id)}
        type="checkbox"
        className="checkbox appearance-none h-4 w-4 border border-light-gray rounded-sm mr-4 outline-none cursor-pointer"
        onChange={(event) => {
          const { checked, value } = event.currentTarget;

          if (checked) {
            onAddBroker(Number(value));
          } else {
            onRemoveBroker(Number(value));
          }
        }}
        checked={selectedBrokerIds.includes(id)}
        value={id}
      />
      <Box
        className="flex-grow font-sm text-primary-text cursor-pointer"
        onClick={() => onSelectBroker(broker)}
      >
        {name}
      </Box>
      <Box
        className={classNames({
          "mr-4": !isChildBroker,
          "mr-10": isChildBroker,
        })}
        component="span"
      >
        {positions}
      </Box>
    </Box>
  );
}

export { BrokersList };
