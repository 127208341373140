import { useState } from "react";
import useSWR from "swr";

import { useProxyEvents } from "src/api/adapters/nexus/hooks/useProxyEvents";
import { proxy } from "../../api/adapters/proxy";
import { getPageOffset } from "../../utils/get-page-offset";
import { FilingListFiling } from "./types";
import { nexus } from "src/api/adapters";

export type Response = {
  count: number;
  next: null | string;
  previous: null | string;
  results: FilingListFiling[];
};

type Query = {
  pageSize: number;
  page: number;
  ordering: OrderingKey;
};

export type OrderingKey =
  | "created"
  | "latest_edgar_filing_date"
  | "first_record_date"
  | "meeting_date"
  | "stats__internal_digital_send_deadline"
  | "issuer_name"
  | "stats__total_positions"
  | "stats__digital_send_deadline"
  | "issuer__company_name"
  | "solicitor__name"
  | "record_date"
  | "polymorphic_ctype"
  | "stats__estimated_digital_materials_expected_date"
  | "type"
  | "-digital_materials_expected_date";

export type OnUpdateQuery = (
  taskList: "drafts" | "ready" | "review" | "open",
  query: "pageSize" | "page" | "ordering",
  value: string | number | OrderingKey
) => void;

const fetcher = (url: string) => proxy.get<Response>(url);
const nexusFetcher = (url: string) => nexus.get<Response>(url);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useTasks() {
  const [draftsQuery, setRequestQuery] = useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: "first_record_date",
  });
  const [readyQuery, setReadyQuery] = useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: "-digital_materials_expected_date",
  });
  const [reviewQuery, setReviewQuery] = useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: "stats__internal_digital_send_deadline",
  });
  const [openQuery, setOpenQuery] = useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: "record_date",
  });

  const drafts = useSWR(
    `filings/?tab=drafts&limit=${draftsQuery.pageSize}&ordering=${
      draftsQuery.ordering
    }&offset=${getPageOffset({
      page: draftsQuery.page,
      pageSize: draftsQuery.pageSize,
    })}`,
    fetcher
  );
  const ready = useSWR(
    `filings/?tab=complete&limit=${readyQuery.pageSize}&ordering=${
      readyQuery.ordering
    }&offset=${getPageOffset({
      page: readyQuery.page,
      pageSize: readyQuery.pageSize,
    })}`,
    fetcher
  );
  const review = useSWR(
    `filings/?tab=review_emails&limit=${reviewQuery.pageSize}&ordering=${
      reviewQuery.ordering
    }&offset=${getPageOffset({
      page: reviewQuery.page,
      pageSize: reviewQuery.pageSize,
    })}`,
    fetcher
  );
  // The Open tab uses nexus
  const open = useSWR(
    `proxy-events/?tab=open&limit=${openQuery.pageSize}&ordering=${
      openQuery.ordering
    }&offset=${getPageOffset({
      page: openQuery.page,
      pageSize: openQuery.pageSize,
    })}`,
    nexusFetcher
  );
  const draftsProxyEvents = useProxyEvents(drafts.data);
  const readyProxyEvents = useProxyEvents(ready.data);
  const reviewProxyEvents = useProxyEvents(review.data);
  const openProxyEvents = useProxyEvents(open.data, true);

  const handleUpdateQuery: OnUpdateQuery = (taskList, query, value) => {
    switch (taskList) {
      case "drafts":
        setRequestQuery({ ...draftsQuery, [query]: value });
        break;
      case "ready":
        setReadyQuery({ ...readyQuery, [query]: value });
        break;
      case "review":
        setReviewQuery({ ...reviewQuery, [query]: value });
        break;
      case "open":
        setOpenQuery({ ...openQuery, [query]: value });
        break;
    }
  };

  return {
    handleUpdateQuery,
    draftsQuery,
    readyQuery,
    reviewQuery,
    openQuery,
    drafts,
    ready,
    review,
    open,
    draftsProxyEvents,
    readyProxyEvents,
    reviewProxyEvents,
    openProxyEvents,
  };
}

export { useTasks };
