import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { Tabs, Tab, Button, IconButton, Box } from "@mui/material/";
import { FormikProps } from "formik";
import moment from "moment";

import { IFilingCreateValues, OperationsStatus } from "../types";
import { formatCamelCaseString } from "../../../utils/format-camel-case";
import { formatDate } from "../../../utils/format-date";
import { Avatar } from "src/components/atoms/Avatar";
import { CusipTagList } from "src/components/CusipTagList";
import { ProxyStatusBadge } from "src/components/molecules/ProxyStatusBadge";
import { LabelField } from "src/components/molecules/LabelField";

type CreateHeaderProps = {
  formikBag: FormikProps<IFilingCreateValues>;
  errorBanner: boolean;
  setErrorBanner: (error: boolean) => void;
  badRequest: string;
};

export const CreateHeader = ({
  formikBag,
  errorBanner,
  setErrorBanner,
  badRequest,
}: CreateHeaderProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        zIndex: 1100,
        flexShrink: 0,
        position: "sticky",
        top: 0,
        left: "auto",
        right: 0,
        backgroundColor: "#FFFFFF",
        "& > *": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #e5e5e5",
          padding: "0 24px",
        },
      }}
    >
      <Box
        sx={{
          height: "58px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > *": {
              marginRight: "16px",
            },
          }}
        >
          <Avatar name="px" />
          <h1 className="font-medium text-primary-text text-xl capitalize inline-block">
            {formikBag.values.type
              ? formatCamelCaseString(formikBag.values.type)
              : "Draft Filing"}
          </h1>
          <ProxyStatusBadge filingStatus={OperationsStatus.New} />
        </Box>
      </Box>

      <Box
        id="ProxyDetailHeader"
        sx={{
          height: "64px",
          cursor: "default !important",
        }}
      >
        <Box
          sx={{
            width: "30%",
          }}
        >
          <LabelField label="Issuer">
            <div>
              {formikBag.values.issuer &&
              formikBag.values.issuer.companyName ? (
                formikBag.values.issuer.companyName
              ) : (
                <span style={{ color: "rgba(0,0,0,.38)" }}>Issuer</span>
              )}
            </div>
          </LabelField>
        </Box>
        <LabelField label="CUSIP">
          {formikBag.values.cusips.length > 0 ? (
            <CusipTagList
              cusips={formikBag.values.cusips.map((item) => item.cusip)}
            />
          ) : (
            <span style={{ color: "rgba(0,0,0,.38)" }}>CUSIP</span>
          )}
        </LabelField>

        <LabelField
          label="Filing Type"
          value={
            formikBag.values.type ? (
              formatCamelCaseString(formikBag.values.type)
            ) : (
              <span style={{ color: "rgba(0,0,0,.38)" }}>Filing Type</span>
            )
          }
        />

        <LabelField
          label="Record Date"
          value={
            formikBag.values.recordDate &&
            moment(new Date(formikBag.values.recordDate)).isValid() ? (
              formatDate(new Date(formikBag.values.recordDate))
            ) : (
              <span style={{ color: "rgba(0,0,0,.38)" }}>Record Date</span>
            )
          }
        />

        <LabelField label="EDGAR Date">
          <span style={{ color: "rgba(0,0,0,.38)" }}>N/A</span>
        </LabelField>
        <LabelField label="Positions">
          <span style={{ color: "rgba(0,0,0,.38)" }}>N/A</span>
        </LabelField>
        <LabelField label="Materials">
          <span style={{ color: "rgba(0,0,0,.38)" }}>N/A</span>
        </LabelField>
      </Box>
      <Box
        id="FilingDetailTab"
        sx={{
          minHeight: "48px",
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          value=""
        >
          <Tab label="Filing" value="" />
          <Tab label="Email" value="email" disabled />
        </Tabs>
        <div id="FilingDetailSave">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              setErrorBanner(false);
              if (formikBag.errors || !formikBag.isValid) {
                setErrorBanner(true);
              }
            }}
          >
            Add Filing
          </Button>
        </div>
      </Box>

      {/* Very basic - replace with error message(s) mapping fields to specific tabs. */}
      <Box
        sx={
          errorBanner
            ? {
                display: "flex",
                alignItems: "center",
                padding: "12px 24px",
                fontSize: ".75rem",
              }
            : {
                display: "none",
              }
        }
      >
        <div>
          <ErrorOutlineIcon style={{ fill: "#d33f33", marginRight: "12px" }} />
          <span>Incomplete submission. {badRequest}</span>
        </div>
        <IconButton size="small" onClick={() => setErrorBanner(false)}>
          <CancelIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  );
};
