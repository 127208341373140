import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useCorporateActionEvents } from "src/api/adapters/nexus/hooks/useCorporateActionEvents";
import { Avatar } from "src/components/atoms/Avatar";
import { Box } from "src/components/atoms/Box";
import { Tabs } from "src/components/atoms/Tabs";
import { PageHeader } from "src/components/molecules/PageHeader";
import { formatNumber } from "src/utils/format-number";
import { getTabFromUrl } from "src/utils/get-tab-from-url";

const DEFAULT_QUERY_PARAMS = {
  limit: 25,
  ordering: "deadline",
  offset: 0,
};

export function TaskListContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { count: newCount } = useCorporateActionEvents(
    "new",
    DEFAULT_QUERY_PARAMS
  );
  const { count: inProgressCount } = useCorporateActionEvents(
    "in_progress",
    DEFAULT_QUERY_PARAMS
  );
  const { count: openCount } = useCorporateActionEvents(
    "open",
    DEFAULT_QUERY_PARAMS
  );
  const { count: expiredCount } = useCorporateActionEvents(
    "historical",
    DEFAULT_QUERY_PARAMS
  );

  function onTabChange(value: string) {
    navigate(`/vca/tasks/${value}`);
  }

  return (
    <>
      <PageHeader
        icon={<Avatar name="ca" />}
        title="Voluntary Corporate Actions"
      />
      <Box id="VcaTabs" sx={{ pl: 6, borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          onChange={onTabChange}
          value={getTabFromUrl(location)}
          tabs={[
            {
              label: `New (${formatNumber(newCount || 0)})`,
              value: "new",
              testId: "VcaNewTab",
            },
            {
              label: `In Progress (${formatNumber(inProgressCount || 0)})`,
              value: "in-progress",
              testId: "VcaInProgressTab",
            },
            {
              label: `Open (${formatNumber(openCount || 0)})`,
              value: "open",
              testId: "VcaOpenTab",
            },
            {
              label: `Expired (${formatNumber(expiredCount || 0)})`,
              value: "historical",
              testId: "VcaExpiredTab",
            },
          ]}
        />
      </Box>
      <Outlet />
    </>
  );
}
