import MenuItem from "@mui/material/MenuItem";
import { TextField, Box } from "@mui/material";
import { Field, FieldProps, FormikProps } from "formik";
import { useEffect } from "react";

import { FilingDetailsFormValues } from "../types";
import { FilingDatePicker } from "./FilingDatePicker";
import { AdditionalRecordDates } from "./AdditionalRecordDates";
import { CusipTypeahead } from "src/components/typeaheads";
import { formatCamelCaseString } from "src/utils/format-camel-case";
import { useProxyWarningDispatch } from "../proxy-warning-context";

interface IBaseDetailsProps {
  additionalRecordDatesError: string;
  formikBag: FormikProps<FilingDetailsFormValues>;
  isDraft: boolean;
}

export function BaseDetails({
  additionalRecordDatesError,
  formikBag,
  isDraft,
}: IBaseDetailsProps) {
  const filing = formikBag.values;
  const dispatch = useProxyWarningDispatch();

  useEffect(() => {
    if (formikBag.dirty) {
      dispatch({ type: "MARK_DIRTY" });
    }
  }, [dispatch, formikBag.dirty]);

  return (
    <Box
      id="ProxyDetailsFormField"
      sx={{
        marginTop: "8px",
        "& > *": {
          margin: "12px 0",
        },
        "& > .MuiFormControl-root": {
          margin: "inherit",
        },
      }}
    >
      <TextField
        id="ProxyDetailFilingType"
        fullWidth
        required
        disabled
        variant="outlined"
        size="small"
        label="Filing Type"
        value={formatCamelCaseString(filing.type)}
      />
      <div id="ProxyDetailCusip">
        <Field name="cusips">
          {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
            <CusipTypeahead
              value={field.value}
              onSetCusips={(cusips) => form.setFieldValue("cusips", cusips)}
              issuerId={
                form.values.issuer ? String(form.values.issuer.id) : null
              }
              fieldError={formikBag.errors.cusips}
            />
          )}
        </Field>
      </div>

      <TextField
        size="small"
        required
        fullWidth
        id="issuer"
        name="issuer.name"
        label="Issuer"
        value={filing.issuer?.name}
        disabled
      />

      <Box sx={{ my: 3 }}>
        <FilingDatePicker
          required={true}
          label="Broker Search Date"
          name="brokerSearchReceivedDate"
          formikBag={formikBag}
        />
      </Box>

      <Box sx={{ my: 3 }}>
        <TextField
          size="small"
          fullWidth
          id="job-number"
          name="solicitorJobNumber"
          label="Solicitor Job Number"
          value={filing.solicitorJobNumber}
          onChange={formikBag.handleChange}
          error={
            formikBag.touched.solicitorJobNumber &&
            Boolean(formikBag.errors.solicitorJobNumber)
          }
          helperText={
            formikBag.touched.solicitorJobNumber &&
            formikBag.errors.solicitorJobNumber
          }
        />
      </Box>

      <div className="grid grid-cols-2 gap-6">
        <FilingDatePicker
          label="Record Date"
          name="recordDate"
          required
          formikBag={formikBag}
        />

        <FilingDatePicker
          label="Expected Date"
          name="expectedDate"
          formikBag={formikBag}
        />
      </div>

      <AdditionalRecordDates
        error={additionalRecordDatesError}
        formikBag={formikBag}
        additionalRecordDates={filing.additionalRecordDates}
      />

      {filing.type === "FundReport" && (
        <TextField
          size="small"
          select={true}
          required={!isDraft}
          fullWidth
          id="report-type"
          name="reportType"
          label="Report Type"
          value={filing.reportType}
          onChange={formikBag.handleChange}
          error={
            formikBag.touched.reportType && Boolean(formikBag.errors.reportType)
          }
          helperText={
            formikBag.touched.reportType && formikBag.errors.reportType
          }
        >
          <MenuItem value="annual">Annual</MenuItem>
          <MenuItem value="semi_annual">Semi-Annual</MenuItem>
        </TextField>
      )}

      {(filing.type === "MeetingContest" ||
        filing.type === "FundMeeting" ||
        filing.type === "FirmMeeting") && (
        <div className="grid grid-cols-2 gap-6">
          <FilingDatePicker
            label="Meeting Date"
            name="meetingDate"
            required={!isDraft}
            formikBag={formikBag}
          />
          <TextField
            size="small"
            select={true}
            required={!isDraft}
            fullWidth
            id="meeting-type"
            name="meetingType"
            label="Meeting Type"
            value={filing.meetingType}
            onChange={formikBag.handleChange}
            error={
              formikBag.touched.meetingType &&
              Boolean(formikBag.errors.meetingType)
            }
            helperText={
              formikBag.touched.meetingType && formikBag.errors.meetingType
            }
          >
            <MenuItem value="annual">Annual</MenuItem>
            <MenuItem value="special">Special</MenuItem>
          </TextField>
        </div>
      )}
      <Box sx={{ my: 3 }}>
        <TextField
          size="small"
          select={true}
          required
          fullWidth
          id="delivery-method"
          name="deliveryMethod"
          label="Delivery Method"
          value={filing.deliveryMethod}
          onChange={formikBag.handleChange}
          error={
            formikBag.touched.deliveryMethod &&
            Boolean(formikBag.errors.deliveryMethod)
          }
          helperText={
            formikBag.touched.deliveryMethod && formikBag.errors.deliveryMethod
          }
        >
          <MenuItem value="traditional">Traditional</MenuItem>
          <MenuItem value="notice_and_access">Notice and Access</MenuItem>
        </TextField>
      </Box>
      {filing.type === "MeetingContest" && (
        <>
          <Box sx={{ py: 1 }}>
            <TextField
              size="small"
              fullWidth
              required
              id="issuer-filing"
              name="issuerFiling"
              label="Issuer Filing"
              type="number"
              value={formikBag.values.issuerFiling}
              onChange={formikBag.handleChange}
              error={
                formikBag.touched.issuerFiling &&
                Boolean(formikBag.errors.issuerFiling)
              }
              helperText={
                formikBag.touched.issuerFiling && formikBag.errors.issuerFiling
              }
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box sx={{ py: 1 }}>
            <FilingDatePicker
              label="Vote Cutoff Date"
              name="voteCutoffDate"
              required
              formikBag={formikBag}
            />
          </Box>
          <Box sx={{ py: 1 }}>
            <TextField
              size="small"
              fullWidth
              required
              id="contesting-entity"
              name="contestingEntity"
              label="Contesting Entity"
              value={formikBag.values.contestingEntity}
              onChange={formikBag.handleChange}
              error={
                formikBag.touched.contestingEntity &&
                Boolean(formikBag.errors.contestingEntity)
              }
              helperText={
                formikBag.touched.contestingEntity &&
                formikBag.errors.contestingEntity
              }
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box sx={{ py: 1 }}>
            <TextField
              size="small"
              fullWidth
              id="minimum-shares"
              name="minimumShares"
              label="Minimum Shares"
              required
              value={formikBag.values.minimumShares}
              onChange={formikBag.handleChange}
              error={
                formikBag.touched.minimumShares &&
                Boolean(formikBag.errors.minimumShares)
              }
              helperText={
                formikBag.touched.minimumShares &&
                formikBag.errors.minimumShares
              }
              InputLabelProps={{ shrink: true }}
              type="number"
            />
          </Box>
        </>
      )}
    </Box>
  );
}
