function getPageOffset({
  page,
  pageSize,
}: {
  page: number;
  pageSize: number;
}): number {
  const offset = page === 0 ? 0 : page * pageSize;

  return offset;
}

export { getPageOffset };
