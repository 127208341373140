import { toast } from "react-toastify";
import { sendTestAdditionalMailingEmailWarrenG } from "src/api/adapters";
import { useAdditionalMailingEmailPreview } from "src/api/adapters/nexus/hooks/useAdditionalMailingEmailPreview";
import { AdditionalMailingBroker } from "src/types/additionalMailing";
import { EmailPreviewPane } from "./EmailPreviewPane";

interface IAdditionalMailingEmailPreviewPaneProps {
  additionalMailingId: string;
  broker: AdditionalMailingBroker | null | undefined;
  proxyEventId: string;
}

export function AdditionalMailingEmailPreviewPane({
  additionalMailingId,
  broker,
  proxyEventId,
}: IAdditionalMailingEmailPreviewPaneProps) {
  const { preview, isLoading, isError } = useAdditionalMailingEmailPreview(
    proxyEventId,
    additionalMailingId,
    broker?.id || null
  );

  const handleSendTestEmail = async (emails: string[]) => {
    if (broker) {
      try {
        await sendTestAdditionalMailingEmailWarrenG(
          proxyEventId,
          additionalMailingId,
          broker.id,
          { emails }
        );
        toast.success("Test email sent!");
      } catch (e) {
        toast.error("An error occurred when trying to send a test email.");
      }
    }
  };

  return (
    <EmailPreviewPane
      brokerName={broker?.name}
      isLoading={isLoading}
      hasError={isError}
      onSendTestEmail={handleSendTestEmail}
      preview={preview}
    />
  );
}
