import { SxProps } from "@mui/material";
import { ReactNode } from "react";
import { Box } from "../atoms/Box";

interface ILabelFieldProps {
  label: string;
  value?: string | JSX.Element | null | number;
  sx?: SxProps;
  children?: ReactNode | JSX.Element;
}

export function LabelField({ label, value, children, sx }: ILabelFieldProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        ...sx,
      }}
    >
      <Box
        sx={{
          fontSize: "12px",
          fontWeight: "bold",
          pb: 1,
          whiteSpace: "nowrap",
        }}
      >
        {label}
      </Box>
      {children ? children : <div>{value}</div>}
    </Box>
  );
}
