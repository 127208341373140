import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { updateVoluntaryCorporateActionStatus } from "src/api/adapters";

import { Dialog } from "../atoms/dialog/Dialog";

interface IDeleteVcaFilingDialogProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteVcaFilingDialog = ({
  id,
  isOpen,
  onClose,
}: IDeleteVcaFilingDialogProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnDelete = async () => {
    setIsLoading(true);
    try {
      await updateVoluntaryCorporateActionStatus(id, {
        operationsStatus: "closed",
      });
      navigate("/vca/tasks/new");
    } catch (e) {
      toast.error(
        "Unable to delete vca filing. Please try again or contact support."
      );
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      isPrimaryLoading={isLoading}
      open={isOpen}
      onClose={onClose}
      onPrimaryClick={handleOnDelete}
      onSecondaryClick={onClose}
      secondaryButtonText="Cancel"
      primaryButtonColor="error"
      primaryButtonText="Delete"
      title="Delete Corporate Action"
    >
      Are you sure you want to delete this Corporate Action?
    </Dialog>
  );
};
