import { AxiosRequestConfig } from "axios";
import useSWR from "swr";

import { PaginatedResponse } from "src/types/PaginatedResponse";
import { nexus } from "..";
import { IQueryParams } from "../../types";
import { Nobo } from "src/types/nobo";

type ListType = "new" | "approved" | "delivered";

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) =>
  nexus.get<PaginatedResponse<Nobo>>(url, { params });

export function useNobos(
  listType: ListType,
  { limit, ordering, offset }: IQueryParams
) {
  const url = `/nobo-events/?tab=${listType}&limit=${limit}&ordering=${ordering}&offset=${offset}`;
  const { data, error } = useSWR(url, fetcher);

  return {
    count: data?.data?.count,
    isError: error,
    isLoading: !error && !data,
    nobos: data?.data?.results,
  };
}
