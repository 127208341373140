import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";

import { ThumbnailList } from "./ThumbnailList";
import { PreviewPane } from "./PreviewPane";
import { SendTestForm } from "./SendTestForm";
import { PreviewViewSwitcher } from "./PreviewViewSwitcher";
import { getTemplateApi, getCommunicationApi } from "./endpointGenerators";
import { FilingType } from "../types";
import { swrDisablePolling } from "../../../constants/configs";
import { EmptyCard } from "../../../components/EmptyCard";
import { Box } from "src/components/atoms/Box";
import { useBrokers } from "src/api/adapters/proxy/hooks/useBrokers";
import { useProxy } from "src/api/adapters/proxy/hooks/useProxy";

type ReviewEmailParams = {
  id: string;
};

const ReviewEmail = (): JSX.Element => {
  const params = useParams() as ReviewEmailParams;
  const { id: filingId } = params;
  const { filing } = useProxy(filingId);
  const { brokers } = useBrokers(filingId);
  const [searchParams, setSearchParams] = useSearchParams();
  const initBroker = searchParams.get("broker") || "";
  const initView = searchParams.get("view") || "email";
  const [broker, setBroker] = useState(initBroker);
  const [view, setView] = useState(initView);

  useEffect(() => {
    if (brokers && brokers.length > 0 && broker === "") {
      setBroker(brokers[0].slug);
      setView("email");
    }
  }, [brokers]);

  const communicationApi = getCommunicationApi({
    filingId,
    broker,
  });
  const templateApi = getTemplateApi({
    filingId,
    broker,
  });

  const { data: communication } = useSWR(communicationApi, swrDisablePolling);
  const { data: template } = useSWR(templateApi, swrDisablePolling);

  const isVoting = filing
    ? filing.type === FilingType.FirmMeeting ||
      filing.type === FilingType.FundMeeting ||
      filing.type === FilingType.FirmConsentSolicitation
    : false;

  if (brokers?.length) {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateAreas:
            "'thumbnailList topBar' 'thumbnailList previewPane'",
          gridTemplateColumns: "220px 1fr",
          gridTemplateRows: "40px 1fr",
          gridRowGap: "24px",
          width: "100%",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <ThumbnailList
          filingId={filingId}
          onClick={(slug) => {
            setBroker(slug);
            setSearchParams({ broker: slug, view });
          }}
          selectedBroker={broker}
        />
        <Box
          sx={{
            background: "#fff",
            gridArea: "topBar",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 24px",
            position: "sticky",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <SendTestForm filingId={filingId} selectedBroker={broker} />
          <PreviewViewSwitcher
            isVoting={isVoting}
            onChange={(view) => {
              setView(view);
              setSearchParams({ broker, view });
            }}
            selectedView={view}
          />
        </Box>
        <PreviewPane
          template={template}
          communication={communication}
          selectedView={view}
        />
      </Box>
    );
  }

  return (
    <EmptyCard
      body="We don't have any positions for this filing."
      sx={{ p: 6 }}
      title="No emails to review."
    />
  );
};

export { ReviewEmail };
