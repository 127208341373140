import axios from "axios";
import jwt_decode from "jwt-decode";

import { getTokenFromCookies } from "./getToken";
import { setAuthToken } from "./setToken";
import { removeAuthToken } from "./removeToken";

interface IJWT {
  exp: number;
  refresh_token: string;
}

const refreshTokenIfExpired = () => {
  const token = getTokenFromCookies();
  if (token) {
    const decodedJWT: IJWT = jwt_decode(token);
    const nowInSeconds = Math.floor(Date.now() / 1000);
    const { exp, refresh_token } = decodedJWT;
    return new Promise((resolve, reject) => {
      if (nowInSeconds > exp) {
        axios
          .post(`${process.env.REACT_APP_SSO_URL}/v1/auth/refresh-token/`, {
            token: refresh_token,
          })
          .then((response) => {
            const { access_token } = response.data;
            setAuthToken(access_token);
            resolve(access_token);
          })
          .catch((error) => {
            localStorage.clear();
            removeAuthToken();
            window.location.href = window.location.origin;
            reject(error);
          });
      } else {
        resolve(token);
      }
    });
  }
  return null;
};

export { refreshTokenIfExpired };
