import Cookies from "js-cookie";

const setAuthToken = (token: string): void => {
  const domainParts = window.location.hostname.split(".");
  const protocol = window.location.protocol;
  const topDomain = domainParts.slice(-2).join(".");
  const domain =
    process.env.NODE_ENV === "production"
      ? topDomain
      : window.location.hostname;

  Cookies.set("authToken", token, {
    path: "/",
    domain,
    sameSite: "strict",
    secure: protocol === "https:" ? true : false,
  });
  return;
};

export { setAuthToken };
