import { MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import { Box } from "src/components/atoms/Box";
import { FormSection } from "src/components/molecules/FormSection";
import { LabelField } from "src/components/molecules/LabelField";
import { AdditionalMailingBroker } from "src/types/additionalMailing";
import { formatNumber } from "src/utils/format-number";

interface IAdditionalMailingBrokerStatsSectionProps {
  eligibleBrokers: AdditionalMailingBroker[];
}

export function AdditionalMailingBrokerStatsSection({
  eligibleBrokers,
}: IAdditionalMailingBrokerStatsSectionProps) {
  const [broker, setBroker] = useState<AdditionalMailingBroker | null>(null);

  useEffect(() => {
    setBroker(null);
  }, [eligibleBrokers]);

  const handleBrokerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedBroker = eligibleBrokers?.find(
      (broker) => broker.id === e.target.value
    );
    setBroker(selectedBroker || null);
  };

  return (
    <FormSection title="Broker Stats">
      <TextField
        sx={{ my: 2 }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Broker"
        size="small"
        select={true}
        onChange={handleBrokerChange}
        value={broker?.id || ""}
      >
        {eligibleBrokers?.map((broker, index) => {
          return (
            <MenuItem key={index} value={broker.id}>
              {broker.name}
            </MenuItem>
          );
        })}
      </TextField>
      <Box
        sx={{
          display: "flex",
          mt: 4,
          p: 4,
          justifyContent: "space-between",
          border: "1px solid #e5e5e5",
          backgroundColor: "#f8f8f8",
        }}
      >
        {!broker ? (
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            Select a broker to view stats
          </Box>
        ) : (
          <>
            <LabelField label="Communication Count">
              {broker.additionalMailingBrokerStats.communicationCount
                ? formatNumber(
                    broker.additionalMailingBrokerStats.communicationCount
                  )
                : "0"}
            </LabelField>
            <LabelField label="Total Shares">
              {broker.additionalMailingBrokerStats.totalShares || "0"}
            </LabelField>
            <LabelField label="Full Share Notification Total">
              {broker.additionalMailingBrokerStats.fullShareNotificationCount
                ? formatNumber(
                    broker.additionalMailingBrokerStats
                      .fullShareNotificationCount
                  )
                : "0"}
            </LabelField>
            <LabelField label="Non-Full Share Notification Total">
              {broker.additionalMailingBrokerStats.nonFullShareNotificationCount
                ? formatNumber(
                    broker.additionalMailingBrokerStats
                      .nonFullShareNotificationCount
                  )
                : "0"}
            </LabelField>
          </>
        )}
      </Box>
    </FormSection>
  );
}
