import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";

import { Box } from "../atoms/Box";
import { DatePicker } from "../atoms/DatePicker";
import { Dialog } from "../atoms/dialog/Dialog";
import { updateProspectusDocumentNexus } from "../../api/adapters/nexus";
import { Label } from "../Label";
import {
  ProspectusDocument,
  ProspectusPackage,
} from "../../api/adapters/nexus/types";

export const TYPE_OPTIONS = [
  { value: "summary", label: "Summary" },
  { value: "sticker", label: "Sticker" },
  {
    value: "statement_of_additional_information",
    label: "Statement of Additional Information",
  },
  { value: "statutory", label: "Statutory" },
];

type EditDocumentForm = {
  effectiveDate: Date;
  name: string;
  type: string;
};

interface IEditProspectusDocumentDialogProps {
  doc: ProspectusDocument;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
  prospectus: ProspectusPackage;
}

export function EditProspectusDocumentDialog({
  doc,
  isOpen,
  onClose,
  onUpdate,
  prospectus,
}: IEditProspectusDocumentDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { effectiveDate, name, type } = doc;
  const [editForm, setEditForm] = useState<EditDocumentForm>({
    effectiveDate: new Date(effectiveDate + "T00:00:00"),
    name,
    type,
  });
  useEffect(() => {
    if (doc) {
      setEditForm({
        name: doc.name,
        type: doc.type,
        effectiveDate: new Date(doc.effectiveDate + "T00:00:00"),
      });
    }
  }, [doc]);

  const getDocumentNameFromType = (type: string) => {
    switch (type) {
      case "summary":
        return "Summary Prospectus";
      case "statutory":
        return "Statutory Prospectus";
      case "sticker":
        return "Sticker";
      case "statement_of_additional_information":
        return "Statement of Additional Information";
      default:
        return "";
    }
  };

  const handleDocumentTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const type = event.currentTarget.value;
    setEditForm({
      ...editForm,
      name: getDocumentNameFromType(type),
      type,
    });
  };

  const handleDocumentNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = event.currentTarget.value;
    setEditForm({
      ...editForm,
      name,
    });
  };

  const handleDocumentEffectiveDateChange = (effectiveDate: Date | null) => {
    if (effectiveDate) {
      setEditForm({
        ...editForm,
        effectiveDate,
      });
    }
  };

  const handleSubmit = async () => {
    const { effectiveDate, name, type } = editForm;
    try {
      setIsLoading(true);
      await updateProspectusDocumentNexus(prospectus.id, doc.id, {
        name,
        effectiveDate: format(effectiveDate, "yyyy-MM-dd"),
        type,
      });
      toast.success("Document updated.");
    } catch (error) {
      toast.error("An error occurred. Unable to update document.");
    } finally {
      onUpdate();
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      container={document.getElementById("proxy-body")}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={isOpen}
      onPrimaryClick={handleSubmit}
      onSecondaryClick={onClose}
      secondaryButtonText="Cancel"
      primaryButtonText="Save"
      isPrimaryLoading={isLoading}
      title="Edit Document"
    >
      <form className="w-full" id="edit-prospectus-document">
        <Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
          <Label>Document Type</Label>
          <select
            className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
            name="type"
            value={editForm.type}
            onChange={handleDocumentTypeChange}
          >
            <option value="summary">Summary Prospectus</option>
            <option value="statutory">Statutory Prospectus</option>
            <option value="sticker">Sticker</option>
            <option value="statement_of_additional_information">
              Statement of Additional Information
            </option>
          </select>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
          <Label>Document Name</Label>
          <input
            type="text"
            name="name"
            className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
            required={true}
            onChange={handleDocumentNameChange}
            value={editForm.name}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
          <Label>Effective Date</Label>
          <DatePicker
            name="effectiveDate"
            required={true}
            onChange={handleDocumentEffectiveDateChange}
            selected={editForm.effectiveDate}
          />
        </Box>
      </form>
    </Dialog>
  );
}
