import { DatePicker } from "@mui/lab";
import {
  Autocomplete,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { documentTypeOptions } from "src/features/Proxy/select-options";
import {
  Document as SayDocument,
  DocumentType,
  FilingDetails,
  Security,
} from "src/features/Proxy/types";
import { Dialog } from "../atoms/dialog/Dialog";
import { getSecuritiesFromIds } from "./utils";

interface ISayDocumentDialogProps {
  document?: SayDocument;
  filing: FilingDetails;
  isOpen: boolean;
  onClose: () => void;
  onCreateUpdate: (values: SayDocumentForm) => void;
}

export type SayDocumentForm = {
  name: string;
  type: DocumentType | "";
  securities: Security[];
  shouldDistribute: boolean;
  effectiveDate: string | null;
};

export function SayDocumentDialog({
  document,
  filing,
  isOpen,
  onClose,
  onCreateUpdate,
}: ISayDocumentDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    reset,
    watch,
  } = useForm<SayDocumentForm>({
    defaultValues: {
      name: document?.name || "",
      type: document?.type || "",
      securities: document
        ? getSecuritiesFromIds(filing.securities, document.securities)
        : filing.securities || [],
      shouldDistribute: document
        ? document.shouldDistributeInNextMailing
        : false,
      effectiveDate: document?.effectiveDate || null,
    },
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  useEffect(() => {
    // Want to re-initalize the form once we
    // receive an updated document
    if (document) {
      reset({
        name: document.name,
        type: document.type,
        securities: getSecuritiesFromIds(
          filing.securities,
          document.securities
        ),
        shouldDistribute: document.shouldDistributeInNextMailing,
        effectiveDate: document.effectiveDate,
      });
    }
  }, [document]);

  const type = watch("type");
  // Using useController hook for type b/c we want access to the field
  // We need access to the field b/c need to implement some side effects
  // when changing type value.
  const {
    field: typeField,
    fieldState: { error: fieldError },
  } = useController({
    name: "type",
    control,
    rules: { required: true },
  });

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    typeField.onChange(value);

    // Side Effect: When we change the document type we want to set the name to be the
    // document type's label except when the user selects "Other".
    const label =
      documentTypeOptions.find((type) => type.value === value)?.label || "";
    setValue("name", value === "other" ? "" : label);
    // Side Effect: We want to set the effectiveDate to null b/c if we're updating the type from a prospectus document
    // to a non-prospectus document we want to set the effectiveDate to the current date/time which is done
    // in the submit handler.
    setValue("effectiveDate", null);
    // Side Effect: If document type is Proxy Card, Statment of Additional Information,
    // or Statutory Prospectus set default to false
    setValue(
      "shouldDistribute",
      value === DocumentType.ProxyCard ||
        value === DocumentType.StatementOfAdditionalInformation ||
        value === DocumentType.StatutoryProspectus
        ? false
        : true
    );
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      title={`${document ? "Update" : "Create"} Document`}
      primaryButtonText={document ? "Update" : "Create"}
      secondaryButtonText="Cancel"
      onPrimaryClick={handleSubmit(onCreateUpdate)}
      onSecondaryClick={onClose}
      onClose={onClose}
      isPrimaryLoading={isSubmitting}
    >
      <TextField
        {...typeField}
        sx={{ my: 2 }}
        InputLabelProps={{ shrink: true }}
        error={!!fieldError}
        fullWidth
        onChange={handleTypeChange}
        label="Document Type*"
        size="small"
        select={true}
      >
        <MenuItem value="" disabled />
        {documentTypeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <Controller
        control={control}
        name="name"
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              sx={{ my: 2 }}
              InputLabelProps={{ shrink: true }}
              error={!!fieldState.error}
              fullWidth
              label="Name*"
              size="small"
            />
          );
        }}
      />
      <Controller
        control={control}
        name="securities"
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <Autocomplete
              {...field}
              multiple
              options={filing.securities}
              getOptionLabel={(option) => option.cusip || ""}
              filterSelectedOptions
              ChipProps={{ size: "small" }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ my: 2 }}
                  InputLabelProps={{ shrink: true }}
                  label="CUSIP"
                  size="small"
                />
              )}
              onChange={(_, value) => setValue("securities", value)}
            />
          );
        }}
      />
      {(type === DocumentType.SummaryProspectus ||
        type === DocumentType.StatutoryProspectus ||
        type === DocumentType.StatementOfAdditionalInformation ||
        type === DocumentType.Sticker) && (
        <Controller
          control={control}
          name="effectiveDate"
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <DatePicker
                value={field.value}
                onChange={(date) => {
                  field.onChange(date);
                }}
                renderInput={(props: TextFieldProps) => {
                  return (
                    <TextField
                      {...props}
                      sx={{ my: 2 }}
                      error={!!fieldState.error}
                      InputLabelProps={{ shrink: true }}
                      id="effectiveDate"
                      label="Effective Date"
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  );
                }}
              />
            );
          }}
        />
      )}
      <FormControlLabel
        sx={{ my: 2 }}
        control={
          <Controller
            control={control}
            name="shouldDistribute"
            render={({ field }) => (
              <Switch id="should-distribute" {...field} checked={field.value} />
            )}
          />
        }
        label="Distribute in next mailing"
      />
    </Dialog>
  );
}
