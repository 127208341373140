import React from "react";
import classNames from "classnames";
import { default as ReactDatePicker } from "react-datepicker";
import { IBaseProps } from "../../types/BaseProps";

interface IDatePickerProps extends IBaseProps {
  dateFormat?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  hasBorders?: boolean;
  name?: string;
  onChange: (date: Date) => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  placeholder?: string;
  required?: boolean;
  selected: Date | null | undefined;
}

export function DatePicker({
  className,
  dateFormat,
  disabled = false,
  fullWidth = false,
  hasBorders = true,
  name,
  onChange,
  onFocus,
  onKeyDown,
  placeholder = "",
  required = false,
  selected,
}: IDatePickerProps) {
  return (
    <ReactDatePicker
      className={classNames(
        "border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none",
        className,
        {
          "w-full": fullWidth,
          "border border-light-gray rounded focus:border-primary-text":
            hasBorders,
        }
      )}
      dateFormat={dateFormat}
      disabled={disabled}
      name={name}
      onChange={(date) => {
        if (date && !Array.isArray(date)) {
          onChange(date);
        }
      }}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      placeholderText={placeholder}
      required={required}
      selected={selected}
    />
  );
}
