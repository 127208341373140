import React from "react";
import { useParams } from "react-router-dom";
import { SectionTitle } from "../SectionTitle";
import { ItemRow } from "../ItemRow";
import { SecurityRow } from "../SecurityRow";
import { ItemRowColumn } from "../ItemRowColumn";
import { ActionButtons } from "../ActionButtons";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select";
import {
  Formik,
  Field,
  FormikProps,
  FieldProps,
  FormikActions,
  Form,
} from "formik";
import { updateAdminIssuer } from "../../../api/adapters/hub";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { humanize } from "../../../utils/humanize";
import { IssuerType, Security } from "..";
import { PageWrapper } from "../../../components/PageWrapper";
import axios from "axios";

interface IDetailsProps {
  name: string;
  issuerType: null | IssuerType;
  cik: null | string;
  securities: Security[];
}

export interface IssuerFormValues {
  name: string;
  cik: string;
  issuerType: IssuerType;
}

const issuerTypeOptions = [
  { value: "corporation", label: "Corporation" },
  { value: "investment_trust", label: "Investment Trust" },
  { value: "government", label: "Government" },
];

type DetailsParams = {
  id: string;
};

function Details({
  name,
  issuerType,
  cik,
  securities,
}: IDetailsProps): JSX.Element {
  const { id } = useParams() as DetailsParams;
  const [isEditingIssuer, setIsEditingIssuer] = React.useState(false);

  const handleSetEditing = () => setIsEditingIssuer(true);
  const handleCancelEditing = () => setIsEditingIssuer(false);
  const handleSaveIssuer = async (
    values: IssuerFormValues,
    actions: FormikActions<IssuerFormValues>
  ) => {
    const { cik, issuerType, name } = values;
    actions.setSubmitting(true);
    try {
      const response = await updateAdminIssuer(id, {
        name,
        cik,
        issuerType,
      });

      mutate(`/admin/issuers/${id}/`, response);
      setIsEditingIssuer(false);
      actions.resetForm();
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      if (axios.isAxiosError(error) && error?.response?.data) {
        toast.error(JSON.stringify(error.response.data));
      } else {
        toast.error("An error occurred while trying to save this issuer.");
      }
    }
  };

  const handleActionSuccess = () => {
    mutate(`/admin/issuers/${id}/`);
  };

  return (
    <PageWrapper>
      <SectionTitle>Issuer</SectionTitle>
      <div data-testid="Issuer_box">
        {isEditingIssuer && (
          <Formik
            initialValues={{
              name,
              issuerType: issuerType || "corporation",
              cik: cik || "",
            }}
            onSubmit={handleSaveIssuer}
            render={({
              isValid,
              isSubmitting,
            }: FormikProps<IssuerFormValues>) => (
              <Form data-testid="Issuer_form">
                <ItemRow className="grid-cols-4">
                  <Field
                    name="name"
                    render={({ field }: FieldProps<IssuerFormValues>) => (
                      <ItemRowColumn label="issuer name">
                        <Input {...field} />
                      </ItemRowColumn>
                    )}
                  />
                  <Field
                    name="issuerType"
                    render={({ field }: FieldProps<IssuerFormValues>) => (
                      <ItemRowColumn label="issuer type">
                        <Select
                          options={issuerTypeOptions}
                          {...field}
                          onChange={field.onChange}
                        />
                      </ItemRowColumn>
                    )}
                  />
                  <Field
                    name="cik"
                    render={({ field }: FieldProps<IssuerFormValues>) => (
                      <ItemRowColumn label="edgar cik">
                        <Input {...field} />
                      </ItemRowColumn>
                    )}
                  />
                  <div
                    className="flex items-center"
                    style={{ justifySelf: "end" }}
                  >
                    <button
                      className="font-medium text-cobalt hover:underline mr-4 disabled:text-light-gray disabled:cursor-not-allowed disabled:no-underline"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      {isSubmitting ? "Saving..." : "Save"}
                    </button>
                    <button
                      onClick={handleCancelEditing}
                      className="font-medium text-gray hover:text-cobalt"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </ItemRow>
              </Form>
            )}
          />
        )}
        {!isEditingIssuer && (
          <ItemRow className="grid-cols-4">
            <ItemRowColumn label="issuer name">
              <p>{name}</p>
            </ItemRowColumn>
            <ItemRowColumn label="issuer type">
              <p className="capitalize">{humanize(issuerType || "")}</p>
            </ItemRowColumn>
            <ItemRowColumn label="edgar cik">
              <p>{cik}</p>
            </ItemRowColumn>

            <ActionButtons onEdit={handleSetEditing} />
          </ItemRow>
        )}
      </div>
      <div className="mt-8" data-testid="securities">
        <SectionTitle>Securities</SectionTitle>
        {securities.map((security) => (
          <SecurityRow
            {...security}
            key={security.id}
            onSave={handleActionSuccess}
            onDelete={handleActionSuccess}
            data-testid="security_row"
          />
        ))}
      </div>
    </PageWrapper>
  );
}

export { Details };
