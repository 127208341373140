import { createFilterOptions } from "@mui/material/Autocomplete";
import { FilterOptionsState } from "@mui/material/useAutocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import debounce from "lodash.debounce";
import { useState, useEffect, useCallback } from "react";
import useSWR from "swr";

import { nexus } from "src/api/adapters";
import { PaginatedResponse } from "src/types/PaginatedResponse";
import { Security } from "src/types/vca";

type AutoCompleteFilter = (
  options: Security[],
  state: FilterOptionsState<Security>
) => unknown[];

interface ICusipFieldProps {
  hasError?: boolean;
  label: string;
  setSecurities: (securities: Security[]) => void;
  options?: Security[];
  values: Security[] | [];
}

const filter: AutoCompleteFilter = createFilterOptions();
const fetcher = (url: string) => nexus.get<PaginatedResponse<Security>>(url);

export const CusipField = ({
  hasError,
  label,
  options,
  setSecurities,
  values,
}: ICusipFieldProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");
  const [query, setQuery] = useState("");
  const debounceSetQuery = useCallback(debounce(setQuery, 200), []);
  const { data, error } = useSWR(query, fetcher, {
    revalidateOnFocus: false,
  });
  const loading = !data && !error;

  useEffect(() => {
    const queryTemplate = `/securities/?cusip={{searchTerm}}&issuer_id={{issuerId}}`;
    debounceSetQuery(
      queryTemplate
        .replace("{{searchTerm}}", encodeURIComponent(searchTerm))
        .replace(
          "{{issuerId}}",
          encodeURIComponent(
            values.length > 0 ? values[0]?.issuer?.id || "" : ""
          )
        )
    );
  }, [searchTerm, debounceSetQuery, values]);

  return (
    <Autocomplete
      multiple
      limitTags={3}
      options={options ? options : data?.data?.results || []}
      value={values}
      loading={loading}
      inputValue={searchTerm}
      getOptionLabel={(option) => option.cusip}
      filterOptions={(options, params) => {
        return filter(options, params) as Security[];
      }}
      isOptionEqualToValue={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value) || !value.id
      }
      filterSelectedOptions
      ChipProps={{ size: "small" }}
      onChange={(_, changeValue) => {
        return setSecurities(changeValue);
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === "clear") {
          setSecurities([]);
          setSearchTerm("");
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ my: 2 }}
          error={hasError}
          InputLabelProps={{ shrink: true }}
          label={label}
          size="small"
        />
      )}
    />
  );
};
