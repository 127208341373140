import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  createAdditionalMailing,
  updateAdditionalMailing,
} from "src/api/adapters";
import { Dialog } from "src/components/atoms/dialog/Dialog";
import {
  AdditionalMailing,
  AdditionalMailingType,
  Audience,
} from "src/types/additionalMailing";
import { capitalize } from "src/utils/capitalize";

interface IAdditionalMailingDialogProps {
  additionalMailing?: AdditionalMailing;
  open: boolean;
  onClose: () => void;
  proxyEventId: string;
  onUpdate: () => void;
}

type AdditionalMailingForm = {
  audience: Audience | null;
  dateReceived: string | null;
  frontLink: string;
  minimumShares: number | null;
  type: AdditionalMailingType | null;
};

export function AdditionalMailingDialog({
  additionalMailing,
  open,
  onClose,
  proxyEventId,
  onUpdate,
}: IAdditionalMailingDialogProps) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm<AdditionalMailingForm>({
    defaultValues: {
      audience: additionalMailing?.audience || null,
      dateReceived: additionalMailing?.dateReceived || null,
      frontLink: additionalMailing?.frontLink || "",
      minimumShares: additionalMailing?.minimumShares || null,
      type: additionalMailing?.type || null,
    },
  });

  useEffect(() => {
    if (additionalMailing) {
      reset({
        audience: additionalMailing.audience,
        dateReceived: additionalMailing.dateReceived,
        frontLink: additionalMailing.frontLink,
        minimumShares: additionalMailing.minimumShares,
        type: additionalMailing.type,
      });
    }
  }, [additionalMailing]);

  const handleOnSubmit: SubmitHandler<AdditionalMailingForm> = async (
    values
  ) => {
    const { dateReceived, type, frontLink, audience, minimumShares } = values;
    try {
      if (type && dateReceived && audience && minimumShares !== null) {
        if (additionalMailing) {
          await updateAdditionalMailing(proxyEventId, additionalMailing.id, {
            audience,
            dateReceived: moment(dateReceived).format("yyyy-MM-DD"),
            frontLink,
            minimumShares,
            type,
          });
        } else {
          await createAdditionalMailing(proxyEventId, {
            audience,
            dateReceived: moment(dateReceived).format("yyyy-MM-DD"),
            frontLink,
            minimumShares,
            type,
          });
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e) && e?.response?.data?.length > 0) {
        const errorMessage = e?.response?.data[0];
        toast.error(capitalize(errorMessage));
      } else {
        toast.error(
          `An error occured when trying to ${
            additionalMailing ? "update" : "create"
          } the additional mailing. Please try ${
            additionalMailing ? "updating" : "creating"
          } the additional mailing again.`
        );
      }
    } finally {
      handleOnClose();
      onUpdate();
    }
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <Dialog
      fullWidth
      onClose={handleOnClose}
      onPrimaryClick={handleSubmit(handleOnSubmit)}
      onSecondaryClick={handleOnClose}
      open={open}
      title={
        additionalMailing
          ? "Update Additional Mailing"
          : "Create Additional Mailing"
      }
      isPrimaryDisabled={!isDirty && !!additionalMailing}
      primaryButtonText={additionalMailing ? "Update" : "Create"}
      secondaryButtonText="Cancel"
      isPrimaryLoading={isSubmitting}
    >
      <form id="additional-mailings-form">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Controller
            control={control}
            name="type"
            rules={{ required: "Type is required." }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  sx={{ my: 2 }}
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error}
                  fullWidth
                  label="Additional Mailing Type*"
                  size="small"
                  select={true}
                  data-testid="addtional_mailing_type"
                >
                  <MenuItem key="vote_reminder" value="vote_reminder">
                    Vote Reminder
                  </MenuItem>
                  <MenuItem key="informational" value="informational">
                    Other (Informational)
                  </MenuItem>
                </TextField>
              );
            }}
          />
          <Controller
            control={control}
            name="dateReceived"
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <DatePicker
                  value={field.value}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  renderInput={(props: TextFieldProps) => {
                    return (
                      <TextField
                        {...props}
                        sx={{ my: 2 }}
                        error={!!fieldState.error}
                        InputLabelProps={{ shrink: true }}
                        id="dateReceived"
                        label="Date Received"
                        size="small"
                        variant="outlined"
                        fullWidth
                        data-testid="date_received"
                      />
                    );
                  }}
                />
              );
            }}
          />
          <Controller
            name="frontLink"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 2 }}
                  error={!!fieldState.error}
                  label="Front Link*"
                  size="small"
                  fullWidth
                  data-testid="front_link"
                />
              );
            }}
          />
          <Controller
            name="minimumShares"
            control={control}
            rules={{
              required: true,
              min: {
                value: 0,
                message: "Minimum Shares can not be negative.",
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  sx={{ my: 2 }}
                  error={!!fieldState.error}
                  label="Position Size*"
                  size="small"
                  type="number"
                  fullWidth
                  data-testid="position_size"
                />
              );
            }}
          />
          <Controller
            control={control}
            name="audience"
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  sx={{ my: 2 }}
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error}
                  fullWidth
                  label="Audience*"
                  size="small"
                  select={true}
                  data-testid="audience"
                >
                  <MenuItem key="informational" value="all_shareholders">
                    All Shareholders
                  </MenuItem>
                  <MenuItem key="vote_reminder" value="not_voted_shareholders">
                    Not Voted Shareholders
                  </MenuItem>
                </TextField>
              );
            }}
          />
        </LocalizationProvider>
      </form>
    </Dialog>
  );
}
