import Alert from "@mui/material/Alert";
import { Send } from "@mui/icons-material";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import {
  InputAdornment,
  CircularProgress,
  Snackbar,
  IconButton,
  TextField,
} from "@mui/material";

import { useSendTestEmail } from "./useSendTestEmail";

interface FormValues {
  email: string;
}

interface Props {
  filingId: string;
  selectedBroker: string;
}

const initialValues: FormValues = { email: "" };

const validationSchema = Yup.object().shape({
  email: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
    .required(),
});

function SendTestForm({ filingId, selectedBroker }: Props): JSX.Element {
  const { sendTestEmail } = useSendTestEmail();
  const [open, setOpen] = useState(false);

  function handleClose(
    event: Event | React.SyntheticEvent<unknown, Event>,
    reason?: string
  ) {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            actions.setSubmitting(true);
            await sendTestEmail(filingId, selectedBroker, {
              emails: values.email.replace(/\s/g, "").split(","),
            });
            setOpen(true);
            actions.setSubmitting(false);
            actions.resetForm();
          } catch (_) {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          submitForm,
          isSubmitting,
          values,
          touched,
          errors,
          handleChange,
        }) => (
          <Form>
            <TextField
              size="small"
              fullWidth
              id="email"
              name="email"
              placeholder="Send test emails (separated by comma)"
              value={values.email}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              sx={{
                width: "450px",
                padding: 0,
                ".MuiFormHelperText-root": {
                  display: "none",
                },
                input: {
                  padding: "8px 16px",
                  fontSize: "14px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isSubmitting ? (
                      <CircularProgress size={20} />
                    ) : (
                      <IconButton
                        size="small"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <Send sx={{ color: "rgba(0, 0, 0, 0.33)" }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Form>
        )}
      </Formik>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Your email was successfully sent!"
      >
        <Alert
          onClose={handleClose}
          severity="success"
          elevation={6}
          variant="filled"
        >
          Test email was successfully sent!
        </Alert>
      </Snackbar>
    </>
  );
}

export { SendTestForm };
