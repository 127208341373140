import { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { ReviewEmail } from "../ReviewEmail";
import { BatchBar } from "../BatchBar";
import {
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from "../batch-filings-context";
import { useProxyWarningDispatch } from "../proxy-warning-context";
import { useDetailsData } from "./useDetailsData";
import { TradeAlertBanner } from "../TradeAlertBanner";
import { getTabFromUrl } from "src/utils/get-tab-from-url";
import { LoadingState } from "src/components/LoadingState";
import { Tabs } from "src/components/atoms/Tabs";
import { Box } from "src/components/atoms/Box";
import { BallotDetails } from "../DetailsForm/BallotDetails";
import { ProspectusAccordion } from "src/components/ProspectusAccordion";
import {
  PreviewItem,
  PreviewPane,
} from "src/components/PreviewPane/PreviewPane";
import {
  previewItemsFromFiling,
  previewItemsFromProspectusPackagesBySecurity,
} from "src/components/PreviewPane/utils";
import { ProxyPageHeader } from "src/components/organisms/ProxyPageHeader";
import { DisplayType, OperationsStatus } from "../types";
import { SplitPane } from "src/components/molecules/SplitPane";
import { FilingDetailsPanel } from "./FilingDetailsPanel";
import { AdditionalMailings } from "../AdditionalMailings";
import { IssuerDetails } from "./IssuerDetails";
import { VoteTransmissions } from "../VoteTransmissions";
import { useVoteTransmissions } from "src/api/adapters/nexus/hooks/useVoteTransmissions";

type DetailsParams = {
  id: string;
};

export function Details(): JSX.Element {
  const { id } = useParams() as DetailsParams;
  const navigate = useNavigate();
  const location = useLocation();
  const batchFilings = useBatchFilingsState();
  const dispatch = useBatchFilingsDispatch();
  const dispatchDirty = useProxyWarningDispatch();
  const {
    isLoading,
    isError,
    filing,
    brokers,
    mutateFiling,
    mutateProspectusPackages,
    prospectusPackages,
    prospectusPackagesBySecurity,
    tradeAlerts,
  } = useDetailsData(id);
  const [previewItem, setPreviewItem] = useState<PreviewItem | null>(null);
  const { voteTransmissions, isLoading: isVtLoading } = useVoteTransmissions(
    filing?.globalFilingId
  );

  const mapBatchPathToActionName = new Map([
    ["/proxy/tasks/drafts", "confirmed"],
    ["/proxy/tasks/ready", "completed"],
    ["/proxy/tasks/review", "approved"],
  ]);

  useEffect(() => {
    dispatchDirty({ type: "INITIALIZE_PROXY" });
  }, [dispatchDirty]);

  useEffect(() => {
    if (
      batchFilings.filings.length &&
      batchFilings.filings.length === batchFilings.finishedFilings.length
    ) {
      dispatch({
        type: "OPEN_MESSAGE",
        message: `You’ve ${mapBatchPathToActionName.get(
          batchFilings.taskListPath
        )} ${batchFilings.finishedFilings.length} of ${
          batchFilings.filings.length
        } ${batchFilings.taskListPath
          .split("/")
          .pop()} filings previously selected. ✅`,
      });
      navigate(batchFilings.taskListPath);
    }
  }, [
    batchFilings.filings,
    batchFilings.finishedFilings,
    batchFilings.taskListPath,
    dispatch,
    mapBatchPathToActionName,
  ]);

  if (isLoading || isVtLoading) {
    return <LoadingState />;
  }

  if (isError || !filing || !brokers || !prospectusPackages || !tradeAlerts) {
    return <div>There is an error</div>;
  }
  const { documents, globalFilingId, issuer, operationsStatus, type } = filing;

  const previewItems = previewItemsFromFiling(filing).concat(
    previewItemsFromProspectusPackagesBySecurity(prospectusPackagesBySecurity)
  );

  const handleTabChange = (value: string) => {
    const queryParams = `?broker=${
      brokers && brokers.length > 0 ? brokers[0].slug : ""
    }&view=email`;
    navigate(`/proxy/${id}/${value}${queryParams}`);
  };

  const handlePreviewChange = (preview: PreviewItem) => {
    setPreviewItem(preview);
  };

  const renderTradeBanner = () => {
    if (tradeAlerts.length > 0) {
      return <TradeAlertBanner filing={filing} tradeAlert={tradeAlerts[0]} />;
    }
  };

  const renderProspectusPanel = () => {
    return (
      <Box>
        {Object.keys(prospectusPackagesBySecurity).length === 0 && (
          <Box textAlign="center">No prospectus packages found</Box>
        )}
        {Object.keys(prospectusPackagesBySecurity).map((securityId, index) => {
          return (
            <ProspectusAccordion
              isExpanded={true}
              isReadOnly={true}
              key={index}
              onDocumentPreviewClick={(doc) => {
                setPreviewItem({
                  id: doc.id,
                  name: doc.name,
                  preview: doc.url,
                  type: DisplayType.Document,
                });
              }}
              onProspectusPackageUpdate={() => mutateProspectusPackages()}
              prospectusPackages={prospectusPackagesBySecurity[securityId]}
            />
          );
        })}
      </Box>
    );
  };

  let tabs = [];
  if (
    type === "FundReport" ||
    type === "FirmInformationStatement" ||
    type === "FundInformationStatement"
  ) {
    tabs = [
      {
        label: "Filing Details",
        value: "",
        testId: "ProxyFilingTab",
      },
      {
        label: "Email",
        value: "email",
        testId: "ProxyEmailTab",
      },
      {
        label: "Invoicing",
        value: "invoicing",
        testId: "InvoicingTab",
      },
    ];
  } else {
    tabs = [
      {
        label: "Filing Details",
        value: "",
        testId: "ProxyFilingTab",
      },
      {
        label: "Ballot",
        value: "ballot",
        testId: "ProxyBallotTab",
      },
      {
        label: "Email",
        value: "email",
        testId: "ProxyEmailTab",
      },
      {
        label: "Invoicing",
        value: "invoicing",
        testId: "ProxyInvoicingTab",
      },
    ];
    if (operationsStatus === "approved") {
      tabs.push({
        label: "Additional Mailings",
        value: "additional-mailings",
        testId: "ProxyAdditionalMailingsTab",
      });

      if (
        type !== "FirmConsentSolicitation" &&
        voteTransmissions &&
        voteTransmissions.length > 0
      ) {
        tabs.push({
          label: "Vote Transmissions",
          value: "vote-transmissions",
          testId: "ProxyVoteTransmissionsTab",
        });
      }
    }
  }

  if (type === "FundInformationStatement") {
    tabs.push({
      label: `Prospectus`,
      value: "prospectus",
      testId: "ProxyProspectusTab",
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden",
      }}
      id="proxy-body"
    >
      {batchFilings.filings.length > 0 && (
        <BatchBar
          totalSelected={batchFilings.filings.length}
          finishedSelected={batchFilings.finishedFilings.length}
          currentPosition={batchFilings.filings.indexOf(Number(id)) + 1}
        />
      )}
      <ProxyPageHeader
        filing={filing}
        id={id}
        hideCta={getTabFromUrl(location) === "additional-mailings"}
        isCtaDisabled={
          operationsStatus !== OperationsStatus.Draft && documents.length === 0
        }
        onFilingUpdate={() => mutateFiling()}
      />
      <Tabs
        sx={{ borderBottom: "1px solid #e5e5e5", paddingLeft: "24px" }}
        onChange={handleTabChange}
        value={getTabFromUrl(location)}
        tabs={tabs}
      />
      {renderTradeBanner()}
      <Routes>
        <Route
          index
          element={
            <SplitPane
              primaryPane={
                <FilingDetailsPanel
                  id={id}
                  filing={filing}
                  onPreviewChange={handlePreviewChange}
                />
              }
              primaryPaneId="ProxyDetailsPanel"
              secondaryPane={
                <PreviewPane
                  currentPreview={previewItem}
                  options={previewItems}
                />
              }
              secondarySx={{
                display: "flex",
                px: 8,
                py: 4,
              }}
            />
          }
        />
        <Route path="email" element={<ReviewEmail />} />
        <Route
          path="ballot"
          element={
            <SplitPane
              primaryPane={<BallotDetails />}
              primaryPaneId="ProxyBallotsPanel"
              primarySx={{
                px: 8,
                py: 4,
              }}
              secondaryPane={
                <PreviewPane
                  currentPreview={previewItem}
                  options={previewItems}
                />
              }
              secondarySx={{
                display: "flex",
                px: 8,
                py: 4,
              }}
            />
          }
        />
        <Route
          path="prospectus"
          element={
            <SplitPane
              primaryPane={renderProspectusPanel()}
              primaryPaneId="ProxyProspectusPanel"
              secondaryPane={
                <PreviewPane
                  currentPreview={previewItem}
                  options={previewItems}
                />
              }
              secondarySx={{
                display: "flex",
                px: 8,
                py: 4,
              }}
            />
          }
        />
        <Route
          path="additional-mailings"
          element={<AdditionalMailings proxyEventId={globalFilingId} />}
        />
        <Route
          path="vote-transmissions"
          element={<VoteTransmissions filing={filing} />}
        />
        <Route
          path="invoicing"
          element={
            <SplitPane
              primaryPane={<IssuerDetails id={issuer.globalIssuerId || ""} />}
              primaryPaneId="InvoicingPanel"
              primarySx={{
                px: 8,
                py: 4,
              }}
              secondaryPane={
                <PreviewPane
                  currentPreview={previewItem}
                  options={previewItems}
                />
              }
              secondarySx={{
                display: "flex",
                px: 8,
                py: 4,
              }}
            />
          }
        />
      </Routes>
    </Box>
  );
}
