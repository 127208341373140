import { Tabs as MUITabs, Tab, SxProps } from "@mui/material";
import { IBaseProps } from "src/types/BaseProps";

type Tab = {
  label: string;
  value: string;
  testId: string;
};

interface ITabsProps extends IBaseProps {
  onChange: (value: string) => void;
  value: string;
  sx?: SxProps;
  tabs: Tab[];
}

export function Tabs({ sx, onChange, value, tabs }: ITabsProps) {
  return (
    <MUITabs
      sx={sx}
      onChange={(e, v) => onChange(v)}
      indicatorColor="primary"
      value={value}
    >
      {tabs.map((tab, index) => {
        const { label, value, testId } = tab;
        return (
          <Tab
            key={index}
            label={label}
            sx={{ fontSize: "12px" }}
            value={value}
            data-testid={testId}
          />
        );
      })}
    </MUITabs>
  );
}
