import { Navigate, useLocation } from "react-router-dom";
import { AppLayout } from "../AppLayout";
import { getCanLogIn } from "./utils";

const PrivateRoute = (): JSX.Element => {
  const location = useLocation();
  const canLogIn = getCanLogIn();
  return canLogIn ? (
    <AppLayout />
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
};

export { PrivateRoute };
