import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, MenuItem, TextField, Box } from "@mui/material/";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Formik, Form as FormikForm, FormikActions, FormikProps } from "formik";

import {
  IFilingCreateValues,
  FilingType,
  MeetingType,
  ReportType,
  DeliveryMethod,
  CompanyContact,
  Security,
} from "../types";
import { createValidationSchema } from "../validation-schema";
import { CreateHeader } from "./Header";
import { FilingDatePicker } from "../DetailsForm/FilingDatePicker";
import { DuplicateConfirmationDialog } from "../../../components/Dialog/DuplicateConfirmationDialog";
import { SimpleSnackbar } from "../../../components/Snackbar";
import {
  DynamicCusipTypeahead,
  DynamicIssuerTypeahead,
} from "../../../components/typeaheads/Dynamic";
import { createFiling, createFilingCheck } from "../../../api/adapters/proxy";
import { formatCamelCaseString } from "../../../utils/format-camel-case";
import { ContactDetails } from "../DetailsForm/Contact";
import { DirtyFormDialog } from "../../../components/Dialog/DirtyFormDialog";
import { useProxyWarningDispatch } from "../proxy-warning-context";
import { getSecurityIds, getIssuerId } from "./apiHelpers";

export const Create = (): JSX.Element => {
  const navigate = useNavigate();
  const [existingFiling, setExistingFiling] = useState<boolean>(false);
  const [existingFilingId, setExistingFilingId] = useState<number>(NaN);
  const [existingFilingDialog, setExistingFilingDialog] =
    useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
  const [errorBanner, setErrorBanner] = React.useState<boolean>(false);
  const [badRequest, setBadRequest] = React.useState("");
  const [toastMessage, setToastMessage] = React.useState("");

  const dispatchDirty = useProxyWarningDispatch();

  useEffect(() => {
    dispatchDirty({ type: "INITIALIZE_PROXY" });
  }, [dispatchDirty]);

  const handleSnackbar = (message?: string) => {
    if (message) setToastMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (
    event: Event | React.SyntheticEvent<unknown, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setToastMessage("");
    setOpenSnackbar(false);
  };

  const submitFiling = async (
    payload: unknown,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setErrorBanner(false);
    try {
      const response = await createFiling(payload);
      setExistingFiling(false);
      setExistingFilingId(NaN);
      setSubmitting(false);
      navigate(`/proxy/${response.data.id}`);
      handleSnackbar("This filing has been created. 🎉");
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.data) {
        const err = Object.keys(error.response.data).reduce(
          (acc: string, cv: string) => {
            acc = `${acc} ${formatCamelCaseString(cv).split("_").join(" ")}: ${
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (error as any).response.data[cv]
            }`;
            return acc;
          },
          ""
        );
        setBadRequest(err);
        handleSnackbar(err);
      }
      setSubmitting(false);
    }
  };

  const checkIfFilingExists = async (
    type: FilingType,
    issuerId: number | null,
    recordDate: Date | string,
    payload: unknown,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await createFilingCheck({
        filingType: type,
        issuer: issuerId,
        recordDate: recordDate,
      });
      submitFiling(payload, setSubmitting);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error?.response &&
        error.response.status === 400
      ) {
        const errors = error.response.data.nonFieldErrors;

        if (errors) {
          const filingAlreadyExistsErrorIndex = errors.findIndex(
            (error: string) => error.includes("already exists")
          );

          if (filingAlreadyExistsErrorIndex >= 0) {
            const existingFilingIdFromResponse =
              errors[filingAlreadyExistsErrorIndex].match(/\d+/)[0];
            setExistingFiling(true);
            setExistingFilingId(existingFilingIdFromResponse);
            setExistingFilingDialog(true);
          }
        }
      }
    }
  };

  const createProxyFiling = async (
    values: IFilingCreateValues,
    formikActions: FormikActions<IFilingCreateValues>
  ) => {
    if (values.issuer) {
      const {
        solicitor,
        tabulator,
        replyTo,
        sendTo,
        issuer,
        cusips,
        expectedDate,
        ...theRest
      } = values;

      const issuerResponse = await getIssuerId(issuer);
      if (!issuerResponse || issuerResponse.error) {
        setBadRequest(issuerResponse.error);
        handleSnackbar(issuerResponse.error);
      } else {
        const securityResponse = await getSecurityIds(
          issuerResponse.id,
          cusips
        );

        if (!securityResponse || securityResponse.error) {
          if (securityResponse !== undefined) {
            setBadRequest(securityResponse.error);
            handleSnackbar(securityResponse.error);
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const payload: any = {
            ...theRest,
            companyContactIds: [],
            issuerId: issuerResponse.id,
            meeting: {
              date: values.meetingDate,
              type: values.meetingType,
            },
            securityIds: securityResponse.ids,
          };

          formikActions.setSubmitting(true);

          if (expectedDate) {
            payload.digitalMaterialsExpectedDate = expectedDate;
          }
          if (solicitor) {
            payload.solicitorId = solicitor.id;
          }
          if (tabulator) {
            payload.tabulatorId = tabulator.id;
          }
          if (replyTo) {
            const ids = replyTo.map((contact) => contact.id);
            payload.companyContactIds = [...payload.companyContactIds, ...ids];
          }
          if (sendTo) {
            const ids = sendTo.map((contact) => contact.id);
            payload.companyContactIds = [...payload.companyContactIds, ...ids];
          }
          if (
            values.type === FilingType.FirmMeeting ||
            values.type === FilingType.FundMeeting ||
            values.type === FilingType.FirmConsentSolicitation
          ) {
            if (values.meetingDate) {
              payload.voteCutoffDate = moment(values.meetingDate)
                .subtract(1, "days")
                .format("YYYY-MM-DD");
            }
            payload.voteCutoffTime = "23:59";
            payload.voteCutoffTimezone = "US/Eastern";
          } else if (values.type === FilingType.MeetingContest) {
            payload.voteCutoffTime = "23:59";
            payload.voteCutoffTimezone = "US/Eastern";
          }

          if (!existingFiling && !existingFilingId) {
            checkIfFilingExists(
              values.type,
              issuerResponse.id,
              values.recordDate ? values.recordDate : "",
              payload,
              formikActions.setSubmitting
            );
          } else {
            submitFiling(payload, formikActions.setSubmitting);
          }
        }
      }
    }
  };

  const hasExistingCusip = (cusips: Security[]) =>
    !cusips.length || cusips.some((itm) => itm && Number.isFinite(itm.id));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={createValidationSchema}
        initialValues={{
          issuer: null,
          invoicerAddressLine1: "",
          invoicerAddressLine2: "",
          invoicerAddressLine3: "",
          invoicerCity: "",
          invoicerState: "",
          invoicerPostalCode: "",
          type: "" as FilingType,
          cusips: [],
          brokers: [],
          meetingDate: null,
          meetingType: MeetingType.Annual,
          reportType: "annual" as ReportType,
          deliveryMethod: "traditional" as DeliveryMethod,
          contestingEntity: "",
          solicitor: undefined,
          tabulator: undefined,
          replyTo: [] as CompanyContact[],
          sendTo: [] as CompanyContact[],
          expectedDate: null,
          brokerSearchReceivedDate: null,
          recordDate: null,
          voteCutoffDate: null,
          minimumShares: 0,
        }}
        onSubmit={(
          values: IFilingCreateValues,
          formikActions: FormikActions<IFilingCreateValues>
        ) => {
          createProxyFiling(values, formikActions);
        }}
      >
        {(formikBag: FormikProps<IFilingCreateValues>) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormikForm
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
              noValidate={true}
              onChange={() => {
                if (!formikBag.dirty) {
                  dispatchDirty({
                    type: "MARK_DIRTY",
                  });
                }
              }}
            >
              <CreateHeader
                errorBanner={errorBanner}
                setErrorBanner={setErrorBanner}
                badRequest={badRequest}
                formikBag={formikBag}
              />
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  backgroundColor: "#F8f8f8",
                  py: "24px",
                  justifyContent: "center",
                }}
                id="proxy-body"
              >
                <Paper
                  elevation={3}
                  sx={{
                    overflowY: "auto",
                    height: "100%",
                    px: "24px",
                    py: "24px",
                    minWidth: "650px",
                  }}
                >
                  <Box id="ProxyDetailFilingType" sx={{ py: 2 }}>
                    <TextField
                      size="small"
                      required
                      select={true}
                      fullWidth
                      id="type"
                      name="type"
                      label="Filing Type"
                      InputLabelProps={{ shrink: true }}
                      value={formikBag.values.type}
                      onChange={formikBag.handleChange}
                      error={
                        formikBag.touched.type && Boolean(formikBag.errors.type)
                      }
                      helperText={
                        formikBag.touched.type && formikBag.errors.type
                      }
                    >
                      {Object.values(FilingType).map((type) => (
                        <MenuItem key={type} value={type}>
                          {formatCamelCaseString(type)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box id="ProxyDetailCusip" sx={{ py: 2 }}>
                    <DynamicCusipTypeahead
                      values={formikBag.values.cusips}
                      setFieldValue={formikBag.setFieldValue}
                      field="cusips"
                      label="CUSIP"
                      optionLabelField="cusip"
                      issuerId={
                        formikBag.values.issuer && formikBag.values.issuer.id
                          ? String(formikBag.values.issuer.id)
                          : null
                      }
                    />
                    {formikBag.errors.cusips ? (
                      <Box
                        sx={{
                          color: "#d33f33",
                          fontSize: ".75rem",
                          margin: "3px 0 0 14px",
                        }}
                      >
                        {formikBag.errors.cusips}
                      </Box>
                    ) : null}
                  </Box>
                  <Box sx={{ py: 2 }}>
                    <DynamicIssuerTypeahead
                      value={formikBag.values.issuer}
                      setFieldValue={formikBag.setFieldValue}
                      field="issuer"
                      label="Issuer"
                      optionLabelField="companyName"
                      disable={hasExistingCusip(formikBag.values.cusips)}
                    />
                  </Box>
                  <Box sx={{ py: 2 }}>
                    <FilingDatePicker
                      required={true}
                      label="Broker Search Date"
                      name="brokerSearchReceivedDate"
                      formikBag={formikBag}
                    />
                  </Box>
                  <Box sx={{ py: 2 }}>
                    <TextField
                      size="small"
                      fullWidth
                      id="solicitor-job-number"
                      name="solicitorJobNumber"
                      label="Job Number"
                      value={formikBag.values.solicitorJobNumber}
                      onChange={formikBag.handleChange}
                      InputLabelProps={{ shrink: true }}
                      error={
                        formikBag.touched.solicitorJobNumber &&
                        Boolean(formikBag.errors.solicitorJobNumber)
                      }
                      helperText={
                        formikBag.touched.solicitorJobNumber &&
                        formikBag.errors.solicitorJobNumber
                      }
                    />
                  </Box>
                  {formikBag.values.type === FilingType.MeetingContest && (
                    <>
                      <Box sx={{ py: 2 }}>
                        <TextField
                          size="small"
                          fullWidth
                          required
                          id="issuer-filing"
                          name="issuerFiling"
                          label="Issuer Filing"
                          type="number"
                          value={formikBag.values.issuerFiling}
                          onChange={formikBag.handleChange}
                          error={
                            formikBag.touched.issuerFiling &&
                            Boolean(formikBag.errors.issuerFiling)
                          }
                          helperText={
                            formikBag.touched.issuerFiling &&
                            formikBag.errors.issuerFiling
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      </Box>
                      <Box sx={{ py: 2 }}>
                        <FilingDatePicker
                          label="Vote Cutoff Date"
                          name="voteCutoffDate"
                          required
                          formikBag={formikBag}
                        />
                      </Box>
                      <Box sx={{ py: 2 }}>
                        <TextField
                          size="small"
                          fullWidth
                          required
                          id="contesting-entity"
                          name="contestingEntity"
                          label="Contesting Entity"
                          value={formikBag.values.contestingEntity}
                          onChange={formikBag.handleChange}
                          error={
                            formikBag.touched.contestingEntity &&
                            Boolean(formikBag.errors.contestingEntity)
                          }
                          helperText={
                            formikBag.touched.contestingEntity &&
                            formikBag.errors.contestingEntity
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      </Box>
                      <Box sx={{ py: 2 }}>
                        <TextField
                          size="small"
                          fullWidth
                          id="minimum-shares"
                          name="minimumShares"
                          label="Minimum Shares"
                          required
                          value={formikBag.values.minimumShares}
                          onChange={formikBag.handleChange}
                          error={
                            formikBag.touched.minimumShares &&
                            Boolean(formikBag.errors.minimumShares)
                          }
                          helperText={
                            formikBag.touched.minimumShares &&
                            formikBag.errors.minimumShares
                          }
                          InputLabelProps={{ shrink: true }}
                          type="number"
                        />
                      </Box>
                    </>
                  )}
                  <Box className="grid grid-cols-2 gap-6 py-2">
                    <FilingDatePicker
                      label="Record Date"
                      name="recordDate"
                      required
                      formikBag={formikBag}
                    />
                    <FilingDatePicker
                      label="Expected Date"
                      name="expectedDate"
                      formikBag={formikBag}
                    />
                  </Box>
                  {formikBag.values.type === FilingType.FundReport && (
                    <Box id="ProxyDetailReportType" sx={{ py: 2 }}>
                      <TextField
                        size="small"
                        select={true}
                        fullWidth
                        required
                        id="report-type"
                        name="reportType"
                        label="Report Type"
                        value={formikBag.values.reportType}
                        onChange={formikBag.handleChange}
                        error={
                          formikBag.touched.reportType &&
                          Boolean(formikBag.errors.reportType)
                        }
                        helperText={
                          formikBag.touched.reportType &&
                          formikBag.errors.reportType
                        }
                      >
                        <MenuItem value="annual">Annual</MenuItem>
                        <MenuItem value="semi_annual">Semi-Annual</MenuItem>
                      </TextField>
                    </Box>
                  )}
                  {(formikBag.values.type === FilingType.MeetingContest ||
                    formikBag.values.type === FilingType.FundMeeting ||
                    formikBag.values.type === FilingType.FirmMeeting) && (
                    <Box className="grid grid-cols-2 gap-6 py-2">
                      <FilingDatePicker
                        label="Meeting Date"
                        name="meetingDate"
                        formikBag={formikBag}
                      />
                      <Box id="ProxyDetailMeetingType">
                        <TextField
                          size="small"
                          select={true}
                          fullWidth
                          id="meeting-type"
                          name="meetingType"
                          label="Meeting Type"
                          value={formikBag.values.meetingType}
                          onChange={formikBag.handleChange}
                          error={
                            formikBag.touched.meetingType &&
                            Boolean(formikBag.errors.meetingType)
                          }
                          helperText={
                            formikBag.touched.meetingType &&
                            formikBag.errors.meetingType
                          }
                        >
                          <MenuItem value="annual">Annual</MenuItem>
                          <MenuItem value="special">Special</MenuItem>
                        </TextField>
                      </Box>
                    </Box>
                  )}
                  <Box id="ProxyDetailDeliveryMethod" sx={{ py: 2 }}>
                    <TextField
                      size="small"
                      select={true}
                      required
                      fullWidth
                      id="delivery-method"
                      name="deliveryMethod"
                      label="Delivery Method"
                      value={formikBag.values.deliveryMethod}
                      onChange={formikBag.handleChange}
                      error={
                        formikBag.touched.deliveryMethod &&
                        Boolean(formikBag.errors.deliveryMethod)
                      }
                      helperText={
                        formikBag.touched.deliveryMethod &&
                        formikBag.errors.deliveryMethod
                      }
                    >
                      <MenuItem value="traditional">Traditional</MenuItem>
                      <MenuItem value="notice_and_access">
                        Notice and Access
                      </MenuItem>
                    </TextField>
                  </Box>
                  <ContactDetails isDraft={true} formikBag={formikBag} />
                </Paper>
                <DuplicateConfirmationDialog
                  existingFilingId={existingFilingId}
                  isOpen={existingFilingDialog}
                  onConfirm={() => {
                    setExistingFilingDialog(false);
                    formikBag.submitForm();
                  }}
                  onRequestClose={() => {
                    if (existingFiling && existingFilingId) {
                      setExistingFiling(false);
                      setExistingFilingId(NaN);
                    }
                    setExistingFilingDialog(false);
                    formikBag.setSubmitting(false);
                  }}
                />
              </Box>
              <DirtyFormDialog />
            </FormikForm>
          </LocalizationProvider>
        )}
      </Formik>
      <SimpleSnackbar
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        message={toastMessage}
      />
    </Box>
  );
};
