import React from "react";
import { Routes, Route } from "react-router-dom";
import { InProgress, New, Open } from "./tasks";
import { Create } from "./Create";
import { Details } from "./Details";
import { TaskListLayout } from "./TaskListLayout";
import { BrokerEmail } from "./BrokerEmail";
import { FilingDetails } from "./FilingDetails";
import { Redirect } from "src/components/Redirect";

const CorporateActions: React.FC = () => {
  return (
    <Routes>
      <Route path="create" element={<Create />} />
      <Route path=":id/*" element={<Details />}>
        <Route path="broker-email" element={<BrokerEmail />} />
        <Route index element={<FilingDetails />} />
      </Route>
      <Route path="tasks" element={<TaskListLayout />}>
        <Route path="new" element={<New />} />
        <Route path="in-progress" element={<InProgress />} />
        <Route path="open" element={<Open />} />
      </Route>
      <Route path="/" element={<Redirect to="tasks/new" />} />
    </Routes>
  );
};

export { CorporateActions };
