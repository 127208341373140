import React, { ReactNode } from "react";
import { Field, FieldProps, FormikProps } from "formik";
import { Box, Button, TextField } from "@mui/material";

import {
  FilingDetailsFormValues,
  FilingType,
  IFilingCreateValues,
  CompanyContact,
} from "../types";
import {
  SolicitorTypeahead,
  TabulatorTypeahead,
  GenericTypeAhead,
} from "../../../components/typeaheads";
import { AddCompanyContactDialog } from "../../../components/Dialog/AddCompanyContactDialog";

type ContactProps = {
  formikBag:
    | FormikProps<FilingDetailsFormValues>
    | FormikProps<IFilingCreateValues>;
  isDraft: boolean;
};

export type Company = {
  id: number;
  name: string;
  address: null | string;
  companyContacts: CompanyContact[];
  defaultTabulator?: DefaultTabulator | null;
  type: CompanyType;
};

export interface Contact {
  id: number;
  name: string;
  email: string;
  phone: null | string;
}

type DefaultTabulator = {
  id: number;
  name: string;
  address: string;
  companyContacts: CompanyContact[];
};

type CompanyType = "Solicitor" | "Tabulator";

export const ContactDetails: React.FC<ContactProps> = ({
  formikBag,
  isDraft,
}) => {
  const { solicitor, tabulator } = formikBag.values;
  let { type } = formikBag.values;
  type = type.replace(/\s/g, "") as FilingType;

  function setReplyToContactsFromSolicitor(solicitor: Company) {
    const replyToContacts = solicitor.companyContacts.filter(
      (companyContact) =>
        companyContact.role === "solicitor_reply_to" &&
        companyContact.isDefaultForRole
    );
    formikBag.setFieldValue("replyTo", replyToContacts);
  }

  function setDefaultTabulatorFromCompany(company: Company) {
    if (company.defaultTabulator) {
      formikBag.setFieldValue("tabulator", company.defaultTabulator);
    }
  }

  function setSendToContactsFromCompany(company: Company) {
    if (company.defaultTabulator) {
      const sendToTabulatorContacts =
        company.defaultTabulator.companyContacts.filter(
          (companyContact) =>
            companyContact.role === "tabulator_reply_to" &&
            companyContact.isDefaultForRole
        );
      formikBag.setFieldValue("sendTo", sendToTabulatorContacts);
    }
  }

  function filterReplyToContacts(solicitor: Company) {
    return solicitor.companyContacts.filter(
      (companyContact) => companyContact.role === "solicitor_reply_to"
    );
  }

  function filterSendToContacts(tabulator: Company) {
    return tabulator.companyContacts.filter(
      (companyContact) => companyContact.role === "tabulator_reply_to"
    );
  }

  const errorStyle = {
    color: "#d33f33",
    fontSize: ".75rem",
    margin: "3px 0 0 14px",
  };

  // toggle Dialog
  const [isAddSolicitorContactOpen, setIsAddSolicitorContactOpen] =
    React.useState(false);
  const [isAddTabulatorContactOpen, setIsAddTabulatorContactOpen] =
    React.useState(false);

  const getSolicitorContactsTypeahead = (company: Company) => (
    <Box sx={{ py: 2 }}>
      <Field name="replyTo">
        {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
          <>
            <Box sx={{ py: 2 }}>
              <GenericTypeAhead
                values={field.value}
                setFieldValue={form.setFieldValue}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                options={filterReplyToContacts(solicitor as any)}
                field="replyTo"
                label="Solicitor Contact(s)"
                optionLabelField="contact.email"
                placeholder="Start typing solicitor contacts..."
              />
            </Box>
            <Button
              size="small"
              onClick={() => setIsAddSolicitorContactOpen(true)}
              type="button"
            >
              Create New Solicitor Contact
            </Button>
            {isAddSolicitorContactOpen && (
              <AddCompanyContactDialog
                role="solicitor_reply_to"
                fieldName="replyTo"
                values={field.value}
                setFieldValue={form.setFieldValue}
                company={company}
                isOpen={isAddSolicitorContactOpen}
                onClose={() => setIsAddSolicitorContactOpen(false)}
              />
            )}
          </>
        )}
      </Field>
    </Box>
  );

  const getTabulatorContactsTypeahead = (company: Company) => (
    <Box sx={{ py: 2 }}>
      <Field name="sendTo">
        {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
          <>
            <GenericTypeAhead
              values={field.value}
              setFieldValue={form.setFieldValue}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              options={filterSendToContacts(tabulator as any)}
              field="sendTo"
              label="Tabulator Contact(s)"
              optionLabelField="contact.email"
              placeholder="Start typing tabulator contact..."
            />
            <Button
              size="small"
              onClick={() => setIsAddTabulatorContactOpen(true)}
              type="button"
            >
              Create New Tabulator Contact
            </Button>
            {isAddTabulatorContactOpen && (
              <AddCompanyContactDialog
                role="tabulator_reply_to"
                fieldName="sendTo"
                values={field.value}
                setFieldValue={form.setFieldValue}
                company={company}
                isOpen={isAddTabulatorContactOpen}
                onClose={() => setIsAddTabulatorContactOpen(false)}
              />
            )}
          </>
        )}
      </Field>
    </Box>
  );

  const fields = (): ReactNode => {
    switch (type) {
      case "FundMeeting":
        return (
          <>
            <Box sx={{ py: 2 }}>
              <Field name="tabulator">
                {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                  <TabulatorTypeahead
                    onClear={() => form.setFieldValue("tabulator", null)}
                    onSelect={(tabulator) => {
                      form.setFieldValue("tabulator", tabulator);
                      setSendToContactsFromCompany(tabulator);
                    }}
                    value={field.value}
                    required={!isDraft}
                  />
                )}
              </Field>
              {tabulator && getTabulatorContactsTypeahead(tabulator as Company)}
            </Box>
            <Box sx={{ py: 2 }}>
              <TextField
                size="small"
                required={!isDraft}
                fullWidth
                id="fund-owner"
                name="fundOwner"
                label="Fund Owner"
                value={formikBag.values.fundOwner}
                onChange={formikBag.handleChange}
                error={
                  formikBag.touched.fundOwner &&
                  Boolean(formikBag.errors.fundOwner)
                }
                helperText={
                  formikBag.touched.fundOwner && formikBag.errors.fundOwner
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </>
        );
      case "FundReport":
      case "FundInformationStatement":
        return (
          <Box sx={{ py: 2 }}>
            <TextField
              size="small"
              required={!isDraft}
              fullWidth
              id="fund-owner"
              name="fundOwner"
              label="Fund Owner"
              value={formikBag.values.fundOwner}
              onChange={formikBag.handleChange}
              error={
                formikBag.touched.fundOwner &&
                Boolean(formikBag.errors.fundOwner)
              }
              helperText={
                formikBag.touched.fundOwner && formikBag.errors.fundOwner
              }
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        );
      case "FirmMeeting":
      case "FirmConsentSolicitation":
      case "MeetingContest":
        return (
          <Box sx={{ py: 2 }}>
            <Field name="tabulator">
              {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
                <TabulatorTypeahead
                  onClear={() => form.setFieldValue("tabulator", null)}
                  onSelect={(tabulator) => {
                    form.setFieldValue("tabulator", tabulator);
                    setSendToContactsFromCompany(tabulator);
                  }}
                  value={field.value}
                  required={!isDraft}
                />
              )}
            </Field>
            {tabulator && getTabulatorContactsTypeahead(tabulator as Company)}
          </Box>
        );
      case "FirmInformationStatement":
      default:
        return null;
    }
  };

  return (
    <Box id="ProxyDetailContacts">
      <Box sx={{ py: 2 }}>
        <Field name="solicitor">
          {({ field, form }: FieldProps<FilingDetailsFormValues>) => (
            <SolicitorTypeahead
              onClear={() => form.setFieldValue("solicitor", null)}
              onSelect={(solicitor) => {
                form.setFieldValue("solicitor", solicitor);
                setReplyToContactsFromSolicitor(solicitor);
                setDefaultTabulatorFromCompany(solicitor);
                setSendToContactsFromCompany(solicitor);
              }}
              value={field.value}
            />
          )}
        </Field>
        {formikBag.errors.solicitor && (
          <div style={errorStyle}>{formikBag.errors.solicitor}</div>
        )}
        {solicitor && getSolicitorContactsTypeahead(solicitor as Company)}
        {formikBag.errors.replyTo && (
          <div style={errorStyle}>{formikBag.errors.replyTo}</div>
        )}
      </Box>
      {fields()}
      <Box sx={{ py: 2 }}>
        <TextField
          size="small"
          multiline
          rows={5}
          fullWidth
          id="notes"
          name="notes"
          label="Notes"
          value={formikBag.values.notes}
          onChange={formikBag.handleChange}
          error={formikBag.touched.notes && Boolean(formikBag.errors.notes)}
          helperText={formikBag.touched.notes && formikBag.errors.notes}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </Box>
  );
};
