import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { Tabs } from "src/components/atoms/Tabs";
import { LoadingState } from "src/components/LoadingState";
import { PageWrapper } from "src/components/PageWrapper";
import { getTabFromUrl } from "src/utils/get-tab-from-url";
import { ReactComponent as VCAIcon } from "src/assets/icons/vca.svg";
import { useCorporateAction } from "../../api/adapters/proxy/hooks/useCorporateAction";
import { PageHeader } from "src/components/molecules/PageHeader";

type DetailsParams = {
  id: string;
};

export function Details(): JSX.Element {
  const { id } = useParams() as DetailsParams;
  const location = useLocation();
  const { corporateAction, isLoading } = useCorporateAction(id);
  const navigate = useNavigate();

  function onTabChange(value: string) {
    navigate(`/corporate-actions/${id}/${value}`);
  }

  if (isLoading || !corporateAction) {
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );
  }

  const tabs = [
    {
      label: `Filing details`,
      value: "",
      testId: "CorporateActionsFilingDetailsTab",
    },
    {
      label: `Broker email`,
      value: "broker-email",
      testId: "CorporateActionsBrokerEmailTab",
    },
  ];

  const { companyName } = corporateAction.issuer;

  return (
    <>
      <PageHeader icon={<VCAIcon />} title={companyName || ""} />
      <Tabs
        sx={{ pl: 6 }}
        onChange={onTabChange}
        value={getTabFromUrl(location)}
        tabs={tabs}
      />
      <Outlet />
    </>
  );
}
