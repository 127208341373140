import { useState } from "react";
import { toast } from "react-toastify";
import { COLORS } from "@asayinc/component-library";
import { IconButton, Tooltip } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { IBaseProps } from "../../types/BaseProps";
import { AccordionDetails } from "../atoms/AccordionDetails";
import { Box } from "../atoms/Box";
import { Divider } from "../atoms/Divider";
import { Label } from "../Label";
import { DeleteConfirmationDialog } from "../Dialog/DeleteConfirmationDialog";
import { deleteProspectusDocument } from "../../api/adapters/nexus";
import { EditProspectusDocumentDialog } from "../EditProspectusDocumentDialog";
import { formatDate } from "../../utils/format-date";
import {
  ProspectusPackage,
  ProspectusDocument,
} from "../../api/adapters/nexus/types";

interface IProspectusDocumentsAccordionDetailsProps extends IBaseProps {
  isReadOnly?: boolean;
  onDocumentPreviewClick?: (doc: ProspectusDocument) => void;
  onProspectusPackageUpdate: () => void;
  prospectusPackage: ProspectusPackage;
}

export function ProspectusDocumentsAccordionDetails({
  isReadOnly = false,
  onDocumentPreviewClick,
  onProspectusPackageUpdate,
  prospectusPackage,
}: IProspectusDocumentsAccordionDetailsProps) {
  const { documents, expectedEndDate } = prospectusPackage;
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<ProspectusDocument | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleDeleteDocument = async (document: ProspectusDocument) => {
    try {
      await deleteProspectusDocument(prospectusPackage.id, document.id);
      toast.success("Prospectus was deleted successfully.");
    } catch (error) {
      toast.error("Unable to delete prospectus. An unexpect error occurred.");
    } finally {
      onProspectusPackageUpdate();
      setConfirmDeleteDialog(false);
    }
  };

  const handleOpenDeleteDialog = (document: ProspectusDocument) => {
    setSelectedDocument(document);
    setConfirmDeleteDialog(true);
  };

  const handleDocumentEdit = (document: ProspectusDocument) => {
    setSelectedDocument(document);
    setIsEditDialogOpen(true);
  };

  return (
    <AccordionDetails>
      {documents.length > 0 && (
        <Box sx={{ border: `1px solid ${COLORS.LIGHT_GREY}`, px: 2, mt: 2 }}>
          {documents.map((document, index) => {
            return (
              <Box key={`document-${document.id}-${index}`}>
                {index !== 0 && <Divider />}
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  py={2}
                >
                  <Box sx={{ color: COLORS.SAY_COBALT, flex: 2 }}>
                    <a href={document.url} target="_blank" rel="noreferrer">
                      {document.name}
                    </a>
                  </Box>
                  <Box pr={19.5}>
                    <Label>Effective date</Label>
                    <Box
                      sx={{
                        fontSize: ".8rem",
                        color:
                          new Date(expectedEndDate + "T00:00:00") < new Date()
                            ? COLORS.P_MED_LOW_EMPHASIS
                            : COLORS.P_HIGH_EMPHASIS,
                      }}
                    >
                      {formatDate(new Date(document.effectiveDate))}
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Tooltip title="Preview">
                      <span>
                        <IconButton
                          aria-label="preview"
                          disabled={!onDocumentPreviewClick}
                          onClick={() => {
                            if (onDocumentPreviewClick) {
                              onDocumentPreviewClick(document);
                            }
                          }}
                          size="small"
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <span>
                        <IconButton
                          aria-label="edit"
                          disabled={
                            new Date(expectedEndDate + "T00:00:00") <
                              new Date() || isReadOnly
                          }
                          onClick={() => handleDocumentEdit(document)}
                          size="small"
                        >
                          <CreateIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <span>
                        <IconButton
                          aria-label="delete"
                          disabled={
                            new Date(expectedEndDate + "T00:00:00") <
                              new Date() || isReadOnly
                          }
                          onClick={() => handleOpenDeleteDialog(document)}
                          size="small"
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
      {selectedDocument && (
        <>
          <DeleteConfirmationDialog
            deleteType="prospectus"
            isOpen={confirmDeleteDialog}
            onConfirmDelete={() => handleDeleteDocument(selectedDocument)}
            onRequestClose={() => setConfirmDeleteDialog(false)}
          />
          <EditProspectusDocumentDialog
            doc={selectedDocument}
            isOpen={isEditDialogOpen}
            onClose={() => setIsEditDialogOpen(false)}
            onUpdate={onProspectusPackageUpdate}
            prospectus={prospectusPackage}
          />
        </>
      )}
    </AccordionDetails>
  );
}
