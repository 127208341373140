import { Node } from "slate";
import { Issuer } from "../../types/common";

export enum DisplayType {
  Document = "document",
  Email = "email",
  Ballot = "ballot",
  CSV = "csv",
}

export type CurrentDisplay = {
  itemType: DisplayType | null;
  index?: number;
};

// .oOo.oOo.oOo.oOo.oO   FILING   Oo.oOo.oOo.oOo.oOo.

export type FilingListFiling = {
  actionType?: CorporateActionType;
  brokers?: { id: number; name: string; slug: string }[];
  created: Date;
  daysOpen: number;
  deliveryMethod: DeliveryMethod;
  digitalMaterialsExpectedDate: Date | null;
  digitalMaterialsReceivedDate: Date | null;
  digitalSendDeadline: Date | null;
  documents: Document[];
  edgarFilings: EdgarFiling[];
  globalFilingId: string;
  electionCutoffDate?: string | null;
  electionCutoffTimezome?: string;
  expirationDate?: string;
  haveMaterialsBeenRequested: boolean;
  id: number;
  informationAgent?: string;
  informationAgentEmail?: string | null;
  invoiceSentDate: Date | null;
  issuer: Issuer;
  // This value will only appear from nexus filings
  legacyId?: number;
  meetingDate?: Date | null;
  notes: string | null;
  operationsStatus: OperationsStatus;
  paperMaterialsExpectedDate: Date | null;
  paperMaterialsReceivedDate: Date | null;
  paperSendDeadline: Date | null;
  recordDate: string | null;
  recordDateEnd?: string | null;
  recordDateStart?: string;
  referenceMaterials: Reference[];
  securities: Security[];
  solicitor: Solicitor | null;
  solicitorCompany: null | string;
  solicitorContactEmail: null | string;
  solicitorContactName: null | string;
  solicitorJobNumber: null | string;
  stats: null | Stats;
  status: Status;
  type: FilingType;
};

export enum DeliveryMethod {
  NoticeAndAccess = "notice_and_access",
  Traditional = "traditional",
}

export enum OperationsStatus {
  Draft = "draft",
  New = "new",
  Active = "active",
  MaterialsRequested = "materials_requested",
  ReadyForReview = "ready_for_review",
  Approved = "approved",
  Closed = "closed",
}

export enum Status {
  SendMaterialsEstimate = "send_materials_estimate",
  ConfirmMeetingReportInfo = "comfirm_meeting_report_info",
  ConfirmMeetingInfo = "confirm_meeting_info",
  ConfirmReportInfo = "confirm_report_info",
  SendMaterialsCount = "send_materials_count",
  ReceiveMaterials = "receive_materials",
  SendMaterials = "send_materials",
  SendInvoice = "send_invoice",
  Closed = "closed",
}

// .oOo.oOo.oOo.oOo.oO   STATS   Oo.oOo.oOo.oOo.oOo.

export type Stats = {
  brokerCount: number;
  digitalSendDeadline: string;
  internalDigitalSendDeadline: string;
  estimatedDigitalMaterialsExpectedDate: string | null;
  estimatedFractionalPositions: number;
  estimatedFullSharePositions: number;
  estimatedMaterialsRequired: number;
  estimatedTotalPositions: number;
  fractionalSharePositions: number;
  fullSharePositions: number;
  invoiceDate: string;
  invoiceDueDate: string;
  lastDigitalMaterialSendDate: string;
  lastPaperMaterialSendDate: string;
  materialsRequired: number;
  paperSendDeadline: string;
  totalPositions: number;
};

// .oOo.oOo.oOo.oOo.oO   FILING TYPE   Oo.oOo.oOo.oOo.oOo.

export enum FilingType {
  FundReport = "FundReport",
  FirmInformationStatement = "FirmInformationStatement",
  FundInformationStatement = "FundInformationStatement",
  FirmConsentSolicitation = "FirmConsentSolicitation",
  FirmMeeting = "FirmMeeting",
  FundMeeting = "FundMeeting",
  MeetingContest = "MeetingContest",
}

// .oOo.oOo.oOo.oOo.oO   DOCUMENT   Oo.oOo.oOo.oOo.oOo.

export type Document = {
  attachment: string | null;
  effectiveDate: string;
  filing: number;
  id: number;
  isInternal: boolean;
  name: string;
  pdf: null | string;
  securities: number[];
  shouldDistributeInNextMailing: boolean;
  sortOrder: number | null;
  type: DocumentType;
};

export enum DocumentType {
  ProxyStatement = "proxy_statement",
  ProxyCard = "proxy_card",
  TenK = "10_k",
  AnnualReport = "annual_report",
  SemiAnnualReport = "semi_annual_report",
  StatutoryProspectus = "statutory",
  SummaryProspectus = "summary",
  ConsentSolicitation = "consent_solicitation",
  InformationStatement = "information_statement",
  ShareholderLetter = "shareholder_letter",
  CombinedDocument = "proxy_statement_combined",
  Sticker = "sticker",
  StatementOfAdditionalInformation = "statement_of_additional_information",
}

// .oOo.oOo.oOo.oOo.oO   VOTE PROPOSAL   Oo.oOo.oOo.oOo.oOo.

export type ProposalType =
  | "BoardOfDirectorsNomination"
  | "PlanOfMergerProposalItem"
  | "AdjournmentProposalItem"
  | "ExecutiveCompensationProposalItem"
  | "ManagementProposalItem"
  | "ShareholderProposalItem"
  | "SayOnPay"
  | "RatificationOfAuditors"
  | "Other";

export type VoteType =
  | ""
  | "election_majority"
  | "election_plurality"
  | "election_majority_yesno"
  | "election_majority_foragainst"
  | "election_majority_forwithholdabstain"
  | "election_majority_foragainstwithhold"
  | "election_majority_forabstain";

export type ElectionType =
  | "yes_no"
  | "yes_no_partial"
  | "cash_stock"
  | "cash_stock_mixed";

export type RecommendationType =
  | "abstain"
  | "against"
  | "for"
  | "no"
  | "None"
  | "one_year"
  | "three_years"
  | "two_years"
  | "withhold"
  | "cash"
  | "stock"
  | "mixed"
  | "yes";

export type Routineness = "routine" | "nonRoutine";

export type VoteProposalPayload = {
  id?: number;
  isRoutine: boolean;
  proposalNumber: number;
  recommendationType: RecommendationType;
  securities: number[];
  title: string;
  type: ProposalType;
  tooltipText: TooltipText;
  voteType?: VoteType;
  directorName?: string;
  detail?: string;
  groupNumber: number;
  filing?: string;
};

export type TooltipText = Node[] | null;

export enum SayOnPayRecTypes {
  None = "None",
  OneYear = "One_year",
  ThreeYears = "Three_years",
  TwoYears = "Two_years",
  Abstain = "Abstain",
}

export type MeetingResult = {
  id: number;
  voteProposal: number;
  choice: string;
  totalShares: string;
};

export type VoteProposal = {
  details?: string;
  directorName?: string;
  filing: number;
  groupNumber: number;
  id: number;
  isRoutine: boolean;
  proposalNumber: number;
  recommendationType: RecommendationType;
  securities: number[];
  title: string;
  votedAtMeeting: boolean;
  type: ProposalType;
  voteChoices: string[];
  voteType: VoteType;
  meetingResults?: MeetingResult[];
  tooltipText: TooltipText;
};

export type ElectionItems = {
  id: number;
  corporateAction: number;
  proposalNumber: number;
  groupNumber: number;
  electionType: ElectionType;
  recommendationType: RecommendationType;
  securities: number[];
  details: string;
  voteChoices: string[];
  title: string;
};

export type Cusip = {
  readonly id: number;
  readonly cusip?: string;
  readonly name?: string;
};

// .oOo.oOo.oOo.oOo.oO   INTERMEDIARIES   Oo.oOo.oOo.oOo.oOo.

export type Tabulator = {
  id: number;
  name: string;
  address: string;
  companyContacts: CompanyContact[];
};

export type Solicitor = {
  id: number;
  name: string;
  defaultTabulator: Tabulator | null;
  companyContacts: CompanyContact[];
  type: string;
  address: string;
};

export type Contact = {
  id: number;
  email: string;
  phone: string;
  name: string | null;
};

export type CompanyContact = {
  id: number;
  contact?: Contact;
  role: CompanyContactRole;
  isDefaultForRole: boolean;
};

export type CompanyContactRole =
  | "solicitor_receive_from"
  | "solicitor_reply_to"
  | "tabulator_reply_to"
  | "other";

// .oOo.oOo.oOo.oOo.oO   FILING DETAILS   Oo.oOo.oOo.oOo.oOo.

export type Action = {
  id: number;
  username: string;
  action: OperationsStatus;
  performedAt: string;
  created: string;
  modified: string;
};

export type Security = {
  globalSecurityId?: string;
  id: number;
  created?: string;
  modified?: string;
  ticker?: string | null;
  cusip: string;
  name?: string;
  isin?: string | null;
  issuer?: Issuer;
  addNew?: boolean;
};

export enum MeetingType {
  Annual = "annual",
  Special = "special",
}

export enum ProxyType {
  Traditional = "traditional",
  Unspecified = "unspecified",
}

export type Meeting = {
  address: string;
  canVoteAtPhysicalMeeting: boolean;
  canVoteAtVirtualMeeting: boolean;
  canVoteAtTelephoneMeeting: boolean;
  canAttendMeeting?: "yes" | "no";
  contested: boolean;
  date: Date;
  enablePhysicalMeeting: boolean;
  enableVirtualMeeting: boolean;
  enableTelephoneMeeting: boolean;
  isPhysical: boolean;
  postalCode: string;
  proxyType: ProxyType;
  telephone: string;
  time: string;
  formats: IApiMeetingFormat[];
  timezone: string;
  title: null | string;
  type: MeetingType;
  virtualMeetingUrl: null | string;
};

export enum ReportType {
  Annual = "annual",
  SemiAnnual = "semi_annual",
}

export type BulkUploadSet = {
  id: number;
  csvFile: string;
};

export type CorporateActionApproval = {
  id: number;
  broker: number;
  corporateAction: number;
};

type IApiMeetingFormatType = IMeetingFormatType | "private" | "in_person";

export type IApiMeetingFormat = {
  format: IApiMeetingFormatType;
  value: string;
  canVote: boolean;
};

export type FilingDetails = {
  additionalRecordDates: string[];
  actionHistory: Action[];
  brokerSearchReceivedDate: Date;
  bulkUploadSet: BulkUploadSet | null;
  changesets: Changeset[];
  companyContacts: CompanyContact[];
  contestingEntity?: string;
  deliveryMethod: DeliveryMethod;
  digitalMaterialsExpectedDate: Date;
  digitalMaterialsReceivedDate: Date;
  documents: Document[];
  edgarFilings: EdgarFiling[];
  globalFilingId: string;
  hasMaterialsFiled: boolean;
  id: number;
  invoicerAddressLine1: string;
  invoicerAddressLine2: string;
  invoicerAddressLine3: string;
  invoicerCity: string;
  invoicerState: string;
  invoicerPostalCode: string;
  invoicerCountry: string;
  invoicerCompany: string;
  invoicerContact: string;
  invoicerNotes: string;
  issuer: Issuer;
  issuerFiling?: number;
  wasDraft: boolean;
  notes: string;
  operationsStatus: OperationsStatus;
  paperMaterialsExpectedDate: Date;
  paperMaterialsReceivedDate: Date;
  referenceMaterials: Reference[];
  recordDate: Date;
  securities: Security[];
  solicitor: Solicitor;
  solicitorJobNumber: string;
  stats: Stats;
  tabulator?: Tabulator;
  tabulatorAddressLine1?: string;
  tabulatorAddressLine2?: string;
  tabulatorCity?: string;
  tabulatorCompanyName?: string;
  tabulatorContactEmail?: string;
  tabulatorContactName?: string;
  tabulatorPhoneNumber?: string;
  tabulatorState?: string;
  tabulatorZip?: string;
  meeting?: Meeting;
  meetingDate?: string | null;
  minimumShares?: number;
  voteCutoffDate?: Date;
  voteCutoffTime?: string;
  voteCutoffTimezone?: string;
  voteCutoffDatetime?: Date;
  voteProposals?: VoteProposal[];
  reportType?: ReportType;
  fundOwner?: string;
  informationStatementType?: string;
  type: FilingType;
};

export interface Changeset {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  after: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  before: any;
  created: string;
  type: ChangesetType;
  user: {
    username: string;
    firstName: string;
    lastName: string;
  } | null;
}

export type ChangesetType =
  | "attribute"
  | "security"
  | "filing_created"
  | "vote_proposal_created"
  | "vote_proposal_modified"
  | "vote_proposal_deleted";

export type Reference = {
  created: string;
  csvData: null | string;
  csvSource: null | string;
  emailAddress: null | string;
  html: null | string;
  sourceType: SourceType;
  subject: null | string;
  text: null | string;
  wasApplied: boolean;
};

export enum SourceType {
  "new_email" = "Broker Search",
  "update_email" = "Broker Search Update",
  "proposals_email" = "Meeting Agenda",
  "materials_email" = "Material URL(s)",
  "additional_email" = "Additional Mailing",
  "csv" = "CSV",
}

export enum CorporateActionType {
  BIDS = "Repurchase Offer",
  DRIP = "Dividend Reinvestment",
  DIVO = "Dividend Option",
  CONE = "Consideration Election",
  TEND = "Tender Offer",
  EXOF = "Exchange Offer",
  ODLT = "Odd Lot Sale/Purchase",
  RHTS = "Rights Issue",
  PRIO = "Priority Issue",
}

// .oOo.oOo.oOo.oOo.oO   EDGAR   Oo.oOo.oOo.oOo.oOo.

export type EdgarFiling = {
  cik: number;
  id: number;
  type: EdgarFilingType;
  url: string;
  status: EdgarFilingStatus;
  filingDate: string | null;
  created: string;
};

export type EdgarFilingType =
  | "DEF 14A"
  | "DEF 14C"
  | "DEFA14A"
  | "DEFA14C"
  | "DEFC14A"
  | "DEFC14C"
  | "DEFM14A"
  | "DEFM14C"
  | "DEFN14A"
  | "DEFR14A"
  | "DEFR14C"
  | "DFAN14A"
  | "DFRN14A"
  | "8-K"
  | "6-K"
  | "6-K/A"
  | "S-4"
  | "S-4/A";

export type EdgarFilingStatus = "pending" | "confirmed" | "dismissed";

// .oOo.oOo.oOo.oOo.oO   DATA   Oo.oOo.oOo.oOo.oOo.

export type IMeetingFormatType = "inPerson" | "virtual" | "telephone";

export type IFormMeetingFormat = {
  value: string;
  canVote: string;
  enabled?: boolean;
};
export type IFormMeetingFormatObj = {
  [key in IMeetingFormatType]: IFormMeetingFormat;
};
export type IFormCanAttendMeeting = {
  value: string;
  enabled: string;
};

export type FilingDetailsFormValues = {
  brokerSearchReceivedDate: Date | null;
  meetingFormats: IFormMeetingFormatObj;
  canAttendMeeting: IFormCanAttendMeeting;
  cusips: Cusip[];
  contestingEntity?: string;
  brokers?: { id: number; name: string; slug: string }[];
  deliveryMethod: DeliveryMethod;
  digitalMaterialsReceivedDate: Date | null;
  digitalSendDeadline: string | null;
  expectedDate: Date | null;
  fundOwner?: string;
  invoicerAddress?: string;
  invoicerAddressLine1?: string;
  invoicerAddressLine2?: string;
  invoicerAddressLine3?: string;
  invoicerCity?: string;
  invoicerState?: string;
  invoicerPostalCode?: string;
  invoicerCountry?: string;
  issuer: { id: number; name: string; globalIssuerId?: string } | null;
  issuerFiling?: number;
  meetingDate: Date | null;
  meetingTime: string | null;
  meetingTimezone: string | null;
  meetingType: MeetingType;
  minimumShares?: number;
  notes?: string;
  paperMaterialsReceivedDate: Date | null;
  paperSendDeadline: string | null;
  recordDate: Date | null;
  recordDateCompare: boolean;
  additionalRecordDates?: string[];
  replyTo?: CompanyContact[];
  reportType?: ReportType;
  sendTo?: CompanyContact[];
  solicitor?: Solicitor;
  solicitorJobNumber?: string;
  tabulator?: Tabulator;
  type: FilingType;
  voteCutoffDate: Date | null;
  voteCutoffTime: string | null;
  voteCutoffTimezone: string | null;
};

export type IFilingCreateValues = {
  issuer: Issuer | null;
  deliveryMethod: DeliveryMethod | null;
  type: FilingType;
  reportType: ReportType;
  fundOwner?: string;
  cusips: {
    id: number;
    cusip: string;
    name: string;
  }[];
  brokers?: Broker[];
  contestingEntity?: string;
  expectedDate?: Date | null;
  recordDate?: Date | null;
  brokerSearchReceivedDate?: Date | null;
  meetingDate?: Date | null;
  meetingType?: MeetingType;
  solicitorJobNumber?: string;
  solicitor?: Solicitor;
  tabulator?: Tabulator;
  issuerFiling?: string;
  notes?: string;
  replyTo?: CompanyContact[];
  sendTo?: CompanyContact[];
  invoicerAddress?: string;
  invoicerAddressLine1?: string;
  invoicerAddressLine2?: string;
  invoicerAddressLine3?: string;
  invoicerCity?: string;
  invoicerState?: string;
  invoicerPostalCode?: string;
  invoicerCountry?: string;
  voteCutoffDate?: string | null;
  minimumShares?: number;
};

export type Broker = {
  id: number;
  name: string;
  slug: string;
};

// Review email
export interface BrokersResponseBroker {
  communicationCount: number;
  id: string;
  logo: string;
  name: string;
  slug: string;
}
