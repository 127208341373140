import "./styles/tailwind.generated.css";
import "react-datepicker/dist/react-datepicker.css";
import { Routes, Route } from "react-router-dom";

import { Issuers } from "./features/IssuersList";
import { Issuer } from "./features/IssuerDetails";
import { CorporateActions } from "./features/CorporateActions";
import { Proxy } from "./features/Proxy";

import { StyledEngineProvider } from "@mui/material/styles";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { PrivateRoute } from "./components/PrivateRoute";
import { SWRConfig } from "swr";
import { BatchFilingsProvider } from "./features/Proxy/batch-filings-context";
import { ProxyWarningProvider } from "./features/Proxy/proxy-warning-context";
import { proxy } from "./api/adapters/proxy";
import { Provider as SayProvider } from "@asayinc/component-library";
import { Prospectuses } from "./features/Prospectuses";
import { Vca } from "./features/vca";
import { LogIn } from "./features/LogIn";
import { Nobo } from "./features/nobo";

function fetcher<T>(url: string) {
  return proxy.get<T>(url);
}

const App = (): JSX.Element => {
  return (
    <>
      <ToastContainer transition={Slide} />
      <BatchFilingsProvider>
        <ProxyWarningProvider>
          <SWRConfig value={{ fetcher }}>
            <StyledEngineProvider injectFirst>
              <SayProvider>
                <Routes>
                  <Route path="/" element={<LogIn />} />
                  <Route element={<PrivateRoute />}>
                    <Route path="proxy/*" element={<Proxy />} />
                    <Route path="prospectuses/*" element={<Prospectuses />} />
                    <Route
                      path="corporate-actions/*"
                      element={<CorporateActions />}
                    />
                    <Route path="vca/*" element={<Vca />} />
                    <Route path="issuers" element={<Issuers />} />
                    <Route path="issuers/:id/*" element={<Issuer />} />
                    <Route path="nobo/*" element={<Nobo />} />
                  </Route>
                </Routes>
              </SayProvider>
            </StyledEngineProvider>
          </SWRConfig>
        </ProxyWarningProvider>
      </BatchFilingsProvider>
    </>
  );
};

export default App;
