import React from "react";
import { FilingDetailsFormValues } from "../../types";
import { FilingDatePicker } from "../FilingDatePicker";
import { FormikProps } from "formik";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import { TIMEZONES } from "../../../../constants/timezones";
import { MeetingFormats } from "./Organisms";
import { CanShareholdersAttend } from "./Molecules";

type MeetingProps = {
  formikBag: FormikProps<FilingDetailsFormValues>;
  isDraft: boolean;
};

const MeetingDetails: React.FC<MeetingProps> = ({ formikBag, isDraft }) => {
  const formatTimezoneLabel = (timeZone: string) => {
    if (timeZone) {
      const abbreviatedTimeZone = new Intl.DateTimeFormat("en-US", {
        timeZone,
        timeZoneName: "short",
      })
        .format()
        .split(", ")
        .pop();
      return `${timeZone.split("_").join(" ")} (${abbreviatedTimeZone})`;
    }
    return timeZone;
  };

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        "& > *": {
          marginBottom: "24px",
        },
      }}
    >
      {formikBag.values.type !== "FirmConsentSolicitation" && (
        <div className="grid grid-cols-3 gap-6 mt-3">
          <FilingDatePicker
            name="meetingDate"
            label="Meeting Date"
            formikBag={formikBag}
            required={!isDraft}
          />
          <FormControl variant="outlined" fullWidth>
            <TextField
              type="time"
              name="meetingTime"
              variant="outlined"
              required={!isDraft}
              label="Meeting Time"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              value={formikBag.values.meetingTime || ""}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                formikBag.setFieldValue("meetingTime", e.target.value);
              }}
            />
            {formikBag.errors.meetingTime && formikBag.touched.meetingTime ? (
              <Box
                sx={{
                  color: "#d33f33",
                  fontSize: ".75rem",
                  margin: "3px 0 0 14px",
                }}
              >
                {formikBag.errors.meetingTime}
              </Box>
            ) : null}
          </FormControl>

          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="meeting-timezone"
              options={TIMEZONES}
              fullWidth
              getOptionLabel={formatTimezoneLabel}
              value={formikBag.values.meetingTimezone || ""}
              onChange={(e, newValue) => {
                formikBag.setFieldValue("meetingTimezone", newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={!isDraft}
                  label="Meeting Timezone"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </div>
      )}

      <div className="grid grid-cols-3 gap-6">
        <FilingDatePicker
          name="voteCutoffDate"
          label="Vote By Date"
          formikBag={formikBag}
          required={!isDraft}
        />

        <TextField
          type="time"
          name="voteCutoffTime"
          variant="outlined"
          label="Vote Cutoff Time"
          size="small"
          required={!isDraft}
          InputLabelProps={{
            shrink: true,
          }}
          value={formikBag.values.voteCutoffTime || ""}
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            formikBag.setFieldValue("voteCutoffTime", e.target.value);
          }}
        />
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            id="meeting-timezone"
            options={TIMEZONES}
            fullWidth
            getOptionLabel={formatTimezoneLabel}
            value={formikBag.values.voteCutoffTimezone || ""}
            onChange={(e, newValue) => {
              formikBag.setFieldValue("voteCutoffTimezone", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={!isDraft}
                label="Vote Cutoff Timezone"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
          />
        </FormControl>
      </div>
      {formikBag.values.type !== "FirmConsentSolicitation" && (
        <>
          <CanShareholdersAttend formikBag={formikBag} />
          {formikBag.values.canAttendMeeting.enabled === "yes" && (
            <MeetingFormats formikBag={formikBag} />
          )}
        </>
      )}
    </Box>
  );
};

export default MeetingDetails;
