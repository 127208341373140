import useSWR from "swr";
import { nexus } from "..";

const fetcher = (url: string) => {
  return nexus.get(url);
};

export function useVcaEmailPreview(vcaId: string, brokerId: string | null) {
  const url = brokerId
    ? `corporate-action-events/${vcaId}/preview/${brokerId}/`
    : null;
  const { data, error, mutate } = useSWR(url, fetcher);

  return {
    isError: error,
    isLoading: !data && !error,
    preview: data?.data,
    mutate,
  };
}
