import { useParams } from "react-router-dom";
import useSWR from "swr";
import { proxy } from "../../../api/adapters/proxy";
import { FilingsList } from "../FilingsList";
import { CorporateActionsList } from "../CorporateActionsList";
import { PageWrapper } from "../../../components/PageWrapper";

export type FilingsResponse = Filing[];
export type CorporateActionsResponse = CorporateAction[];

interface Filing {
  id: number;
  issuerId: string;
  issuerName: string;
  cusips: string[];
  tickers: string[] | null[];
  edgarDate: null | string;
  edgarUrl: null | string;
  type: string;
  operationsStatus: string;
  recordDate: string;
  meetingDate: string;
  positions: number;
  expectedDate: string;
}

interface CorporateAction {
  announcementDate: string;
  cusips: string[];
  edgarFilings: EdgarFiling[];
  electionCutoffDate: string;
  id: number;
  issuerId: string;
  issuerName: string;
  operationsStatus: string;
  positions: number;
  record_date: string;
  tickers: string[] | null;
  type: string;
}

interface EdgarFiling {
  cik: null | string;
  filingDate: null | string;
  status: null | string;
  type: null | string;
  url: null | string;
}

const issuerFilingsFetcher = (url: string) => proxy.get<FilingsResponse>(url);
const issuerCorporateActionsFetcher = (url: string) =>
  proxy.get<CorporateActionsResponse>(url);

function Activity(): JSX.Element {
  const { id } = useParams();
  const filings = useSWR(`/admin/issuers/${id}/filings/`, issuerFilingsFetcher);

  const corporateActions = useSWR(
    `/admin/issuers/${id}/corporate-actions/`,
    issuerCorporateActionsFetcher
  );

  return (
    <PageWrapper>
      <FilingsList cache={filings} />

      <CorporateActionsList cache={corporateActions} />
    </PageWrapper>
  );
}

export { Activity };
