import { HeaderDataType } from "../../../components/TasklistTable/TableColumnHeaders";

/**
 * Review Table Header data for mapping over the fields
 */
export const TABLE_HEADER_DATA: Array<HeaderDataType> = [
  {
    queryValue: null,
    label: "CUSIP",
  },
  {
    queryValue: "polymorphic_ctype",
    label: "Event",
  },
  {
    queryValue: "record_date",
    label: "Record",
  },
  {
    queryValue: "stats__total_positions",
    label: "Positions",
  },
  {
    queryValue: "meeting_date",
    label: "Meeting",
  },
  {
    queryValue: "stats__internal_digital_send_deadline",
    label: "Send",
  },
];
