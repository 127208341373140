import { useState } from "react";
import { COLORS } from "@asayinc/component-library";

import { ProspectusAccordionSummary } from "./ProspectusAccordionSummary";
import { ProspectusDocumentsAccordion } from "../ProspectusDocumentsAccordion";
import { Accordion } from "../atoms/Accordion";
import { Divider } from "../atoms/Divider";
import { AccordionDetails } from "../atoms/AccordionDetails";
import { IBaseProps } from "../../types/BaseProps";
import {
  ProspectusDocument,
  ProspectusPackage,
} from "../../api/adapters/nexus/types";

interface IProspectusAccordion extends IBaseProps {
  isExpanded?: boolean;
  isReadOnly?: boolean;
  onDocumentPreviewClick?: (doc: ProspectusDocument) => void;
  onProspectusPackageUpdate: () => void;
  prospectusPackages: ProspectusPackage[];
}

export function ProspectusAccordion({
  isExpanded = false,
  isReadOnly = false,
  onDocumentPreviewClick,
  onProspectusPackageUpdate,
  prospectusPackages,
}: IProspectusAccordion) {
  const [isOpen, setIsOpen] = useState(isExpanded);
  const {
    security: { cusip, issuerName, ticker },
  } = prospectusPackages[0];

  // Sorting in ascending order
  // This assumes each package has an unique
  // effectiveStartDate year.
  prospectusPackages.sort((a, b) => {
    const dateA = a.effectiveStartDate.split("-");
    const dateB = b.effectiveStartDate.split("-");
    return Number(dateA[0]) - Number(dateB[0]);
  });

  const handleProspectusUpdate = () => {
    if (onProspectusPackageUpdate) {
      onProspectusPackageUpdate();
    }
  };

  return (
    <Accordion
      expanded={isOpen}
      sx={{
        boxShadow: "none",
        border: `1px solid ${COLORS.LIGHT_GREY}`,
      }}
    >
      <ProspectusAccordionSummary
        chipLabel={cusip}
        onClick={() => setIsOpen(!isOpen)}
        ticker={ticker}
        titleColor={COLORS.P_HIGH_EMPHASIS}
        title={issuerName}
      />
      <Divider />
      <AccordionDetails sx={{ p: 0, pl: 2 }}>
        {prospectusPackages.map((prospectusPackage, index) => {
          return (
            <ProspectusDocumentsAccordion
              key={index}
              isReadOnly={isReadOnly}
              onDocumentPreviewClick={onDocumentPreviewClick}
              onProspectusPackageUpdate={handleProspectusUpdate}
              prospectusPackage={prospectusPackage}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
