import axios from "axios";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { FilingDetails, OperationsStatus } from "src/features/Proxy/types";
import { formatCamelCaseString } from "src/utils/format-camel-case";
import { formatDate } from "src/utils/format-date";
import { Avatar } from "../atoms/Avatar";
import { Box } from "../atoms/Box";
import { Button } from "../atoms/Button";
import { PageHeader } from "../molecules/PageHeader";
import { ProxyStatusBadge } from "../molecules/ProxyStatusBadge";
import { DeleteConfirmationDialog } from "../Dialog/DeleteConfirmationDialog";
import { deleteFiling, updateFilingStatus } from "src/api/adapters";
import { UpdateStatusDialog } from "../Dialog/UpdateStatusDialog";
import {
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from "src/features/Proxy/batch-filings-context";
import { pluralize } from "src/utils/pluralize";
import { formatUpdateStatusError } from "src/utils/format-update-status-error";

interface IProxyPageHeaderProps {
  filing: FilingDetails;
  hideCta?: boolean;
  id: string;
  isCtaDisabled?: boolean;
  onFilingUpdate: () => void;
}

export function ProxyPageHeader({
  filing,
  hideCta = false,
  id,
  isCtaDisabled,
  onFilingUpdate,
}: IProxyPageHeaderProps) {
  const dispatch = useBatchFilingsDispatch();
  const navigate = useNavigate();
  const batchFilings = useBatchFilingsState();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isUpdateStatusDialogOpen, setIsUpdateStatusDialogOpen] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { operationsStatus } = filing;

  const STATUS_TO_BUTTON_TEXT: { [key in OperationsStatus]: string } = {
    [OperationsStatus.Draft]: "confirm",
    [OperationsStatus.New]: "complete",
    [OperationsStatus.Active]: "complete",
    [OperationsStatus.MaterialsRequested]: "complete",
    [OperationsStatus.ReadyForReview]: "approve",
    [OperationsStatus.Closed]: "",
    [OperationsStatus.Approved]: "",
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => setMenuAnchorEl(event.currentTarget);

  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleDeleteFiling = async () => {
    try {
      setIsDeleting(true);
      await deleteFiling(id);
      navigate("/proxy");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error);
      } else {
        toast.error("An error occurred. Unable to delete filing.");
      }
    } finally {
      setIsDeleting(false);
    }
  };

  const handleUpdateStatus = async (targetStatus: OperationsStatus) => {
    try {
      setIsUpdating(true);
      await updateFilingStatus(id, { operationsStatus: targetStatus });
      // Calling this function to mutate the filing
      onFilingUpdate();
      toast.success(toastMessageFromOperationsStatus(operationsStatus));
      if (batchFilings.filings.length) {
        // If we are in a batch filing workflow, we will navigate to the next
        // filing in the queue
        dispatch({ type: "ADD_FINISHED_FILING", filing: Number(id) });
        navigate(
          `/proxy/${getNextBatchFiling(batchFilings.filings, Number(id))}`
        );
      }
      setIsUpdateStatusDialogOpen(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(formatUpdateStatusError(error));
      }
    } finally {
      setIsUpdating(false);
    }
  };

  function getNextBatchFiling(filings: number[], selected: number) {
    const currentIndex = filings.indexOf(selected);
    const next = filings[(currentIndex + 1) % filings.length];
    return next;
  }

  /**
   * @param operationsStatus status of current filing
   * @returns ReactNode
   */
  const toastMessageFromOperationsStatus = (
    operationsStatus: OperationsStatus
  ) => {
    /** length of filings in batch  */
    const filingLength = batchFilings.filings.length || 1;
    /** length of filings finished inclusive of current */
    const finishedLength = batchFilings.finishedFilings.length + 1;
    /** String for batch filings to include "of {num}" */

    const batchString = pluralize({
      singular: "",
      plural: `of ${filingLength}`,
      count: filingLength,
    });
    const pluralString = pluralize({
      singular: "",
      plural: `s`,
      count: filingLength,
    });
    const href = `/proxy/${id}`;
    const className = "hover:text-blue underline";

    switch (operationsStatus) {
      case OperationsStatus.Draft:
        return (
          <a href={href} className={className}>
            {`${finishedLength} ${batchString} filing${pluralString} marked confirmed.`}
          </a>
        );
      case OperationsStatus.New:
      case OperationsStatus.Active:
      case OperationsStatus.MaterialsRequested:
        return (
          <a href={href} className={className}>
            {`${finishedLength} ${batchString} filing${pluralString} marked for review.`}
          </a>
        );
      case OperationsStatus.ReadyForReview:
        return (
          <a href={href} className={className}>
            {`${finishedLength} ${batchString} filing${pluralString} marked approved.`}
          </a>
        );
      default:
        return (
          <a href={href} className={className}>
            {`Filing${pluralString} marked ${STATUS_TO_BUTTON_TEXT[operationsStatus]}.`}
          </a>
        );
    }
  };

  const headerDetails =
    filing.actionHistory.length > 0
      ? `${
          filing.actionHistory[0]?.modified ? "Modified:" : "Created:"
        } ${formatDate(
          new Date(
            filing.actionHistory[0]?.modified ||
              filing.actionHistory[0]?.created
          )
        )}`
      : undefined;

  return (
    <PageHeader
      icon={<Avatar name="px" />}
      details={headerDetails}
      title={`${formatCamelCaseString(filing.type)}: ${
        filing.issuer.companyName
      }`}
    >
      <ProxyStatusBadge filingStatus={filing.operationsStatus} sx={{ ml: 4 }} />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        {filing.operationsStatus !== "approved" &&
          filing.operationsStatus !== "closed" &&
          !hideCta && (
            <Box>
              <Button
                sx={{ mr: 2 }}
                disabled={isCtaDisabled}
                onClick={() => setIsUpdateStatusDialogOpen(true)}
                variant="contained"
                size="small"
              >
                {STATUS_TO_BUTTON_TEXT[operationsStatus]}
              </Button>
            </Box>
          )}
        <IconButton onClick={handleMenuClick}>
          <MoreHorizIcon />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            disabled={filing.operationsStatus === OperationsStatus.Approved}
            onClick={() => {
              handleMenuClose();
              setIsDeleteDialogOpen(true);
            }}
          >
            Delete Filing
          </MenuItem>
        </Menu>
      </Box>
      <DeleteConfirmationDialog
        deleteType="filing"
        isOpen={isDeleteDialogOpen}
        isPrimaryLoading={isDeleting}
        onConfirmDelete={handleDeleteFiling}
        onRequestClose={() => setIsDeleteDialogOpen(false)}
      />
      <UpdateStatusDialog
        currentStatus={operationsStatus}
        isOpen={isUpdateStatusDialogOpen}
        isPrimaryLoading={isUpdating}
        onConfirmUpdate={(targetStatus: OperationsStatus) =>
          handleUpdateStatus(targetStatus)
        }
        onRequestClose={() => setIsUpdateStatusDialogOpen(false)}
      />
    </PageHeader>
  );
}
