import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as IssuerIcon } from "src/assets/icons/issuer-icon.svg";
import { PageWrapper } from "../../components/PageWrapper";
import { LoadingState } from "../../components/LoadingState";
import { ErrorCard } from "../../components/ErrorCard";
import { Box } from "../../components/atoms/Box";
import { Button } from "../../components/atoms/Button";
import { ProspectusAccordion } from "../../components/ProspectusAccordion";
import { CreateProspectusPackageDialog } from "../../components/CreateProspectusPackageDialog";
import { PreviewPane } from "../../components/PreviewPane";
import { previewItemsFromProspectusPackages } from "../../components/PreviewPane/utils";
import { PreviewItem } from "../../components/PreviewPane/PreviewPane";
import { DisplayType } from "../Proxy/types";
import { useProspectusPackages } from "src/api/adapters/nexus/hooks/useProspectusPackages";
import { useSecurity } from "src/api/adapters/nexus/hooks/useSecurity";
import { PageHeader } from "src/components/molecules/PageHeader";
import { SplitPane } from "src/components/molecules/SplitPane";
import { ProspectusDocument } from "src/api/adapters/nexus/types";

type SecurityProspectusDetailsParams = {
  id: string;
};

export function SecurityProspectusDetails(): JSX.Element {
  const { id } = useParams() as SecurityProspectusDetailsParams;
  const navigate = useNavigate();
  const { isError, isLoading, mutate, prospectusPackages } =
    useProspectusPackages([id]);
  const { isLoading: isSecurityLoading, security } = useSecurity(id);
  const [isProspectusCreationDialogOpen, setIsProspectusCreationDialogOpen] =
    useState(false);
  const [currentPreview, setCurrentPreview] = useState<PreviewItem | null>(
    null
  );

  if (isLoading || isSecurityLoading) {
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );
  }

  if (isError || !prospectusPackages || !security) {
    return (
      <PageWrapper>
        <ErrorCard
          title="Unable to load this trade alert."
          body="Something went wrong when attempting to load this trade alert. Please contact support for help with this issue."
        />
      </PageWrapper>
    );
  }

  const handleOpenCreationDialog = () => {
    setIsProspectusCreationDialogOpen(true);
  };

  const handleCloseCreationDialog = () => {
    setIsProspectusCreationDialogOpen(false);
  };

  const handleOnSuccess = (newPackageId: string) => {
    mutate();
    navigate(`/prospectuses/security/${id}?targetPackage=${newPackageId}`);
  };

  const handleDocumentPreviewClick = (doc: ProspectusDocument) => {
    setCurrentPreview({
      id: doc.id,
      name: doc.name,
      preview: doc.url,
      type: DisplayType.Document,
    });
  };

  const previewOptions = previewItemsFromProspectusPackages(prospectusPackages);

  const renderPrimaryPane = () => {
    return (
      <Box id="TradeAlertDetailsFormTab">
        <Box
          sx={{
            mb: 4,
            mx: -8,
            px: 8,
            alignItems: "center",
            display: "flex",
            borderBottom: "1px solid #e5e5e5",
            height: "48px",
          }}
        >
          <Box component="b" sx={{ flexGrow: 1 }}>
            Prospectus Packages
          </Box>
          {!prospectusPackages.some((p) => p.status === "pending") && (
            <Button
              onClick={handleOpenCreationDialog}
              size="small"
              variant="contained"
            >
              Create
            </Button>
          )}
        </Box>
        {prospectusPackages.length > 0 && (
          <ProspectusAccordion
            isExpanded={true}
            onDocumentPreviewClick={handleDocumentPreviewClick}
            onProspectusPackageUpdate={mutate}
            prospectusPackages={prospectusPackages}
          />
        )}
      </Box>
    );
  };

  const renderSecondaryPane = () => {
    return (
      <PreviewPane currentPreview={currentPreview} options={previewOptions} />
    );
  };

  return (
    <>
      <PageHeader icon={<IssuerIcon />} title={security.issuerName} />
      <SplitPane
        primaryPane={renderPrimaryPane()}
        primarySx={{ px: 8 }}
        secondaryPane={renderSecondaryPane()}
        secondarySx={{
          display: "flex",
          px: 8,
          py: 4,
        }}
      />
      <CreateProspectusPackageDialog
        isOpen={isProspectusCreationDialogOpen}
        onClose={handleCloseCreationDialog}
        onSuccess={handleOnSuccess}
        securityOptions={[
          {
            id: id,
            cusip: String(security.cusip),
          },
        ]}
      />
    </>
  );
}
