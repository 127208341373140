import { Box } from "src/components/atoms/Box";

interface IBrokerLogoProps {
  logo: string;
  name: string;
}

function BrokerLogo({ logo, name }: IBrokerLogoProps): JSX.Element {
  return (
    <Box
      sx={{
        height: "24px",
        width: "24px",
        background: "#fff",
        borderRadius: "4px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={logo} alt={name} />
    </Box>
  );
}

export { BrokerLogo };
