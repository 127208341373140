import { Button as MUIButton, ButtonProps } from "@mui/material";

export type ButtonColors =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";

export function Button({ children, ...props }: ButtonProps) {
  return <MUIButton {...props}>{children}</MUIButton>;
}
