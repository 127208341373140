import { IconButton, Tooltip } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CreateIcon from "@mui/icons-material/Create";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { COLORS } from "@asayinc/component-library";
import axios from "axios";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";

import { Box } from "../atoms/Box";
import { DeleteConfirmationDialog } from "../Dialog/DeleteConfirmationDialog";
import { capitalize } from "src/utils/capitalize";
import { WarrenGDocument } from "src/types/warreng";
import { NonEdiDocumentType } from "src/features/CorporateActions/types";
import {
  WarrenGDocumentDialog,
  WarrenGDocumentForm,
} from "../WarrenGDocumentDialog";
import { documentTypeOptions as vcaDocumentTypeOptions } from "src/features/CorporateActions/select-options";
import { documentTypeOptions as proxyDocumentTypeOptions } from "src/features/Proxy/select-options";

interface IDocumentRowProps {
  document: WarrenGDocument;
  onDeleteDocument: (doc: WarrenGDocument) => void;
  onEditDocument?: (
    doc: WarrenGDocument,
    type: NonEdiDocumentType,
    name: string
  ) => void;
  onPreviewClick?: (doc: WarrenGDocument) => void;
  showProxyDocumentOptions: boolean;
  updateDocuments?: () => void; // This function is to update the document list at the parent level
}

// This component should only be used for warrenG documents
// Currently we use warrenG documents for VCAs and Additional Mailing
export function DocumentRow({
  document,
  onDeleteDocument,
  onEditDocument,
  onPreviewClick,
  showProxyDocumentOptions,
  updateDocuments,
}: IDocumentRowProps) {
  const { name, type, url } = document;
  const [isDeleteDocumentDialogOpen, setIsDeleteDocumentDialogOpen] =
    useState(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenDeleteDialog = () => {
    setIsDeleteDocumentDialogOpen(true);
  };

  const handleOpenUpdateDialog = () => {
    setIsUpdateDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDocumentDialogOpen(false);
  };

  const handleCloseUpdateDialog = () => {
    setIsUpdateDialogOpen(false);
  };

  const handleDocumentUpdate = async (values: WarrenGDocumentForm) => {
    const { type, name } = values;
    if (onEditDocument && type) {
      try {
        setIsLoading(true);
        await onEditDocument(document, type, name);
      } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.data?.length > 0) {
          const errorMessage = e?.response?.data[0];
          toast.error(capitalize(errorMessage));
        } else {
          toast.error(
            "An error occured when trying to update the additional mailing."
          );
        }
      } finally {
        setIsLoading(false);
        setIsUpdateDialogOpen(false);
        // Update the document list at the parent level
        if (updateDocuments) {
          updateDocuments();
        }
      }
    }
  };

  const handleOnDelete = async () => {
    try {
      setIsLoading(true);
      await onDeleteDocument(document);
    } catch (e) {
      if (axios.isAxiosError(e) && e?.response?.data?.length > 0) {
        const errorMessage = e?.response?.data[0];
        toast.error(capitalize(errorMessage));
      } else {
        toast.error("An error occured when trying to delete the document.");
      }
    } finally {
      setIsLoading(false);
      setIsDeleteDocumentDialogOpen(false);
      // Update the document list at the parent level
      if (updateDocuments) {
        updateDocuments();
      }
    }
  };

  const handlePreviewClick = () => {
    if (onPreviewClick) {
      onPreviewClick(document);
    }
  };

  const options = showProxyDocumentOptions
    ? proxyDocumentTypeOptions
    : vcaDocumentTypeOptions;

  const documentTypeLabel = useMemo(() => {
    return options.find((option) => option.value === type)?.label || "";
  }, [type]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #e5e5e5",
        marginBottom: "16px",
        padding: "8px 16px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: COLORS.ICE_BLUE,
        },
      }}
    >
      <InsertDriveFileIcon style={{ color: "#979797", marginRight: "16px" }} />
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box component="p">
          <a
            className="text-blue hover:underline hover:text-blue"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        </Box>
        <Box sx={{ color: COLORS.SATURATED_GREY, fontSize: "12px" }}>
          {documentTypeLabel}
        </Box>
      </Box>
      <Box sx={{ textAlign: "right" }}>
        {onPreviewClick && (
          <Tooltip title="Preview">
            <IconButton
              size="small"
              aria-label="preview"
              onClick={handlePreviewClick}
            >
              <VisibilityOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        {onEditDocument && (
          <Tooltip title="Edit">
            <IconButton
              size="small"
              aria-label="edit"
              onClick={handleOpenUpdateDialog}
            >
              <CreateIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Delete">
          <IconButton
            size="small"
            aria-label="delete"
            onClick={handleOpenDeleteDialog}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <DeleteConfirmationDialog
        deleteType="document"
        isOpen={isDeleteDocumentDialogOpen}
        isPrimaryLoading={isLoading}
        onConfirmDelete={handleOnDelete}
        onRequestClose={handleCloseDeleteDialog}
      />
      <WarrenGDocumentDialog
        document={document}
        isOpen={isUpdateDialogOpen}
        isLoading={isLoading}
        onClose={handleCloseUpdateDialog}
        onCreateUpdate={handleDocumentUpdate}
        showProxyDocumentOptions={showProxyDocumentOptions}
      />
    </Box>
  );
}
