import { AppBar, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import { ChevronLeft, ChevronRight, Add } from "@mui/icons-material";
import { COLORS } from "@asayinc/component-library";
import { MouseEvent, useRef, useState, ReactNode } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";

import { useSearch } from "src/api/adapters/proxy/hooks/useSearch";
import { SearchInput, SearchResults } from "src/features/Search";
import { useBatchFilingsState } from "src/features/Proxy/batch-filings-context";
import {
  useProxyWarningDispatch,
  useProxyWarningState,
  navigateAway,
} from "src/features/Proxy/proxy-warning-context";
import { Box } from "../atoms/Box";
import { Avatar } from "../atoms/Avatar";
import { BulkUploadDialog } from "../BulkUploadDialog/BulkUploadDialog";
import { VcaCreationDialog } from "../VcaCreationDialog/VcaCreationDialog";
import { NoboDialog } from "../NoboDialog/NoboDialog";

export function Header() {
  const { searchTerm, onSetSearch, data, error } = useSearch();
  const navigate = useNavigate();
  const match = useMatch("/proxy/:id/*");
  const [isBulkUploadDialogOpen, setIsBulkUploadDialogOpen] = useState(false);
  const [isVcaDialogOpen, setIsVcaDialogOpen] = useState(false);
  const [isNoboDialogOpen, setIsNoboDialogOpen] = useState(false);
  const filingId = match?.params?.id;
  const batchFilings = useBatchFilingsState();
  const isLoading = !data && !error;
  const isError = !data && error;

  const searchResults = data && data.data ? data.data : null;
  const showingResults = searchTerm;

  const proxyCheck = useProxyWarningState();
  const dispatch = useProxyWarningDispatch();

  // Create Filing menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleCreateClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCreateClose = () => {
    setAnchorEl(null);
  };

  // Detect search focus
  const [isSearchWide, setIsSearchWide] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => onSetSearch(""));

  function handleBackClick() {
    if (batchFilings.filings.length && filingId) {
      navigate(
        `/proxy/${getPreviousBatchFiling(
          batchFilings.filings,
          Number(filingId)
        )}`
      );
    } else {
      window.history.back();
    }
  }

  function handleForwardClick() {
    if (batchFilings.filings.length && filingId) {
      navigate(
        `/proxy/${getNextBatchFiling(batchFilings.filings, Number(filingId))}`
      );
    } else {
      window.history.forward();
    }
  }

  const handleOpenBulkDialog = () => {
    setIsBulkUploadDialogOpen(true);
  };

  const handleCloseBulkDialog = () => {
    setIsBulkUploadDialogOpen(false);
  };

  const handleOpenVcaDialog = () => {
    setIsVcaDialogOpen(true);
  };

  const handleCloseVcaDialog = () => {
    setIsVcaDialogOpen(false);
  };

  const handleOpenNoboDialog = () => {
    setIsNoboDialogOpen(true);
  };

  const handleCloseNoboDialog = () => {
    setIsNoboDialogOpen(false);
  };

  return (
    <AppBar
      aria-label="menu"
      position="sticky"
      elevation={0}
      sx={{
        height: "4.375rem",
        borderBottom: "1px solid #e5e5e5",
        background: !isSearchWide && !showingResults ? "#F8f8f8" : "#FFFFFF",
      }}
    >
      <Toolbar
        sx={{
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        {!showingResults && !isSearchWide && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mx: 4,
            }}
          >
            <IconButton
              size="medium"
              aria-label="go back"
              onClick={handleBackClick}
            >
              <ChevronLeft fontSize="small" />
            </IconButton>
            <IconButton
              size="medium"
              aria-label="go forward"
              onClick={handleForwardClick}
            >
              <ChevronRight fontSize="small" />
            </IconButton>
          </Box>
        )}
        <Box ref={ref} sx={{ flexGrow: 1 }}>
          <SearchInput
            onChange={onSetSearch}
            onClear={() => onSetSearch("")}
            setIsSearchWide={setIsSearchWide}
            value={searchTerm}
          />
          {showingResults && (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                top: "4.375rem",
                right: 0,
                left: 0,
                backgroundColor: "#FFF",
              }}
            >
              <SearchResults
                isLoading={isLoading}
                isError={isError}
                results={searchResults}
                onClear={() => onSetSearch("")}
              />
            </Box>
          )}
        </Box>
        {!showingResults && !isSearchWide && (
          <Box sx={{ color: "primary" }}>
            <IconButton
              sx={{ mr: 4 }}
              size="medium"
              aria-label="create"
              onClick={handleCreateClick}
            >
              <Add fontSize="small" color="primary" />
            </IconButton>
            <Menu
              aria-controls="simple-menu"
              aria-haspopup="true"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCreateClose}
            >
              <CreateOption
                icon={<Avatar name="px" size="small" />}
                onClick={() => {
                  handleCreateClose();
                  navigateAway(proxyCheck, dispatch, () =>
                    navigate("/proxy/create")
                  );
                }}
                label="Proxy Filing"
              />
              <CreateOption
                icon={<Avatar name="No" size="small" />}
                onClick={() => {
                  handleCreateClose();
                  handleOpenNoboDialog();
                }}
                label="NOBO"
              />
              <CreateOption
                icon={<Avatar name="vca" size="small" />}
                onClick={() => {
                  handleCreateClose();
                  handleOpenVcaDialog();
                }}
                label="Voluntary Corporate Action"
              />
              <CreateOption
                icon={<Avatar name="mf" size="small" />}
                onClick={() => {
                  handleOpenBulkDialog();
                  handleCreateClose();
                }}
                label="Multiple Filings"
              />
            </Menu>
          </Box>
        )}
      </Toolbar>
      <BulkUploadDialog
        isOpen={isBulkUploadDialogOpen}
        onClose={handleCloseBulkDialog}
      />
      <VcaCreationDialog
        isOpen={isVcaDialogOpen}
        onClose={handleCloseVcaDialog}
      />
      <NoboDialog isOpen={isNoboDialogOpen} onClose={handleCloseNoboDialog} />
    </AppBar>
  );
}

interface ICreateOption {
  icon: ReactNode;
  label: string;
  onClick: () => void;
}

function CreateOption({ icon, label, onClick }: ICreateOption) {
  return (
    <MenuItem
      disableGutters={true}
      sx={{
        minWidth: "20rem",
        padding: "0 !important",
        borderBottom: "1px solid rgba(0,0,0,.12)",
        "&:hover": {
          backgroundColor: COLORS.ICE_BLUE,
        },
        "&:last-child": {
          borderBottom: "none",
        },
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          width: "100%",
        }}
      >
        <Box sx={{ padding: "1.25rem" }}>{icon}</Box>
        <Box
          sx={{
            flexGrow: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {label}
        </Box>
      </Box>
    </MenuItem>
  );
}

function getNextBatchFiling(filings: number[], selected: number) {
  const currentIndex = filings.indexOf(selected);
  const next = filings[(currentIndex + 1) % filings.length];

  return next;
}

function getPreviousBatchFiling(filings: number[], selected: number) {
  const currentIndex = filings.indexOf(selected);
  const previous =
    filings[(currentIndex + filings.length - 1) % filings.length];

  return previous;
}
