import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useNobos } from "src/api/adapters/nexus/hooks/useNobos";
import { CusipTagList } from "src/components/CusipTagList";
import { LoadingState } from "src/components/LoadingState";
import { PageWrapper } from "src/components/PageWrapper";
import { Table } from "src/components/Table";
import { TableBodyCell } from "src/components/TableBodyCell";
import { TableBodyRow } from "src/components/TableBodyRow";
import { TableHeadCell } from "src/components/TableHeadCell";
import { TableHeader } from "src/components/TableHeader";
import { Security } from "src/types/vca";
import { formatDate } from "src/utils/format-date";
import { getPageOffset } from "src/utils/get-page-offset";
import { notEmpty } from "src/utils/not-empty";

interface INoboTableProps {
  defaultOrdering?: string;
  listType: "new" | "approved" | "delivered";
}

export function NoboTable({
  listType,
  defaultOrdering = "-date_received",
}: INoboTableProps) {
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState({
    limit: 25,
    offset: 0,
    ordering: defaultOrdering,
    page: 0,
  });
  const { count, nobos, isError, isLoading } = useNobos(listType, queryParams);

  const handleOrderingClick = useCallback(
    (ordering: string) => {
      setQueryParams({ ...queryParams, ordering });
    },
    [queryParams]
  );

  if (isLoading) {
    return <LoadingState />;
  }

  if (isError || !nobos) return <PageWrapper>Error...</PageWrapper>;

  const { limit, ordering, page } = queryParams;

  function handleTableRowClick(path: string) {
    return function (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
      if (e.metaKey) {
        window.open(path, "_blank");
        return;
      }
      navigate(path);
    };
  }

  return (
    <PageWrapper>
      <TableHeader
        count={count || 0}
        page={page}
        pageSize={limit}
        itemType="filing"
        onPageChange={(page) =>
          setQueryParams({
            ...queryParams,
            page,
            offset: getPageOffset({ page, pageSize: queryParams.limit }),
          })
        }
        onPageSizeChange={(limit) => setQueryParams({ ...queryParams, limit })}
      />
      <Table isFixed={true}>
        <thead className="bg-white">
          <tr>
            {listType !== "delivered" ? (
              <TableHeadCell
                name="Received date"
                orderingKey="date_received"
                currentOrdering={ordering}
                onOrderingClick={handleOrderingClick}
              />
            ) : (
              <TableHeadCell
                name="Record Date"
                orderingKey="record_date"
                currentOrdering={ordering}
                onOrderingClick={handleOrderingClick}
              />
            )}
            <TableHeadCell name="Issuer" />
            <TableHeadCell name="CUSIP" />
            <TableHeadCell name="Solicitor" />
            {listType !== "delivered" ? (
              <TableHeadCell
                name="Record Date"
                orderingKey="record_date"
                currentOrdering={ordering}
                onOrderingClick={handleOrderingClick}
              />
            ) : (
              <TableHeadCell
                name="Delivered Date"
                orderingKey="nobo_file_last_delivered"
                currentOrdering={ordering}
                onOrderingClick={handleOrderingClick}
              />
            )}
            {listType === "delivered" && <TableHeadCell name="Notes" />}
          </tr>
        </thead>
        <tbody>
          {nobos.map((result) => (
            <TableBodyRow
              key={result.id}
              onClick={handleTableRowClick(`/nobo/${result.id}`)}
            >
              {listType !== "delivered" ? (
                <TableBodyCell>
                  {result.dateReceived
                    ? formatDate(new Date(result.dateReceived))
                    : "-"}
                </TableBodyCell>
              ) : (
                <TableBodyCell>
                  {result.recordDate
                    ? formatDate(new Date(result.recordDate))
                    : "-"}
                </TableBodyCell>
              )}
              <TableBodyCell>
                {result.securities[0].issuer?.name || "-"}
              </TableBodyCell>
              <TableBodyCell>
                <CusipTagList
                  cusips={result.securities
                    .map((security: Security) => security.cusip)
                    .filter(notEmpty)}
                />
              </TableBodyCell>
              <TableBodyCell>{result.solicitor.name}</TableBodyCell>
              {listType !== "delivered" ? (
                <TableBodyCell>
                  {result.recordDate
                    ? formatDate(new Date(result.recordDate))
                    : "-"}
                </TableBodyCell>
              ) : (
                <TableBodyCell>
                  {formatDate(
                    new Date(result.noboEventStats.noboFileLastDelivered)
                  )}
                </TableBodyCell>
              )}
              {listType === "delivered" && (
                <TableBodyCell>{result.notes}</TableBodyCell>
              )}
            </TableBodyRow>
          ))}
        </tbody>
      </Table>
    </PageWrapper>
  );
}
