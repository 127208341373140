import useSWR from "swr";
import { nexus } from "..";
import { SecurityShort } from "../types";

const fetcher = (url: string) => {
  return nexus.get<SecurityShort>(url);
};

export function useSecurity(id: string) {
  const url = `/securities/${id}/`;
  const { data, error, mutate } = useSWR(url, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    security: data?.data,
  };
}
