import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikActions,
  FormikProps,
} from "formik";

import { CusipTypeahead } from "../../components/CusipTypeahead/CusipTypeahead";
import { Select } from "../../components/Select";
import { Label } from "../../components/Label";
import { Input } from "../../components/Input";
import { FormStatusBar } from "./FormStatusBar";
import {
  createElection,
  createVCA,
  createVCACheck,
  createAttachment,
  uploadAttachment,
} from "../../api/adapters/proxy";
import { FieldError } from "../../components/FieldError/FieldError";
import { FormValues, CorporateActionType } from "./types";
import { createValidationSchema } from "./validation-schema";
import { corporateActionTypeOptions, frequencyOptions } from "./select-options";
import { createFormPayload, getElectionChoices } from "./helpers";

import { formatErrors } from "./formatErrors";
import { DuplicateCheckDialog } from "./DuplicateCheckDialog";
import axios, { AxiosResponse } from "axios";
import { TextareaAutosize } from "./TextareaAutosize";
import { DatePicker } from "../../components/atoms/DatePicker";
import { VcaDocumentList } from "src/components/organisms/VcaDocumentList";
import {
  PreviewItem,
  PreviewPane,
} from "src/components/PreviewPane/PreviewPane";
import { DisplayType } from "../Proxy/types";
import { Button } from "src/components/atoms/Button";
import { PageHeader } from "src/components/molecules/PageHeader";
import { ReactComponent as VCAIcon } from "src/assets/icons/vca.svg";
import { ReactComponent as GrayVCAIcon } from "src/assets/icons/gray-vca.svg";
import { Card } from "src/components/atoms/Card";
import { WarrenGDocument } from "src/types/warreng";
import { FormSection } from "src/components/molecules/FormSection";

export type CheckResponse = {
  duplicateExists: boolean;
  duplicateCorporateActionIds: Array<number>;
};

function Create(): JSX.Element {
  const navigate = useNavigate();

  const [documents, setDocuments] = useState<WarrenGDocument[]>([]);
  const [previewItem, setPreviewItem] = useState<PreviewItem | null>(null);
  const [dialog, setDialog] = useState<
    | {
        resolve: (value: unknown) => void;
        reject: (value: unknown) => void;
        context: CheckResponse;
      }
    | undefined
  >();

  const handleFormSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikActions<FormValues>
  ) => {
    if (values.issuer && values.securities.length && values.announcementDate) {
      try {
        setSubmitting(true);
        // check for duplicate corporate actions
        const checkPayload = {
          cusips: values.securities.map((security) => security.cusip),
          announcementDate: format(values.announcementDate, "yyyy-MM-dd"),
        };
        const checkResponse: AxiosResponse<CheckResponse> =
          await createVCACheck(checkPayload);
        const userAction = new Promise((resolve, reject) =>
          setDialog({ resolve, reject, context: checkResponse.data })
        );
        try {
          await userAction;
        } catch (_) {
          setSubmitting(false);
          setDialog(undefined);
          return;
        }
        setDialog(undefined);

        const payload = createFormPayload(values);
        const response = await createVCA(payload);
        const newCorporateActionId = response.data.id;

        const electionPayload = {
          title: values.electionTitle,
          detail: values.electionDetails,
          choices: getElectionChoices(values.type as CorporateActionType),
          corporateActionId: newCorporateActionId,
        };

        await createElection(electionPayload);

        documents.forEach(async (document) => {
          const formData = new FormData();
          if (document.file) {
            formData.append("file", document.file, document.name);
          }
          const attachementResponse = await createAttachment({
            corporateAction: newCorporateActionId,
            name: document.name,
            type: document.type,
            effectiveDate: format(new Date(), "yyyy-MM-dd"), // hardcode until we have an entry field
          });
          await uploadAttachment(attachementResponse.data.id, formData);
        });
        setSubmitting(false);
        navigate(`/corporate-actions/${response.data.id}`);
      } catch (error) {
        setSubmitting(false);
        if (axios.isAxiosError(error) && error?.response?.data) {
          toast.error(formatErrors(error.response.data), { autoClose: false });
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    }
  };

  const handlePreviewChange = (item: PreviewItem) => {
    setPreviewItem(item);
  };

  const handleDocumentsChange = (docs: WarrenGDocument[]) => {
    setDocuments(docs);
  };

  return (
    <Formik
      initialValues={{
        securities: [],
        issuer: null,
        announcementDate: null,
        recordDate: null,
        electionCutoffDate: null,
        dtcExpirationDate: null,
        type: "" as CorporateActionType,
        operationsStatus: "active",
        electionDetails: "",
        electionTitle: "",
        bidIncrement: "",
        consentPayment: "",
        contraCusip: "",
        dripReinvestmentPrice: "",
        frequency: "",
        grossDividend: "",
        maximumBidPrice: "",
        maximumQualifyingQuantity: "",
        minimumBidPrice: "",
        minimumQualifyingQuantity: "",
        offererParty: "",
        outTurn: "",
        price: "",
        ratio: "",
        tradeSecurity: "",
      }}
      onSubmit={handleFormSubmit}
      enableReinitialize={true}
      validationSchema={createValidationSchema}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
      }: FormikProps<FormValues>) => (
        <Form>
          <PageHeader
            icon={values.issuer?.name ? <VCAIcon /> : <GrayVCAIcon />}
            title={values.issuer?.name || ""}
          />
          <FormStatusBar
            electionCutoff={null}
            status="new"
            sx={{
              py: 4,
              px: 8,
            }}
          >
            <Button
              color="primary"
              disabled={!isValid}
              size="small"
              type="submit"
              variant="outlined"
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </FormStatusBar>
          <div className="grid grid-cols-2 gap-8 py-4 px-8">
            <div>
              <FormSection
                id="FilingDetailContainer"
                title="Filing Details"
                isCollapsible={false}
              >
                <Field name="securities">
                  {({ field, form }: FieldProps<FormValues>) => (
                    <InputGroup>
                      <Label required={true}>CUSIP(s)</Label>
                      <CusipTypeahead
                        selectedItems={field.value}
                        onSelectItem={(security) => {
                          form.setFieldValue("securities", [
                            ...field.value,
                            security,
                          ]);
                          form.setFieldValue("issuer", {
                            id: security.issuer.id,
                            name: security.issuer.companyName,
                          });
                        }}
                        onClear={() => {
                          form.setFieldValue("securities", []);
                          form.setFieldValue("issuer", { id: 0, name: "" });
                        }}
                        onRemoveItem={(index) => {
                          if (field.value.length === 1) {
                            form.setFieldValue("issuer", {
                              id: 0,
                              name: "",
                            });
                          }

                          form.setFieldValue(
                            "securities",
                            field.value.filter(
                              (_: unknown, i: number) => i !== index
                            )
                          );
                        }}
                        issuerId={values.issuer ? values.issuer.id : undefined}
                        disabled={values.operationsStatus === "closed"}
                      />
                    </InputGroup>
                  )}
                </Field>
                <Field name="issuer">
                  {({ field }: FieldProps<FormValues>) => (
                    <InputGroup>
                      <Label required={true}>Issuer</Label>
                      <Input
                        id="IssuerValue"
                        disabled={true}
                        type="text"
                        value={field.value ? field.value.name : ""}
                      />
                    </InputGroup>
                  )}
                </Field>
                <div className="grid grid-cols-2 gap-4">
                  <Field name="announcementDate">
                    {({ form, field }: FieldProps<FormValues>) => (
                      <InputGroup>
                        <Label required={true}>Announcement Date</Label>
                        <DatePicker
                          fullWidth={true}
                          name="announcementDate"
                          onChange={(date) => {
                            form.setFieldValue("announcementDate", date);
                          }}
                          selected={field.value}
                          dateFormat="MM/dd/yyyy"
                          placeholder="MM/DD/YYYY"
                        />
                      </InputGroup>
                    )}
                  </Field>

                  <Field name="recordDate">
                    {({ field, form }: FieldProps<FormValues>) => (
                      <InputGroup>
                        <Label showOptional={true}>Record Date</Label>
                        <DatePicker
                          fullWidth={true}
                          name="recordDate"
                          onChange={(date) => {
                            form.setFieldValue("recordDate", date);
                          }}
                          selected={field.value}
                          placeholder="MM/DD/YYYY"
                        />
                      </InputGroup>
                    )}
                  </Field>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <Field name="electionCutoffDate">
                    {({ field, form }: FieldProps<FormValues>) => (
                      <InputGroup>
                        <Label required={true}>Election Cutoff Date</Label>
                        <DatePicker
                          fullWidth={true}
                          name="electionCutoffDate"
                          onChange={(date) => {
                            form.setFieldValue("electionCutoffDate", date);
                          }}
                          selected={field.value}
                          dateFormat="MM/dd/yyyy"
                          placeholder="MM/DD/YYYY"
                        />
                      </InputGroup>
                    )}
                  </Field>

                  <Field name="dtcExpirationDate">
                    {({ field, form }: FieldProps<FormValues>) => (
                      <InputGroup>
                        <Label required={true}>DTC Expiration Date</Label>
                        <DatePicker
                          fullWidth={true}
                          name="dtcExpirationDate"
                          onChange={(date) => {
                            form.setFieldValue("dtcExpirationDate", date);
                          }}
                          selected={field.value}
                          dateFormat="MM/dd/yyyy"
                          placeholder="MM/DD/YYYY"
                        />
                      </InputGroup>
                    )}
                  </Field>
                </div>

                <Field name="type">
                  {({ field, form }: FieldProps<FormValues>) => (
                    <InputGroup>
                      <Label required={true}>
                        Voluntary Corporate Action Type
                      </Label>
                      <Select
                        {...field}
                        onChange={(event) => {
                          form.setFieldValue("type", event.currentTarget.value);
                          form.setFieldValue(
                            "electionTitle",
                            event.currentTarget.options[
                              event.currentTarget.selectedIndex
                            ].text
                          );
                        }}
                        options={corporateActionTypeOptions}
                      />
                    </InputGroup>
                  )}
                </Field>

                {values.type ? (
                  <div
                    id="TypeValueContainer"
                    className="border-t border-light-gray pt-2 mt-4"
                  >
                    {values.type === "OfferToPurchaseThirdPartyOffer" ? (
                      <Field name="offererParty">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={true}>offerer Party</Label>
                            <Input type="text" {...field} />
                            {errors.offererParty && touched.offererParty ? (
                              <FieldError>{errors.offererParty}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {(values.type === "OfferToPurchaseBuyBack" ||
                      values.type === "OfferToPurchaseThirdPartyOffer") &&
                    !values.price ? (
                      <Field name="bidIncrement">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Bid Increment</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {(values.type === "OfferToPurchaseBuyBack" ||
                      values.type === "OfferToPurchaseThirdPartyOffer") &&
                    !values.price ? (
                      <Field name="minimumBidPrice">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Minimum Bid Price</Label>
                            <Input type="text" {...field} />
                            {errors.minimumBidPrice &&
                            touched.minimumBidPrice ? (
                              <FieldError>{errors.minimumBidPrice}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {(values.type === "OfferToPurchaseBuyBack" ||
                      values.type === "OfferToPurchaseThirdPartyOffer") &&
                    !values.price ? (
                      <Field name="maximumBidPrice">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Maximum Bid Price</Label>
                            <Input type="text" {...field} />
                            {errors.maximumBidPrice &&
                            touched.maximumBidPrice ? (
                              <FieldError>{errors.maximumBidPrice}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === "ExchangeOffer" ? (
                      <Field name="contraCusip">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Contra Cusip</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === "ExchangeOffer" ? (
                      <Field name="ratio">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Ratio</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === "MergerConsiderationElection" ||
                    values.type === "ConsentSolicitation" ? (
                      <Field name="consentPayment">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Consent Payment</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === "RightsIssue" ? (
                      <Field name="tradeSecurity">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Trade Security</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === "RightsIssue" ? (
                      <Field name="outTurn">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>Out Turn</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === "OddLotOffer" ? (
                      <Field name="minimumQualifyingQuantity">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={true}>
                              Minimum Qualifying Quantity
                            </Label>
                            <Input type="text" {...field} />
                            {errors.minimumQualifyingQuantity &&
                            touched.minimumQualifyingQuantity ? (
                              <FieldError>
                                {errors.minimumQualifyingQuantity}
                              </FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === "OddLotOffer" ? (
                      <Field name="maximumQualifyingQuantity">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={true}>
                              Maximum Qualifying Quantity
                            </Label>
                            <Input type="text" {...field} />
                            {errors.maximumQualifyingQuantity &&
                            touched.maximumQualifyingQuantity ? (
                              <FieldError>
                                {errors.maximumQualifyingQuantity}
                              </FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === "OddLotOffer" ||
                    values.type === "OfferToPurchaseBuyBack" ||
                    values.type === "OfferToPurchaseThirdPartyOffer" ? (
                      <Field name="price">
                        {({ field, form }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={values.type === "OddLotOffer"}>
                              Price
                            </Label>
                            <Input
                              type="text"
                              {...field}
                              onChange={(event) => {
                                const value = event.target.value;
                                if (
                                  values.type === "OfferToPurchaseBuyBack" ||
                                  values.type ===
                                    "OfferToPurchaseThirdPartyOffer"
                                ) {
                                  form.setFieldValue("bidIncrement", "");
                                  form.setFieldValue("minimumBidPrice", "");
                                  form.setFieldValue("maximumBidPrice", "");
                                }

                                form.setFieldValue("price", value);
                              }}
                            />
                            {errors.price && touched.price ? (
                              <FieldError>{errors.price}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === "DividendOption" ? (
                      <Field name="frequency">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={true}>Frequency</Label>
                            <Select options={frequencyOptions} {...field} />
                            {errors.frequency && touched.frequency ? (
                              <FieldError>{errors.frequency}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === "DividendOption" ? (
                      <Field name="grossDividend">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label required={true}>Gross Dividend</Label>
                            <Input type="text" {...field} />
                            {errors.grossDividend && touched.grossDividend ? (
                              <FieldError>{errors.grossDividend}</FieldError>
                            ) : null}
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    {values.type === "DividendReinvestmentPlan" ? (
                      <Field name="dripReinvestmentPrice">
                        {({ field }: FieldProps<FormValues>) => (
                          <InputGroup>
                            <Label>DRIP Reinvestment Price</Label>
                            <Input type="text" {...field} />
                          </InputGroup>
                        )}
                      </Field>
                    ) : null}

                    <Field name="electionTitle">
                      {({ field }: FieldProps<FormValues>) => (
                        <InputGroup>
                          <Label required={true}>Election Title</Label>
                          <Input type="text" {...field} />
                          {errors.electionTitle && touched.electionTitle ? (
                            <FieldError>{errors.electionTitle}</FieldError>
                          ) : null}
                        </InputGroup>
                      )}
                    </Field>

                    <Field name="electionDetails">
                      {({ field }: FieldProps<FormValues>) => (
                        <InputGroup>
                          <Label required={true}>Election Details</Label>
                          <TextareaAutosize minRows={3} {...field} />
                          {errors.electionDetails && touched.electionDetails ? (
                            <FieldError>{errors.electionDetails}</FieldError>
                          ) : null}
                        </InputGroup>
                      )}
                    </Field>
                  </div>
                ) : null}
              </FormSection>
              <VcaDocumentList
                documents={documents}
                onDocumentsChange={handleDocumentsChange}
                onPreviewClick={handlePreviewChange}
              />
            </div>
            <Card
              id="documentsField"
              sx={{
                height: "calc(100vh - 16.5rem)",
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                overflowX: "hidden",
                overflowY: "auto",
                p: 4,
              }}
            >
              <PreviewPane
                currentPreview={previewItem}
                options={documents.map((doc) => {
                  return {
                    id: doc.name,
                    name: doc.name,
                    preview: doc.url,
                    type: DisplayType.Document,
                  };
                })}
              />
            </Card>
          </div>
          {dialog && <DuplicateCheckDialog {...dialog} />}
        </Form>
      )}
    </Formik>
  );
}

function InputGroup({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-col mt-2">{children}</div>;
}

export { Create };
