import { useState } from "react";

import { ProspectusPackage } from "../../api/adapters/nexus/types";
import { Button } from "../atoms/Button";
import { Box } from "../atoms/Box";
import { UploadProspectusDocumentDialog } from "../UploadProspectusDocumentDialog";
import { UpdateProspectusDocumentDateDialog } from "../UpdateProspectusDocumentDateDialog";
import { DismissProspectusPackageDialog } from "../DismissProspectusPackageDialog";
import { ApproveProspectusDialog } from "../ApproveProspectusDialog";

type ProspectusPackageActionsProps = {
  isTargetPackage: boolean;
  onProspectusPackageUpdate: () => void;
  prospectusPackage: ProspectusPackage;
};

export function ProspectusPackageActions({
  isTargetPackage = false,
  onProspectusPackageUpdate,
  prospectusPackage,
}: ProspectusPackageActionsProps): JSX.Element | null {
  const { status } = prospectusPackage;
  const [isDateDialogOpen, setIsDateDialogOpen] = useState<boolean>(false);
  const [isUploadDocumentDialogOpen, setIsUploadDocumentDialog] =
    useState<boolean>(false);
  const [isDismissDialogOpen, setIsDismissDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);

  return (
    <Box pb={4}>
      <Box alignItems="start" display="flex" flexDirection="column">
        <Button onClick={() => setIsUploadDocumentDialog(true)}>
          Upload additional documents
        </Button>
        <Button onClick={() => setIsDateDialogOpen(true)}>
          Update expected end date
        </Button>
        {status === "approved" && isTargetPackage && (
          <Button onClick={() => setIsDismissDialogOpen(true)}>Dismiss</Button>
        )}
        {status === "pending" && (
          <Button onClick={() => setIsApproveDialogOpen(true)}>Approve</Button>
        )}
      </Box>
      <UploadProspectusDocumentDialog
        prospectusPackage={prospectusPackage}
        isOpen={isUploadDocumentDialogOpen}
        onClose={() => setIsUploadDocumentDialog(false)}
        onUpdate={onProspectusPackageUpdate}
      />
      <UpdateProspectusDocumentDateDialog
        prospectusPackage={prospectusPackage}
        isOpen={isDateDialogOpen}
        onClose={() => setIsDateDialogOpen(false)}
        onUpdate={onProspectusPackageUpdate}
      />
      <DismissProspectusPackageDialog
        isOpen={isDismissDialogOpen}
        onClose={() => setIsDismissDialogOpen(false)}
        onUpdate={onProspectusPackageUpdate}
        prospectusPackage={prospectusPackage}
      />
      <ApproveProspectusDialog
        isOpen={isApproveDialogOpen}
        onClose={() => setIsApproveDialogOpen(false)}
        onUpdate={onProspectusPackageUpdate}
        prospectusPackage={prospectusPackage}
      />
    </Box>
  );
}
