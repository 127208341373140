import groupBy from "lodash.groupby";
import axios from "axios";

import { Security } from "../types";
import { createIssuer, createSecurity } from "../../../api/adapters/proxy";
import { Issuer } from "../../../types/common";

/**
 * @typedef {Object} IssuerResponse
 * @property {number} id - Issuer id
 * @property {string} error - ErrorMessage
 */

/**
 * retrieves issuer id
 * @param issuer - issuer to add to filing
 * @returns IssuerResponse
 */
export const getIssuerId = async (
  issuer: Issuer
): Promise<
  | {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      id: any;
      error: null;
    }
  | {
      error: string;
      id: number;
    }
> => {
  // If issuer exists return it
  if (Number.isFinite(issuer.id)) {
    return {
      error: null,
      id: issuer.id,
    };
  }
  try {
    // If issue does not exist, add it
    const response = await createIssuer({
      // eslint-disable-next-line no-control-regex
      companyName: issuer.companyName.replace(/[^\x00-\xFF]/g, ""),
    });
    return {
      id: response.data.id,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error) && error?.response) {
      console.log("Issuer API Failed", error.response);
    }
    return {
      error: "Failed to create Issuer",
      id: NaN,
    };
  }
};

/**
 * @typedef {Object} SecurityResponse
 * @property {number} ids - number[]
 * @property {string} error - ErrorMessage
 */
/**
 * Convert security array to id array
 * @param arr of securities
 * @returns SecurityResponse
 */
const extractSecurityIds = (arr: Security[] | undefined) =>
  arr ? arr.map((itm) => itm.id) : [];

export const getSecurityIds = async (
  issuerId: number,
  securities: Security[]
): Promise<
  | {
      ids: number[];
      error: null;
    }
  | {
      ids: never[];
      error: string;
    }
  | undefined
> => {
  // seperate out securities into existing and no existing
  const groupedSecurities = groupBy(securities, (itm) =>
    Number.isFinite(itm.id)
  );

  const existingSecurities = extractSecurityIds(groupedSecurities.true);

  const newSecurities = groupedSecurities.false || [];
  if (!newSecurities.length) {
    // no new cusips just return exisiting
    return {
      ids: extractSecurityIds(securities),
      error: null,
    };
  }
  // create requests to add all new securities
  const cusipRequests = newSecurities.map((security: Security) => {
    return createSecurity({
      issuer: issuerId,
      cusip: security.cusip,
    });
  });
  try {
    const cusipResponses = await Promise.all(cusipRequests);
    const newCusipIds = cusipResponses.map((res) => res.data.id);
    // combine new and existing cusips
    const allIds = existingSecurities.concat(newCusipIds);
    return {
      ids: allIds,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error) && error?.response) {
      console.log("securities api failed", error.response);
      return {
        ids: [],
        error: "Failed to add cusip to issuer",
      };
    }
  }
};
