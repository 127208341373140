import { useNavigate } from "react-router-dom";

import { formatNumber } from "../../utils/format-number";
import { formatDate } from "../../utils/format-date";
import { PageWrapper } from "../../components/PageWrapper";
import { TableHeader } from "../../components/TableHeader";
import { TableBodyRow } from "../../components/TableBodyRow";
import { TableBodyCell } from "../../components/TableBodyCell";
import { TableHeadCell } from "../../components/TableHeadCell";
import { Table } from "../../components/Table";
import { AlertIcon } from "../../components/AlertIcon";
import { LoadingState } from "../../components/LoadingState";
import { useTasks } from "./useTasks";
import { SecurityProspectusPackage } from "src/api/adapters/nexus/types";
import { Box } from "src/components/atoms/Box";
import { CusipTagList } from "src/components/CusipTagList";
import { useCallback } from "react";

export const TradeAlerts = (): JSX.Element | null => {
  const navigate = useNavigate();
  const {
    tradeAlertsProspectuses: alerts,
    tradeAlertQuery: query,
    setTradeAlertQuery: setQuery,
  } = useTasks();

  const handleOrderingClick = useCallback(
    (ordering: string) => {
      setQuery({ ...query, ordering });
    },
    [query]
  );

  const { isLoading, isError, count, prospectuses } = alerts;
  const { ordering, pageSize, page } = query;

  if (isLoading || !prospectuses) {
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );
  }

  if (isError) {
    return <PageWrapper>Error...</PageWrapper>;
  }

  const handleRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    prospectus: SecurityProspectusPackage
  ) => {
    const {
      currentProxyEvents,
      security: { id },
      prospectusPackage,
    } = prospectus;

    let url = `/prospectuses/security/${id}?targetPackage=${prospectusPackage.id}`;
    if (currentProxyEvents.length > 0) {
      url = `/proxy/${String(currentProxyEvents[0])}`;
    }

    if (e.metaKey) {
      window.open(url, "_blank");
      return;
    }
    navigate(url);
  };

  return (
    <PageWrapper>
      <TableHeader
        count={count || 0}
        page={page}
        pageSize={pageSize}
        itemType="fund"
        onPageChange={(page) => setQuery({ ...query, page })}
        onPageSizeChange={(pageSize) => setQuery({ ...query, pageSize })}
      />
      <Table>
        <thead className="bg-white">
          <tr>
            <TableHeadCell name="Issuer" />
            <TableHeadCell name="CUSIP" />
            <TableHeadCell
              name="Trades"
              align="right"
              orderingKey="recent_trades"
              currentOrdering={ordering}
              onOrderingClick={handleOrderingClick}
            />
            <TableHeadCell
              name="Weekly Trades"
              align="right"
              orderingKey="weekly_trades"
              currentOrdering={ordering}
              onOrderingClick={handleOrderingClick}
            />
            <TableHeadCell
              name="Effective"
              orderingKey="effective_start_date"
              currentOrdering={ordering}
              onOrderingClick={handleOrderingClick}
            />
            <TableHeadCell
              name="Expect"
              orderingKey="expected_end_date"
              currentOrdering={ordering}
              onOrderingClick={handleOrderingClick}
            />
            <TableHeadCell name="Filing ID" />
          </tr>
        </thead>
        <tbody>
          {prospectuses.map((prospectus: SecurityProspectusPackage) => {
            const {
              currentProxyEvents,
              tradeStats: { recentTrades, weeklyTrades },
              prospectusPackage,
              security,
            } = prospectus;
            return (
              <TableBodyRow
                key={security.id}
                onClick={(e) => handleRowClick(e, prospectus)}
              >
                <TableBodyCell>
                  <Box component="span" sx={{ color: "black" }}>
                    {security.issuerName}
                  </Box>
                </TableBodyCell>
                <TableBodyCell>
                  <CusipTagList cusips={[String(security.cusip)]} />
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-primary-text font-medium text-right">
                    {formatNumber(recentTrades)}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-primary-text font-medium text-right">
                    {formatNumber(weeklyTrades)}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-primary-text">
                    {prospectusPackage?.effectiveStartDate
                      ? formatDate(
                          new Date(
                            prospectusPackage.effectiveStartDate + "T00:00:00"
                          )
                        )
                      : "--"}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-red">
                    {prospectusPackage?.expectedEndDate ? (
                      formatDate(
                        new Date(
                          prospectusPackage.expectedEndDate + "T00:00:00"
                        )
                      )
                    ) : (
                      <div className="flex items-center justify-end">
                        -- <AlertIcon className="ml-2" />
                      </div>
                    )}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-primary-text">
                    {currentProxyEvents.length > 0
                      ? String(currentProxyEvents[0])
                      : "--"}
                  </div>
                </TableBodyCell>
              </TableBodyRow>
            );
          })}
        </tbody>
      </Table>
    </PageWrapper>
  );
};
