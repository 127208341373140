import { COLORS } from "@asayinc/component-library";
import { LinearProgress } from "@mui/material";
import numeral from "numeral";

import { Box } from "src/components/atoms/Box";

interface Props {
  totalSelected: number;
  finishedSelected: number;
  currentPosition: number;
}

function BatchBar({
  totalSelected,
  finishedSelected,
  currentPosition,
}: Props): JSX.Element {
  const progress = Number(
    numeral((finishedSelected / totalSelected) * 100).format("0(.00)")
  );
  const barTextStyle = {
    fontSize: "10px",
    fontWeight: "500",
    letterSpacing: "1.5px",
    color: "#ffffff",
    textTransform: "uppercase",
  };

  return (
    <Box
      sx={{
        background: COLORS.SAY_COBALT,
        height: "24px",
        padding: "0 24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box component="span" sx={barTextStyle}>
        {`${currentPosition} of ${totalSelected} selected`}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box component="span" sx={barTextStyle}>
          Finished
        </Box>{" "}
        <LinearProgress
          sx={{
            background: "#00000033",
            width: "94px",
            margin: "0 8px",
            ".MuiLinearProgress-barColorPrimary": {
              background: "#fff",
            },
          }}
          variant="determinate"
          value={progress}
        />{" "}
        <Box component="span" sx={barTextStyle}>
          {progress}%
        </Box>
      </Box>
    </Box>
  );
}

export { BatchBar };
