import { createTestFilingEmail } from "../../../api/adapters/proxy";

function useSendTestEmail(): {
  sendTestEmail: (
    filingId: string,
    slug: string,
    payload: {
      emails: string[];
    }
  ) => Promise<void>;
} {
  async function sendTestEmail(
    filingId: string,
    slug: string,
    payload: { emails: string[] }
  ) {
    await createTestFilingEmail(filingId, slug, payload);
  }

  return { sendTestEmail };
}

export { useSendTestEmail };
