import React from "react";
import { TableTitle } from "../TableTitle";
import { PaginationPageSize } from "../PaginationPageSize";
import { PaginationButtons } from "../PaginationButtons";
import { pluralize } from "../../utils/pluralize";
import { formatNumber } from "../../utils/format-number";
import { Box } from "../atoms/Box";

type TableHeaderProps = {
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  count: number;
  itemType: "fund" | "security" | "issuer" | "filing" | "nobo";
};

const TableHeader = ({
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  count,
  itemType,
}: TableHeaderProps): JSX.Element => {
  const totalPages = Math.ceil(count / pageSize);
  const pluralItemType = React.useMemo(() => {
    switch (itemType) {
      case "security":
        return "securities";
      case "fund":
      case "issuer":
      case "filing":
      case "nobo":
      default:
        return `${itemType}s`;
    }
  }, [itemType]);
  const title = `${formatNumber(count)} ${pluralize({
    count,
    plural: pluralItemType,
    singular: itemType,
  })}`;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        borderBottom: "1px solid #e5e5e5",
        py: 4,
        px: 8,
        mx: -8,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TableTitle title={title} />
        {count > pageSize && (
          <PaginationPageSize
            onChange={onPageSizeChange}
            count={count}
            pageSize={pageSize}
          />
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
        <PaginationButtons
          currentPage={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </Box>
    </Box>
  );
};

export { TableHeader };
