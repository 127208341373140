import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import App from "./App";
import Raven from "raven-js";
import MaintenanceMode from "./components/MaintenanceMode/MaintenanceMode";

Raven.config("https://e66d881abf434aae94cb217119b389e4@sentry.io/303033", {
  environment: process.env.NODE_ENV,
}).install();

if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
  ReactDOM.render(<MaintenanceMode />, document.getElementById("root"));
} else {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
}
