import React from "react";
import { useMatch } from "react-router-dom";
import { DirtyPromptLink } from "../../features/Proxy/proxy-warning-context";
import { COLORS } from "@asayinc/component-library";

type NavItemProps = {
  children: React.ReactNode;
  to: string;
};

const NavItem = ({ children, to }: NavItemProps): JSX.Element => {
  const activeStyle = {
    display: "flex",
    height: "48px",
    alignItems: "center",
    paddingLeft: "24px",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: COLORS.ICE_BLUE,
    "&:hover": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    textDecoration: "none",
  };

  const inactiveStyle = {
    display: "flex",
    height: "48px",
    alignItems: "center",
    paddingLeft: "24px",
    color: "rgba(0, 0, 0, 0.6)",
    filter: "grayscale(100)",
    "&:hover": {
      backgroundColor: COLORS.ICE_BLUE,
      filter: "none",
      color: "rgba(0, 0, 0, 0.87)",
    },
    textDecoration: "none",
  };

  const match = useMatch(to + "/*");
  return (
    <DirtyPromptLink
      className={match ? "active" : ""}
      style={match ? activeStyle : inactiveStyle}
      to={to}
    >
      {children}
    </DirtyPromptLink>
  );
};

export { NavItem };
