import useSWR from "swr";
import { nexus } from "..";
import { PaginatedResponse } from "../../../../types/PaginatedResponse";
import { ProspectusPackage } from "../types";

export type ProspectusPackagesBySecurity = {
  [securityId: string]: ProspectusPackage[];
};

const fetcher = (url: string) => {
  return nexus.get<PaginatedResponse<ProspectusPackage>>(url);
};

export function useProspectusPackages(securityIds: string[]) {
  const url = `/prospectus-packages/?security_ids=${securityIds.join(",")}`;
  const { data, error, mutate } = useSWR(
    securityIds.length > 0 ? url : null,
    fetcher
  );

  const prospectusPackagesBySecurity: ProspectusPackagesBySecurity = {};
  if (data?.data.results) {
    data?.data.results.forEach((p) => {
      const {
        security: { id },
      } = p;
      if (prospectusPackagesBySecurity[id] === undefined) {
        prospectusPackagesBySecurity[id] = [p];
      } else {
        prospectusPackagesBySecurity[id].push(p);
      }
    });
  }

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    prospectusPackages: data?.data.results,
    prospectusPackagesBySecurity: prospectusPackagesBySecurity,
  };
}
