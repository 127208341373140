import { Link } from "@mui/material";
import { Box } from "src/components/atoms/Box";
import { FormSection } from "src/components/molecules/FormSection";
import { LabelField } from "src/components/molecules/LabelField";
import { AdditionalMailing } from "src/types/additionalMailing";
import { formatNumber } from "src/utils/format-number";
import {
  ADDITIONAL_MAILING_AUDIENCE_TO_LABEL,
  ADDITIONAL_MAILING_TYPE_TO_LABEL,
} from "../utils";

interface IAdditionalMailingKeyDetailsSectionProps {
  additionalMailing: AdditionalMailing;
  isPreviewEnabled: boolean;
  onEditClick: () => void;
  onPreviewClick: () => void;
}

export function AdditionalMailingKeyDetailsSection({
  additionalMailing,
  isPreviewEnabled,
  onEditClick,
  onPreviewClick,
}: IAdditionalMailingKeyDetailsSectionProps) {
  return (
    <FormSection
      data-testid="additional-mailing-key-details"
      isCollapsible={false}
      onEditClick={
        additionalMailing.operationsStatus === "approved"
          ? undefined // Have this here b/c we need oneEditClick to be undefined to hide the edit button
          : onEditClick
      }
      title="Key Details"
    >
      <Box
        data-testid="key_details"
        sx={{
          display: "flex",
          mt: 4,
          p: 4,
          justifyContent: "space-between",
          border: "1px solid #e5e5e5",
          backgroundColor: "#f8f8f8",
        }}
      >
        <LabelField label="Type">
          {ADDITIONAL_MAILING_TYPE_TO_LABEL[additionalMailing.type]}
        </LabelField>
        <LabelField label="Date Received">
          {additionalMailing.dateReceived}
        </LabelField>
        <LabelField label="Audience">
          {ADDITIONAL_MAILING_AUDIENCE_TO_LABEL[additionalMailing.audience]}
        </LabelField>
        <LabelField label="Minimum Shares">
          {additionalMailing.minimumShares
            ? formatNumber(additionalMailing.minimumShares)
            : "0"}
        </LabelField>
        <LabelField label="Frontlink">
          <Link
            sx={{
              color: "#1853b4",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            href={additionalMailing.frontLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Link
          </Link>
        </LabelField>
        <LabelField label="Preview Email">
          <Box
            onClick={onPreviewClick}
            sx={{
              color: isPreviewEnabled ? "#1853b4" : "gray",
              cursor: isPreviewEnabled ? "pointer" : "auto",
              "&:hover": {
                textDecoration: isPreviewEnabled ? "underline" : "none",
              },
            }}
          >
            {isPreviewEnabled ? "Preview" : "Not Available"}
          </Box>
        </LabelField>
      </Box>
    </FormSection>
  );
}
