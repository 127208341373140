interface ICSVViewProps {
  csv: string;
}

function CSVView({ csv }: ICSVViewProps): JSX.Element {
  return (
    <div
      style={{ height: "50vh" }}
      className="flex w-full flex-col font-medium uppercase p-4"
    >
      {csv.split("\n").map((row, index) => (
        <p key={index}>{row}</p>
      ))}
    </div>
  );
}

export { CSVView };
