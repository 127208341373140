import { Dialog } from "../atoms/dialog/Dialog";

interface IDeleteDialogProps {
  deleteType: "filing" | "prospectus" | "document";
  isOpen: boolean;
  isPrimaryLoading?: boolean;
  onConfirmDelete: () => void;
  onRequestClose: () => void;
}

export function DeleteConfirmationDialog({
  deleteType,
  isOpen,
  isPrimaryLoading,
  onConfirmDelete,
  onRequestClose,
}: IDeleteDialogProps) {
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onRequestClose}
      isPrimaryLoading={isPrimaryLoading}
      primaryButtonColor="error"
      primaryButtonText="Delete"
      secondaryButtonText="Cancel"
      onSecondaryClick={onRequestClose}
      onPrimaryClick={onConfirmDelete}
      title="Delete"
    >
      {`Are you sure you want to delete this ${deleteType}?`}
    </Dialog>
  );
}
