import { useEffect, useState } from "react";
import { COLORS } from "@asayinc/component-library";
import { MenuItem, TextField } from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";

import { BallotView } from "./BallotView";
import { CSVView } from "./CSVView";
import { DocumentView } from "./DocumentView";
import { EmailView } from "./EmailView";
import { Box } from "../atoms/Box";
import { DisplayType } from "../../features/Proxy/types";

export type PreviewItem = {
  id: string;
  name: string;
  // TODO(David): Would really want to make this type string, but
  // Reference type seems to be too flexible. Need to sync up w/ backend for this
  // or transform the data on the front-end.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preview: any;
  type: DisplayType;
};

interface IPreviewPaneProps {
  currentPreview: PreviewItem | null;
  options: PreviewItem[];
}

export function PreviewPane({ currentPreview, options }: IPreviewPaneProps) {
  const [currentItem, setCurrentItem] = useState<PreviewItem | null>(
    currentPreview
  );

  useEffect(() => {
    setCurrentItem(currentPreview);
  }, [currentPreview]);

  useEffect(() => {
    if (currentItem) {
      const exists = options.some((option) => option.id === currentItem.id);
      if (!exists) {
        setCurrentItem(null);
      }
    }
    options;
  }, [options]);

  const renderPane = () => {
    let previewPane = (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <VisibilityOutlined fontSize="large" />
        <p className="pt-1">PREVIEW</p>
      </Box>
    );

    if (currentItem) {
      const { id, preview, type } = currentItem;
      if (type === DisplayType.Document) {
        previewPane = <DocumentView id={id} url={preview} />;
      } else if (type === DisplayType.Ballot) {
        previewPane = <BallotView filingId={preview} />;
      } else if (type === DisplayType.Email) {
        previewPane = <EmailView html={preview} />;
      } else if (type === DisplayType.CSV) {
        previewPane = <CSVView csv={preview} />;
      }
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          border: "1px solid #e5e5e5",
          color: COLORS.SATURATED_GREY,
          backgroundColor: "#fefefe",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {previewPane}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextField
          sx={{ my: 2 }}
          disabled={options.length === 0}
          InputLabelProps={{ shrink: true }}
          fullWidth
          label="Document"
          onChange={(e) => {
            const item = options.find((option) => option.id === e.target.value);
            setCurrentItem(item || null);
          }}
          size="small"
          select={true}
          value={currentItem?.id || ""}
          defaultValue={""}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
      {renderPane()}
    </Box>
  );
}
