import { SxProps } from "@mui/material";
import { ReactNode } from "react";
import { Box } from "../atoms/Box";

interface ISplitPaneProps {
  primaryPane: ReactNode;
  primaryPaneId?: string;
  primarySx?: SxProps;
  primaryWidth?: number;
  secondaryPane: ReactNode;
  secondaryPaneId?: string;
  secondarySx?: SxProps;
}

export function SplitPane({
  primaryPane,
  primaryPaneId,
  primarySx,
  primaryWidth = 50,
  secondaryPane,
  secondaryPaneId,
  secondarySx,
}: ISplitPaneProps) {
  return (
    <Box sx={{ display: "flex", flexGrow: 1, overflowY: "hidden" }}>
      <Box
        id={primaryPaneId}
        sx={{
          borderRight: "1px solid #e5e5e5",
          minWidth: `${primaryWidth}%`,
          width: `${primaryWidth}%`,
          ...primarySx,
        }}
      >
        {primaryPane}
      </Box>
      <Box
        id={secondaryPaneId}
        sx={{
          flexGrow: 1,
          ...secondarySx,
        }}
      >
        {secondaryPane}
      </Box>
    </Box>
  );
}
