import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useCorporateAction } from "../../api/adapters/proxy/hooks/useCorporateAction";
import { updateVCA, updateVCAStatus } from "../../api/adapters/proxy";
import { formatUpdateStatusError } from "../../utils/format-update-status-error";
import {
  Broker,
  useCorporateActionBrokers,
} from "src/api/adapters/proxy/hooks/useCorporateActionBrokers";
import { LoadingState } from "src/components/LoadingState";
import { Box } from "src/components/atoms/Box";
import { Button } from "src/components/atoms/Button";
import { Dialog } from "src/components/atoms/dialog/Dialog";
import { BrokersList } from "./BrokersList";
import { FormStatusBar } from "./FormStatusBar";
import { SayVcaEmailPreviewPane } from "src/components/EmailPreviewPane/SayVcaEmailPreviewPane";

type BrokerEmailParams = {
  id: string;
};

export function BrokerEmail(): JSX.Element | null {
  const { id } = useParams() as BrokerEmailParams;
  const {
    corporateAction,
    isLoading: isCorporateActionLoading,
    mutate,
  } = useCorporateAction(id);
  const { corporateActionBrokers, isLoading: isCorporateActionBrokersLoading } =
    useCorporateActionBrokers(id);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState<Broker | null>(null);
  const [selectedBrokerIds, setSelectedBrokerIds] = useState<number[]>([]);

  useEffect(() => {
    if (corporateAction) {
      setSelectedBrokerIds(corporateAction.brokers.map((broker) => broker.id));
    }
  }, [corporateAction]);

  if (
    isCorporateActionLoading ||
    isCorporateActionBrokersLoading ||
    !corporateAction ||
    !corporateActionBrokers
  ) {
    return <LoadingState />;
  }

  function handleAddBroker(brokerId: number) {
    if (corporateActionBrokers) {
      const foundBroker = corporateActionBrokers.find(
        (broker) => broker.id === brokerId
      );

      if (foundBroker?.childBrokers?.length) {
        const parentAndChildBrokerIds = [
          ...foundBroker.childBrokers.map((childBroker) => childBroker.id),
          brokerId,
        ];

        setSelectedBrokerIds([
          ...selectedBrokerIds,
          ...parentAndChildBrokerIds,
        ]);
      } else {
        setSelectedBrokerIds([...selectedBrokerIds, brokerId]);
      }
    }
  }

  function handleRemoveBroker(brokerId: number) {
    if (corporateActionBrokers) {
      const foundBroker = corporateActionBrokers.find(
        (broker) => broker.id === brokerId
      );

      if (foundBroker?.childBrokers?.length) {
        const parentAndChildBrokerIds = [
          ...foundBroker.childBrokers.map((childBroker) => childBroker.id),
          brokerId,
        ];

        const filteredSelectedBrokerIds = selectedBrokerIds.filter(
          (selectedBrokerId) =>
            !parentAndChildBrokerIds.includes(selectedBrokerId)
        );

        setSelectedBrokerIds(filteredSelectedBrokerIds);
      } else {
        setSelectedBrokerIds(
          selectedBrokerIds.filter(
            (selectedBrokerId) => selectedBrokerId !== brokerId
          )
        );
      }
    }
  }

  async function handleSaveBrokerIds() {
    try {
      const vca = await updateVCA(id, { brokerIds: selectedBrokerIds });
      mutate(vca);
    } catch (error) {
      toast.error("Unable to add brokers to this corporate action.");
    }
  }

  async function handleMarkAsApproved() {
    try {
      await updateVCAStatus(id, { operationsStatus: "approved" });
      mutate();
      setIsDialogOpen(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(formatUpdateStatusError(error));
      } else {
        toast.error("Unable to mark as approved.");
      }
    }
  }

  const handleApproveClick = () => {
    if (operationsStatus === "new" || operationsStatus === "active") {
      toast.error(
        "Status must be ready for review in order to mark it as approved."
      );
    } else if (operationsStatus === "approved") {
      toast.error("Status is already marked as approved.");
    } else {
      setIsDialogOpen(true);
    }
  };

  const { operationsStatus, electionCutoffDatetime } = corporateAction;
  return (
    <Box className="h-full">
      <FormStatusBar
        electionCutoff={electionCutoffDatetime}
        status={operationsStatus}
        sx={{
          py: 4,
          px: 8,
        }}
      >
        <Button
          color="primary"
          disabled={!selectedBrokerIds.length}
          onClick={handleApproveClick}
          size="small"
          variant="contained"
        >
          Approve
        </Button>
      </FormStatusBar>
      <Box className="grid grid-cols-2 gap-8 py-4 px-8">
        <Box>
          <Box
            component="h3"
            className="text-secondary-text font-medium text-xs uppercase"
          >
            Add Brokers
          </Box>
          <Box className="flex items-center justify-between bg-very-light-gray mt-4 p-4">
            <Box
              component="span"
              className="uppercase text-secondary-text font-medium text-xxs"
            >
              Broker
            </Box>
            <Box
              component="span"
              className="text-right uppercase text-secondary-text font-medium text-xxs"
            >
              Positions
            </Box>
          </Box>
          <BrokersList
            brokers={corporateActionBrokers ? corporateActionBrokers : []}
            onAddBroker={handleAddBroker}
            onRemoveBroker={handleRemoveBroker}
            onSelectBroker={(broker: Broker) => setSelectedBroker(broker)}
            selectedBrokerIds={selectedBrokerIds}
          />
          <Box className="grid grid-cols-2 gap-x-16 mt-2">
            <Button variant="outlined" onClick={() => setSelectedBrokerIds([])}>
              Un-select All
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!selectedBrokerIds.length}
              onClick={handleSaveBrokerIds}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Box>
          <Box
            component="h3"
            className="text-secondary-text font-medium text-xs uppercase mb-4"
          >
            Preview Broker Email
          </Box>
          <SayVcaEmailPreviewPane broker={selectedBroker} vcaId={id} />
        </Box>
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onPrimaryClick={handleMarkAsApproved}
        onSecondaryClick={() => setIsDialogOpen(false)}
        primaryButtonText="Yes"
        secondaryButtonText="No"
        title="Approve this email?"
      >
        Approved emails will be sent to shareholders.
      </Dialog>
    </Box>
  );
}
