import { Routes, Route } from "react-router-dom";
import React from "react";

import { ProspectusDetails } from "./ProspectusDetails";
import { SecurityProspectusDetails } from "./SecurityProspectusDetails";
import { Redirect } from "src/components/Redirect";
import { TaskListLayout } from "./TaskListLayout";
import { TradeAlerts } from "./TradeAlerts";
import { MissingExpired } from "./MissingExpired";
import { Upcoming } from "./Upcoming";

const Prospectuses: React.FC = () => {
  return (
    <Routes>
      <Route path="/:id" element={<ProspectusDetails />} />
      <Route path="/security/:id" element={<SecurityProspectusDetails />} />
      <Route path="tasks" element={<TaskListLayout />}>
        <Route path="trade-alerts" element={<TradeAlerts />} />
        <Route path="missing-expired" element={<MissingExpired />} />
        <Route path="upcoming" element={<Upcoming />} />
      </Route>
      <Route path="/" element={<Redirect to="tasks/trade-alerts" />} />
    </Routes>
  );
};

export { Prospectuses };
