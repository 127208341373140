import { AccordionDetails as MUIAccordionDetails } from "@mui/material";
import { SxProps } from "@mui/system";
import { IBaseProps } from "../../types/BaseProps";

interface IAccordionDetailsProps extends IBaseProps {
  sx?: SxProps;
}

export function AccordionDetails({ children, sx }: IAccordionDetailsProps) {
  return <MUIAccordionDetails sx={sx}>{children}</MUIAccordionDetails>;
}
