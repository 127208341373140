import { Accordion as MUIAccordion } from "@mui/material";
import { SxProps } from "@mui/system";
import { IBaseProps } from "../../types/BaseProps";

interface IAccordionProps extends IBaseProps {
  expanded?: boolean;
  sx?: SxProps;
}

export function Accordion({ children, expanded, sx }: IAccordionProps) {
  return (
    <MUIAccordion expanded={expanded} sx={sx}>
      {children}
    </MUIAccordion>
  );
}
