import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  InputBase,
  IconButton,
  InputAdornment,
  inputBaseClasses,
} from "@mui/material";

type SearchInput = {
  onChange: (value: string) => void;
  onClear: () => void;
  value: string;
  setIsSearchWide: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchInput = ({
  onChange,
  value,
  onClear,
  setIsSearchWide,
}: SearchInput): JSX.Element => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <InputBase
        placeholder="Search by issuer name or CUSIP"
        sx={{
          width: "50%",
          height: "44px",
          background: "#fff",
          padding: "0 16px",
          [`& .${inputBaseClasses["input"]}`]: {
            paddingLeft: "12px",
            "&:focus": {
              backgroundColor: "#FFFFFF",
            },
          },
        }}
        inputProps={{ "aria-label": "search" }}
        value={value}
        onChange={(event) => onChange(event.currentTarget.value)}
        onFocus={() => setIsSearchWide(true)}
        onBlur={() => setIsSearchWide(false)}
        startAdornment={
          <InputAdornment disablePointerEvents position="start">
            <IconButton edge="start" size="large">
              <SearchIcon
                sx={{
                  color: "rgba(0, 0, 0, 0.38)",
                }}
              />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {value && (
              <IconButton
                onClick={() => onClear()}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                size="large"
              >
                <ClearIcon
                  sx={{
                    color: "rgba(0, 0, 0, 0.38)",
                  }}
                />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
    </Box>
  );
};

export { SearchInput };
