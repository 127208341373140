import { SxProps } from "@mui/material";

import { OperationsStatus } from "./types";
import { Box } from "src/components/atoms/Box";
import { IBaseProps } from "src/types/BaseProps";
import { OperationsStatus as WarrenGOperationsStatus } from "src/types/vca";
import { VCAStatusBadge } from "src/components/molecules/VCAStatusBadge";

interface IFormStatusBarProps extends IBaseProps {
  electionCutoff: string | null;
  showBottomBorder?: boolean;
  sx?: SxProps;
  status: OperationsStatus | WarrenGOperationsStatus;
}

function FormStatusBar({
  children,
  electionCutoff,
  showBottomBorder = true,
  status,
  sx,
}: IFormStatusBarProps): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        borderBottom: showBottomBorder ? "1px solid #e5e5e5" : "",
        ...sx,
      }}
    >
      <Box className="font-medium text-sm text-primary-text flex items-center flex-grow">
        <Box component="span" className="mr-1">
          Status:
        </Box>
        <VCAStatusBadge status={status} electionCutoff={electionCutoff} />
      </Box>
      {children}
    </Box>
  );
}

export { FormStatusBar };
