import { createAdapter } from "../adapterFactory";

const sso = createAdapter({
  baseURL: `${process.env.REACT_APP_SSO_URL}/v1`,
  isPublic: true,
});

interface ILoginRequest {
  email: string;
  password: string;
}

function login(payload: ILoginRequest) {
  const url = "/auth/login/";
  return sso.post(url, payload);
}

export { login };
