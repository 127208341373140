import get from "lodash.get";
import { IconButton, TextField } from "@mui/material";
import { Controller, UseFormReturn, useFieldArray } from "react-hook-form";
import { MdRemove } from "react-icons/md";

import { Box } from "src/components/atoms/Box";
import { Button } from "src/components/atoms/Button";
import { CreateCorporateActionForm } from "src/types/vca";

interface IOddLotOfferConditionsProps {
  form: UseFormReturn<CreateCorporateActionForm>;
}

export const OddLotOfferConditions = ({
  form,
}: IOddLotOfferConditionsProps) => {
  const {
    control,
    formState: { errors },
  } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "offer.conditions.options",
    rules: {
      required: true,
      minLength: { value: 1, message: "Must have at least 1 option." },
    },
  });
  const hasOptionsError = get(errors, "offer.conditions.options.root", false);

  return (
    <>
      {fields.map((item, index) => {
        return (
          <Box
            key={item.id}
            sx={{
              border: "1px solid #e5e5e5",
              borderRadius: 2,
              px: 4,
              py: 2,
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", mb: 2 }}>
              <Box sx={{ flexGrow: 1 }}>Option {index + 1}</Box>
              <IconButton
                size="small"
                aria-label="Remove Option"
                onClick={() => remove(index)}
              >
                <MdRemove className="mr-2" size={16} />
              </IconButton>
            </Box>
            <Controller
              control={control}
              name={`offer.conditions.options.${index}.contraCusip`}
              rules={{
                required: true,
                maxLength: {
                  value: 9,
                  message: "Contra Cusip must be 9 digits",
                },
                minLength: {
                  value: 9,
                  message: "Contra Cusip must be 9 digits",
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ my: 2 }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    label="Contra Cusip*"
                    size="small"
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={`offer.conditions.options.${index}.price`}
              rules={{
                required: true,
                min: {
                  value: 0,
                  message: "Price cannot be a negative number",
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ my: 2 }}
                    InputProps={{ inputProps: { min: 0 } }}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    fullWidth={true}
                    label="Price*"
                    size="small"
                    type="number"
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={`offer.conditions.options.${index}.minimumShares`}
              rules={{
                required: true,
                min: {
                  value: 1,
                  message: "Minimum Shares must be greater than 0",
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ my: 2 }}
                    InputProps={{ inputProps: { min: 1 } }}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    fullWidth={true}
                    label="Minimum Shares*"
                    size="small"
                    type="number"
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={`offer.conditions.options.${index}.maximumShares`}
              rules={{
                required: true,
                min: {
                  value: 1,
                  message: "Maximum Shares must be greater than 0",
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ my: 2 }}
                    InputProps={{ inputProps: { min: 1 } }}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    fullWidth={true}
                    label="Maximum Shares*"
                    size="small"
                    type="number"
                  />
                );
              }}
            />
          </Box>
        );
      })}
      <Button
        sx={{ mb: hasOptionsError ? 0 : 4 }}
        onClick={() =>
          append({
            contraCusip: "",
            price: 0,
            minimumShares: 0,
            maximumShares: 0,
          })
        }
        variant="outlined"
        size="small"
        color={hasOptionsError ? "error" : "primary"}
      >
        + Add Lot
      </Button>
      {hasOptionsError && (
        <Box
          component="p"
          sx={{
            color: "#d32f2f",
            fontSize: "0.625rem",
            letterSpacing: "0.33px",
            mt: 1,
            mb: 2,
          }}
        >
          {"Must have at least 1 option."}
        </Box>
      )}
    </>
  );
};
