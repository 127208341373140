import { COLORS } from "@asayinc/component-library";
import classNames from "classnames";

import { Box } from "./Box";

interface IAvatarProps {
  name: string;
  size?: "small" | "large";
}

export const Avatar = ({ name, size = "large" }: IAvatarProps) => {
  const label = name.length > 2 ? name.slice(0, 2) : name;
  return (
    <Box
      className={classNames(
        "rounded font-medium flex items-center justify-center text-white",
        {
          "text-s": size === "large",
          "text-xxs": size !== "large",
        }
      )}
      sx={{
        backgroundColor: COLORS.SAY_COBALT,
        minHeight: size === "large" ? "1.875rem" : "24px",
        minWidth: size === "large" ? "1.875rem" : "24px",
        textTransform: "uppercase",
      }}
    >
      {label}
    </Box>
  );
};
