import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Box } from "src/components/atoms/Box";
import { Tabs } from "src/components/atoms/Tabs";
import { PageHeader } from "src/components/molecules/PageHeader";
import { formatNumber } from "src/utils/format-number";
import { getTabFromUrl } from "src/utils/get-tab-from-url";
import { Avatar } from "src/components/atoms/Avatar";
import { useTasks } from "./useTasks";

const TaskListLayout = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    tradeAlertsProspectuses,
    missingExpiredProspectuses,
    upcomingProspectuses,
  } = useTasks();

  function onTabChange(value: string) {
    navigate(`/prospectuses/tasks/${value}`);
  }

  return (
    <>
      <PageHeader icon={<Avatar name="ps" />} title="Prospectus" />
      <Box sx={{ pl: 6, borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          onChange={onTabChange}
          value={getTabFromUrl(location)}
          tabs={[
            {
              label: `Trade alerts (${formatNumber(
                tradeAlertsProspectuses.count || 0
              )})`,
              value: "trade-alerts",
              testId: "ProspectusTradeAlertsTab",
            },
            {
              label: `Missing expired (${formatNumber(
                missingExpiredProspectuses.count || 0
              )})`,
              value: "missing-expired",
              testId: "ProspectusMissingExpiredTab",
            },
            {
              label: `Upcoming (${formatNumber(
                upcomingProspectuses.count || 0
              )})`,
              value: "upcoming",
              testId: "ProspectusUpcomingTab",
            },
          ]}
        />
      </Box>
      <Outlet />
    </>
  );
};

export { TaskListLayout };
