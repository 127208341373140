import { FC } from "react";
import { CircularProgress } from "@asayinc/component-library";

type LoadingStateProps = {
  className?: string;
};

const LoadingState: FC<LoadingStateProps> = ({ className }) => (
  <div
    className={`w-full h-full flex flex-col items-center justify-center ${className}`}
  >
    <CircularProgress />
    <p className="text-black text-lg font-medium mt-8">We are on it.</p>
  </div>
);

export { LoadingState };
