import { SxProps } from "@mui/material";
import classNames from "classnames";
import { ReactNode, useState } from "react";
import { FiChevronDown, FiChevronUp, FiEdit } from "react-icons/fi";

import { Box } from "../atoms/Box";

interface IFormSectionProps {
  children: ReactNode;
  id?: string;
  isCollapsible?: boolean;
  onEditClick?: () => void; // Assuming we are going to start using the paradigm where we edit in a modal
  sx?: SxProps;
  title: string;
}

export function FormSection({
  children,
  id,
  isCollapsible = true,
  onEditClick,
  sx,
  title,
}: IFormSectionProps) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Box id={id} sx={{ mb: 4, ...sx }}>
      <Box
        sx={{
          display: "flex",
          fontWeight: 500,
          mb: 4,
          pb: 2,
          borderBottom: "1px solid #e5e5e5",
          alignItems: "center",
        }}
      >
        {title}
        <Box
          sx={{ display: "flex", justifyContent: "end", flexGrow: 1, pr: 2 }}
        >
          {onEditClick && (
            <FiEdit
              data-testid="onClick"
              className={classNames("cursor-pointer", {
                "mr-4": isCollapsible,
              })}
              onClick={onEditClick}
              size={16}
            />
          )}
          {isCollapsible &&
            (isOpen ? (
              <FiChevronDown
                className="cursor-pointer"
                onClick={() => setIsOpen(false)}
                size={16}
              />
            ) : (
              <FiChevronUp
                className="cursor-pointer"
                onClick={() => setIsOpen(true)}
                size={16}
              />
            ))}
        </Box>
      </Box>
      {isOpen && children}
    </Box>
  );
}
