import { AxiosRequestConfig } from "axios";
import useSWR from "swr";

import { nexus } from "..";
import { IQueryParams } from "../../types";
import { CorporateActionEvent } from "src/types/vca";

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) =>
  nexus.get<CorporateActionEvent>(url, { params });

export function useCorporateActionEvent(id: string) {
  const url = `/corporate-action-events/${id}/`;
  const { data, error, mutate } = useSWR(url, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    corporateActionEvent: data?.data,
    mutate,
  };
}
