import { FC } from "react";
import ReactTooltip from "react-tooltip";

type TooltipProps = {
  id: string;
};

const Tooltip: FC<TooltipProps> = ({ id }) => {
  return (
    <ReactTooltip
      textColor="#1a1a19"
      borderColor="#e5e5e5"
      backgroundColor="#fff"
      border={true}
      id={id}
      delayShow={1000}
      effect="solid"
    />
  );
};

export { Tooltip };
