import { ExpandMore } from "@mui/icons-material";
import { AccordionSummary } from "../atoms/AccordionSummary";
import { Box } from "../atoms/Box";
import { CusipTagList } from "../CusipTagList";

interface IProspectusAccodionSummaryProps {
  chipLabel: number;
  onClick: () => void;
  ticker: string | null;
  titleColor: string;
  title: string;
}

export function ProspectusAccordionSummary({
  chipLabel,
  onClick,
  ticker,
  title,
  titleColor,
}: IProspectusAccodionSummaryProps) {
  return (
    <AccordionSummary expandIcon={<ExpandMore />} onClick={onClick}>
      <CusipTagList cusips={[String(chipLabel)]} />
      <Box
        sx={{
          alignContent: "center",
          color: titleColor,
          fontWeight: 500,
          flex: 1,
        }}
      >
        {ticker && <strong className="mx-2">({ticker})</strong>}
        {title}
      </Box>
    </AccordionSummary>
  );
}
