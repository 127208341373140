import { Link } from "@mui/material";
import { Box } from "src/components/atoms/Box";
import { FormSection } from "src/components/molecules/FormSection";
import { LabelField } from "src/components/molecules/LabelField";
import { VoteTransmission } from "src/types/voteTransmission";

interface IVoteTransmissionKeyDetailsSectionProps {
  voteTransmission: VoteTransmission;
}

export function VoteTransmissionKeyDetailsSection({
  voteTransmission,
}: IVoteTransmissionKeyDetailsSectionProps) {
  const { noboEvent } = voteTransmission;
  const { dateReceived, id, securities, solicitor } = noboEvent;
  const { email, name } = solicitor;

  return (
    <FormSection
      data-testid="vote-transmission-key-details"
      isCollapsible={false}
      title="Key Details"
    >
      <Box
        data-testid="key_details"
        sx={{
          display: "flex",
          mt: 4,
          p: 4,
          justifyContent: "space-between",
          border: "1px solid #e5e5e5",
          backgroundColor: "#f8f8f8",
        }}
      >
        <LabelField label="CUSIP(s)">
          {securities.map(
            (s, i) => `${s.cusip}${i === securities.length - 1 ? "" : ", "}`
          )}
        </LabelField>
        <LabelField label="Solicitor">{name}</LabelField>
        <LabelField label="Solicitor Email">{email}</LabelField>
        <LabelField label="Date Received">{dateReceived}</LabelField>
        <LabelField label="Reference">
          <Link
            sx={{
              color: "#1853b4",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            href={`/nobo/${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            NOBO Request
          </Link>
        </LabelField>
      </Box>
    </FormSection>
  );
}
