import React from "react";

type FieldErrorProps = {
  children: React.ReactNode;
};

function FieldError({ children }: FieldErrorProps): JSX.Element {
  return <p className="text-xs text-red mt-1">{children}</p>;
}

export { FieldError };
