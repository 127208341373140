import { Stack } from "@mui/material";
import { MeetingFormat } from "../../Molecules";
import { FormikProps } from "formik";
import { FilingDetailsFormValues } from "../../../../types";

interface Props {
  formikBag: FormikProps<FilingDetailsFormValues>;
}

const MeetingFormats = ({ formikBag }: Props) => {
  return (
    <>
      <span>
        <b>Meeting Format</b>
        {formikBag.touched.meetingFormats &&
        formikBag.errors.meetingFormats &&
        typeof formikBag.errors.meetingFormats === "string" ? (
          <span
            style={{
              color: "#d33f33",
              fontSize: ".75rem",
              margin: "0 0 0 8px",
            }}
            data-testid="error-select-meetingformat-type"
          >
            {formikBag.errors.meetingFormats}
          </span>
        ) : null}
      </span>

      <Stack spacing={6}>
        <MeetingFormat
          formikBag={formikBag}
          enabledLabel="In-person"
          canVoteLabel="Can shareholders vote at the in-person meeting?"
          field="inPerson"
          maxLength={256}
          infoField={{
            label: "Meeting Address",
            id: "meeting-address",
          }}
        />
        <MeetingFormat
          formikBag={formikBag}
          enabledLabel="Virtual"
          canVoteLabel="Can shareholders vote at the virtual meeting?"
          field="virtual"
          maxLength={256}
          infoField={{
            label: "Virtual Meeting URL Address",
            id: "virtual-meeting-url",
          }}
        />
        <MeetingFormat
          formikBag={formikBag}
          enabledLabel="Teleconference"
          canVoteLabel="Can shareholders vote at the teleconference?"
          field="telephone"
          infoField={{
            label: "Teleconference Meeting Phone Number",
            id: "telephone",
          }}
        />
      </Stack>
    </>
  );
};

export default MeetingFormats;
