import { useState } from "react";
import get from "lodash.get";
import { Security, CompanyContact } from "../../../features/Proxy/types";
import { FormikActions, FormikValues } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { SecurityShort } from "../../../api/adapters/nexus/types";

type AutoCompleteOption = Security | CompanyContact | SecurityShort;

type Props = {
  options: AutoCompleteOption[];
  setFieldValue: FormikActions<FormikValues>["setFieldValue"];
  values: AutoCompleteOption[] | [];
  field: string;
  optionLabelField: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  sideEffects?: (value: AutoCompleteOption[]) => void;
};

/**
 * Generic typeahead to be used with any amount of static data
 * @param setFieldValue - formik set field
 * @param values - object of current selected values
 * @param field - field within value object for display
 * @param options - array of options to filter from
 * @param label - input label
 * @param sideEffects - function to run if sideEffects are needed on change
 * @param optionLabelField - field to be used for display of typeahead option
 * @param placeholder - placeholder text for input
 */
const GenericTypeAhead = ({
  options,
  setFieldValue,
  values,
  field,
  label,
  optionLabelField,
  sideEffects,
  required,
  placeholder = "",
}: Props): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");
  const currentValue = values || [];

  return (
    <Autocomplete
      multiple
      limitTags={3}
      options={options}
      value={currentValue}
      inputValue={searchTerm}
      getOptionLabel={(option) => get(option, optionLabelField)}
      isOptionEqualToValue={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      filterSelectedOptions
      ChipProps={{ size: "small" }}
      onChange={(_, changeValue) => {
        setFieldValue(field, changeValue);
        sideEffects && sideEffects(changeValue);
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === "clear") {
          setFieldValue(field, []);
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          required={required}
          size="small"
        />
      )}
    />
  );
};

export { GenericTypeAhead };
