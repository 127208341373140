import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Ready } from "./Ready";
import { TaskListLayout } from "./TaskListLayout";
import { Details } from "./Details/Details";
import { Drafts } from "./Drafts";
import { Create } from "./Create";
import { Review } from "./Review";
import { Open } from "./Open";
import {
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from "./batch-filings-context";
import { Redirect } from "src/components/Redirect";

function Proxy(): JSX.Element {
  const batchActions = useBatchFilingsState();
  const dispatch = useBatchFilingsDispatch();
  const location = useLocation();

  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_BATCH" });
    };
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname.includes("tasks")) {
      dispatch({ type: "RESET_BATCH" });
      return;
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (batchActions.filings.length) {
      const filingIdFromUrl = Number(location.pathname.split("/")[2]);

      if (
        !Number.isNaN(filingIdFromUrl) &&
        !batchActions.filings.includes(Number(filingIdFromUrl))
      ) {
        dispatch({ type: "RESET_BATCH" });
        return;
      }
    }
  }, [batchActions.filings, dispatch, location.pathname]);

  return (
    <Routes>
      <Route path=":id/*" element={<Details />} />
      <Route path="create" element={<Create />} />
      <Route path="tasks" element={<TaskListLayout />}>
        <Route path="drafts" element={<Drafts />} />
        <Route path="ready" element={<Ready />} />
        <Route path="review" element={<Review />} />
        <Route path="open" element={<Open />} />
      </Route>
      <Route path="/" element={<Redirect to="tasks/drafts" />} />
    </Routes>
  );
}

export { Proxy };
