import { useProspectusByIssuerId } from "../../../api/adapters/hub/hooks/useProspectusByIssuerId";
import { useProspectusPackages } from "../../../api/adapters/nexus/hooks/useProspectusPackages";

export function useProspectusPageData(issuerId: string) {
  const { isError, isLoading, response } = useProspectusByIssuerId(issuerId);
  const ids = response?.securities.map((security) => String(security.id));

  const {
    isError: isProspectusPackageError,
    isLoading: isProspectusPackageLoading,
    mutate,
    prospectusPackages,
    prospectusPackagesBySecurity,
  } = useProspectusPackages(ids || []);

  return {
    isError: isError || isProspectusPackageError,
    isLoading: isLoading || isProspectusPackageLoading,
    mutate,
    prospectus: response,
    prospectusPackages,
    prospectusPackagesBySecurity,
    securities: response?.securities,
  };
}
