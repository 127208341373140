import { Security } from "../features/Proxy/types";

interface MapSecurities {
  selectedIds: number[];
  allSecurities: Security[];
}
/**
 * Maps selected security ids to full cusip data
 * @param allSecurities - full cusip data for the proxy
 * @param selectedIds - array of security ids currently mapped to the ballot
 * @returns Security[] - security array containing only ids from selectedIds
 */
export const mapSecuritiesWithCusip = ({
  allSecurities,
  selectedIds,
}: MapSecurities): Security[] => {
  const idsMapped: Security[] = [];
  allSecurities.forEach((itm) => {
    if (selectedIds.includes(itm.id)) {
      idsMapped.push(itm);
    }
  });
  return idsMapped;
};
