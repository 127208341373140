import { AxiosResponse } from "axios";
import { PaginatedResponse } from "src/types/PaginatedResponse";
import useSWR from "swr";
import { nexus } from "../..";
import { Response } from "../../../../features/Proxy/useTasks";

export interface ProxyEvent {
  id: string;
  type: string;
  recordDate: string;
  tradeStats: {
    recentTrades: number;
    weeklyTrades: number;
    monthlyTrades: number;
    yearlyTrades: number;
  };
}

const fetcher = (url: string) => nexus.get<PaginatedResponse<ProxyEvent>>(url);

function useProxyEvents(
  data: AxiosResponse<Response, unknown> | undefined,
  useId = false
) {
  const proxyEvents = useSWR(
    data
      ? "proxy-events/?proxy_event_ids=" +
          data.data.results.map((filing) =>
            useId ? filing.id : filing.globalFilingId
          )
      : null,
    fetcher
  );

  return proxyEvents;
}

export { useProxyEvents };
