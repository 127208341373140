import DOMPurify from "dompurify";

interface IEmailViewProps {
  html: string;
}

export const EmailView = ({ html }: IEmailViewProps): JSX.Element => {
  function createMarkup() {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  return <div className="p-8" dangerouslySetInnerHTML={createMarkup()} />;
};
