import { useState } from "react";
import { useParams } from "react-router-dom";

import { useProspectusPageData } from "./useProspectusPageData";
import { LoadingState } from "../../../components/LoadingState";
import { PageWrapper } from "../../../components/PageWrapper";
import { ProspectusAccordion } from "../../../components/ProspectusAccordion";
import { Box } from "../../../components/atoms/Box";
import { Issuer, Prospectus, SecurityStats } from "../../../types/common";
import { CreateProspectusPackageDialog } from "src/components/CreateProspectusPackageDialog";
import { Button } from "src/components/atoms/Button";

export type Security = {
  cusip: string;
  iconUrl: null | string;
  id: string;
  issuer: Issuer;
  name: string;
  price: null | string;
  priceLastUpdated: null | string;
  primaryColor: null | string;
  prospectuses: Prospectuses;
  securityType: string;
  stats: SecurityStats;
  ticker: string | null;
};

type Prospectuses = {
  active: Prospectus[];
  upcoming: Prospectus[];
  archived: Prospectus[];
};

export type ProspectusStatus = "active" | "upcoming" | "archived";

type ProspectusComponentParams = {
  id: string;
};

function ProspectusComponent(): JSX.Element | null {
  const { id } = useParams() as ProspectusComponentParams;
  const [isProspectusCreationDialogOpen, setIsProspectusCreationDialogOpen] =
    useState(false);

  const {
    isError,
    isLoading,
    mutate,
    prospectus,
    prospectusPackages,
    prospectusPackagesBySecurity,
    securities,
  } = useProspectusPageData(id);

  if (isLoading) {
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );
  }

  if (isError || !prospectus || !prospectusPackages) {
    return <PageWrapper>Error...</PageWrapper>;
  }

  return (
    <PageWrapper>
      <Button
        className="mb-4"
        onClick={() => setIsProspectusCreationDialogOpen(true)}
        sx={{ width: "300px" }}
        variant="outlined"
        data-testid="Create"
      >
        Create prospectus package
      </Button>
      {Object.keys(prospectusPackagesBySecurity).length === 0 && (
        <Box textAlign="center">No prospectus packages found</Box>
      )}
      {Object.keys(prospectusPackagesBySecurity).map((key, index) => {
        const packages = prospectusPackagesBySecurity[key];
        return (
          <Box sx={{ mb: 4 }} key={index}>
            <ProspectusAccordion
              data-testid="Accordion"
              isExpanded={true}
              onProspectusPackageUpdate={() => mutate()}
              prospectusPackages={packages}
            />
          </Box>
        );
      })}
      <CreateProspectusPackageDialog
        isOpen={isProspectusCreationDialogOpen}
        onSuccess={() => {
          mutate();
        }}
        onClose={() => {
          setIsProspectusCreationDialogOpen(false);
        }}
        securityOptions={
          securities?.map((security) => {
            return {
              id: security.id,
              cusip: security.cusip,
            };
          }) || []
        }
      />
    </PageWrapper>
  );
}

export { ProspectusComponent as Prospectus };
