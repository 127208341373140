import useSWR from "swr";
import { AxiosRequestConfig } from "axios";

import { hub } from "..";
import { IQueryParams } from "../../types";
import { Issuer } from "src/features/IssuerDetails/Invoicing";

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) => {
  return hub.get<Issuer>(url, { params });
};

// Getting issuer information based on issuer id from Hub
export function useIssuer(id: string) {
  const url = `/issuers/issuer/${id}/`;
  const { data, error, mutate } = useSWR(url, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    issuer: data?.data,
  };
}
