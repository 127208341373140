import { IconButton, Box } from "@mui/material";
import { Menu } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SayLogo } from "src/assets/images/say-logo.svg";
import { NavItem } from "./NavItem";
import { Avatar } from "../atoms/Avatar";
import { removeAuthToken } from "src/utils/removeToken";
import { Dialog } from "../atoms/dialog/Dialog";

type NavProps = {
  isCollapsed: boolean;
  onToggleNav: () => void;
};

export const SideNavigationBar = ({
  isCollapsed,
  onToggleNav,
}: NavProps): JSX.Element => {
  const navigate = useNavigate();
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const handleLogoutClick = () => {
    removeAuthToken();
    navigate("/");
  };

  const handleCloseLogoutDialog = () => {
    setIsLogoutDialogOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        zIndex: 1101,
        top: 0,
        overflow: "hidden",
        position: "sticky",
      }}
    >
      <Box
        id="SayLogo"
        sx={{
          display: "flex",
          paddingLeft: "24px",
          height: "4.375rem",
          alignItems: "center",
          borderBottom: "1px solid #e5e5e5",
          background: "#F8f8f8",
        }}
      >
        <IconButton
          onClick={onToggleNav}
          edge="start"
          sx={{ marginRight: "20px" }}
          size="large"
        >
          <Menu />
        </IconButton>
        {!isCollapsed && <SayLogo />}
      </Box>
      <Box id="NavMenu" sx={{ borderRight: "1px solid #e5e5e5", flexGrow: 1 }}>
        <NavItem to="/proxy">
          <Avatar name="px" size="small" />
          {!isCollapsed && <PageTitle>Proxy</PageTitle>}
        </NavItem>
        <NavItem to="/prospectuses">
          <Avatar name="ps" size="small" />
          {!isCollapsed && <PageTitle>Prospectus</PageTitle>}
        </NavItem>
        <NavItem to="/vca">
          <Avatar name="va" size="small" />
          {!isCollapsed && <PageTitle>VCA</PageTitle>}
        </NavItem>
        <NavItem to="/issuers">
          <Avatar name="Is" size="small" />
          {!isCollapsed && <PageTitle>Issuers</PageTitle>}
        </NavItem>
        <NavItem to="/nobo">
          <Avatar name="No" size="small" />
          {!isCollapsed && <PageTitle>NOBO</PageTitle>}
        </NavItem>
      </Box>
      <Box
        onClick={() => setIsLogoutDialogOpen(true)}
        sx={{
          borderRight: "1px solid #e5e5e5",
          cursor: "pointer",
          pb: 4,
          fontSize: "12px",
          textAlign: "center",
        }}
      >
        Logout
      </Box>
      <Dialog
        open={isLogoutDialogOpen}
        onClose={handleCloseLogoutDialog}
        onPrimaryClick={handleLogoutClick}
        onSecondaryClick={handleCloseLogoutDialog}
        primaryButtonColor="error"
        primaryButtonText="Logout"
        secondaryButtonText="Cancel"
      >
        Are you sure you want to log out?
      </Dialog>
    </Box>
  );
};

const PageTitle = ({ children }: { children: React.ReactNode }) => (
  <Box component="span" sx={{ marginLeft: 4 }}>
    {children}
  </Box>
);
