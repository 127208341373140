import Cookies from "js-cookie";

export const getTokenFromCookies = (): string => {
  const token = Cookies.get("authToken");

  if (token) {
    return token;
  }

  return "";
};
