import { useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";

import { updateProspectusNexus } from "../../api/adapters/nexus";
import { ProspectusPackage } from "../../api/adapters/nexus/types";
import { Box } from "../atoms/Box";
import { Dialog } from "../atoms/dialog/Dialog";
import { Label } from "../Label";
import { DatePicker } from "../atoms/DatePicker";

interface IUpdateProspectusDocumentDateDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
  prospectusPackage: ProspectusPackage;
}

export function UpdateProspectusDocumentDateDialog({
  isOpen,
  onClose,
  onUpdate,
  prospectusPackage,
}: IUpdateProspectusDocumentDateDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [expectedDate, setExpectedDate] = useState(
    new Date(`${prospectusPackage.expectedEndDate}T00:00:00`)
  );

  const handleChangeExpectedDate = (date: Date | null) => {
    if (date) {
      setExpectedDate(date);
    }
  };

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      await updateProspectusNexus(prospectusPackage.id, {
        expectedEndDate: format(expectedDate, "yyyy-MM-dd"),
        securityId: prospectusPackage.security.id,
      });
      toast.success("Expected date was updated successfully.");
    } catch {
      toast.error(
        "An unknown error occurred. Please ensure your selections are correct.",
        {
          autoClose: false,
        }
      );
    } finally {
      setIsLoading(false);
      onUpdate();
      onClose();
    }
  };

  return (
    <Dialog
      isPrimaryLoading={isLoading}
      open={isOpen}
      onClose={onClose}
      onPrimaryClick={handleUpdate}
      onSecondaryClick={onClose}
      primaryButtonText="Update"
      secondaryButtonText="Cancel"
      title="Update package expected end date"
    >
      <Box display="flex" flexDirection="column" sx={{ height: "300px" }}>
        <Label>Expected Date:</Label>
        <DatePicker
          name="effectiveStartDate"
          onChange={handleChangeExpectedDate}
          required={true}
          selected={expectedDate}
        />
      </Box>
    </Dialog>
  );
}
