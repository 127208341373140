import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import { WarrenGDocument } from "src/types/warreng";
import { CreateCorporateActionForm as CreateCorporateActionFormType } from "src/types/vca";
import { useCorporateActionEvent } from "src/api/adapters/nexus/hooks/useCorporateActionEvent";
import { Box } from "src/components/atoms/Box";
import { CreateCorporateActionForm } from "src/components/CreateCorporateActionForm/CreateCorporateActionForm";
import { VcaDocumentList } from "src/components/organisms/VcaDocumentList";
import { PreviewPane } from "src/components/PreviewPane";
import { PreviewItem } from "src/components/PreviewPane/PreviewPane";
import { DisplayType } from "src/features/Proxy/types";
import { LoadingState } from "src/components/LoadingState";
import { CreateVCAPayload } from "src/api/adapters/nexus/types";
import { updateVoluntaryCorporateActionFiling } from "src/api/adapters";
import { getFormDefaultValues } from "./utils";
import { capitalize } from "src/utils/capitalize";
import { SplitPane } from "src/components/molecules/SplitPane";
import { Button } from "src/components/atoms/Button";
import { LoadingButton } from "@mui/lab";

type FilingDetailsParams = {
  id: string;
};

export const FilingDetails = () => {
  const { id } = useParams() as FilingDetailsParams;
  const { corporateActionEvent, isLoading, mutate } =
    useCorporateActionEvent(id);
  const [docs, setDocs] = useState<WarrenGDocument[]>([]);
  const [previewItem, setPreviewItem] = useState<PreviewItem | null>(null);
  const form = useForm<CreateCorporateActionFormType>({
    defaultValues: {
      announcementDate: null,
      dtcExpiration: null,
      dtcExpirationTimezone: null,
      electionCutoff: null,
      electionCutoffTimezone: null,
      recordDate: null,
      operationsStatus: "new",
      offer: {
        title: "",
        detail: "",
        conditions: {},
      },
      securities: [],
      type: null,
    },
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
  } = form;

  useEffect(() => {
    if (corporateActionEvent) {
      reset(getFormDefaultValues(corporateActionEvent));
      setDocs(corporateActionEvent.documents);
    }
  }, [corporateActionEvent]);

  if (isLoading || !corporateActionEvent) {
    return <LoadingState />;
  }

  const handleOnCancelClick = () => {
    reset(getFormDefaultValues(corporateActionEvent));
  };

  const handleUpdateVCA: SubmitHandler<CreateCorporateActionFormType> = async (
    values
  ) => {
    if (values.type) {
      const payload: CreateVCAPayload = {
        type: values.type,
        announcementDate: moment(values.announcementDate).format("yyyy-MM-DD"),
        electionCutoff: values.electionCutoff
          ? moment(values.electionCutoff).format("YYYY-MM-DDTHH:mm:ss")
          : null,
        electionCutoffTimezone: values.electionCutoffTimezone || "",
        dtcExpiration: moment(values.dtcExpiration).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        dtcExpirationTimezone: values.dtcExpirationTimezone || "",
        recordDate: values.recordDate
          ? moment(values.recordDate).format("yyyy-MM-DD")
          : null,
        securityIds: values.securities.map((security) => security.id),
        offer: values.offer,
        brokerIds: corporateActionEvent.brokers.map((broker) => broker.id),
      };
      try {
        await updateVoluntaryCorporateActionFiling(id, payload);
      } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.data?.nonFieldErrors) {
          const errorMessage = e.response.data.nonFieldErrors[0];
          toast.error(capitalize(errorMessage));
        } else {
          toast.error(
            "An error has occured when trying to update the vca filing. Please try updating the filing again."
          );
        }
      } finally {
        mutate();
      }
    }
  };

  const refreshVca = () => mutate();

  const handlePreviewChange = (item: PreviewItem) => {
    setPreviewItem(item);
  };

  const handleDocumentsChange = (docs: WarrenGDocument[]) => {
    setDocs(docs);
  };

  const renderPrimaryPanel = () => {
    return (
      <>
        <Box
          sx={{
            height: `calc(100vh - ${isDirty ? "245px" : "179px"})`,
            overflowY: "auto",
            px: 6,
            pt: 2,
          }}
        >
          <CreateCorporateActionForm form={form} sx={{ mt: 4, mb: 2 }} />
          <VcaDocumentList
            documents={docs}
            onDocumentsChange={handleDocumentsChange}
            onPreviewClick={handlePreviewChange}
            updateVca={refreshVca}
            vcaId={id}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            border: "1px solid #e5e5e5",
            height: "64px",
            display: isDirty ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
            px: "24px",
            mx: "-1px",
          }}
        >
          <Button
            color="primary"
            data-testid="save-button"
            disabled={!isDirty}
            onClick={handleOnCancelClick}
            size="small"
            sx={{ mr: 2 }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            data-testid="save-button"
            disabled={!isDirty}
            loading={isSubmitting}
            onClick={handleSubmit(handleUpdateVCA)}
            size="small"
            sx={{ mr: 2 }}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </>
    );
  };

  const renderSecondaryPanel = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          px: 8,
          py: 4,
        }}
      >
        <h2 className="pb-2 text-secondary-text font-medium text-xs uppercase">
          Document Preview
        </h2>
        <PreviewPane
          currentPreview={previewItem}
          options={docs.map((doc) => {
            return {
              id: doc.name,
              name: doc.name,
              preview: doc.url,
              type: DisplayType.Document,
            };
          })}
        />
      </Box>
    );
  };

  return (
    <SplitPane
      primaryPane={renderPrimaryPanel()}
      secondaryPane={renderSecondaryPanel()}
      secondarySx={{ display: "flex" }}
    />
  );
};
