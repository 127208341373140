import {
  Grid,
  MenuItem,
  Button,
  TextField,
  FormHelperText,
  Box,
} from "@mui/material";
import React from "react";
import {
  Formik,
  Form,
  Field,
  FormikActions,
  FieldArray,
  FieldProps,
} from "formik";
import { ERRORS } from "../../../constants/errors";
import { GroupedProposalWithCusip } from "./BallotDetails";
import { BallotDetailsProposalGroupRow } from "./BallotDetailsProposalGroupRow";
import { VoteProposalPayload, VoteType, Security } from "../types";
import { Add } from "@mui/icons-material";
import {
  createVoteProposal,
  updateVoteProposal,
} from "../../../api/adapters/proxy";
import { GenericTypeAhead } from "../../../components/typeaheads/Static/GenericTypeAhead";
import * as Yup from "yup";
import { ProposalContextEditor } from "./ProposalContextEditor";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Type is required."),
  title: Yup.string().max(200).required("Title is required."),
  voteType: Yup.string().when("type", {
    is: "SayOnPay",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Vote type is required."),
  }),
  routineness: Yup.string().required("Routineness is required."),
  recommendationType: Yup.string().required("Recommendation type is required."),
  securities: Yup.array().min(1, ERRORS.SECURITY_REQUIRED),
  proposals: Yup.array()
    .when("type", {
      is: "BoardOfDirectorsNomination",
      then: Yup.array().of(
        Yup.object().shape({
          directorName: Yup.string().required("Director name is required."),
        })
      ),
      otherwise: Yup.array().of(
        Yup.object().shape({
          details: Yup.string().required("Details are required."),
        })
      ),
    })
    .min(1, "At least 1 proposal is required."),
});

type Props = {
  value: GroupedProposalWithCusip;
  onCancelEditing: () => void;
  isNew: boolean;
  securities: Security[];
  onSave: () => void;
  filingId: string;
};

type Values = GroupedProposalWithCusip;

function BallotDetailsProposalForm({
  value,
  onCancelEditing,
  isNew,
  securities: allSecurities,
  onSave,
  filingId,
}: Props): JSX.Element {
  const initialValues = value || {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values: Values, { setSubmitting }: FormikActions<Values>) => {
        setSubmitting(true);
        const securities = values.securities
          ? values.securities.map((security) => security.id)
          : []; // api takes array of ids not cusip labels

        const payloads: VoteProposalPayload[] = values.proposals.map(
          (proposal, index) => ({
            id: proposal.id,
            isRoutine: values.routineness === "nonRoutine" ? false : true,
            recommendationType: values.recommendationType,
            securities: securities,
            title: values.title,
            type: values.type,
            voteType: values.voteType,
            proposalNumber: index + 1,
            tooltipText: proposal.tooltipText,
            groupNumber: values.groupNumber,
            ...(values.type === "BoardOfDirectorsNomination" && {
              directorName: proposal.directorName,
            }),
            ...(values.type !== "BoardOfDirectorsNomination" && {
              details: proposal.details,
            }),
          })
        );

        const apiCalls = payloads.map((payload: VoteProposalPayload) => {
          const { id, tooltipText, ...rest } = payload;
          const emptyTooltipSchema = JSON.stringify([
            {
              children: [{ text: "" }],
            },
          ]);
          const hasTooltipText =
            JSON.stringify(tooltipText) !== emptyTooltipSchema;

          return id && isFinite(id)
            ? updateVoteProposal(id, {
                ...rest,
                tooltipText: hasTooltipText ? tooltipText : null,
              })
            : createVoteProposal({
                ...rest,
                tooltipText: hasTooltipText ? tooltipText : null,
                filing: filingId,
              });
        });

        Promise.all(apiCalls)
          .then(() => {
            setSubmitting(false);
          })
          .catch((error) => {
            setSubmitting(false);
            console.error(error.response);
          })
          .finally(onSave);
      }}
    >
      {({
        values,
        touched,
        setFieldValue,
        isSubmitting,
        submitForm,
        errors,
        handleChange,
      }) => (
        <Form>
          <BallotDetailsProposalGroupRow
            title={values.title}
            routineness={values.routineness}
            numberOfProposals={values.proposals.length}
            recommendationType={values.recommendationType}
            voteType={values.voteType}
            proposalType={values.type}
            cusipStrings={values.securities.map((security) => security.cusip)}
          />

          <Box
            sx={{
              padding: "24px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <Grid container={true} spacing={3}>
              {isNew && (
                <Grid item={true} xs={12}>
                  <TextField
                    size="small"
                    select={true}
                    required
                    fullWidth
                    id="type"
                    name="type"
                    label="Proposal Type"
                    value={values.type}
                    error={touched.type && Boolean(errors.type)}
                    helperText={touched.type && errors.type}
                    onChange={(event) => {
                      const proposalType = event.target.value;
                      setFieldValue("proposals", []);

                      switch (proposalType) {
                        case "BoardOfDirectorsNomination":
                          setFieldValue("routineness", "nonRoutine");
                          setFieldValue("voteType", "election_plurality");
                          setFieldValue("recommendationType", "for");
                          setFieldValue("title", "Election of Directors");
                          setFieldValue("type", proposalType);
                          setFieldValue("proposals", [
                            {
                              id: Infinity,
                              proposalNumber: Infinity,
                              tooltipText: null,
                              directorName: "",
                            },
                          ]);
                          break;
                        case "ManagementProposalItem":
                          setFieldValue("routineness", "nonRoutine");
                          setFieldValue("voteType", "election_majority");
                          setFieldValue("recommendationType", "for");
                          setFieldValue("type", proposalType);
                          setFieldValue("title", "");
                          break;
                        case "ExecutiveCompensationProposalItem":
                          setFieldValue("routineness", "nonRoutine");
                          setFieldValue("voteType", "election_majority");
                          setFieldValue("recommendationType", "for");
                          setFieldValue("title", "Executive Compensation");
                          setFieldValue("type", proposalType);
                          break;
                        case "AdjournmentProposalItem":
                          setFieldValue("routineness", "nonRoutine");
                          setFieldValue("voteType", "election_majority");
                          setFieldValue("recommendationType", "for");
                          setFieldValue("title", "Adjournment of Meeting");
                          setFieldValue("type", proposalType);
                          break;
                        case "PlanOfMergerProposalItem":
                          setFieldValue("routineness", "nonRoutine");
                          setFieldValue("voteType", "election_majority");
                          setFieldValue("recommendationType", "for");
                          setFieldValue("title", "Plan of Merger");
                          setFieldValue("type", proposalType);
                          break;
                        case "ShareholderProposalItem":
                          setFieldValue("routineness", "nonRoutine");
                          setFieldValue("voteType", "election_majority");
                          setFieldValue("recommendationType", "against");
                          setFieldValue("type", proposalType);
                          setFieldValue("title", "");
                          break;
                        case "RatificationOfAuditors":
                          setFieldValue("title", "Ratification of Auditors");
                          setFieldValue("type", proposalType);
                          setFieldValue("routineness", "routine");
                          setFieldValue("voteType", "election_majority");
                          setFieldValue("recommendationType", "for");

                          break;
                        case "SayOnPay":
                          setFieldValue("title", "Say on Pay");
                          setFieldValue("type", proposalType);
                          setFieldValue("routineness", "nonRoutine");
                          setFieldValue("voteType", "");
                          setFieldValue("recommendationType", "none");
                          break;
                        default:
                          setFieldValue("type", proposalType);
                          setFieldValue("title", "");
                          return;
                      }
                    }}
                  >
                    <MenuItem value="BoardOfDirectorsNomination">
                      Election of Directors
                    </MenuItem>
                    <MenuItem value="PlanOfMergerProposalItem">
                      Plan of Merger
                    </MenuItem>
                    <MenuItem value="AdjournmentProposalItem">
                      Adjournment of Meeting
                    </MenuItem>
                    <MenuItem value="ExecutiveCompensationProposalItem">
                      Executive Compensation
                    </MenuItem>
                    <MenuItem value="ManagementProposalItem">
                      Management Proposal
                    </MenuItem>
                    <MenuItem value="ShareholderProposalItem">
                      Shareholder Proposal
                    </MenuItem>
                    <MenuItem value="SayOnPay">Say on Pay</MenuItem>
                    <MenuItem value="RatificationOfAuditors">
                      Ratification of Auditors
                    </MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid item={true} xs={12}>
                <TextField
                  size="small"
                  required
                  fullWidth
                  id="title"
                  name="title"
                  label="Proposal Title"
                  value={values.title}
                  onChange={handleChange}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>
              <Grid item={true} xs={6}>
                {values.type === "SayOnPay" ? (
                  <TextField
                    size="small"
                    select={true}
                    required
                    fullWidth
                    id="vote-type"
                    name="voteType"
                    label="Vote Type"
                    value={values.voteType}
                    onChange={handleChange}
                    defaultValue="default"
                    error={touched.voteType && Boolean(errors.voteType)}
                    helperText={touched.voteType && errors.voteType}
                  >
                    <MenuItem value="default">
                      One year, Two years, Three years, Abstain
                    </MenuItem>
                  </TextField>
                ) : (
                  <TextField
                    size="small"
                    select={true}
                    required
                    fullWidth
                    id="vote-type"
                    name="voteType"
                    label="Vote Type"
                    value={values.voteType}
                    error={touched.voteType && Boolean(errors.voteType)}
                    helperText={touched.voteType && errors.voteType}
                    onChange={(event) => {
                      const voteType = event.target.value as VoteType;
                      setFieldValue("voteType", voteType);
                      const firstVoteChoice = getVoteChoices(voteType)[0];
                      setFieldValue("recommendationType", firstVoteChoice);
                    }}
                  >
                    <MenuItem value="election_majority_foragainst">
                      For, Against
                    </MenuItem>
                    <MenuItem value="election_majority_forabstain">
                      For, Abstain
                    </MenuItem>
                    <MenuItem value="election_majority">
                      For, Against, Abstain
                    </MenuItem>
                    <MenuItem value="election_majority_foragainstwithhold">
                      For, Against, Withhold
                    </MenuItem>
                    <MenuItem value="election_plurality">
                      For, Withhold
                    </MenuItem>
                    <MenuItem value="election_majority_forwithholdabstain">
                      For, Withhold, Abstain
                    </MenuItem>
                    <MenuItem value="election_majority_yesno">Yes, No</MenuItem>
                  </TextField>
                )}
              </Grid>

              <Grid item={true} xs={6}>
                <TextField
                  size="small"
                  select={true}
                  required
                  fullWidth
                  id="routineness"
                  name="routineness"
                  label="Routineness"
                  value={values.routineness}
                  onChange={handleChange}
                  error={touched.routineness && Boolean(errors.routineness)}
                  helperText={touched.routineness && errors.routineness}
                >
                  <MenuItem value="routine">Routine</MenuItem>
                  <MenuItem value="nonRoutine">Non-Routine</MenuItem>
                </TextField>
              </Grid>

              <Grid item={true} xs={6}>
                <TextField
                  size="small"
                  select={true}
                  required
                  fullWidth
                  id="recommendation-type"
                  name="recommendationType"
                  label="Recommendation Type"
                  value={values.recommendationType}
                  onChange={handleChange}
                  error={
                    touched.recommendationType &&
                    Boolean(errors.recommendationType)
                  }
                  helperText={
                    touched.recommendationType && errors.recommendationType
                  }
                >
                  {getVoteChoices(
                    values.voteType,
                    values.type === "SayOnPay"
                  ).map((voteChoice) => (
                    <MenuItem value={voteChoice} key={voteChoice}>
                      {voteChoiceMap[voteChoice]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item={true} xs={6}>
                <GenericTypeAhead
                  values={values.securities}
                  setFieldValue={setFieldValue}
                  options={allSecurities}
                  field="securities"
                  label="CUSIP"
                  optionLabelField="cusip"
                />
                {typeof errors.securities === "string" ? (
                  <Box width="100%" px={3} py={1}>
                    <FormHelperText error={true}>
                      {errors.securities}
                    </FormHelperText>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              padding: "14px 24px",
              color: "#000000",
              fontSize: "14px",
              fontWeight: "500",
              background: "#f5f5f5",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            {values.type === "BoardOfDirectorsNomination"
              ? "List of Director Nominees"
              : "Proposal Details"}
          </Box>

          <FieldArray
            name="proposals"
            render={(arrayHelpers) => (
              <div>
                {values.proposals.map((proposal, index) => (
                  <React.Fragment key={proposal.id}>
                    <Box sx={{ padding: "12px 24px" }}>
                      {values.type === "BoardOfDirectorsNomination" ? (
                        <TextField
                          size="small"
                          required
                          fullWidth
                          placeholder="Director Name *"
                          variant="standard"
                          id={`proposals.${index}.directorName`}
                          name={`proposals.${index}.directorName`}
                          value={values.proposals[index].directorName}
                          onChange={handleChange}
                          InputProps={{ disableUnderline: true }}
                          error={
                            touched.proposals &&
                            touched.proposals[index]?.directorName &&
                            Boolean(
                              errors.proposals &&
                                errors.proposals[index]?.directorName
                            )
                          }
                          helperText={
                            touched.proposals &&
                            touched.proposals[index]?.directorName &&
                            errors.proposals &&
                            errors.proposals[index]?.directorName
                          }
                        />
                      ) : (
                        <TextField
                          size="small"
                          required
                          fullWidth
                          variant="standard"
                          id={`proposals.${index}.details`}
                          name={`proposals.${index}.details`}
                          placeholder="Details *"
                          value={values.proposals[index].details}
                          onChange={handleChange}
                          InputProps={{ disableUnderline: true }}
                          error={
                            touched.proposals &&
                            touched.proposals[index]?.details &&
                            Boolean(
                              errors.proposals &&
                                errors.proposals[index]?.details
                            )
                          }
                          helperText={
                            touched.proposals &&
                            touched.proposals[index]?.details &&
                            errors.proposals &&
                            errors.proposals[index]?.details
                          }
                        />
                      )}
                    </Box>

                    <Field
                      id={`proposals.${index}.tooltipText`}
                      name={`proposals.${index}.tooltipText`}
                    >
                      {({ field }: FieldProps) => (
                        <ProposalContextEditor
                          value={field.value}
                          onChange={(value: unknown) =>
                            setFieldValue(
                              `proposals.${index}.tooltipText`,
                              value
                            )
                          }
                        />
                      )}
                    </Field>
                    <hr
                      style={{
                        height: " 1px",
                        border: "0",
                        borderTop: "1px solid #e5e5e5",
                        marginTop: "2em",
                        padding: "0",
                      }}
                    />
                  </React.Fragment>
                ))}

                {typeof errors.proposals === "string" ? (
                  <Box width="100%" px={3} py={1}>
                    <FormHelperText error={true}>
                      {errors.proposals}
                    </FormHelperText>
                  </Box>
                ) : null}

                <Box sx={{ padding: "8px 16px" }}>
                  <Button
                    sx={{
                      color: "#a0a0a0",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    startIcon={<Add />}
                    onClick={() =>
                      arrayHelpers.push({
                        id: Infinity,
                        proposalNumber: Infinity,
                        tooltipText: [
                          {
                            children: [{ text: "" }],
                          },
                        ],
                        details: "",
                        directorName: "",
                      })
                    }
                  >
                    {values.type === "BoardOfDirectorsNomination"
                      ? "Director"
                      : "Details"}
                  </Button>
                </Box>
              </div>
            )}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "8px 12px",
            }}
          >
            <Button type="button" onClick={onCancelEditing}>
              Cancel
            </Button>
            <Button
              color="primary"
              type="button"
              disabled={isSubmitting}
              onClick={(event) => {
                event.stopPropagation();
                submitForm();
              }}
            >
              {isSubmitting ? "Saving..." : "Done"}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

const voteChoiceMap: { [index: string]: string } = {
  abstain: "Abstain",
  against: "Against",
  for: "For",
  no: "No",
  none: "None",
  one_year: "One Year",
  three_years: "Three Years",
  two_years: "Two Years",
  withhold: "Withhold",
  cash: "Cash",
  stock: "Stock",
  mixed: "Mixed",
  yes: "Yes",
};

function getVoteChoices(voteType: VoteType, isSayOnPay = false) {
  if (isSayOnPay) {
    return ["none", "one_year", "two_years", "three_years", "abstain"];
  }

  switch (voteType) {
    case "election_majority":
      return ["for", "against", "abstain", "none"];
    case "election_majority_forabstain":
      return ["for", "abstain", "none"];
    case "election_majority_foragainst":
      return ["for", "against", "none"];
    case "election_majority_foragainstwithhold":
      return ["for", "against", "withhold", "none"];
    case "election_majority_forwithholdabstain":
      return ["for", "withhold", "abstain", "none"];
    case "election_majority_yesno":
      return ["yes", "no", "none"];
    case "election_plurality":
      return ["for", "withhold", "none"];
    default:
      return ["none"];
  }
}

export { BallotDetailsProposalForm };
