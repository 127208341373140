import useSWR from "swr";
import { nexus } from "..";
import { ProspectusPackage } from "../types";

const fetcher = (url: string) => {
  return nexus.get<ProspectusPackage>(url);
};

export function useProspectusPackage(id: string) {
  const url = `/prospectus-packages/${id}/`;
  const { data, error, mutate } = useSWR(url, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    prospectus: data?.data,
  };
}
