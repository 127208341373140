import { format } from "date-fns";

function formatDate(date: Date): string {
  const utcDate = new Date(
    date.valueOf() + date.getTimezoneOffset() * 60 * 1000
  );
  return format(utcDate, "MM-dd-yyyy");
}

export { formatDate };
