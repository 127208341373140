import { Box } from "src/components/atoms/Box";
import { VcaBroker } from "src/types/vca";
import { BrokerRow } from "./BrokerRow";

interface IVcaBrokersListProps {
  brokers: VcaBroker[];
  selectedBrokerIds: string[];
  onAddBroker: (brokerId: string) => void;
  onRemoveBroker: (brokerId: string) => void;
  onSelectBroker: (broker: VcaBroker) => void;
}

export const VcaBrokersList = ({
  brokers,
  selectedBrokerIds,
  onAddBroker,
  onRemoveBroker,
  onSelectBroker,
}: IVcaBrokersListProps) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        pb: 6,
        overflowY: "auto",
      }}
    >
      {brokers.map((broker) => (
        <BrokerRow
          key={broker.id}
          broker={broker}
          onAddBroker={onAddBroker}
          onRemoveBroker={onRemoveBroker}
          onSelectBroker={onSelectBroker}
          selectedBrokerIds={selectedBrokerIds}
        />
      ))}
    </Box>
  );
};
