import React, { useCallback, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { LoadingState } from "../../../components/LoadingState";
import { useTasks, OrderingKey } from "../useTasks";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  Link,
  Tooltip,
  Box,
} from "@mui/material";
import { TABLE_HEADER_DATA } from "./constants";
import TableColumnHeaders from "../../../components/TasklistTable/TableColumnHeaders";
import { formatDate } from "../../../utils/format-date";
import { formatCamelCaseString } from "../../../utils/format-camel-case";
import { formatNumber } from "../../../utils/format-number";
import { useTableOrdering } from "../useTableOrdering";
import { BatchActionButtons } from "../BatchActionButtons";
import {
  deleteFilings,
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from "../batch-filings-context";
import { TradeAlertIndicator } from "../TradeAlertIndicator";
import { checkIfFilingHasTradeAlert } from "../checkIfFilingHasTradeAlert";
import { CusipTagList } from "src/components/CusipTagList";
import { PageWrapper } from "src/components/PageWrapper";
import { TableHeader } from "src/components/TableHeader";
import {
  table,
  tableHeadCell,
  tableBodyRow,
  tableBodyCell,
  tableBodyCellCheckbox,
} from "../styles/tableStyles";
import { TableSortLabel } from "src/components/TableSortLabel";
import { formatDeadline, getMeetingDate } from "../utils";

export const Review = (): JSX.Element | null => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    review: { data, error, mutate },
    reviewQuery: query,
    handleUpdateQuery,
    reviewProxyEvents: proxyEvents,
  } = useTasks();
  const dispatch = useBatchFilingsDispatch();
  const batchFilings = useBatchFilingsState();
  const { getIsActive, direction } = useTableOrdering(query.ordering);
  const currentQueryField = query.ordering.replace("-", "");
  const [batchActionButtonsVisible, setBatchActionButtonsVisible] =
    useState(false);
  const handleOrderingClick = useCallback(
    (orderingKey: OrderingKey) => {
      // default to ascending for new field clicks
      const dir = currentQueryField === orderingKey ? direction : "desc";
      switch (dir) {
        case "desc":
          return handleUpdateQuery("review", "ordering", orderingKey);
        case "asc":
        default:
          return handleUpdateQuery("review", "ordering", `-${orderingKey}`);
      }
    },
    [query]
  );

  function handleSelectAll(event: React.ChangeEvent<HTMLInputElement>) {
    if (!data) return;

    if (event.target.checked) {
      const allFilings = data.data.results.map((filing) => filing.id);
      dispatch({
        type: "SELECT_ALL_FILINGS",
        filings: allFilings,
        path: location.pathname,
      });
      return;
    }

    dispatch({ type: "DESELECT_ALL_FILINGS" });
  }

  function handleSelect(
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) {
    if (event.target.checked) {
      dispatch({ type: "SELECT_FILING", filing: id, path: location.pathname });
    } else {
      dispatch({ type: "DESELECT_FILING", filing: id });
    }
  }

  function handleTableRowClick(id: number) {
    navigate(`/proxy/${id}/email`);
  }

  function handleBatchDeleteFilings() {
    deleteFilings(dispatch, batchFilings.filings, mutate);
  }

  function handleBatchEditFilings() {
    navigate(`/proxy/${batchFilings.filings[0]}`);
  }

  if ((!error && !data) || !data?.data?.results)
    return (
      <Box p={4}>
        <LoadingState />
      </Box>
    );

  if (error) return <Box p={4}>Error...</Box>;

  return (
    <PageWrapper>
      <TableHeader
        count={data.data.count}
        page={query.page}
        pageSize={query.pageSize}
        itemType="filing"
        onPageChange={(page: number) =>
          handleUpdateQuery("review", "page", page)
        }
        onPageSizeChange={(pageSize: number) =>
          handleUpdateQuery("review", "pageSize", pageSize)
        }
      />
      <Box sx={{ overflowX: "auto" }}>
        <Table sx={table}>
          <TableHead
            onMouseEnter={() => setBatchActionButtonsVisible(true)}
            onMouseLeave={() => setBatchActionButtonsVisible(false)}
          >
            <TableRow>
              <TableCell
                padding="checkbox"
                sx={{
                  position: "sticky",
                  left: 0,
                  background: "white",
                  zIndex: 10,
                }}
              >
                <Checkbox
                  color="default"
                  onChange={handleSelectAll}
                  checked={
                    batchFilings.filings.length > 0 &&
                    batchFilings.filings.length === data.data.results.length
                  }
                  indeterminate={
                    batchFilings.filings.length > 0 &&
                    batchFilings.filings.length < data.data.results.length
                  }
                />
              </TableCell>
              <TableCell
                sx={tableHeadCell}
                style={{
                  paddingRight: "80px",
                  width: "300px",
                  left: 47,
                  position: "sticky",
                  zIndex: 10,
                }}
              >
                <BatchActionButtons
                  isVisible={
                    batchActionButtonsVisible &&
                    Boolean(batchFilings.filings.length)
                  }
                  onDelete={handleBatchDeleteFilings}
                  onEdit={handleBatchEditFilings}
                />
                <TableSortLabel
                  active={getIsActive("issuer__company_name")}
                  direction={direction}
                  onClick={() => handleOrderingClick("issuer__company_name")}
                >
                  Issuer
                </TableSortLabel>
              </TableCell>
              <TableColumnHeaders
                headerData={TABLE_HEADER_DATA}
                activeQueryField={currentQueryField}
                direction={direction}
                clickHandler={handleOrderingClick}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data.results.map((filing) => {
              const hasProxyEvent = proxyEvents?.data?.data?.results
                ? checkIfFilingHasTradeAlert(
                    filing.globalFilingId,
                    proxyEvents.data.data.results
                  )
                : false;

              return (
                <TableRow
                  key={filing.id}
                  selected={batchFilings.filings.includes(filing.id)}
                  onClick={() => handleTableRowClick(filing.id)}
                  hover={true}
                  sx={tableBodyRow}
                >
                  <TableCell
                    sx={{
                      ...tableBodyCellCheckbox,
                      position: "sticky",
                      left: 0,
                      zIndex: 20,
                      overflow: "visible",
                      background: "white",
                    }}
                  >
                    <Checkbox
                      onChange={(event) => handleSelect(event, filing.id)}
                      checked={batchFilings.filings.includes(filing.id)}
                      onClick={(event) => event.stopPropagation()}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      ...tableBodyCell,
                      position: "sticky",
                      borderRight: `1px solid`,
                      borderRightColor: "divider",
                    }}
                  >
                    <Tooltip arrow title={filing.issuer.companyName}>
                      <Link
                        to={`/issuers/${filing.issuer.globalIssuerId}`}
                        component={RouterLink}
                        underline="hover"
                      >
                        {filing.issuer.companyName}
                      </Link>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={tableBodyCell}>
                    <CusipTagList
                      cusips={filing.securities.map(
                        (security) => security.cusip
                      )}
                    />
                  </TableCell>
                  <TableCell sx={tableBodyCell}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {hasProxyEvent && <TradeAlertIndicator />}
                      {formatCamelCaseString(filing.type)}
                    </Box>
                  </TableCell>
                  <TableCell sx={tableBodyCell}>
                    {filing.recordDate
                      ? formatDate(new Date(filing.recordDate))
                      : ""}
                  </TableCell>
                  <TableCell sx={tableBodyCell}>
                    {filing.stats && filing.stats.totalPositions
                      ? formatNumber(filing.stats.totalPositions)
                      : "--"}
                  </TableCell>
                  <TableCell sx={tableBodyCell}>
                    {getMeetingDate(filing)}
                  </TableCell>
                  <TableCell sx={tableBodyCell}>
                    {filing.stats && filing.stats.internalDigitalSendDeadline
                      ? formatDeadline(filing.stats.internalDigitalSendDeadline)
                      : ""}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </PageWrapper>
  );
};
