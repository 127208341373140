import { Routes, Route } from "react-router-dom";
import { Redirect } from "src/components/Redirect";
import { TaskListContainer } from "src/pages/vca/tasks";
import { NewCorporateActionEvents } from "src/pages/vca/tasks/new";
import { InProgressCorporateActionEvents } from "src/pages/vca/tasks/inProgress";
import { OpenCorporateActionEvents } from "src/pages/vca/tasks/open";
import { ExpiredCorporateActionEvents } from "src/pages/vca/tasks/expired";
import { VcaDetailPage } from "src/pages/vca/details/VcaDetailPage";
import { FilingDetails } from "src/pages/vca/details/FilingDetails";
import { BrokerEmail } from "src/pages/vca/details/brokerEmail/BrokerEmail";

export function Vca() {
  return (
    <Routes>
      <Route path=":id/*" element={<VcaDetailPage />}>
        <Route path="broker-email" element={<BrokerEmail />} />
        <Route index element={<FilingDetails />} />
      </Route>
      <Route path="tasks" element={<TaskListContainer />}>
        <Route path="new" element={<NewCorporateActionEvents />} />
        <Route
          path="in-progress"
          element={<InProgressCorporateActionEvents />}
        />
        <Route path="open" element={<OpenCorporateActionEvents />} />
        <Route path="historical" element={<ExpiredCorporateActionEvents />} />
      </Route>
      <Route path="/" element={<Redirect to="tasks/new" />} />
    </Routes>
  );
}
