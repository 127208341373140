import useSWR from "swr";
import { proxy } from "..";

export type Broker = {
  id: number;
  name: string;
  slug: string;
  globalBrokerId: string;
  totalPositions: number;
  childBrokers?: Broker[];
};

const fetcher = (url: string) => {
  return proxy.get<Broker[]>(url);
};

export function useCorporateActionBrokers(id: string) {
  const url = `/brokers/corporate-action-broker-typeahead/?ca_id=${id}`;
  const { data, error, mutate } = useSWR(url, fetcher);
  return {
    isError: error,
    isLoading: !data && !error,
    corporateActionBrokers: data?.data,
    mutate,
  };
}
