import { TextField } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";

import { CreateCorporateActionForm } from "src/types/vca";

interface IOfferToPurchaseConditionsProps {
  form: UseFormReturn<CreateCorporateActionForm>;
}

export const OfferToPurchaseConditions = ({
  form,
}: IOfferToPurchaseConditionsProps) => {
  const { control } = form;
  return (
    <>
      <Controller
        control={control}
        name="offer.conditions.contraCusip"
        rules={{
          required: true,
          maxLength: { value: 9, message: "Contra Cusip must be 9 digits" },
          minLength: { value: 9, message: "Contra Cusip must be 9 digits" },
        }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              sx={{ mb: 2 }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              InputLabelProps={{ shrink: true }}
              fullWidth={true}
              label="Contra Cusip*"
              size="small"
            />
          );
        }}
      />
      <Controller
        control={control}
        name="offer.conditions.price"
        rules={{
          required: true,
          min: {
            value: 0,
            message: "Price cannot be a negative number",
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              sx={{ my: 2 }}
              InputProps={{ inputProps: { min: 0 } }}
              InputLabelProps={{ shrink: true }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              fullWidth={true}
              label="Price*"
              size="small"
            />
          );
        }}
      />
      <Controller
        control={control}
        name="offer.conditions.offererParty"
        rules={{
          required: true,
          maxLength: {
            value: 128,
            message: "Offerer Party can not be longer than 128 characters.",
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              InputLabelProps={{ shrink: true }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              sx={{ my: 2 }}
              fullWidth={true}
              label="Offerer Party*"
              size="small"
            />
          );
        }}
      />
    </>
  );
};
