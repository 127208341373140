import { MenuItem, TextField } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import {
  Controller,
  SubmitHandler,
  useController,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";

import { updateIssuer } from "src/api/adapters";
import { COUNTRIES } from "src/constants/countries";
import { STATES } from "src/constants/states";
import { Issuer } from "src/features/IssuerDetails/Invoicing";
import { validateEmailAddress } from "src/utils/validate-email-address";
import { Dialog } from "../atoms/dialog/Dialog";

interface IInvoicingContactDialogProps {
  issuer: Issuer;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

type InvoicingContactDetailsForm = {
  email: string;
  notes: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

export function InvoicingContactDialog({
  issuer,
  isOpen,
  onClose,
  onUpdate,
}: IInvoicingContactDialogProps) {
  const { id, invoicingAddress } = issuer;
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isDirty, isSubmitting },
  } = useForm<InvoicingContactDetailsForm>({
    defaultValues: {
      email: invoicingAddress?.email || "",
      notes: invoicingAddress?.notes || "",
      addressLine1: invoicingAddress?.addressLine1 || "",
      addressLine2: invoicingAddress?.addressLine2 || "",
      state: invoicingAddress?.state || "",
      city: invoicingAddress?.city || "",
      postalCode: invoicingAddress?.postalCode || "",
      country: invoicingAddress?.country || "",
    },
  });

  // Using useController hook for type b/c we want access to the country field
  // We want to add some side-effects to the onChange handler
  const {
    field: countryField,
    fieldState: { error: countryError },
  } = useController({
    name: "country",
    control,
    rules: { required: true },
  });
  const country = watch("country");

  useEffect(() => {
    const { invoicingAddress } = issuer;
    reset({
      email: invoicingAddress?.email || "",
      notes: invoicingAddress?.notes || "",
      addressLine1: invoicingAddress?.addressLine1 || "",
      addressLine2: invoicingAddress?.addressLine2 || "",
      state: invoicingAddress?.state || "",
      city: invoicingAddress?.city || "",
      postalCode: invoicingAddress?.postalCode || "",
      country: invoicingAddress?.country || "",
    });
  }, [issuer]);

  const handleSave: SubmitHandler<InvoicingContactDetailsForm> = async (
    values
  ) => {
    try {
      await updateIssuer(id, {
        ...issuer,
        invoicingAddress: {
          ...issuer.invoicingAddress,
          ...values,
        },
      });
      toast.success("Issuer contact information was updated successfully.");
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.data) {
        toast.error(JSON.stringify(error.response.data, null, 2));
      } else {
        toast.error("An unexpected error occurred.");
      }
    } finally {
      onUpdate();
      onClose();
    }
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    countryField.onChange(e.target.value);
    // When a user selects a country that is not the US
    // We want to clear the state field so we don't send the BE
    // a state
    if (value !== "US") {
      setValue("state", "");
    }
  };

  const handleOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog
      fullWidth
      isPrimaryDisabled={!isDirty}
      isPrimaryLoading={isSubmitting}
      open={isOpen}
      onClose={handleOnClose}
      onPrimaryClick={handleSubmit(handleSave)}
      onSecondaryClick={handleOnClose}
      secondaryButtonText="Cancel"
      primaryButtonText="Save"
      title="Update Contact Details"
    >
      <form id="invoicing-contact-details-form">
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            validate: (emailAddress) => validateEmailAddress(emailAddress),
          }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                sx={{ my: 2 }}
                InputLabelProps={{ shrink: true }}
                error={!!fieldState.error}
                fullWidth
                label="Email*"
                size="small"
              />
            );
          }}
        />
        <Controller
          name="notes"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                sx={{ my: 2 }}
                InputLabelProps={{ shrink: true }}
                error={!!fieldState.error}
                fullWidth
                rows={3}
                multiline
                label="Notes"
                size="small"
              />
            );
          }}
        />
        <Controller
          name="addressLine1"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                sx={{ my: 2 }}
                InputLabelProps={{ shrink: true }}
                error={!!fieldState.error}
                fullWidth
                label="Address Line 1*"
                size="small"
              />
            );
          }}
        />
        <Controller
          name="addressLine2"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                sx={{ my: 2 }}
                InputLabelProps={{ shrink: true }}
                error={!!fieldState.error}
                fullWidth
                label="Address Line 2"
                size="small"
              />
            );
          }}
        />
        <Controller
          name="city"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                sx={{ my: 2 }}
                InputLabelProps={{ shrink: true }}
                error={!!fieldState.error}
                fullWidth
                label="City*"
                size="small"
              />
            );
          }}
        />
        {country === "US" && (
          <Controller
            control={control}
            name="state"
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  sx={{ my: 2 }}
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error}
                  fullWidth
                  label="State*"
                  size="small"
                  select={true}
                >
                  {STATES.map((state, index) => {
                    return (
                      <MenuItem key={index} value={state.value}>
                        {state.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              );
            }}
          />
        )}
        <Controller
          name="postalCode"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                sx={{ my: 2 }}
                InputLabelProps={{ shrink: true }}
                error={!!fieldState.error}
                fullWidth
                label="Postal Code*"
                size="small"
              />
            );
          }}
        />
        <TextField
          {...countryField}
          sx={{ my: 2 }}
          InputLabelProps={{ shrink: true }}
          error={!!countryError}
          fullWidth
          label="Country*"
          size="small"
          select={true}
          onChange={handleCountryChange}
        >
          {COUNTRIES.map((country, index) => {
            return (
              <MenuItem key={index} value={country.value}>
                {country.label}
              </MenuItem>
            );
          })}
        </TextField>
      </form>
    </Dialog>
  );
}
