import { Checkbox } from "@mui/material";
import { useState } from "react";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";

import { VcaBroker } from "src/types/vca";
import { Box } from "../atoms/Box";

interface IBrokerRow {
  broker: VcaBroker;
  className?: string;
  isChildBroker?: boolean;
  onAddBroker: (brokerId: string) => void;
  onRemoveBroker: (brokerId: string) => void;
  onSelectBroker: (broker: VcaBroker) => void;
  selectedBrokerIds: string[];
}

export const BrokerRow = ({
  broker,
  className,
  isChildBroker,
  onAddBroker,
  onRemoveBroker,
  onSelectBroker,
  selectedBrokerIds,
}: IBrokerRow) => {
  const [isShowingChildren, setIsShowingChildren] = useState(false);
  const {
    id,
    name,
    childBrokers,
    corporateActionEventBrokerStats: { averageFullShareCustomersProcessed },
  } = broker;
  return (
    <>
      <Box
        key={id}
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #e5e5e5",
          borderLeft: "1px solid #e5e5e5",
          borderRight: "1px solid #e5e5e5",
          p: 4,
          "&:hover": {
            backgroundColor: "#f8f8f8",
          },
        }}
      >
        <Box
          className={className}
          sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}
        >
          <Checkbox
            id={id}
            checked={selectedBrokerIds.includes(id)}
            disableRipple
            sx={{
              height: "1rem",
              width: "1rem",
              mr: "1rem",
              backgroundColor: "white",
            }}
            size="small"
            onChange={(event) => {
              const { checked, value } = event.currentTarget;
              if (checked) {
                onAddBroker(value);
              } else {
                onRemoveBroker(value);
              }
            }}
            value={id}
          />
          <Box
            className="flex-grow font-sm text-primary-text cursor-pointer"
            onClick={() => onSelectBroker(broker)}
          >
            {name}
          </Box>
          <Box sx={{ mr: isChildBroker ? 8 : 4 }} component="span">
            {averageFullShareCustomersProcessed}
          </Box>
        </Box>
        {!isChildBroker &&
          (isShowingChildren ? (
            <FaAngleDown
              className="cursor-pointer"
              onClick={() => setIsShowingChildren(false)}
              size={16}
            />
          ) : (
            <FaAngleRight
              className="cursor-pointer"
              onClick={() => setIsShowingChildren(true)}
              size={16}
            />
          ))}
      </Box>
      {isShowingChildren &&
        childBrokers.map((childBroker) => {
          return (
            <BrokerRow
              className="ml-4"
              key={childBroker.id}
              broker={childBroker}
              isChildBroker={true}
              onAddBroker={onAddBroker}
              onRemoveBroker={onRemoveBroker}
              onSelectBroker={onSelectBroker}
              selectedBrokerIds={selectedBrokerIds}
            />
          );
        })}
    </>
  );
};
