import { ProspectusPackagesBySecurity } from "../../api/adapters/nexus/hooks/useProspectusPackages";
import {
  ProspectusDocument,
  ProspectusPackage,
} from "../../api/adapters/nexus/types";
import {
  DisplayType,
  FilingDetails,
  SourceType,
} from "../../features/Proxy/types";
import { PreviewItem } from "./PreviewPane";

export function previewItemsFromFiling(filing: FilingDetails) {
  const sourceTypeNames = new Map([
    ["new_email", "Broker Search"],
    ["update_email", "Broker Search Update"],
    ["proposals_email", "Meeting Agenda"],
    ["materials_email", "Material URL(s)"],
    ["additional_email", "Additional Mailing"],
    ["csv", "Morrow.csv"],
  ]);

  const previewItems: PreviewItem[] = [];
  const { id, referenceMaterials, voteProposals, documents } = filing;
  referenceMaterials.forEach((material) => {
    //TODO(David): This is not great, ask BE to send over id's for csv and emails
    if (material.sourceType === ("csv" as SourceType)) {
      previewItems.push({
        id: material.created,
        name: sourceTypeNames.get(material.sourceType) || "",
        preview: material.csvData,
        type: DisplayType.CSV,
      });
    } else {
      previewItems.push({
        id: material.created,
        name: sourceTypeNames.get(material.sourceType) || "",
        preview: material.html,
        type: DisplayType.Email,
      });
    }
  });

  if (voteProposals && voteProposals.length > 0) {
    previewItems.push({
      id: String(voteProposals[0].id),
      name: "Vote Website",
      preview: id,
      type: DisplayType.Ballot,
    });
  }

  documents.forEach((doc) => {
    previewItems.push({
      id: String(doc.id),
      name: doc.name,
      preview: doc.attachment,
      type: DisplayType.Document,
    });
  });

  return previewItems;
}

export function previewItemsFromProspectusPackages(
  prospectusPackages: ProspectusPackage[]
) {
  const previewItems: PreviewItem[] = [];
  prospectusPackages.forEach((prospectusPackage) => {
    prospectusPackage.documents.forEach((doc) => {
      previewItems.push({
        id: doc.id,
        name: doc.name,
        preview: doc.url,
        type: DisplayType.Document,
      });
    });
  });

  return previewItems;
}

export function previewItemsFromProspectusPackagesBySecurity(
  prospectusPackages: ProspectusPackagesBySecurity
) {
  const previewItems: PreviewItem[] = [];
  Object.keys(prospectusPackages).forEach((securityId: string) => {
    prospectusPackages[securityId].forEach(
      (prospectusPackage: ProspectusPackage) => {
        prospectusPackage.documents.forEach((doc: ProspectusDocument) => {
          previewItems.push({
            id: doc.id,
            name: doc.name,
            preview: doc.url,
            type: DisplayType.Document,
          });
        });
      }
    );
  });

  return previewItems;
}
