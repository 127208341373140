import React from "react";
import { Label } from "../../../components/Label";

type ItemRowColumnProps = {
  label: string;
  children: React.ReactNode;
  className?: string;
};

function ItemRowColumn({
  label,
  children,
  className,
}: ItemRowColumnProps): JSX.Element {
  return (
    <div className={`flex flex-col ${className || ""}`}>
      <Label>{label}</Label>
      {children}
    </div>
  );
}

export { ItemRowColumn };
