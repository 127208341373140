import { SxProps } from "@mui/material";
import { FiAlertOctagon } from "react-icons/fi";
import { Box } from "../atoms/Box";

type ErrorCardProps = {
  title: string;
  body: string;
  sx?: SxProps;
};

const ErrorCard = ({ title, body, sx }: ErrorCardProps): JSX.Element => {
  return (
    <Box sx={{ display: "flex", flexGrow: 1, ...sx }}>
      <Box
        sx={{
          flexGrow: 1,
          border: "#e5e5e5 solid 1px",
          background: "#f8f8f8",
          px: 8,
          py: 64,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            background: "#F7D8D6",
            color: "#737373",
            height: "4rem",
            width: "4rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "9999px",
          }}
        >
          <FiAlertOctagon size="2rem" />
        </Box>
        <Box
          component="p"
          sx={{
            fontWeight: 500,
            mt: 2,
            fontSize: "1.125rem",
          }}
        >
          {title}
        </Box>
        <Box
          component="p"
          sx={{
            color: "#737373",
            fontSize: "1rem",
          }}
        >
          {body}
        </Box>
      </Box>
    </Box>
  );
};

export { ErrorCard };
