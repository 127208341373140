import { VoteProposalPayload } from "../../../features/Proxy/types";
import { createAdapter } from "../adapterFactory";
import {
  ICreateContactRequest,
  ICreateCompanyContact,
  ICreateVCACheckRequest,
  IUpdateVCARequest,
  IUpdateVCAStatusRequest,
  ICreateElectionRequest,
  IUpdateElectionRequest,
  ICreateAttachmentRequest,
  IUpdateFilingStatusRequest,
  ICreateFilingCheckRequest,
  ICreateIssuerRequest,
  ICreateSecurityRequest,
  IUpdateAttachmentRequest,
} from "./types";

const proxy = createAdapter({
  baseURL: `${process.env.REACT_APP_HOST_URL}/v1`,
});

const proxyFormData = createAdapter({
  baseURL: `${process.env.REACT_APP_HOST_URL}/v1`,
  isMultipartRequest: true,
  transformRequest: false,
});

function createContact(payload: ICreateContactRequest) {
  const url = `/contacts/`;
  return proxy.post(url, payload);
}

function createCompanyContact(payload: ICreateCompanyContact) {
  const url = `/company-contacts/`;
  return proxy.post(url, payload);
}

function createTestVCAEmail(
  vcaId: string,
  slug: string | null,
  payload: { emails: string[] }
) {
  const url = `/admin/vca/${vcaId}/preview/${slug}/`;
  return proxy.post(url, payload);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createTestFilingEmail(filingId: string, slug: string, payload: any) {
  const url = `/filings/${filingId}/preview/${slug}/`;
  return proxy.post(url, payload);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createVCA(payload: any) {
  const url = `/admin/vca/`;
  return proxy.post(url, payload);
}

function createVCACheck(payload: ICreateVCACheckRequest) {
  const url = `/admin/vca/check/`;
  return proxy.post(url, payload);
}

function updateVCA(id: string, payload: IUpdateVCARequest) {
  const url = `/admin/vca/${id}/`;
  return proxy.patch(url, payload);
}

function updateVCAStatus(id: string, payload: IUpdateVCAStatusRequest) {
  const url = `/admin/vca/${id}/update-status/`;
  return proxy.patch(url, payload);
}

function deleteVCA(id: string) {
  const url = `/admin/vca/${id}/`;
  return proxy.delete(url);
}

function createElection(payload: ICreateElectionRequest) {
  const url = `/admin/elections/`;
  return proxy.post(url, payload);
}

function updateElection(id: string, payload: IUpdateElectionRequest) {
  const url = `/admin/elections/${id}/`;
  return proxy.patch(url, payload);
}

function createAttachment(payload: ICreateAttachmentRequest) {
  const url = `/attachments/`;
  return proxy.post(url, payload);
}

function uploadAttachment(attachmentId: string, payload: FormData) {
  const url = `/attachments/${attachmentId}/upload/`;
  return proxyFormData.post(url, payload);
}

function updateAttachment(id: number, payload: IUpdateAttachmentRequest) {
  const url = `/attachments/${id}/`;
  return proxy.patch(url, payload);
}

function deleteAttachment(id: number) {
  const url = `/attachments/${id}/`;
  return proxy.delete(url);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createFiling(payload: any) {
  const url = `/filings/`;
  return proxy.post(url, payload);
}

function createFilingCheck(payload: ICreateFilingCheckRequest) {
  const url = `/filings/check/`;
  return proxy.post(url, payload);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function updateFiling(id: string, payload: any) {
  const url = `/filings/${id}/`;
  return proxy.patch(url, payload);
}

function updateFilingStatus(id: string, payload: IUpdateFilingStatusRequest) {
  const url = `/filings/${id}/update-status/`;
  return proxy.patch(url, payload);
}

function deleteFiling(id: string) {
  const url = `/filings/${id}/`;
  return proxy.delete(url);
}

function createVoteProposal(payload: VoteProposalPayload) {
  const url = `/vote-proposals/`;
  return proxy.post(url, payload);
}

function updateVoteProposal(id: number, payload: VoteProposalPayload) {
  const url = `/vote-proposals/${id}/`;
  return proxy.patch(url, payload);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function updateVoteProposalOrder(payload: any) {
  const url = `/admin/vote-proposals/reorder/`;
  return proxy.patch(url, payload);
}

function deleteVoteProposal(id: string) {
  const url = `/vote-proposals/${id}/`;
  return proxy.delete(url);
}

function createIssuer(payload: ICreateIssuerRequest) {
  const url = `/issuers/`;
  return proxy.post(url, payload);
}

function createSecurity(payload: ICreateSecurityRequest) {
  const url = `/securities/`;
  return proxy.post(url, payload);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createFilings(payload: any) {
  const url = `/filings/bulk-upload/`;
  return proxyFormData.post(url, payload);
}

export {
  proxy,
  createAttachment,
  createContact,
  createCompanyContact,
  createElection,
  createFiling,
  createFilings,
  createFilingCheck,
  createIssuer,
  createSecurity,
  createTestVCAEmail,
  createTestFilingEmail,
  createVCA,
  createVCACheck,
  createVoteProposal,
  deleteAttachment,
  deleteFiling,
  deleteVCA,
  deleteVoteProposal,
  updateAttachment,
  updateElection,
  updateFiling,
  updateFilingStatus,
  updateVCA,
  updateVCAStatus,
  updateVoteProposal,
  updateVoteProposalOrder,
  uploadAttachment,
};
