import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { TextField, TextFieldProps } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { Security } from "src/types/vca";
import { Solicitor } from "src/types/voteTransmission";
import { CusipField } from "../atoms/CusipField";
import { SolicitorField } from "../atoms/SolicitorField";

export interface NoboForm {
  solicitor: Solicitor | null;
  dateReceived: string | null;
  frontLink: string;
  securities: Security[];
  recordDate: string | null;
  notes: string;
}

interface INoboFormProps {
  form: UseFormReturn<NoboForm>;
}

export function NoboForm({ form }: INoboFormProps) {
  const { control, setValue, watch } = form;

  const securities = watch("securities");
  const solicitor = watch("solicitor");
  return (
    <form id="nobo-form">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Controller
          control={control}
          name="securities"
          rules={{ required: true }}
          render={({ fieldState }) => {
            return (
              <CusipField
                hasError={!!fieldState.error}
                label="CUSIP*"
                setSecurities={(securities: Security[]) =>
                  setValue("securities", securities, { shouldDirty: true })
                }
                values={securities}
              />
            );
          }}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={{ my: 2 }}
          label="Issuer Name"
          fullWidth
          size="small"
          disabled={true}
          value={securities[0]?.issuer?.name || ""}
        />
        <Controller
          control={control}
          name="solicitor"
          rules={{ required: true }}
          render={({ fieldState }) => {
            return (
              <SolicitorField
                hasError={!!fieldState.error}
                isNoboEligible={true}
                label="Solicitor*"
                setSolicitor={(solicitor) =>
                  setValue("solicitor", solicitor, { shouldDirty: true })
                }
                value={solicitor}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="dateReceived"
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <DatePicker
                value={field.value}
                onChange={(date) => {
                  field.onChange(date);
                }}
                renderInput={(props: TextFieldProps) => {
                  return (
                    <TextField
                      {...props}
                      sx={{ my: 2 }}
                      error={!!fieldState.error}
                      InputLabelProps={{ shrink: true }}
                      id="dateReceived"
                      label="Date Received*"
                      size="small"
                      variant="outlined"
                      fullWidth
                      data-testid="date_received"
                    />
                  );
                }}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="recordDate"
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <DatePicker
                value={field.value}
                onChange={(date) => {
                  field.onChange(date);
                }}
                renderInput={(props: TextFieldProps) => {
                  return (
                    <TextField
                      {...props}
                      sx={{ my: 2 }}
                      error={!!fieldState.error}
                      InputLabelProps={{ shrink: true }}
                      id="recordDate"
                      label="Record Date*"
                      size="small"
                      variant="outlined"
                      fullWidth
                      data-testid="record_date"
                    />
                  );
                }}
              />
            );
          }}
        />
        <Controller
          name="frontLink"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                sx={{ my: 2 }}
                error={!!fieldState.error}
                label="Front Link*"
                size="small"
                fullWidth
                data-testid="front_link"
              />
            );
          }}
        />
        <Controller
          name="notes"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                sx={{ my: 2 }}
                InputLabelProps={{ shrink: true }}
                error={!!fieldState.error}
                fullWidth
                rows={3}
                multiline
                label="Notes"
                size="small"
              />
            );
          }}
        />
      </LocalizationProvider>
    </form>
  );
}
