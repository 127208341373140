import React from "react";
import { ItemRowColumn } from "../ItemRowColumn";
import { ItemRow } from "../ItemRow";
import { ActionButtons } from "../ActionButtons";
import {
  Formik,
  FormikProps,
  Form,
  Field,
  FieldProps,
  FormikActions,
} from "formik";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select";
import { toast } from "react-toastify";
import { deleteSecurity, updateSecurity } from "../../../api/adapters/hub";
import { SecurityType } from "..";
import { humanize } from "../../../utils/humanize";
import axios from "axios";
import { CusipTagList } from "src/components/CusipTagList";

type SecurityRowProps = {
  id: string;
  name: string;
  securityType: null | SecurityType;
  cusip: string;
  ticker: null | string;
  onSave: () => void;
  onDelete: () => void;
};

type SecurityFormValues = {
  securityType: SecurityType;
  ticker: string;
  name: string;
};

const securityTypeOptions = [
  { value: "etf", label: "ETF" },
  { value: "fund", label: "Fund" },
  { value: "adr", label: "ADR" },
  { value: "etn", label: "ETN" },
  { value: "bond", label: "Bond" },
  { value: "stock", label: "Stock" },
];

function SecurityRow({
  id,
  name,
  securityType,
  cusip,
  ticker,
  onSave,
  onDelete,
}: SecurityRowProps): JSX.Element {
  const [isEditing, setIsEditing] = React.useState(false);

  const handleSetEditing = () => setIsEditing(true);
  const handleCancelEditing = () => setIsEditing(false);
  const handleSaveSecurity = async (
    values: SecurityFormValues,
    actions: FormikActions<SecurityFormValues>
  ) => {
    const { name, securityType, ticker } = values;
    actions.setSubmitting(true);
    try {
      await updateSecurity(id, {
        name,
        securityType,
        ticker,
      });
      setIsEditing(false);
      actions.resetForm();
      actions.setSubmitting(false);
      onSave();
    } catch (error) {
      actions.setSubmitting(false);
      if (axios.isAxiosError(error) && error?.response?.data) {
        toast.error(JSON.stringify(error.response.data));
      } else {
        toast.error("Unable to save security. An unexpect error occurred.");
      }
    }
  };
  const handleDeleteSecurity = async () => {
    try {
      await deleteSecurity(id);
      onDelete();
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.data) {
        toast.error(JSON.stringify(error.response.data));
      } else {
        toast.error("Unable to delete security. An unexpect error occurred.");
      }
    }
  };

  if (isEditing)
    return (
      <Formik
        initialValues={{
          ticker: ticker || "",
          securityType: securityType || "etf",
          name: name || "",
        }}
        onSubmit={handleSaveSecurity}
        render={({
          isValid,
          isSubmitting,
        }: FormikProps<SecurityFormValues>) => (
          <Form>
            <ItemRow className="grid-cols-5">
              <ItemRowColumn label="cusip">
                <CusipTagList cusips={[cusip]} />
              </ItemRowColumn>
              <Field
                name="ticker"
                render={({ field }: FieldProps<SecurityFormValues>) => (
                  <ItemRowColumn label="ticker">
                    <Input {...field} />
                  </ItemRowColumn>
                )}
              />

              <Field
                name="name"
                render={({ field }: FieldProps<SecurityFormValues>) => (
                  <ItemRowColumn label="name">
                    <Input {...field} />
                  </ItemRowColumn>
                )}
              />

              <Field
                name="securityType"
                render={({ field }: FieldProps<SecurityFormValues>) => (
                  <ItemRowColumn label="security type">
                    <Select
                      options={securityTypeOptions}
                      {...field}
                      onChange={field.onChange}
                    />
                  </ItemRowColumn>
                )}
              />
              {/* <ItemRowColumn label="volume">TODO</ItemRowColumn> */}

              <div className="flex items-center" style={{ justifySelf: "end" }}>
                <button
                  className="font-medium text-cobalt hover:underline mr-4 disabled:text-light-gray disabled:cursor-not-allowed disabled:no-underline"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  data-testid="submit"
                >
                  {isSubmitting ? "Saving..." : "Save"}
                </button>
                <button
                  onClick={handleCancelEditing}
                  className="font-medium text-gray hover:text-cobalt"
                  type="button"
                  data-testid="cancel"
                >
                  Cancel
                </button>
              </div>
            </ItemRow>
          </Form>
        )}
      />
    );

  return (
    <ItemRow className="grid-cols-5">
      <ItemRowColumn label="cusip" data-testid="cusip">
        <CusipTagList cusips={[cusip]} />
      </ItemRowColumn>
      <ItemRowColumn label="ticker" data-testid="ticker">
        {ticker}
      </ItemRowColumn>
      <ItemRowColumn label="security name" data-testid="security_name">
        {name}
      </ItemRowColumn>
      <ItemRowColumn label="security type" data-testid="security_type">
        <span className="capitalize" data-testid="capitalize">
          {humanize(securityType || "")}
        </span>
      </ItemRowColumn>
      {/* <ItemRowColumn label="volume">TODO</ItemRowColumn> */}

      <ActionButtons
        onDelete={handleDeleteSecurity}
        onEdit={handleSetEditing}
      />
    </ItemRow>
  );
}

export { SecurityRow };
