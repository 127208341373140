import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { updateVoluntaryCorporateActionFiling } from "src/api/adapters/nexus";
import { useCorporateActionEvent } from "src/api/adapters/nexus/hooks/useCorporateActionEvent";
import { useVcaEligibleBrokers } from "src/api/adapters/nexus/hooks/useVcaEligibleBrokers";
import { Box } from "src/components/atoms/Box";
import { Button } from "src/components/atoms/Button";
import { VcaEmailPreviewPane } from "src/components/EmailPreviewPane/VcaEmailPreviewPane";
import { LoadingState } from "src/components/LoadingState";
import { SplitPane } from "src/components/molecules/SplitPane";
import { VcaBrokersList } from "src/components/VcaBrokerList/VcaBrokerList";
import { VcaBroker } from "src/types/vca";

type BrokerEmailParams = {
  id: string;
};

export const BrokerEmail = () => {
  const { id } = useParams() as BrokerEmailParams;
  const { corporateActionEvent, isLoading, mutate } =
    useCorporateActionEvent(id);
  const { eligibleBrokers, isLoading: isBrokersLoading } =
    useVcaEligibleBrokers(id);
  const [selectedBroker, setSelectedBroker] = useState<VcaBroker | null>(null);
  const [selectedBrokerIds, setSelectedBrokerIds] = useState<string[]>([]);
  const [isSavingBrokers, setIsSavingBrokers] = useState(false);

  useEffect(() => {
    if (corporateActionEvent) {
      setSelectedBrokerIds(
        corporateActionEvent.brokers.map((broker) => broker.id)
      );
    }
  }, [corporateActionEvent]);

  if (
    isLoading ||
    isBrokersLoading ||
    !corporateActionEvent ||
    !eligibleBrokers
  ) {
    return <LoadingState />;
  }

  const handleAddBroker = (brokerId: string) => {
    const foundBroker = eligibleBrokers.find(
      (broker) => broker.id === brokerId
    );

    if (foundBroker?.childBrokers?.length) {
      const parentAndChildBrokerIds = [
        ...foundBroker.childBrokers.map((childBroker) => childBroker.id),
        brokerId,
      ];

      setSelectedBrokerIds([...selectedBrokerIds, ...parentAndChildBrokerIds]);
    } else {
      setSelectedBrokerIds([...selectedBrokerIds, brokerId]);
    }
  };

  const handleRemoveBroker = (brokerId: string) => {
    const foundBroker = eligibleBrokers.find(
      (broker) => broker.id === brokerId
    );

    if (foundBroker?.childBrokers?.length) {
      const parentAndChildBrokerIds = [
        ...foundBroker.childBrokers.map((childBroker) => childBroker.id),
        brokerId,
      ];

      const filteredSelectedBrokerIds = selectedBrokerIds.filter(
        (selectedBrokerId) =>
          !parentAndChildBrokerIds.includes(selectedBrokerId)
      );

      setSelectedBrokerIds(filteredSelectedBrokerIds);
    } else {
      setSelectedBrokerIds(
        selectedBrokerIds.filter(
          (selectedBrokerId) => selectedBrokerId !== brokerId
        )
      );
    }
  };

  const handleSaveBrokers = async () => {
    setIsSavingBrokers(true);
    try {
      await updateVoluntaryCorporateActionFiling(id, {
        announcementDate: corporateActionEvent.announcementDate,
        brokerIds: selectedBrokerIds,
        electionCutoff: corporateActionEvent.electionCutoff,
        electionCutoffTimezone: corporateActionEvent.electionCutoffTimezone,
        dtcExpiration: corporateActionEvent.dtcExpiration,
        dtcExpirationTimezone: corporateActionEvent.dtcExpirationTimezone,
        offer: corporateActionEvent.offer,
        recordDate: corporateActionEvent.recordDate,
        securityIds: corporateActionEvent.securities.map(
          (security) => security.id
        ),
        type: corporateActionEvent.type,
      });
      mutate();
    } catch (e) {
      toast.error("Unable to save brokers. Please try again.");
    } finally {
      setIsSavingBrokers(false);
    }
  };

  const isBrokersUpdated = () => {
    const currentBrokerIds = corporateActionEvent.brokers.map(
      (broker) => broker.id
    );
    const diff = selectedBrokerIds
      .filter((id) => !currentBrokerIds.includes(id))
      .concat(currentBrokerIds.filter((id) => !selectedBrokerIds.includes(id)));
    return diff.length > 0;
  };

  const renderPrimaryPanel = () => {
    return (
      <>
        <Stack
          sx={{
            height: `calc(100vh - ${isBrokersUpdated() ? "245px" : "180px"})`,
            px: 6,
          }}
        >
          <Box
            sx={{
              color: "#737373",
              fontWeight: 500,
              fontSize: ".75rem",
              py: 3,
              textTransform: "uppercase",
            }}
          >
            Brokers
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#f8f8f8",
              padding: 4,
              border: "1px solid #e5e5e5",
            }}
          >
            <Box
              component="span"
              sx={{
                color: "#737373",
                fontSize: ".625rem",
                fontWeight: 500,
                textTransform: "uppercase",
              }}
            >
              Broker
            </Box>
            <Box
              component="span"
              sx={{
                color: "#737373",
                fontSize: ".625rem",
                fontWeight: 500,
                textTransform: "uppercase",
                textAlign: "right",
              }}
            >
              Positions
            </Box>
          </Box>
          <VcaBrokersList
            brokers={eligibleBrokers || []}
            onAddBroker={handleAddBroker}
            onRemoveBroker={handleRemoveBroker}
            onSelectBroker={(broker: VcaBroker) => setSelectedBroker(broker)}
            selectedBrokerIds={selectedBrokerIds}
          />
        </Stack>
        <Box
          sx={{
            backgroundColor: "white",
            border: "1px solid #e5e5e5",
            height: "64px",
            display: isBrokersUpdated() ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
            px: "24px",
            mx: "-1px",
          }}
        >
          <Button
            disabled={!isBrokersUpdated()}
            onClick={() =>
              setSelectedBrokerIds(
                corporateActionEvent.brokers.map((broker) => broker.id)
              )
            }
            size="small"
            sx={{ mr: 2 }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={!isBrokersUpdated()}
            loading={isSavingBrokers}
            onClick={handleSaveBrokers}
            size="small"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </>
    );
  };

  const renderSecondaryPanel = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: `calc(100vh - 180px)`,
          px: 6,
        }}
      >
        <Box
          component="h2"
          sx={{
            color: "#737373",
            fontWeight: 500,
            fontSize: ".75rem",
            py: 3,
            textTransform: "uppercase",
          }}
        >
          Broker Email Preview
        </Box>
        <VcaEmailPreviewPane broker={selectedBroker} vcaId={id} />
      </Box>
    );
  };

  return (
    <SplitPane
      primaryPane={renderPrimaryPanel()}
      secondaryPane={renderSecondaryPanel()}
      secondarySx={{ display: "flex" }}
    />
  );
};
