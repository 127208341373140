import { FaTimesCircle } from "react-icons/fa";
import { CheckResponse } from "./Create";
import { ReactComponent as ExternalLink } from "src/assets/icons/external-link.svg";

type Props = {
  resolve: (value: unknown) => void;
  reject: (value: unknown) => void;
  context: CheckResponse;
};

function DuplicateCheckDialog({
  resolve,
  reject,
  context,
}: Props): JSX.Element {
  return (
    <div
      className="bg-overlay fixed top-0 right-0 left-0 bottom-0 flex items-start justify-center"
      style={{ zIndex: 10000 }}
    >
      <div
        id="VCADialog"
        className="bg-white rounded shadow overflow-hidden mt-32"
      >
        <header
          id="VCADialogHeader"
          className="bg-light-gray relative px-8 py-6"
        >
          <span className="font-medium text-primary-text text-center block">
            Duplicate Filing Check
          </span>
          <button
            id="VCADialogXButton"
            className="absolute text-gray"
            style={{ right: "1rem", top: "1rem" }}
            onClick={reject}
          >
            <FaTimesCircle />
          </button>
        </header>

        <div id="VCADialogBody" className="p-8 flex flex-col items-center">
          {context.duplicateExists && (
            <p className="text-primary-text text-center m-0 max-w-md">
              A corporate action for this CUSIP was created recently. Check to
              make sure this isn&apos;t a duplicate:
              <ul>
                {context.duplicateCorporateActionIds.map((id) => (
                  <li key={id}>
                    <a href={`/corporate-actions/${id}`} target="blank">
                      <div className="flex align-items-center justify-center">
                        <div className="pr-1">{id}</div>
                        <div>
                          <ExternalLink width={16} height={16} />
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </p>
          )}

          {!context.duplicateExists && (
            <p className="text-primary-text text-center m-0 max-w-md">
              No duplicates detected.
            </p>
          )}
          <button
            id="VCADialogContinue"
            className="mt-8 h-10 rounded-full border border-gray text-primary-text font-medium text-sm w-32 hover:bg-primary-text hover:border-primary-text hover:text-white duration-300 transition-color"
            onClick={resolve}
          >
            Continue
          </button>

          <button
            id="VCADialogCancel"
            className="mt-4 text-sm font-medium text-primary-text"
            onClick={reject}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export { DuplicateCheckDialog };
