import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
} from "@mui/material";

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onRequestClose: () => void;
  existingFilingId: number;
};

export const DuplicateConfirmationDialog = ({
  isOpen,
  onConfirm,
  onRequestClose,
  existingFilingId,
}: Props): JSX.Element => {
  return (
    <div data-testid="DuplicateDialog">
      <Dialog
        fullWidth
        open={isOpen}
        onClose={onRequestClose}
        sx={{
          position: "absolute",
          overflow: "hidden",
          [`& .${dialogClasses["container"]}`]: {
            maxHeight: "90vh",
            overflow: "hidden",
          },
        }}
        BackdropProps={{
          sx: {
            position: "absolute",
            backgroundColor: "rgba(0,0,0,.8)",
            width: "100vw",
            height: "100vh",
          },
        }}
        style={{ position: "absolute" }}
      >
        <DialogTitle>Duplicate Filing</DialogTitle>

        <DialogContent
          sx={{
            maxHeight: "90vh",
            overflow: "hidden",
          }}
        >
          <p>
            A filing with the same filing type, issuer, and record date already
            exists. Would you like to add this filing anyway?
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/proxy/${existingFilingId}`}
            >
              Filing #{existingFilingId}
            </a>
          </p>
        </DialogContent>

        <DialogActions>
          <Button onClick={onRequestClose} type="button">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              onConfirm();
            }}
          >
            Add Anyway
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
