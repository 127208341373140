// TBD: a lot of duplicate types, specifically Cusip and Security.
// Need to combine all the duplicate types, as they cause typescript errors
import {
  Button,
  Box,
  Card,
  Grid,
  IconButton,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  formControlLabelClasses,
} from "@mui/material";
import { COLORS } from "@asayinc/component-library";
import {
  Add,
  DragIndicator,
  Edit,
  Delete,
  ExpandMore,
} from "@mui/icons-material";
import axios from "axios";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { createEditor, Node } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";

import {
  ProposalType,
  RecommendationType,
  Routineness,
  TooltipText,
  VoteProposal,
  VoteType,
  Security,
} from "../types";

import { BallotDetailsProposalForm } from "./BallotDetailsProposalForm";
import {
  deleteVoteProposal,
  updateVoteProposalOrder,
} from "../../../api/adapters/proxy";
import { mapSecuritiesWithCusip } from "../../../utils/mapSecuritiesWithCusips";
import { CusipTagList } from "src/components/CusipTagList";
import { useProxy } from "src/api/adapters/proxy/hooks/useProxy";

export type GroupedProposal = {
  groupNumber: number;
  routineness: Routineness;
  recommendationType: RecommendationType;
  voteChoices: string[];
  title: string;
  voteType: VoteType;
  type: ProposalType;
  proposals: Proposal[];
  securities: number[];
};

export type GroupedProposalWithCusip = {
  groupNumber: number;
  routineness: Routineness;
  recommendationType: RecommendationType;
  voteChoices: string[];
  title: string;
  voteType: VoteType;
  type: ProposalType;
  proposals: Proposal[];
  securities: Security[];
};

type Proposal = {
  id: number;
  proposalNumber: number;
  tooltipText: TooltipText;
  details?: string;
  directorName?: string;
};

type BallotDetailsParams = {
  id: string;
};

function BallotDetails(): JSX.Element {
  const { id } = useParams() as BallotDetailsParams;
  const { data, filing, mutate } = useProxy(id);

  const [activeProposalGroupIndex, setActiveProposalGroupIndex] = useState<
    number | null
  >(null);
  const [isShowingForm, setIsShowingForm] = useState(false);

  const groupedProposals = useMemo(
    () =>
      filing
        ? filing.voteProposals
          ? sortProposals(groupVoteProposals(filing.voteProposals))
          : []
        : [],
    [filing]
  );

  async function handleOnDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    if (result.type === "proposalGroup") {
      const newGroupedProposals = reorder<GroupedProposal>(
        groupedProposals,
        result.source.index,
        result.destination.index
      ).map((gp, index) => ({
        ...gp,
        groupNumber: index + 1,
        proposals: gp.proposals.map((p, index) => ({
          ...p,
          proposalNumber: index + 1,
        })),
      }));

      reorderRequest(newGroupedProposals);
    } else {
      const newGroupedProposals = groupedProposals
        .map((groupedProposal) => {
          if (
            groupedProposal.groupNumber === Number(result.type) &&
            result.destination
          ) {
            return {
              ...groupedProposal,
              proposals: reorder<Proposal>(
                groupedProposal.proposals,
                result.source.index,
                result.destination.index
              ),
            };
          }

          return groupedProposal;
        })
        .map((gp, index) => ({
          ...gp,
          groupNumber: index + 1,
          proposals: gp.proposals.map((p, index) => ({
            ...p,
            proposalNumber: index + 1,
          })),
        }));

      reorderRequest(newGroupedProposals);
    }
  }

  async function reorderRequest(groupedProposals: GroupedProposal[]) {
    if (filing && filing.voteProposals && data) {
      const payload = groupedProposals
        .map((gp) => {
          return gp.proposals.map((p) => ({
            id: p.id,
            proposalNumber: p.proposalNumber,
            groupNumber: gp.groupNumber,
          }));
        })
        .flat();

      try {
        mutate(
          {
            ...data,
            data: {
              ...filing,
              voteProposals: filing.voteProposals?.map((voteProposal) => {
                const voteProposalId = voteProposal.id;
                const foundProposalFromPayload = payload.find(
                  (proposal) => proposal.id === voteProposalId
                );
                if (foundProposalFromPayload) {
                  return {
                    ...voteProposal,
                    groupNumber: foundProposalFromPayload.groupNumber,
                    proposalNumber: foundProposalFromPayload.proposalNumber,
                  };
                }
                return voteProposal;
              }),
            },
          },
          false
        );
        await updateVoteProposalOrder(payload);
      } catch (error) {
        if (axios.isAxiosError(error) && error?.response?.data) {
          console.error(error.response.data);
        }
      }
    }
  }

  async function handleGroupDeleteReorder(index: number) {
    groupedProposals.splice(index, 1);
    const payload = groupedProposals
      .map((gp, gIndex) => {
        return gp.proposals.map((p, pIndex) => ({
          id: p.id,
          proposalNumber: pIndex + 1,
          groupNumber: gIndex + 1,
        }));
      })
      .flat();
    await updateVoteProposalOrder(payload).catch((error) =>
      console.error(error.response.data)
    );
  }

  async function handleProposalDeleteReorder(
    index: number,
    proposalId: number
  ) {
    const targetGroup = groupedProposals[index].proposals;
    // if deleting the only proposal in a group, handle as a group deletion/reordering
    if (targetGroup.length === 1) {
      handleGroupDeleteReorder(index);
    } else {
      const payload = targetGroup.reduce(function (filtered, proposal) {
        let initialProposalNumber = 1;
        if (proposal.id !== proposalId) {
          filtered.push({
            id: proposal.id,
            proposalNumber: initialProposalNumber,
            groupNumber: index + 1,
          });
        }
        initialProposalNumber++;
        return filtered;
      }, [] as { id: number; proposalNumber: number; groupNumber: number }[]);
      await updateVoteProposalOrder(payload).catch((error) =>
        console.error(error.response.data)
      );
    }
  }

  const allSecurities = filing ? filing.securities : [];

  /**
   * Parses all proposals securities, to add cusip labels within cusip id array.
   * @returns GroupedProposalWithCusip
   */
  const parseProposals = (): GroupedProposalWithCusip[] => {
    return groupedProposals.map((proposal) => {
      const selectedIds = proposal.securities;
      const idsMapped: Security[] = mapSecuritiesWithCusip({
        allSecurities,
        selectedIds,
      });
      return {
        ...proposal,
        securities: idsMapped,
      };
    });
  };

  // adds cusip data to the selected securities id array
  const proposalsWithCusips: GroupedProposalWithCusip[] = parseProposals();

  /**
   * @returns GroupedProposalWithCusip - either a hardcoded default proposal or
   * the current selected propsoal
   */
  const getActiveProposal = (): GroupedProposalWithCusip => {
    if (activeProposalGroupIndex == null) {
      return {
        groupNumber: groupedProposals.length + 1,
        routineness: "nonRoutine",
        proposals: [
          {
            id: Infinity,
            proposalNumber: Infinity,
            tooltipText: null,
            directorName: "",
          },
        ],
        recommendationType: "for",
        title: "Election of Directors",
        type: "BoardOfDirectorsNomination",
        voteChoices: ["for"],
        voteType: "election_plurality",
        securities: allSecurities,
      };
    }

    return proposalsWithCusips[activeProposalGroupIndex];
  };

  // Get currently selected ballot proposal
  const activeProposal = getActiveProposal();

  return (
    <>
      <Grid
        component="header"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "grey[100]",
          color: "text.primary",
          borderBottom: `1px solid`,
          borderBottomColor: "divider",
          fontSize: "12px",
          fontWeight: 500,
          py: 1.75,
          px: 3,
        }}
        container={true}
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item={true} xs={5}>
          <Tooltip arrow={true} title="Proposal Title">
            <Box
              component="p"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Proposal Title
            </Box>
          </Tooltip>
        </Grid>
        <Grid item={true} xs={"auto"}>
          <Tooltip arrow={true} title="Item">
            <Box
              component="p"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Item
            </Box>
          </Tooltip>
        </Grid>
        <Grid item={true} xs={2}>
          <Tooltip arrow={true} title="Type">
            <Box
              component="p"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Type
            </Box>
          </Tooltip>
        </Grid>
        <Grid item={true} xs={2}>
          <Tooltip arrow={true} title="CUSIP">
            <Box
              component="p"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              CUSIP
            </Box>
          </Tooltip>
        </Grid>
        <Grid item={true} xs={"auto"}>
          <Tooltip arrow={true} title="Routine">
            <Box
              component="p"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Routine
            </Box>
          </Tooltip>
        </Grid>

        <Grid item={true} xs={1}>
          <Tooltip arrow={true} title="Recommendation Type">
            <Box
              component="p"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Recommendation
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {isShowingForm ? (
          <BallotDetailsProposalForm
            value={activeProposal}
            onCancelEditing={() => {
              setIsShowingForm(false);
              setActiveProposalGroupIndex(null);
            }}
            isNew={activeProposalGroupIndex === null}
            securities={allSecurities}
            onSave={() => {
              mutate();
              setIsShowingForm(false);
            }}
            filingId={id}
          />
        ) : (
          <>
            <Droppable droppableId="groupedProposalList" type="proposalGroup">
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{
                    width: "100%",
                  }}
                >
                  {proposalsWithCusips.map((groupedProposal, index) => (
                    <ProposalGroup
                      key={groupedProposal.groupNumber}
                      groupedProposal={groupedProposal}
                      handleGroupDeleteReorder={handleGroupDeleteReorder}
                      handleProposalDeleteReorder={handleProposalDeleteReorder}
                      index={index}
                      onSetGroupEditing={() => {
                        setActiveProposalGroupIndex(index);
                        setIsShowingForm(true);
                      }}
                    />
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>

            <Box
              onClick={() => setIsShowingForm(true)}
              sx={{
                marginTop: "4px",
                py: 0.5,
                px: 2,
                borderBottom: `1px solid`,
                borderBottomColor: "divider",
                "&:hover": {
                  boxShadow:
                    "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
                  backgroundColor: COLORS.ICE_BLUE,
                },
              }}
            >
              <Button
                startIcon={<Add color="primary" />}
                onClick={() => setIsShowingForm(true)}
              >
                Proposal
              </Button>
            </Box>
          </>
        )}
      </DragDropContext>
    </>
  );
}

// child components

type ProposalGroupProps = {
  groupedProposal: GroupedProposalWithCusip;
  index: number;
  onSetGroupEditing: () => void;
  handleGroupDeleteReorder: (index: number) => Promise<void>;
  handleProposalDeleteReorder: (
    index: number,
    proposalId: number
  ) => Promise<void>;
};

type ProposalGroupParams = {
  id: string;
};

function ProposalGroup({
  groupedProposal,
  index,
  onSetGroupEditing,
  handleGroupDeleteReorder,
  handleProposalDeleteReorder,
}: ProposalGroupProps) {
  const { id } = useParams() as ProposalGroupParams;
  const { mutate } = useProxy(id);
  const [isShowingActions, setIsShowingActions] = React.useState(false);

  function handleGroupDeleteClick(proposalIds: number[]) {
    Promise.all(
      proposalIds.map((proposalId) => deleteVoteProposal(String(proposalId)))
    )
      .then(() => {
        mutate();
        handleGroupDeleteReorder(index);
      })
      .catch(() => console.error("Could not delete this proposal group."));
  }

  function handleProposalDeleteClick(proposalId: number) {
    deleteVoteProposal(String(proposalId))
      .then(() => {
        mutate();
        handleProposalDeleteReorder(index, proposalId);
      })
      .catch(() => console.error("Could not delete proposal."));
  }

  // Create string[] of selected cusips
  const cusipis = groupedProposal.securities.map((security) => security.cusip);

  return (
    <Draggable draggableId={String(groupedProposal.groupNumber)} index={index}>
      {(provided) => (
        <Accordion
          square={true}
          ref={provided.innerRef}
          {...(provided.draggableProps as unknown)}
          {...(provided.dragHandleProps as unknown)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            onMouseEnter={() => setIsShowingActions(true)}
            onMouseLeave={() => setIsShowingActions(false)}
            sx={{
              position: "relative",
              px: 3,
            }}
          >
            <Grid
              container={true}
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
              sx={{
                marginRight: "10px",
              }}
            >
              <DragIndicator
                sx={{
                  position: "absolute",
                  left: 0,
                  color: "#C4CAC8",
                }}
              />
              <Grid
                item={true}
                xs={5}
                sx={{
                  marginLeft: "10px",
                  color: "#121212",
                  fontSize: "12px",
                }}
              >
                <Tooltip arrow={true} title={groupedProposal.title}>
                  <Box
                    component="p"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {groupedProposal.title}
                  </Box>
                </Tooltip>
              </Grid>
              <Grid
                item={true}
                xs={"auto"}
                sx={{
                  color: "#121212",
                  fontSize: "12px",
                }}
              >
                {groupedProposal.proposals.length}
              </Grid>
              <Grid
                item={true}
                xs={2}
                sx={{
                  color: "#121212",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
              >
                <Tooltip
                  arrow={true}
                  title={formatVoteChoices(
                    groupedProposal.voteType,
                    groupedProposal.type
                  )}
                >
                  <Box
                    component="p"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {formatVoteChoices(
                      groupedProposal.voteType,
                      groupedProposal.type
                    )}
                  </Box>
                </Tooltip>
              </Grid>
              <Grid
                item={true}
                xs={2}
                sx={{
                  color: "#121212",
                  fontSize: "12px",
                }}
              >
                <CusipTagList cusips={cusipis} />
              </Grid>
              <Grid
                item={true}
                xs={"auto"}
                sx={{
                  color: "#121212",
                  fontSize: "12px",
                }}
              >
                <Tooltip
                  arrow={true}
                  title={
                    groupedProposal.routineness === "routine"
                      ? "Routine"
                      : "Non-Routine"
                  }
                >
                  <Box
                    component="p"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {groupedProposal.routineness === "routine"
                      ? "Routine"
                      : "Non-Routine"}
                  </Box>
                </Tooltip>
              </Grid>

              <Grid
                item={true}
                xs={"auto"}
                sx={{
                  color: "#121212",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
              >
                {groupedProposal.recommendationType}
              </Grid>
              {isShowingActions && (
                <ProposalGroupActionButtons
                  onEditClick={onSetGroupEditing}
                  onDeleteClick={() =>
                    handleGroupDeleteClick(
                      groupedProposal.proposals.map((p) => p.id)
                    )
                  }
                />
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              padding: 0,
            }}
          >
            <Droppable
              droppableId={String(groupedProposal.groupNumber)}
              type={String(groupedProposal.groupNumber)}
            >
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{
                    width: "100%",
                  }}
                >
                  {groupedProposal.proposals.map((proposal, index) => (
                    <Proposal
                      key={proposal.id}
                      proposal={proposal}
                      index={index}
                      voteChoices={groupedProposal.voteChoices}
                      isDraggable={groupedProposal.proposals.length > 1}
                      onDeleteClick={() =>
                        handleProposalDeleteClick(proposal.id)
                      }
                    />
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
}

type ProposalGroupActionButtonsProps = {
  onEditClick: () => void;
  onDeleteClick: () => void;
};

function ProposalGroupActionButtons({
  onEditClick,
  onDeleteClick,
}: ProposalGroupActionButtonsProps) {
  return (
    <Card
      elevation={2}
      sx={{
        background: "white",
        position: "absolute",
        zIndex: 1,
        right: "36px",
        padding: "0 4px",
      }}
    >
      <IconButton
        aria-label="edit"
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          onEditClick();
        }}
      >
        <Edit
          sx={{
            color: "#C4CAC8",
          }}
        />
      </IconButton>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          onDeleteClick();
        }}
      >
        <Delete
          sx={{
            color: "#C4CAC8",
          }}
        />
      </IconButton>
    </Card>
  );
}

type ProposalProps = {
  proposal: Proposal;
  index: number;
  voteChoices: string[];
  isDraggable?: boolean;
  onDeleteClick: () => void;
};

function Proposal({
  proposal,
  index,
  voteChoices,
  isDraggable = true,
  onDeleteClick,
}: ProposalProps) {
  const initialValue = proposal.tooltipText
    ? proposal.tooltipText
    : [
        {
          children: [{ text: "" }],
        },
      ];
  const [value, setValue] = React.useState<Node[]>(initialValue);
  const [isShowingActions, setIsShowingActions] = React.useState(false);
  const editor = React.useMemo(
    () => withHistory(withReact(createEditor())),
    []
  );
  const renderElement = React.useCallback(
    (props) => <Element {...props} />,
    []
  );
  const renderLeaf = React.useCallback((props) => <Leaf {...props} />, []);

  return (
    <Draggable
      draggableId={String(proposal.id)}
      index={index}
      isDragDisabled={!isDraggable}
    >
      {(provided) => (
        <Accordion
          square={true}
          ref={provided.innerRef}
          {...(provided.draggableProps as unknown)}
          {...(provided.dragHandleProps as unknown)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              position: "relative",
              px: 3,
            }}
            onMouseEnter={() => setIsShowingActions(true)}
            onMouseLeave={() => setIsShowingActions(false)}
          >
            <Grid spacing={3} container={true}>
              <Grid
                item={true}
                xs={11}
                alignItems="center"
                justifyContent="center"
                sx={{ display: "inline-flex" }}
              >
                {isDraggable ? (
                  <DragIndicator
                    sx={{
                      color: "#C4CAC8",
                    }}
                  />
                ) : null}
                <Box component="p" sx={{ fontSize: "14px" }}>
                  {proposal.directorName || proposal.details}
                </Box>
                {isShowingActions ? (
                  <Card
                    elevation={2}
                    sx={{
                      background: "white",
                      position: "absolute",
                      zIndex: 1,
                      right: "36px",
                      padding: "0 4px",
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        onDeleteClick();
                      }}
                    >
                      <Delete
                        sx={{
                          color: "#C4CAC8",
                        }}
                      />
                    </IconButton>
                  </Card>
                ) : null}
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            {proposal.tooltipText ? (
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  width: "100%",
                  borderTop: `1px solid`,
                  borderTopColor: "divider",
                }}
              >
                <Slate
                  editor={editor}
                  value={value}
                  onChange={(value) => setValue(value)}
                >
                  <Editable
                    style={{
                      color: "#787878",
                      fontSize: "12px",
                    }}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    readOnly={true}
                  />
                </Slate>
              </Box>
            ) : null}

            <Box
              sx={{
                py: 2,
                px: 3,
                width: "100%",
                borderTop: `1px solid`,
                borderTopColor: "divider",
              }}
            >
              <VoteChoicesPreview voteChoices={voteChoices} />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Element = ({ attributes, children, element }: any): JSX.Element => {
  switch (element.type) {
    case "bulleted-list":
      return (
        <Box
          component="ul"
          sx={{
            listStyle: "disc",
            margin: "initial",
            padding: "initial",
          }}
          {...attributes}
        >
          {children}
        </Box>
      );
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return (
        <Box
          component="ol"
          sx={{
            listStyle: "decimal",
            margin: "initial",
            padding: "initial",
          }}
          {...attributes}
        >
          {children}
        </Box>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Leaf = ({ attributes, children, leaf }: any): JSX.Element => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

type VoteChoicesPreviewProps = {
  voteChoices: string[];
};

function VoteChoicesPreview({ voteChoices }: VoteChoicesPreviewProps) {
  return (
    <FormControl component="fieldset" fullWidth={true}>
      <RadioGroup
        aria-label="choices"
        name="choices"
        row={true}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {voteChoices.map((voteChoice) => (
          <FormControlLabel
            key={voteChoice}
            value="disabled"
            disabled={true}
            control={<Radio size="small" />}
            label={voteChoice}
            sx={{
              [`& .${formControlLabelClasses["label"]}`]: {
                textTransform: "capitalize",
                fontSize: "14px",
              },
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

// utils

function sortProposals(groupedProposals: GroupedProposal[]) {
  const sorted = groupedProposals
    .sort((a, b) => a.groupNumber - b.groupNumber)
    .map((groupedProposal) => ({
      ...groupedProposal,
      proposals: groupedProposal.proposals.sort(
        (a, b) => a.proposalNumber - b.proposalNumber
      ),
    }));

  return sorted;
}

function groupVoteProposals(voteProposals: VoteProposal[]) {
  const grouped = voteProposals.reduce((acc, voteProposal) => {
    const {
      groupNumber,
      isRoutine,
      recommendationType,
      voteChoices,
      title,
      voteType,
      type,
      id,
      proposalNumber,
      details,
      directorName,
      tooltipText,
      securities,
    } = voteProposal;

    const foundGroupedProposal = acc.find(
      (vp: GroupedProposal) => vp.groupNumber === groupNumber
    );

    if (foundGroupedProposal) {
      const newAcc = acc.map((a) => {
        if (a.groupNumber === groupNumber) {
          return {
            ...a,
            proposals: [
              ...a.proposals,
              {
                id,
                proposalNumber,
                tooltipText,
                details,
                directorName,
              },
            ],
          };
        }

        return a;
      });

      return newAcc;
    } else {
      acc.push({
        groupNumber,
        routineness: isRoutine ? "routine" : "nonRoutine",
        recommendationType,
        voteChoices,
        title,
        securities,
        voteType,
        type,
        proposals: [
          {
            id,
            proposalNumber,
            tooltipText,
            details,
            directorName,
          },
        ],
      });
    }

    return acc;
  }, [] as GroupedProposal[]);

  return grouped;
}

function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function formatVoteChoices(voteType: VoteType, proposalType: ProposalType) {
  if (proposalType === "SayOnPay") {
    return "One Year, Two Years, Three Years, Four Years";
  }

  switch (voteType) {
    case "election_majority":
      return "For, Against, Abstain";
    case "election_majority_forabstain":
      return "For, Abstain";
    case "election_majority_foragainst":
      return "For, Against";
    case "election_majority_foragainstwithhold":
      return "For, Against, Withhold";
    case "election_majority_forwithholdabstain":
      return "For, Withhold, Abstain";
    case "election_majority_yesno":
      return "Yes, No";
    case "election_plurality":
      return "For, Withhold";
    default:
      return "None";
  }
}

export { BallotDetails };
