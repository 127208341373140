import { useBrokers } from "src/api/adapters/proxy/hooks/useBrokers";
import { Box } from "src/components/atoms/Box";
import Thumbnail from "./Thumbnail";

interface Props {
  filingId: string;
  onClick: (slug: string) => void;
  selectedBroker: string;
}

function ThumbnailList({
  filingId,
  onClick,
  selectedBroker,
}: Props): JSX.Element | null {
  const { brokers, isLoading } = useBrokers(filingId);

  if (isLoading || !brokers) {
    return null;
  }

  return (
    <Box
      sx={{
        background: "#fff",
        gridArea: "thumbnailList",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        overflow: "scroll",
        height: "calc(100vh - 13rem)",
      }}
    >
      {brokers.map((broker) => (
        <Thumbnail
          key={broker.id}
          logo={broker.logo}
          name={broker.name}
          slug={broker.slug}
          filingId={filingId}
          onClick={onClick}
          selected={selectedBroker === broker.slug}
        />
      ))}
    </Box>
  );
}

export { ThumbnailList };
