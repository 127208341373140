import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useProspectusPackage } from "../../api/adapters/nexus/hooks/useProspectusPackage";
import { ReactComponent as IssuerIcon } from "src/assets/icons/issuer-icon.svg";
import { PageWrapper } from "../../components/PageWrapper";
import { LoadingState } from "../../components/LoadingState";
import { ErrorCard } from "../../components/ErrorCard";
import { Box } from "../../components/atoms/Box";
import { Button } from "../../components/atoms/Button";
import { ApproveProspectusDialog } from "../../components/ApproveProspectusDialog";
import { ProspectusAccordion } from "../../components/ProspectusAccordion";
import { CreateProspectusPackageDialog } from "../../components/CreateProspectusPackageDialog";
import { DismissProspectusPackageDialog } from "../../components/DismissProspectusPackageDialog";
import { PreviewPane } from "../../components/PreviewPane";
import { previewItemsFromProspectusPackages } from "../../components/PreviewPane/utils";
import { PreviewItem } from "../../components/PreviewPane/PreviewPane";
import { DisplayType } from "../Proxy/types";
import { PageHeader } from "src/components/molecules/PageHeader";
import { SplitPane } from "src/components/molecules/SplitPane";
import { ProspectusDocument } from "src/api/adapters/nexus/types";

type ProspectusDetailsParams = {
  id: string;
};

export function ProspectusDetails(): JSX.Element | null {
  const { id } = useParams() as ProspectusDetailsParams;
  const navigate = useNavigate();
  const { isError, isLoading, mutate, prospectus } = useProspectusPackage(id);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isProspectusCreationDialogOpen, setIsProspectusCreationDialogOpen] =
    useState(false);
  const [isDismissDialogOpen, setIsDismissDialogOpen] = useState(false);
  const [currentPreview, setCurrentPreview] = useState<PreviewItem | null>(
    null
  );

  if (isLoading) {
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );
  }

  if (isError || !prospectus) {
    return (
      <PageWrapper>
        <ErrorCard
          title="Unable to load this trade alert."
          body="Something went wrong when attempting to load this trade alert. Please contact support for help with this issue."
        />
      </PageWrapper>
    );
  }

  const handleOpenCreationDialog = () => {
    setIsProspectusCreationDialogOpen(true);
  };

  const handleOpenDismissDialog = () => {
    setIsDismissDialogOpen(true);
  };

  const handleOpenApproveDialog = () => {
    setIsApproveDialogOpen(true);
  };

  const handleCloseDismissDialog = () => {
    setIsDismissDialogOpen(false);
  };

  const handleCloseCreationDialog = () => {
    setIsProspectusCreationDialogOpen(false);
  };

  const handleCloseApproveDialog = () => {
    setIsApproveDialogOpen(false);
  };

  const handleCreationSuccess = (newPackageId: string) => {
    mutate();
    navigate(`/prospectuses/${newPackageId}`);
  };

  const handleDocumentPreviewClick = (doc: ProspectusDocument) => {
    setCurrentPreview({
      id: doc.id,
      name: doc.name,
      preview: doc.url,
      type: DisplayType.Document,
    });
  };

  const { expectedEndDate, status } = prospectus;
  const isExpired = new Date(expectedEndDate + "T00:00:00") < new Date();
  const previewOptions = previewItemsFromProspectusPackages([prospectus]);

  const renderPrimaryPane = () => {
    return (
      <Box id="TradeAlertDetailsFormTab">
        <Box
          sx={{
            mb: 4,
            mx: -8,
            px: 8,
            alignItems: "center",
            display: "flex",
            borderBottom: "1px solid #e5e5e5",
            height: "48px",
          }}
        >
          <Box component="b" sx={{ flexGrow: 1 }}>
            Prospectus Packages
          </Box>
          {status !== "approved" && !isExpired && (
            <Button
              color="primary"
              onClick={handleOpenApproveDialog}
              size="small"
              variant="contained"
            >
              Approve
            </Button>
          )}
          {status === "approved" && (
            <>
              <Button
                color="delete"
                onClick={handleOpenDismissDialog}
                size="small"
                sx={{ mr: 2 }}
                variant="outlined"
              >
                Dismiss
              </Button>
              <Button
                color="primary"
                onClick={handleOpenCreationDialog}
                size="small"
                variant="contained"
              >
                Create
              </Button>
            </>
          )}
        </Box>
        <ProspectusAccordion
          isExpanded={true}
          onDocumentPreviewClick={handleDocumentPreviewClick}
          onProspectusPackageUpdate={mutate}
          prospectusPackages={[prospectus]}
        />
      </Box>
    );
  };

  const renderSecondaryPane = () => {
    return (
      <PreviewPane currentPreview={currentPreview} options={previewOptions} />
    );
  };

  return (
    <>
      <PageHeader
        icon={<IssuerIcon />}
        title={prospectus?.security.issuerName || ""}
      />
      <SplitPane
        primaryPane={renderPrimaryPane()}
        primarySx={{ px: 8 }}
        secondaryPane={renderSecondaryPane()}
        secondarySx={{
          display: "flex",
          px: 8,
          py: 4,
        }}
      />
      <ApproveProspectusDialog
        isOpen={isApproveDialogOpen}
        onClose={handleCloseApproveDialog}
        onUpdate={mutate}
        prospectusPackage={prospectus}
      />
      <CreateProspectusPackageDialog
        isOpen={isProspectusCreationDialogOpen}
        onClose={handleCloseCreationDialog}
        onSuccess={handleCreationSuccess}
        securityOptions={[
          {
            id: prospectus.security.id,
            cusip: String(prospectus.security.cusip),
          },
        ]}
      />
      <DismissProspectusPackageDialog
        isOpen={isDismissDialogOpen}
        onClose={handleCloseDismissDialog}
        onUpdate={mutate}
        prospectusPackage={prospectus}
      />
    </>
  );
}
