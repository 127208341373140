import { AxiosRequestConfig } from "axios";
import useSWR from "swr";

import { PaginatedResponse } from "src/types/PaginatedResponse";
import { nexus } from "..";
import { IQueryParams } from "../../types";
import { Solicitor } from "src/types/voteTransmission";

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) =>
  nexus.get<PaginatedResponse<Solicitor>>(url, { params });

export function useSolicitors(isNoboEligible?: boolean) {
  const url = `/solicitors/${isNoboEligible ? "?is_nobo_eligible=True" : ""}`;
  const { data, error, mutate } = useSWR(url, fetcher);
  return {
    count: data?.data?.count,
    isError: error,
    isLoading: !error && !data,
    mutate,
    solicitors: data?.data?.results,
  };
}
