import useSWR from "swr";
import { AxiosRequestConfig } from "axios";

import { PaginatedResponse } from "../../../../types/PaginatedResponse";
import { IQueryParams } from "../../types";
import { nexus } from "..";
import { SecurityProspectusPackage } from "../types";

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) => {
  return nexus.get<PaginatedResponse<SecurityProspectusPackage>>(url, {
    params,
  });
};

export function useUpcomingProspectuses({
  limit,
  ordering,
  offset,
}: IQueryParams) {
  const url = `security-prospectus-packages/upcoming/?limit=${limit}&ordering=${ordering}&offset=${offset}`;
  const { data, error } = useSWR(url, fetcher);

  return {
    count: data?.data?.count,
    isError: error,
    isLoading: !error && !data,
    prospectuses: data?.data?.results,
  };
}
