import { COLORS, TextField } from "@asayinc/component-library";
import React, { ChangeEvent, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Checkbox,
  Link,
  Tooltip,
  Box,
} from "@mui/material";

import { SourceType } from "../../types";
import { EdgarDateTableCell } from "../../EdgarDateTableCell";
import { formatDate } from "../../../../utils/format-date";
import { FilingListFiling } from "../../types";
import { formatCamelCaseString } from "../../../../utils/format-camel-case";
import {
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from "../../batch-filings-context";
import { updateFiling } from "../../../../api/adapters";
import { TradeAlertIndicator } from "../../TradeAlertIndicator";
import { CusipTagList } from "src/components/CusipTagList";
import {
  tableBodyCell,
  tableBodyCellCheckbox,
  tableBodyRow,
} from "../../styles/tableStyles";

interface ReadyTableRowProps {
  filing: FilingListFiling;
  hasTradeAlert: boolean;
  onSetSnackbar: (message?: React.ReactNode) => void;
}

const ReadyTableRow = ({
  filing,
  hasTradeAlert,
  onSetSnackbar,
}: ReadyTableRowProps) => {
  const navigate = useNavigate();
  const batchFilings = useBatchFilingsState();
  const dispatch = useBatchFilingsDispatch();

  const [notes, setNotes] = useState<string | null>(filing.notes);
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const getMeetingDate = (filing: FilingListFiling) => {
    switch (filing.type) {
      case "MeetingContest":
      case "FundMeeting":
      case "FirmMeeting":
        return filing.meetingDate
          ? formatDate(new Date(filing.meetingDate))
          : "";
      default:
        return "N/A";
    }
  };

  const getAreMaterialsFiled = (filing: FilingListFiling): "Yes" | "No" => {
    if (
      filing.referenceMaterials.some(
        (referenceMaterial) =>
          referenceMaterial.sourceType === ("materials_email" as SourceType)
      )
    ) {
      return "Yes";
    }

    if (filing.edgarFilings.length) {
      return "Yes";
    }

    return "No";
  };

  const handleSelect = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    if (event.target.checked) {
      dispatch({ type: "SELECT_FILING", filing: id, path: location.pathname });
    } else {
      dispatch({ type: "DESELECT_FILING", filing: id });
    }
  };

  const handleTableRowClick = (id: number) => {
    navigate(`/proxy/${id}`);
  };

  const handleChangeNotes = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setNotes(event.target.value);
  };

  const handleSaveNotes = async () => {
    if (isDirty) {
      try {
        const { data } = await updateFiling(String(filing.id), { notes });
        setNotes(data.notes);
        onSetSnackbar("This filing has been saved. 🎉");
        setError("");
        setIsDirty(false);
      } catch (error) {
        setError(
          "An error occurred when trying to update the notes for this filing."
        );
      }
    }
  };

  return (
    <TableRow
      selected={batchFilings.filings.includes(filing.id)}
      onClick={() => handleTableRowClick(filing.id)}
      hover={true}
      sx={tableBodyRow}
    >
      <TableCell
        sx={{
          ...tableBodyCellCheckbox,
          position: "sticky",
          left: 0,
          zIndex: 20,
          overflow: "visible",
          background: "white",
        }}
        id="checkboxContainer"
      >
        <Checkbox
          onChange={(event) => handleSelect(event, filing.id)}
          checked={batchFilings.filings.includes(filing.id)}
          onClick={(event) => event.stopPropagation()}
        />
      </TableCell>
      <TableCell
        sx={{
          ...tableBodyCell,
          position: "sticky",
          borderRight: `1px solid`,
          borderRightColor: "divider",
        }}
      >
        <Tooltip arrow title={filing.issuer.companyName}>
          <Link
            to={`/issuers/${filing.issuer.globalIssuerId}`}
            component={RouterLink}
            underline="hover"
          >
            {filing.issuer.companyName}
          </Link>
        </Tooltip>
      </TableCell>
      <TableCell sx={tableBodyCell}>
        <CusipTagList
          cusips={filing.securities.map((security) => security.cusip)}
        />
      </TableCell>
      <TableCell sx={tableBodyCell}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {hasTradeAlert && <TradeAlertIndicator />}
          {formatCamelCaseString(filing.type)}
        </Box>
      </TableCell>
      <TableCell sx={tableBodyCell}>
        {filing.edgarFilings && filing.edgarFilings[0] && (
          <EdgarDateTableCell
            edgarFilings={filing.edgarFilings}
            issuer={filing.issuer}
          />
        )}
      </TableCell>
      <TableCell sx={tableBodyCell}>
        {filing.recordDate ? formatDate(new Date(filing.recordDate)) : ""}
      </TableCell>
      <TableCell sx={tableBodyCell}>
        {filing.stats && filing.stats.estimatedDigitalMaterialsExpectedDate
          ? formatDate(
              new Date(filing.stats.estimatedDigitalMaterialsExpectedDate)
            )
          : ""}
      </TableCell>
      <TableCell sx={tableBodyCell}>
        {filing.solicitor && (
          <Link
            href={
              filing.solicitor?.name?.includes("Broadridge")
                ? "http://myservice.broadridge.com"
                : "https://app.frontapp.com/inboxes/teams/folders/780739"
            }
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            {filing.solicitor?.name}
          </Link>
        )}
      </TableCell>
      <TableCell sx={tableBodyCell}>{getMeetingDate(filing)}</TableCell>
      <TableCell sx={tableBodyCell}>{getAreMaterialsFiled(filing)}</TableCell>
      {focused ? (
        <TableCell
          align="right"
          sx={{
            ...tableBodyCell,
            position: "relative",
            overflow: "visible !important",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setFocused(true);
          }}
          onBlur={() => {
            handleSaveNotes();
            setFocused(false);
          }}
        >
          <TextField
            error={Boolean(error)}
            inputSize="small"
            multiline
            variant="outlined"
            placeholder="Notes about this filing..."
            value={notes}
            fullWidth={true}
            onChange={handleChangeNotes}
            helperText={error}
            autoFocus
            id="filingNotes"
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              width: "236px",
              position: "absolute",
              top: 10,
              right: -10,
              zIndex: 100,
              bgcolor: "white",
              boxShadow:
                "0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14)",
              borderRadius: "4px",
              marginRight: "16px",
            }}
            inputProps={{ style: { fontSize: "12px" } }}
          />
        </TableCell>
      ) : (
        <TableCell
          sx={{ ...tableBodyCell, width: "236px !important" }}
          align="right"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setFocused(true);
          }}
        >
          <Box
            sx={{
              ":hover": { background: COLORS.ICE_BLUE },
              margin: "1px 13px 1px 11px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "200px",
            }}
          >
            {notes}
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

export { ReadyTableRow };
