type StatusLabelProps = {
  status: string;
};

const StatusLabel = ({ status }: StatusLabelProps): JSX.Element => {
  if (isClosed(status)) {
    return (
      <div className={`${commonClassNames} bg-light-gray`} style={commonStyle}>
        Closed
      </div>
    );
  } else {
    return (
      <div className={`bg-periwinkle ${commonClassNames}`} style={commonStyle}>
        Open
      </div>
    );
  }
};

const isClosed = (status: string) =>
  status === "closed" || status === "approved";

const commonClassNames =
  "h-4 rounded text-xs font-medium text-primary-text inline-flex px-2 items-center justify-center uppercase";
const commonStyle = { width: "max-content" };

export { StatusLabel };
