import { AxiosRequestConfig } from "axios";
import useSWR from "swr";
import { nexus } from "..";
import { PaginatedResponse } from "../../../../types/PaginatedResponse";
import { IQueryParams } from "../../types";
import { SecurityProspectusPackage } from "../types";

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) => {
  return nexus.get<PaginatedResponse<SecurityProspectusPackage>>(url, {
    params,
  });
};

export function useTradeAlertsWithSecurityIds(
  type: string,
  securityIds: string[] = [],
  { limit, offset, ordering }: IQueryParams
) {
  let url = `security-prospectus-packages/${type}/?`;
  const params = new URLSearchParams();
  params.set("limit", String(limit));
  params.set("offset", String(offset));
  params.set("ordering", ordering);

  if (securityIds.length > 0) {
    params.set("security_ids", securityIds.join(","));
  }
  url = url + params.toString();

  //TODO: (David) Need to figure out what to do when we don't want security ids
  const { data, error } = useSWR(securityIds.length > 0 ? url : null, fetcher);

  return {
    count: data?.data?.count,
    isError: error,
    isLoading: !error && !data,
    tradeAlerts: data?.data?.results,
  };
}
