import { v4 as uuid } from "uuid";
import { useState } from "react";
import { format } from "date-fns";

import {
  createProspectusPackage,
  uploadProspectusDocuments,
} from "../../api/adapters";
import { ReactComponent as TrashIcon } from "src/assets/icons/trash.svg";
import { ReactComponent as FileIcon } from "src/assets/icons/file.svg";
import { Box } from "../atoms/Box";
import { DatePicker } from "../atoms/DatePicker";
import { Dialog } from "../atoms/dialog/Dialog";
import { Label } from "../Label";
import { Dropzone } from "../atoms/Dropzone";
import { Autocomplete } from "../atoms/Autocomplete";
import { toast } from "react-toastify";

type Attachment = {
  id: string;
  name: string;
  type: string;
  effectiveDate: Date;
  attachment: File;
};

type SecurityOption = {
  id: string;
  cusip: string;
};

interface ICreateProspectusPackageDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (newPackageId: string) => void;
  securityOptions: SecurityOption[];
}

export function CreateProspectusPackageDialog({
  isOpen,
  onClose,
  onSuccess,
  securityOptions,
}: ICreateProspectusPackageDialogProps) {
  const [createPackageForm, setCreatePackageForm] = useState<{
    effectiveStartDate: Date;
    securities: SecurityOption[];
  }>({
    effectiveStartDate: new Date(),
    securities: securityOptions.length === 1 ? securityOptions : [],
  });
  const [prospectusDocument, setProspectusDocument] =
    useState<Attachment | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleCreatePackage = async () => {
    const formattedStartDate = format(
      createPackageForm.effectiveStartDate,
      "yyyy-MM-dd"
    );
    const newPackageRequests = createPackageForm.securities.map((security) => {
      return createProspectusPackage({
        effectiveStartDate: formattedStartDate,
        securityId: security.id,
      });
    });
    setIsSubmitting(true);
    try {
      const newPackages = await Promise.all(newPackageRequests);
      if (prospectusDocument) {
        const { attachment, name, type } = prospectusDocument;
        const formData = new FormData();
        formData.append("attachment", attachment, name);
        formData.append("name", name);
        formData.append("type", type);
        formData.append("effective_date", formattedStartDate);
        const uploadDocumentRequests = newPackages.map((pack) => {
          return uploadProspectusDocuments(pack.data.id, formData);
        });
        try {
          await Promise.all(uploadDocumentRequests);
        } catch (e) {
          toast.error(
            "Prospectus package has been created, but there was an error when uploading documents. Please try uploading the documents again."
          );
        } finally {
          onClose();
        }
      }
      onSuccess(newPackages[0].data.id);
    } catch (e) {
      toast.error(
        "An error has occured when trying to create the prospectus package. Please try creating the prospectus package again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeEffectiveDate = (newDate: Date) => {
    setCreatePackageForm({
      ...createPackageForm,
      effectiveStartDate: newDate,
    });
  };

  const handleDocumentDrop = (files: File[]) => {
    setProspectusDocument({
      id: uuid(),
      type: "summary",
      name: "Summary Prospectus",
      effectiveDate: new Date(),
      attachment: files[0],
    });
  };

  const handleSecuritiesChange = (
    securities: SecurityOption[] | SecurityOption | null
  ) => {
    if (securities && Array.isArray(securities)) {
      setCreatePackageForm({
        ...createPackageForm,
        securities: securities,
      });
    }
  };

  return (
    <Dialog
      isPrimaryDisabled={
        createPackageForm.effectiveStartDate === null ||
        createPackageForm.securities.length === 0 ||
        isSubmitting
      }
      isPrimaryLoading={isSubmitting}
      isSecondaryDisabled={isSubmitting}
      onPrimaryClick={handleCreatePackage}
      fullWidth
      primaryButtonText="Create"
      onClose={onClose}
      onSecondaryClick={onClose}
      open={isOpen}
      secondaryButtonText="Cancel"
      title="Create Prospectus Package"
    >
      <form
        className="w-full"
        style={{ height: "300px" }}
        id="create-prospectus-package"
      >
        <Box sx={{ display: "flex", flexDirection: "column", my: 4 }}>
          <Label>Effective Date</Label>
          <DatePicker
            name="effectiveDate"
            onChange={handleChangeEffectiveDate}
            required={true}
            selected={createPackageForm.effectiveStartDate}
          />
        </Box>
        {securityOptions.length !== 1 && (
          <Box sx={{ display: "flex", flexDirection: "column", my: 4 }}>
            <Label>CUSIPS</Label>
            <Autocomplete
              placeholder="CUSIPS"
              multiple={true}
              onChange={handleSecuritiesChange}
              options={securityOptions}
              optionLabelPath="cusip"
              optionValuePath="id"
              value={createPackageForm.securities}
            />
          </Box>
        )}
        {prospectusDocument && (
          <Box
            sx={{
              borderTop: "1px solid #e5e5e5",
              borderLeft: "1px solid #e5e5e5",
              borderRight: "1px solid #e5e5e5",
              borderBottom: "1px solid #e5e5e5",
              padding: "16px",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "justify-between",
              }}
            >
              <span className="text-sm flex items-center flex-grow">
                <FileIcon className="mr-2" />
                {prospectusDocument.name}
              </span>
              <button onClick={() => setProspectusDocument(null)} type="button">
                <TrashIcon />
              </button>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
              <Label>Doc Type</Label>
              <select
                className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
                name="type"
                value={prospectusDocument.type}
                onChange={(event) => {
                  const type = event.currentTarget.value;
                  setProspectusDocument({
                    ...prospectusDocument,
                    name:
                      type === "summary"
                        ? "Summary Prospectus"
                        : "Statutory Prospectus",
                    type: event.currentTarget.value,
                  });
                }}
              >
                <option value="summary">Summary Prospectus</option>
                <option value="statutory">Statutory Prospectus</option>
              </select>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
              <Label>Doc Name</Label>
              <input
                type="text"
                name="name"
                className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
                required={true}
                onChange={(event) => {
                  setProspectusDocument({
                    ...prospectusDocument,
                    name: event.currentTarget.value,
                  });
                }}
                value={prospectusDocument.name}
              />
            </Box>
          </Box>
        )}
        {!prospectusDocument && (
          <Dropzone multiple={false} onDrop={handleDocumentDrop} />
        )}
      </form>
    </Dialog>
  );
}
