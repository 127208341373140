import useSWR from "swr";
import { proxy } from "..";
import { Broker } from "./useCorporateActionBrokers";

const fetcher = (url: string) => {
  return proxy.get(url);
};

export function useEmailPreview(id: string, selectedBroker: Broker | null) {
  const url = selectedBroker
    ? `/admin/vca/${id}/preview/${selectedBroker.slug}/`
    : null;
  const { data, error, mutate } = useSWR(url, fetcher);

  return {
    isError: error,
    isLoading: !data && !error,
    preview: data?.data,
    mutate,
  };
}
