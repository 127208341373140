import { createContext, ReactNode, useReducer, useContext } from "react";
import {
  Link as RouterLink,
  NavLinkProps,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Link } from "@mui/material";

type Action =
  | { type: "CHECK_LOCATION"; isProxy: boolean }
  | { type: "INITIALIZE_PROXY" }
  | { type: "MARK_DIRTY" }
  | { type: "OPEN_DIALOG"; handlers: Handlers }
  | { type: "CONFIRM_LEAVE" }
  | { type: "CANCEL_STAY" }
  | { type: "RESET" };

type Dispatch = (action: Action) => void;

interface Handlers {
  resolve: (value?: unknown | PromiseLike<unknown>) => void;
  reject: () => void;
}

interface State {
  isProxy: boolean;
  isDirty: boolean;
  isDialogOpen: boolean;
  handlers: Handlers | null;
}

interface ProxyWarningProviderProps {
  children: ReactNode;
}

const initialState = {
  isProxy: false,
  isDirty: false,
  isDialogOpen: false,
  handlers: null,
};

const ProxyWarningStateContext = createContext<State | undefined>(undefined);
const ProxyWarningDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

function proxyWarningReducer(state: State, action: Action) {
  switch (action.type) {
    case "CHECK_LOCATION":
      return { ...state, isProxy: action.isProxy };
    case "INITIALIZE_PROXY":
      return { ...state, isProxy: true, isDirty: false };
    case "MARK_DIRTY":
      return { ...state, isDirty: true };
    case "OPEN_DIALOG":
      return { ...state, isDialogOpen: true, handlers: action.handlers };
    case "CONFIRM_LEAVE":
      return { ...state, isDialogOpen: false, isDirty: false };
    case "CANCEL_STAY":
      return { ...state, isDialogOpen: false };
    case "RESET":
      return initialState;
  }
}

function ProxyWarningProvider({
  children,
}: ProxyWarningProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(proxyWarningReducer, initialState);

  return (
    <ProxyWarningStateContext.Provider value={state}>
      <ProxyWarningDispatchContext.Provider value={dispatch}>
        {children}
      </ProxyWarningDispatchContext.Provider>
    </ProxyWarningStateContext.Provider>
  );
}

function useProxyWarningState(): State {
  const context = useContext(ProxyWarningStateContext);
  if (context === undefined) {
    throw new Error(
      "useProxyWarningState must be used within a ProxyWarningProvider"
    );
  }
  return context;
}

function useProxyWarningDispatch(): Dispatch {
  const context = useContext(ProxyWarningDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useProxyWarningDispatch must be used within a ProxyWarningProvider"
    );
  }
  return context;
}

const checkLocation = (): boolean => {
  const location = useLocation();
  const urlParts = location.pathname.split("/");
  while (urlParts[urlParts.length - 1] === "") {
    // for urls ending in slash, eliminate final str
    urlParts.pop();
  }
  function isNumeric(val: string) {
    return Number(parseFloat(val)).toString() === val;
  }
  // inelegant but returns true if route is
  //    /proxy/(numeric filing id)
  //    /proxy/create
  //    /proxy/(numeric filing id)/email
  const last = urlParts[urlParts.length - 1];
  const secondLast = urlParts[urlParts.length - 2];
  const thirdLast = urlParts[urlParts.length - 3];

  if (secondLast === "proxy" && (isNumeric(last) || last === "create")) {
    return true;
  } else if (
    thirdLast === "proxy" &&
    isNumeric(secondLast) &&
    last === "email"
  ) {
    return true;
  } else return false;
};

// Navigate
async function navigateAway(
  state: State,
  dispatch: Dispatch,
  navigate: () => void
): Promise<void> {
  if (state.isDirty && state.isProxy) {
    const userAction = new Promise((resolve, reject) =>
      dispatch({
        type: "OPEN_DIALOG",
        handlers: { resolve, reject },
      })
    );
    try {
      await userAction;
      dispatch({ type: "CONFIRM_LEAVE" });
    } catch (_) {
      dispatch({ type: "CANCEL_STAY" });
      return;
    }
  }
  navigate();
}

interface IDirtyPromptLinkProps extends NavLinkProps {
  to: string;
}
// Link
const DirtyPromptLink = (props: IDirtyPromptLinkProps): JSX.Element => {
  const { isDirty, isProxy } = useProxyWarningState();

  const dispatch = useProxyWarningDispatch();

  // For Links used in MC Header/Sidebar
  const navigate = useNavigate();

  if (!checkLocation()) {
    dispatch({ type: "RESET" });
  }

  if (!isDirty || !isProxy) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <Link component={RouterLink} {...(props as any)} />;
  }
  async function test(e: Event) {
    e.preventDefault();
    const userAction = new Promise((resolve, reject) =>
      dispatch({
        type: "OPEN_DIALOG",
        handlers: { resolve, reject },
      })
    );
    try {
      await userAction;
      dispatch({ type: "CONFIRM_LEAVE" });
      navigate(props.to);
    } catch (_) {
      dispatch({ type: "CANCEL_STAY" });
      return;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Link component={RouterLink} onClick={test} {...(props as any)} />;
};

export {
  ProxyWarningProvider,
  useProxyWarningState,
  useProxyWarningDispatch,
  checkLocation,
  navigateAway,
  DirtyPromptLink,
};
