import {
  Box,
  Checkbox,
  Stack,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  InputAdornment,
} from "@mui/material";
import { FormikProps } from "formik";
import { Text } from "@asayinc/component-library";
import { FilingDetailsFormValues } from "../../../../types";
import { RadioLabel } from "../../Atoms";

interface Props {
  formikBag: FormikProps<FilingDetailsFormValues>;
  enabledLabel: string;
  canVoteLabel: string;
  field: "telephone" | "inPerson" | "virtual";
  infoField: {
    id: string;
    label: string;
  };
  maxLength?: number;
}

const MeetingFormat = ({
  formikBag,
  field,
  enabledLabel,
  canVoteLabel,
  infoField,
  maxLength = 120,
}: Props) => {
  const enabled = formikBag.values.meetingFormats[field].enabled;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={3}
    >
      {/* Checkbox for enabling the meeting format */}
      <Box minWidth={148}>
        <FormControlLabel
          label={enabledLabel}
          control={
            <Checkbox
              name={`meetingFormats.${field}.enabled`}
              checked={enabled}
              data-testid={`meetingFormats-${field}-checkbox`}
              onChange={formikBag.handleChange}
            />
          }
        />
      </Box>
      <Box flexGrow={1}>
        {/* TextField for inputing information for the given meeting format */}
        {field !== "virtual" && (
          <TextField
            size="small"
            fullWidth
            multiline
            required={enabled}
            rows={3}
            data-testid={`meetingFormats-${field}-textfield`}
            disabled={!enabled}
            name={`meetingFormats.${field}.value`}
            inputProps={{ maxLength }}
            {...infoField}
            value={formikBag.values.meetingFormats[field].value}
            onChange={formikBag.handleChange}
            error={
              formikBag.touched.meetingFormats?.[field]?.value &&
              Boolean(formikBag.errors.meetingFormats?.[field]?.value)
            }
            helperText={
              formikBag.touched.meetingFormats?.[field]?.value &&
              formikBag.errors.meetingFormats?.[field]?.value
            }
          />
        )}
        {field === "virtual" && (
          <TextField
            size="small"
            fullWidth
            required={enabled}
            data-testid={`meetingFormats-${field}-textfield`}
            disabled={!enabled}
            name={`meetingFormats.${field}.value`}
            inputProps={{ maxLength }}
            {...infoField}
            value={formikBag.values.meetingFormats[field].value}
            onChange={formikBag.handleChange}
            error={
              formikBag.touched.meetingFormats?.[field]?.value &&
              Boolean(formikBag.errors.meetingFormats?.[field]?.value)
            }
            helperText={
              formikBag.touched.meetingFormats?.[field]?.value &&
              formikBag.errors.meetingFormats?.[field]?.value
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">https://</InputAdornment>
              ),
            }}
          />
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text variant="body2" emphasis={enabled ? "medium_high" : "disabled"}>
            {canVoteLabel}
          </Text>
          {/* Radio selections for determining if users can vote at meeting */}
          <RadioGroup
            aria-label={field}
            name={`meetingFormats.${field}.canVote`}
            row={true}
            value={formikBag.values.meetingFormats[field].canVote}
          >
            <FormControlLabel
              value="yes"
              disabled={!enabled}
              label={<RadioLabel enabled={enabled} label="Yes" />}
              onChange={formikBag.handleChange}
              control={<Radio size="small" />}
              data-testid={`${field}-canvote-yes`}
            />
            <FormControlLabel
              value="no"
              disabled={!enabled}
              onChange={formikBag.handleChange}
              control={<Radio size="small" />}
              label={<RadioLabel enabled={enabled} label="No" />}
              data-testid={`${field}-canvote-no`}
            />
          </RadioGroup>
        </Stack>
        {formikBag.errors.meetingFormats?.[field]?.canVote &&
        formikBag.touched.meetingFormats?.[field]?.canVote ? (
          <Box
            sx={{
              color: "#d33f33",
              fontSize: ".75rem",
              margin: "3px 0 0 14px",
              textAlign: "right",
            }}
            data-testid={`meetingFormats-${field}-canvote-error`}
          >
            {formikBag.errors.meetingFormats?.[field]?.canVote}
          </Box>
        ) : null}
      </Box>
    </Stack>
  );
};

export default MeetingFormat;
