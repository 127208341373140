import { useProspectusPackages } from "../../../api/adapters/nexus/hooks/useProspectusPackages";
import { useTradeAlertsWithSecurityIds } from "../../../api/adapters/nexus/hooks/useTradeAlertsWithSecurityIds";
import { useBrokers } from "../../../api/adapters/proxy/hooks/useBrokers";
import { useProxy } from "../../../api/adapters/proxy/hooks/useProxy";

export function useDetailsData(filingId: string) {
  const {
    isError: isFilingError,
    isLoading: isFilingLoading,
    filing,
    mutate: mutateFiling,
  } = useProxy(filingId);

  const {
    isError: isBrokersError,
    isLoading: isBrokersLoading,
    brokers,
  } = useBrokers(filingId);

  const securityIds =
    filing?.securities.map((security) => security.globalSecurityId || "") || [];

  const {
    isError: isSecurityError,
    isLoading: isSecurityLoading,
    tradeAlerts,
  } = useTradeAlertsWithSecurityIds("trades", securityIds, {
    limit: 25,
    offset: 0,
    ordering: "effective_end_date",
  });

  const {
    isError: isProspectusPackagesError,
    isLoading: isLoadingProspectusPackages,
    mutate: mutateProspectusPackages,
    prospectusPackages,
    prospectusPackagesBySecurity,
  } = useProspectusPackages(securityIds);

  return {
    isLoading:
      isFilingLoading ||
      isBrokersLoading ||
      isSecurityLoading ||
      isLoadingProspectusPackages,
    isError:
      isFilingError ||
      isBrokersError ||
      isSecurityError ||
      isProspectusPackagesError,
    filing,
    mutateFiling,
    mutateProspectusPackages,
    brokers,
    tradeAlerts,
    prospectusPackages,
    prospectusPackagesBySecurity,
  };
}
