import { Link } from "@mui/material";

import { useIssuer } from "src/api/adapters/hub/hooks/useIssuer";
import { Box } from "src/components/atoms/Box";
import { LoadingState } from "src/components/LoadingState";
import { FormSection } from "src/components/molecules/FormSection";
import { LabelField } from "src/components/molecules/LabelField";
import { IssuerInvocingDetails } from "src/components/organisms/IssuerInvoicingDetails";

interface IIssuerDetailsProps {
  id: string;
}

export function IssuerDetails({ id }: IIssuerDetailsProps) {
  if (!id) {
    return <Box>Issuer ID was not provided from proxy filing</Box>;
  }

  const { issuer, isLoading, isError } = useIssuer(id);

  if (isLoading) {
    return <LoadingState></LoadingState>;
  }

  if (isError || !issuer) {
    return <Box>There is an error. Could not find Issuer.</Box>;
  }

  const { name } = issuer;

  return (
    <>
      <FormSection title="Issuer Details" isCollapsible={false}>
        <Box
          sx={{
            backgroundColor: "#f8f8f8",
            py: 3,
            px: 4,
            mt: 3,
            border: "1px solid #e5e5e5",
          }}
        >
          <LabelField label="Issuer" sx={{ mb: 2 }}>
            <Link
              sx={{
                color: "#1853b4",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              href={`/issuers/${id}/invoicing/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {name}
            </Link>
          </LabelField>
        </Box>
      </FormSection>
      <IssuerInvocingDetails issuer={issuer} />
    </>
  );
}
