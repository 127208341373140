import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import useSWR from "swr";

import { hub } from "../../api/adapters/hub";
import { PageWrapper } from "../../components/PageWrapper";
import { LoadingState } from "../../components/LoadingState";
import { TableHeader } from "../../components/TableHeader";
import { Table } from "../../components/Table";
import { TableHeadCell } from "../../components/TableHeadCell";
import { TableBodyRow } from "../../components/TableBodyRow";
import { TickerAvatar } from "../../components/TickerAvatar";
import { TableBodyCell } from "../../components/TableBodyCell";
import { CusipTagList } from "../../components/CusipTagList";
import { formatNumber } from "../../utils/format-number";
import { humanize } from "../../utils/humanize";
import { formatDate } from "../../utils/format-date";
import { getPageOffset } from "../../utils/get-page-offset";
import { ErrorCard } from "../../components/ErrorCard";
import { swrDisablePolling } from "../../constants/configs";
import { PageHeader } from "src/components/molecules/PageHeader";
import { Avatar } from "src/components/atoms/Avatar";

type Response = {
  count: number;
  next: string;
  previous: null;
  results: Issuer[];
};

type Issuer = {
  id: string;
  name: string;
  issuerType: IssuerType | null;
  tickers: string[];
  cusips: string[];
  positions: number;
  recordDate: string | null;
  expectedDate: string | null;
};

export type Query = {
  pageSize: number;
  page: number;
  ordering: string;
};

type OnUpdateQuery = (
  query: "pageSize" | "page" | "ordering",
  value: string | number
) => void;

export type IssuerType = "corporation" | "investment_trust" | "government";

const issuersFetcher = (url: string) => hub.get<Response>(url);

const Issuers = (): JSX.Element | null => {
  const navigate = useNavigate();
  const [query, setQuery] = React.useState<Query>({
    pageSize: 25,
    page: 0,
    ordering: "-positions",
  });

  const { data, error } = useSWR(
    `/admin/issuers/?limit=${query.pageSize}&ordering=${
      query.ordering
    }&offset=${getPageOffset({
      page: query.page,
      pageSize: query.pageSize,
    })}`,
    issuersFetcher,
    swrDisablePolling
  );

  const handleOrderingClick = useCallback(
    (ordering: string) => {
      handleUpdateQuery("ordering", ordering);
    },
    [query]
  );

  const handleUpdateQuery: OnUpdateQuery = (name, value) =>
    setQuery({ ...query, [name]: value });

  if (!error && !data)
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );

  if (error)
    return (
      <PageWrapper>
        <ErrorCard
          title="Unable to load issuers."
          body="Something went wrong when attempting to load the requested list of issuers. Please contact support for help with this issue."
        />
      </PageWrapper>
    );

  if (data)
    return (
      <React.Fragment>
        <PageHeader icon={<Avatar name="Is" />} title="Issuers" />
        <PageWrapper>
          <TableHeader
            count={data.data.count}
            page={query.page}
            pageSize={query.pageSize}
            itemType="issuer"
            onPageChange={(page) => handleUpdateQuery("page", page)}
            onPageSizeChange={(pageSize) =>
              handleUpdateQuery("pageSize", pageSize)
            }
          />
          <Table>
            <thead className="bg-white">
              <tr>
                <TableHeadCell name="Ticker" />
                <TableHeadCell
                  name="Issuer"
                  orderingKey="name"
                  currentOrdering={query.ordering}
                  onOrderingClick={handleOrderingClick}
                />
                <TableHeadCell name="CUSIP" />
                <TableHeadCell name="Type" />
                <TableHeadCell
                  name="Positions"
                  orderingKey="positions"
                  currentOrdering={query.ordering}
                  onOrderingClick={handleOrderingClick}
                />
                <TableHeadCell name="Record Date" />
                <TableHeadCell name="Expect Date" />
              </tr>
            </thead>
            <tbody>
              {data.data.results.map((issuer) => (
                <TableBodyRow
                  key={issuer.id}
                  onClick={() => navigate(`/issuers/${issuer.id}`)}
                >
                  <TableBodyCell>
                    <TickerAvatar tickers={issuer.tickers} />
                  </TableBodyCell>
                  <TableBodyCell>
                    <Link
                      className="text-blue hover:underline hover:text-blue"
                      to={`/issuers/${issuer.id}`}
                      target="_blank"
                      onClick={(event) => event.stopPropagation()}
                    >
                      {issuer.name}
                    </Link>
                  </TableBodyCell>
                  <TableBodyCell>
                    <CusipTagList cusips={issuer.cusips} />
                  </TableBodyCell>
                  <TableBodyCell>
                    <span className="capitalize text-primary-text">
                      {humanize(issuer.issuerType || "")}
                    </span>
                  </TableBodyCell>
                  <TableBodyCell>
                    <span className="font-medium text-primary-text">
                      {formatNumber(issuer.positions)}
                    </span>
                  </TableBodyCell>
                  <TableBodyCell>
                    {issuer.recordDate ? (
                      <DateCell date={issuer.recordDate} />
                    ) : (
                      "n/a"
                    )}
                  </TableBodyCell>
                  <TableBodyCell>
                    {issuer.expectedDate ? (
                      <DateCell
                        date={issuer.expectedDate}
                        highlightExpired={true}
                      />
                    ) : (
                      "n/a"
                    )}
                  </TableBodyCell>
                </TableBodyRow>
              ))}
            </tbody>
          </Table>
        </PageWrapper>
      </React.Fragment>
    );

  return null;
};

const DateCell = ({
  date,
  highlightExpired = false,
}: {
  date: null | string;
  highlightExpired?: boolean;
}) => {
  if (!date) return <span className="text-gray">n/a</span>;

  if (highlightExpired && isDateExpired(new Date(date))) {
    return <span className="text-red">{formatDate(new Date(date))}</span>;
  }

  return (
    <span className="text-primary-text">{formatDate(new Date(date))}</span>
  );
};

function isDateExpired(date: Date) {
  const now = new Date();

  return now.getTime() > date.getTime();
}

export { Issuers };
