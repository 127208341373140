import documentTypeMap from "./constants/documentTypeMap";
import { CorporateActionType } from "./types";

export const corporateActionTypeOptions: {
  value: CorporateActionType | "";
  label: string;
  disabled?: boolean;
}[] = [
  {
    value: "",
    label: "Select a type",
    disabled: true,
  },
  {
    value: "ConsentSolicitation",
    label: "Consent Solicitation",
  },
  {
    value: "DividendOption",
    label: "Dividend Option",
  },
  {
    value: "DividendReinvestmentPlan",
    label: "Dividend Reinvestment Plan",
  },
  {
    value: "ExchangeOffer",
    label: "Exchange Offer",
  },
  {
    value: "MergerConsiderationElection",
    label: "Merger with Election",
  },
  {
    value: "OddLotOffer",
    label: "Odd Lot Offer",
  },
  {
    value: "OfferToPurchaseBuyBack",
    label: "Buyback",
  },
  {
    value: "OfferToPurchaseThirdPartyOffer",
    label: "Offer to Purchase",
  },
  {
    value: "RightsIssue",
    label: "Rights Issue",
  },
];

export const documentTypeOptions = Object.entries(documentTypeMap).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const frequencyOptions: {
  value: string;
  label: string;
  disabled?: boolean;
}[] = [
  { value: "", label: "Select a frequency", disabled: true },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Annually", label: "Annually" },
  { value: "Semi-Annually", label: "Semi-Annually" },
  { value: "Irregular", label: "Irregular" },
];
