import { AxiosRequestConfig } from "axios";
import useSWR from "swr";

import { nexus } from "..";
import { PaginatedResponse } from "../../../../types/PaginatedResponse";
import { SecurityProspectusPackage } from "../types";
import { IQueryParams } from "../../types";

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) => {
  return nexus.get<PaginatedResponse<SecurityProspectusPackage>>(url, {
    params,
  });
};

export function useTradeAlerts({ limit, ordering, offset }: IQueryParams) {
  const url = `security-prospectus-packages/trades/?limit=${limit}&ordering=${ordering}&offset=${offset}`;
  const { data, error } = useSWR(url, fetcher);

  return {
    count: data?.data?.count,
    isError: error,
    isLoading: !error && !data,
    prospectuses: data?.data?.results,
  };
}
