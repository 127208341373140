import React, { ReactNode } from "react";
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  open: boolean;
  message?: ReactNode;
  handleClose: (
    event: Event | React.SyntheticEvent<unknown, Event>,
    reason?: string
  ) => void;
};

export const SimpleSnackbar = ({
  open,
  handleClose,
  message,
}: Props): JSX.Element => {
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message ? message : ""}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};
