import useSWR from "swr";
import { AxiosRequestConfig } from "axios";

import { hub } from "..";
import { Security } from "../../../../features/IssuerDetails/Prospectus";
import { IQueryParams } from "../../types";

export type ProspectusResponse = {
  id: string;
  name: string;
  issuerType: string;
  securities: Security[];
};

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) => {
  return hub.get<ProspectusResponse>(url, { params });
};

export function useProspectusByIssuerId(issuerId: string) {
  const url = `/admin/issuers/${issuerId}/prospectus/`;
  const { data, error } = useSWR(url, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    response: data?.data,
  };
}
