import useSWR from "swr";
import { proxy } from "..";
import { FilingDetails } from "../../../../features/Proxy/types";

const fetcher = (url: string) => {
  return proxy.get<FilingDetails>(url);
};

export function useProxy(id: string | undefined) {
  const url = `/filings/${id}/`;
  const { data, error, mutate } = useSWR(url, fetcher);

  return {
    isError: error,
    isLoading: !data && !error,
    filing: data?.data,
    data,
    mutate,
  };
}
