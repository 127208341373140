import React from "react";
import { Grid } from "@mui/material";
import { ProposalType, Routineness, VoteType } from "../types";
import { CusipTagList } from "src/components/CusipTagList";

type Props = {
  title: string;
  routineness: Routineness;
  numberOfProposals: number;
  recommendationType: string;
  cusipStrings: string[];
  voteType: VoteType;
  proposalType: ProposalType;
  children?: React.ReactNode;
};

function BallotDetailsProposalGroupRow({
  title,
  routineness,
  numberOfProposals,
  recommendationType,
  cusipStrings,
  children,
  voteType,
  proposalType,
}: Props): JSX.Element {
  return (
    <Grid
      container={true}
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        fontSize: "12px",
        color: "text.primary",
        alignItems: "center",
        justifyContent: "space-between",
        py: 1.75,
        px: 3,
        borderBottom: `1px solid`,
        borderBottomColor: "divider",
      }}
    >
      <Grid
        item={true}
        xs={5}
        sx={{
          color: "#121212",
          fontSize: "12px",
        }}
      >
        {title}
      </Grid>
      <Grid
        item={true}
        xs={"auto"}
        sx={{
          color: "#121212",
          fontSize: "12px",
        }}
      >
        {numberOfProposals}
      </Grid>
      <Grid
        item={true}
        xs={2}
        sx={{
          textTransform: "capitalize",
          color: "#121212",
          fontSize: "12px",
        }}
      >
        {formatVoteChoices(voteType, proposalType)}
      </Grid>
      <Grid
        item={true}
        xs={2}
        sx={{
          color: "#121212",
          fontSize: "12px",
        }}
      >
        <CusipTagList cusips={cusipStrings} />
      </Grid>
      <Grid
        item={true}
        xs={"auto"}
        sx={{
          color: "#121212",
          fontSize: "12px",
        }}
      >
        {routineness === "routine" ? "Routine" : "Non-Routine"}
      </Grid>
      <Grid
        item={true}
        xs={"auto"}
        sx={{
          textTransform: "capitalize",
          color: "#121212",
          fontSize: "12px",
        }}
      >
        {recommendationType}
      </Grid>
      {children}
    </Grid>
  );
}

function formatVoteChoices(voteType: VoteType, proposalType: ProposalType) {
  if (proposalType === "SayOnPay") {
    return "One Year, Two Years, Three Years, Four Years";
  }

  switch (voteType) {
    case "election_majority":
      return "For, Against, Abstain";
    case "election_majority_forabstain":
      return "For, Abstain";
    case "election_majority_foragainst":
      return "For, Against";
    case "election_majority_foragainstwithhold":
      return "For, Against, Withhold";
    case "election_majority_forwithholdabstain":
      return "For, Withhold, Abstain";
    case "election_majority_yesno":
      return "Yes, No";
    case "election_plurality":
      return "For, Withhold";
    default:
      return "None";
  }
}

export { BallotDetailsProposalGroupRow };
