import { v4 as uuid } from "uuid";
import { useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";

import { ReactComponent as TrashIcon } from "src/assets/icons/trash.svg";
import { ReactComponent as FileIcon } from "src/assets/icons/file.svg";
import { Dialog } from "../atoms/dialog/Dialog";
import { Box } from "../atoms/Box";
import { capitalize } from "../../utils/capitalize";
import { uploadProspectusDocuments } from "../../api/adapters/nexus";
import { Label } from "../Label";
import { ProspectusPackage } from "../../api/adapters/nexus/types";
import { DatePicker } from "../atoms/DatePicker";
import { Dropzone } from "../atoms/Dropzone";

type Attachment = {
  id: string;
  name: string;
  type: string;
  effectiveDate: Date;
  attachment: File;
};

interface IUploadProspectusDocumentDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
  prospectusPackage: ProspectusPackage;
}

export function UploadProspectusDocumentDialog({
  isOpen,
  onClose,
  onUpdate,
  prospectusPackage,
}: IUploadProspectusDocumentDialogProps) {
  const [prospectusDocuments, setProspectusDocuments] = useState<Attachment[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const addNewProspectusDocument = (files: File[]) => {
    const newProspectusDocuments = files.map((file) => {
      return {
        id: uuid(),
        type: "summary",
        name: "Summary Prospectus",
        effectiveDate: new Date(),
        attachment: file,
      };
    });

    setProspectusDocuments([...prospectusDocuments, ...newProspectusDocuments]);
  };

  const handleDeleteProspectusDocument = (prospectusDocument: Attachment) => {
    const newProspectusDocuments = prospectusDocuments.filter(
      (document) => document.id !== prospectusDocument.id
    );
    setProspectusDocuments(newProspectusDocuments);
  };

  const handleProspectusDocumentChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    id: string
  ) => {
    const newProspectusDocuments = prospectusDocuments.map((doc) => {
      if (id !== doc.id) {
        return doc;
      }

      return {
        ...doc,
        [event.currentTarget.name]: event.currentTarget.value,
      };
    });

    setProspectusDocuments(newProspectusDocuments);
  };

  const handleChangeDocumentType = (type: string, id: string) => {
    const getDocumentName = (type: string) => {
      switch (type) {
        case "summary":
          return "Summary Prospectus";
        case "statutory":
          return "Statutory Prospectus";
        case "sticker":
          return "Sticker";
        case "statement_of_additional_information":
          return "Statement of Additional Information";
        default:
          return capitalize(type.split("_").join(" "));
      }
    };

    const newProspectusDocuments = prospectusDocuments.map((doc) => {
      if (id !== doc.id) {
        return doc;
      }

      return {
        ...doc,
        type,
        name: getDocumentName(type),
      };
    });

    setProspectusDocuments(newProspectusDocuments);
  };

  const handleChangeEffectiveDate = (date: Date | null, id: string) => {
    if (date) {
      const newProspectusDocuments = prospectusDocuments.map((doc) => {
        if (id !== doc.id) {
          return doc;
        }

        return {
          ...doc,
          effectiveDate: date,
        };
      });

      setProspectusDocuments(newProspectusDocuments);
    }
  };

  const handleUpload = async () => {
    const documentsToUpload = prospectusDocuments
      .sort((a, b) =>
        a.type === "statutory" ? -1 : b.type === "statutory" ? 1 : 0
      )
      .sort((a, b) =>
        a.type === "summary" ? -1 : b.type === "summary" ? 1 : 0
      )
      .map((doc) => {
        const formData = new FormData();
        formData.append("attachment", doc.attachment, doc.name);
        formData.append("type", doc.type);
        formData.append("name", doc.name);
        formData.append(
          "effective_date",
          format(doc.effectiveDate, "yyyy-MM-dd")
        );
        return uploadProspectusDocuments(prospectusPackage.id, formData);
      });

    try {
      setIsLoading(true);
      await Promise.all(documentsToUpload);
      toast.success("Your prospectus documents were successfully created.");
    } catch (error) {
      toast.error(
        "An unknown error occurred. Please ensure your selections are correct.",
        { autoClose: false }
      );
    } finally {
      setIsLoading(false);
      onUpdate();
      handleDialogClose();
    }
  };

  const handleDialogClose = () => {
    onClose();
    setProspectusDocuments([]);
  };

  return (
    <Dialog
      isPrimaryLoading={isLoading}
      fullWidth
      onClose={handleDialogClose}
      onPrimaryClick={handleUpload}
      onSecondaryClick={handleDialogClose}
      open={isOpen}
      primaryButtonText="Upload"
      secondaryButtonText="Cancel"
      title="Upload additional documents"
    >
      <form className="w-full" id="upload-prospectus-document">
        {prospectusDocuments.map((doc: Attachment) => (
          <Box key={doc.id} sx={{ my: 4 }}>
            <Box
              sx={{
                alignItems: "center",
                borderTop: "1px solid #e5e5e5",
                borderLeft: "1px solid #e5e5e5",
                borderRight: "1px solid #e5e5e5",
                display: "flex",
                justifyContent: "justify-between",
                padding: 4,
              }}
            >
              <span className="text-sm flex items-center flex-grow">
                <FileIcon className="mr-2" />
                {doc.name}
              </span>
              <button
                onClick={() => handleDeleteProspectusDocument(doc)}
                type="button"
              >
                <TrashIcon />
              </button>
            </Box>
            <Box sx={{ border: "1px solid #e5e5e5", px: 4, py: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
                <Label>Doc Type</Label>
                <select
                  className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
                  name="type"
                  value={doc.type}
                  onChange={(event) =>
                    handleChangeDocumentType(event.currentTarget.value, doc.id)
                  }
                >
                  <option value="summary">Summary Prospectus</option>
                  <option value="statutory">Statutory Prospectus</option>
                  <option value="sticker">Sticker</option>
                  <option value="statement_of_additional_information">
                    Statement of Additional Information
                  </option>
                </select>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
                <Label>Doc Name</Label>
                <input
                  type="text"
                  name="name"
                  className="border border-light-gray rounded focus:border-primary-text h-8 px-2 focus:outline-none"
                  required={true}
                  onChange={(event) =>
                    handleProspectusDocumentChange(event, doc.id)
                  }
                  value={doc.name}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
                <Label>Effective Date</Label>
                <DatePicker
                  name="effectiveDate"
                  required={true}
                  onChange={(date) => {
                    if (date && !Array.isArray(date)) {
                      handleChangeEffectiveDate(date, doc.id);
                    }
                  }}
                  selected={doc.effectiveDate}
                />
              </Box>
            </Box>
          </Box>
        ))}
        <Dropzone onDrop={addNewProspectusDocument} />
      </form>
    </Dialog>
  );
}
