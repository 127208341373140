import { COLORS } from "@asayinc/component-library";
import PublishIcon from "@mui/icons-material/Publish";
import classNames from "classnames";
import { default as ReactDropzone } from "react-dropzone";

import { Box } from "./Box";
import { IBaseProps } from "../../types/BaseProps";
import { SUPPORTED_FILE_TYPES } from "src/constants/supported-file-types";
import { toast } from "react-toastify";

interface IDropzoneProps extends IBaseProps {
  isDisabled?: boolean;
  isLarge?: boolean;
  multiple?: boolean;
  onDrop: (file: File[]) => void;
  pdfOnly?: boolean;
}

export function Dropzone({
  className,
  isDisabled = false,
  isLarge = false,
  multiple = true,
  onDrop,
  pdfOnly = false,
}: IDropzoneProps) {
  const handleDrop = (files: File[]) => {
    if (files.length > 0) {
      const areAllFilesSupported = files.every((f) =>
        SUPPORTED_FILE_TYPES.includes(f.type)
      );
      if (areAllFilesSupported) {
        onDrop(files);
      } else {
        toast.error(
          `File type is not supported. Please upload a suported file type (${
            pdfOnly ? "pdf" : "pdf, doc, docx, txt, png, jpg, csv"
          }).`
        );
      }
    }
  };

  return (
    <ReactDropzone
      multiple={multiple}
      onDrop={(files: File[]) => handleDrop(files)}
      disabled={isDisabled}
    >
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div
            {...getRootProps()}
            className={classNames(
              className,
              "border border-dashed rounded flex justify-center items-center h-16",
              {
                "border-primary-text": isDragActive,
                "border-gray": !isDragActive,
                "h-64": isLarge,
                color: isDisabled ? COLORS.P_DISABLED : COLORS.BLACK,
              }
            )}
          >
            <input
              {...getInputProps({
                accept: pdfOnly
                  ? "application/pdf"
                  : "application/pdf, .doc, .docx, .txt, .png, .jpg, .csv",
              })}
            />
            <Box
              sx={{
                alignItems: "center",
                cursor: isDisabled ? "not-allowed" : "pointer",
                display: "flex",
                color: isDisabled ? COLORS.P_DISABLED : COLORS.BLACK,
              }}
            >
              <PublishIcon fontSize="medium" sx={{ marginRight: 2 }} />
              {isDragActive
                ? "Drop file here"
                : "Drag and drop a file or click to browse"}
            </Box>
          </div>
        );
      }}
    </ReactDropzone>
  );
}
