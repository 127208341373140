import useSWR from "swr";
import { nexus } from "..";

const fetcher = (url: string) => {
  return nexus.get(url);
};

export function useAdditionalMailingEmailPreview(
  proxyEventId: string,
  additionalMailingId: string,
  brokerId: string | null
) {
  const url = brokerId
    ? `proxy-events/${proxyEventId}/additional-mailings/${additionalMailingId}/preview/${brokerId}/`
    : null;
  const { data, error, mutate } = useSWR(url, fetcher);

  return {
    isError: error,
    isLoading: !data && !error,
    preview: data?.data,
    mutate,
  };
}
