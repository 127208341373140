import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { IconButton } from "@mui/material";
import { LoadingButton, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { Form, Formik, FormikProps } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";

import MeetingDetails from "../DetailsForm/MeetingDetails";
import {
  FilingDetailsFormValues,
  FilingDetails,
  OperationsStatus,
  ReportType,
  FilingType,
  MeetingType,
  DeliveryMethod,
  Cusip,
  DisplayType,
} from "../types";
import { useProxyWarningDispatch } from "../proxy-warning-context";
import { getValidationSchemaByStatus } from "../validation-schema";
import {
  getMeetingFormatPayload,
  initiateMeetingFormat,
} from "../DetailsForm/MeetingDetails/helpers";
import { Button } from "src/components/atoms/Button";
import { Box } from "src/components/atoms/Box";
import { formatCamelCaseString } from "src/utils/format-camel-case";
import { DirtyFormDialog } from "src/components/Dialog/DirtyFormDialog";
import { updateFiling } from "src/api/adapters";
import { userIdEvent } from "src/utils/analytics";
import { useDetailsData } from "./useDetailsData";
import { FormSection } from "src/components/molecules/FormSection";
import { BaseDetails } from "../DetailsForm/BaseDetails";
import { ContactDetails } from "../DetailsForm/Contact";
import { LabelField } from "src/components/molecules/LabelField";
import { Tabs } from "src/components/atoms/Tabs";
import { MaterialsDetails } from "../DetailsForm/Materials";
import { PreviewItem } from "src/components/PreviewPane/PreviewPane";
import { EdgarDateTableCell } from "../EdgarDateTableCell";
import { formatNumber } from "src/utils/format-number";

export type View =
  | "Details"
  | "Ballot"
  | "Materials"
  | "Meeting"
  | "Prospectus Package";

type DetailsFormProps = {
  filing: FilingDetails;
  id: string;
  onPreviewChange: (preview: PreviewItem) => void;
};

export function FilingDetailsPanel({
  filing,
  id,
  onPreviewChange,
}: DetailsFormProps) {
  const { mutateFiling: mutate } = useDetailsData(id);
  const dispatchDirty = useProxyWarningDispatch();

  // Update Filing Status vs. Save Only
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorBanner, setErrorBanner] = useState<boolean>(false);
  const [additionalRecordDatesError, setAdditionalRecordDatesError] =
    useState("");
  const [badRequest, setBadRequest] = useState("");
  const [currentTab, setCurrentTab] = useState("search");

  const { issuer, operationsStatus, stats, type } = filing;

  const handleFormSubmit = (values: FilingDetailsFormValues) => {
    setErrorBanner(false);
    if (values.recordDateCompare && values.additionalRecordDates) {
      const everyAddRecordDate = values.additionalRecordDates.every(
        (recordDate: string) => Boolean(recordDate)
      );
      if (!everyAddRecordDate) {
        setAdditionalRecordDatesError(
          "All additional record dates must be filled in."
        );
        return;
      }
    }

    patchFiling(values);
    dispatchDirty({ type: "INITIALIZE_PROXY" });
  };

  const patchFiling = (values: FilingDetailsFormValues) => {
    setIsSaving(true);
    const {
      solicitor,
      tabulator,
      replyTo,
      sendTo,
      cusips,
      meetingDate,
      meetingTime,
      meetingFormats,
      canAttendMeeting,
      meetingTimezone,
      voteCutoffDate,
      voteCutoffTime,
      voteCutoffTimezone,
      meetingType,
      expectedDate,
      reportType,
      fundOwner,
      recordDateCompare,
      additionalRecordDates,
      ...theRest
    } = values;

    const formats = getMeetingFormatPayload(meetingFormats, canAttendMeeting);

    const meeting = {
      date: meetingDate,
      time: meetingTime,
      timezone: meetingTimezone,
      type: meetingType,
      formats,
    };

    const securityIds = cusips.map((cusip) => cusip.id);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let payload: any = {
      ...theRest,
      digitalMaterialsExpectedDate: expectedDate,
      companyContactIds: [],
      issuerId: issuer.id,
      securityIds,
    };

    if (solicitor) {
      payload.solicitorId = solicitor.id;
    }

    if (replyTo) {
      const ids = replyTo.map((contact) => contact.id);
      payload.companyContactIds = [...payload.companyContactIds, ...ids];
    }

    if (
      type === "FirmMeeting" ||
      type === "FundMeeting" ||
      type === "FirmConsentSolicitation" ||
      type === "MeetingContest"
    ) {
      if (tabulator) {
        payload.tabulatorId = tabulator.id;
      }
      if (sendTo) {
        const ids = sendTo.map((contact) => contact.id);
        payload.companyContactIds = [...payload.companyContactIds, ...ids];
      }
    }

    if (recordDateCompare) {
      payload.additionalRecordDates = additionalRecordDates;
    } else if (!recordDateCompare && filing.additionalRecordDates) {
      payload.additionalRecordDates = [];
    }

    switch (type) {
      case "MeetingContest":
      case "FundMeeting":
      case "FirmMeeting":
        payload = {
          ...payload,
          voteCutoffTime,
          voteCutoffDate,
          voteCutoffTimezone,
          meeting,
          fundOwner,
        };
        break;
      case "FirmConsentSolicitation":
        payload = {
          ...payload,
          voteCutoffTime,
          voteCutoffDate,
          voteCutoffTimezone,
        };
        break;
      case "FundInformationStatement":
        payload = {
          ...payload,
          fundOwner,
        };
        break;
      case "FundReport":
        payload = {
          ...payload,
          fundOwner,
          reportType,
        };
        break;
      default:
        break;
    }
    const response = updateFiling(id, payload);
    response
      .then(() => {
        setIsSaving(false);
        setBadRequest("");
        toast.success("This filing has been saved. 🎉");
        userIdEvent("Save Filing Clicked", {
          filingId: id,
          operationsStatus: operationsStatus,
          filingType: type,
          save: true,
        });
      })
      .catch((error) => {
        setIsSaving(false);
        if (error.response) {
          if (error.response.status === 500) {
            setBadRequest(
              "500 Internal Server Error. Something's gone wrong on the backend!"
            );
          } else {
            const err = Object.keys(error.response.data).reduce(
              (acc: string, cv: string) => {
                acc = `${acc} ${formatCamelCaseString(cv)
                  .split("_")
                  .join(" ")}: ${error.response.data[cv]}.`;
                return acc;
              },
              ""
            );
            setBadRequest(err);
          }
          toast.error("There was an error saving the filing.");
          setErrorBanner(true);
        }
      })
      .finally(() => {
        mutate();
      });
  };

  // initialize meetingFormat data
  const { meetingFormats, canAttendMeeting } = initiateMeetingFormat(
    filing.meeting?.formats
  );

  const tabs =
    type !== "FundReport" &&
    type !== "FirmInformationStatement" &&
    type !== "FundInformationStatement"
      ? [
          {
            label: "Search",
            value: "search",
            testId: "DetailsTab",
          },
          {
            label: "Meeting",
            value: "meeting",
            testId: "MeetingTab",
          },
          {
            label: "Materials",
            value: "materials",
            testId: "ProxyMaterialsTab",
          },
        ]
      : [
          {
            label: "Search",
            value: "search",
            testId: "DetailsTab",
          },
          {
            label: "Materials",
            value: "materials",
            testId: "ProxyMaterialsTab",
          },
        ];
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Formik
        initialValues={{
          brokerSearchReceivedDate: filing.brokerSearchReceivedDate || null,
          recordDateCompare: Boolean(
            filing.additionalRecordDates &&
              filing.additionalRecordDates.length > 0
          ),
          meetingFormats,
          canAttendMeeting,
          additionalRecordDates: filing.additionalRecordDates,
          contestingEntity: filing.contestingEntity,
          cusips:
            filing.securities.map((security): Cusip => {
              return {
                id: security.id,
                cusip: security.cusip,
                name: security.name,
              };
            }) || [],
          deliveryMethod:
            filing.deliveryMethod || DeliveryMethod.NoticeAndAccess,
          digitalMaterialsReceivedDate:
            filing.digitalMaterialsReceivedDate || null,
          expectedDate: filing.digitalMaterialsExpectedDate || null,
          issuerFiling: filing.issuerFiling,
          digitalSendDeadline: filing.stats.digitalSendDeadline || "",
          paperSendDeadline: filing.stats.paperSendDeadline || "",
          meetingDate: (filing.meeting && filing.meeting.date) || null,
          meetingTime: (filing.meeting && filing.meeting.time) || null,

          meetingTimezone: (filing.meeting && filing.meeting.timezone) || null,
          meetingType:
            (filing.meeting && filing.meeting.type) || MeetingType.Annual,
          minimumShares: filing.minimumShares,
          notes: filing.notes || "",
          paperMaterialsReceivedDate: filing.paperMaterialsReceivedDate || null,
          recordDate: filing.recordDate || null,
          replyTo: filing.companyContacts.filter(
            (companyContact) => companyContact.role === "solicitor_reply_to"
          ),
          sendTo: filing.companyContacts.filter(
            (companyContact) => companyContact.role === "tabulator_reply_to"
          ),
          solicitor: filing.solicitor || null,
          solicitorJobNumber: filing.solicitorJobNumber || "",
          tabulator: filing.tabulator || undefined,
          type: (filing.type as FilingType) || ("" as FilingType),
          voteCutoffDate: filing.voteCutoffDate || null,
          voteCutoffTime: filing.voteCutoffTime || null,
          voteCutoffTimezone: filing.voteCutoffTimezone || null,
          reportType: filing.reportType
            ? filing.reportType
            : ("annual" as ReportType),
          fundOwner: filing.fundOwner || "",
          invoicerAddressLine1: filing.invoicerAddressLine1 || "",
          invoicerAddressLine2: filing.invoicerAddressLine2 || "",
          invoicerAddressLine3: filing.invoicerAddressLine3 || "",
          invoicerCity: filing.invoicerCity || "",
          invoicerState: filing.invoicerState || "",
          invoicerPostalCode: filing.invoicerPostalCode || "",
          invoicerCountry: filing.invoicerCountry || "",
          issuer:
            filing.issuer.id && filing.issuer.companyName
              ? {
                  id: filing.issuer.id,
                  name: filing.issuer.companyName,
                }
              : null,
        }}
        validationSchema={getValidationSchemaByStatus(
          filing.type,
          filing.operationsStatus
        )}
        isInitialValid={true}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {(formikBag: FormikProps<FilingDetailsFormValues>) => (
          <Form noValidate={true}>
            <Tabs
              sx={{ borderBottom: "1px solid #e5e5e5", paddingLeft: "24px" }}
              onChange={(value) => setCurrentTab(value)}
              value={currentTab}
              tabs={tabs}
            />
            <Box
              sx={{
                height: `calc(100vh - ${formikBag.dirty ? "313px" : "247px"})`,
                overflowY: "auto",
                p: 6,
              }}
            >
              {currentTab === "search" && (
                <>
                  <Box
                    sx={{
                      display: errorBanner ? "flex" : "none",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "-12px",
                      paddingBottom: "8px",
                      fontSize: ".75rem",
                      borderBottom: "1px solid #e5e5e5",
                    }}
                  >
                    <Box>
                      <ErrorOutlineIcon
                        sx={{ fill: "#d33f33", marginRight: "12px" }}
                      />
                      <Box component="span">
                        {badRequest.length > 0
                          ? badRequest
                          : "Incomplete Submission."}
                      </Box>
                    </Box>
                    <IconButton
                      size="small"
                      onClick={() => setErrorBanner(false)}
                    >
                      <CancelIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#f8f8f8",
                      height: "60px",
                      py: 2,
                      px: 4,
                      mb: 3,
                      border: "1px solid #e5e5e5",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <LabelField
                      label="Invoice Due Date"
                      sx={{ textAlign: "center" }}
                    >
                      {filing.stats.invoiceDueDate || "-"}
                    </LabelField>
                    <LabelField
                      label="Broker Count"
                      sx={{ textAlign: "center" }}
                    >
                      {filing.stats.brokerCount || "0"}
                    </LabelField>
                    {filing.operationsStatus === OperationsStatus.New ||
                    filing.operationsStatus === OperationsStatus.Draft ? (
                      <LabelField
                        label="Est Full Share Positions"
                        sx={{ textAlign: "center" }}
                      >
                        {filing.stats.estimatedFullSharePositions
                          ? formatNumber(
                              filing.stats.estimatedFullSharePositions
                            )
                          : "0"}
                      </LabelField>
                    ) : (
                      <LabelField
                        label="Full Share Positions"
                        sx={{ textAlign: "center" }}
                      >
                        {filing.stats.fullSharePositions
                          ? formatNumber(filing.stats.fullSharePositions)
                          : "0"}
                      </LabelField>
                    )}
                    {filing.operationsStatus === OperationsStatus.New ||
                    filing.operationsStatus === OperationsStatus.Draft ? (
                      <LabelField
                        label="Est Positions"
                        sx={{ textAlign: "center" }}
                      >
                        {filing.stats.estimatedTotalPositions
                          ? formatNumber(filing.stats.estimatedTotalPositions)
                          : "0"}
                      </LabelField>
                    ) : (
                      <LabelField
                        label="Positions"
                        sx={{ textAlign: "center" }}
                      >
                        {filing.stats.totalPositions
                          ? formatNumber(filing.stats.totalPositions)
                          : "0"}
                      </LabelField>
                    )}
                    <LabelField
                      label="Materials Req"
                      sx={{ textAlign: "center" }}
                    >
                      {filing.stats.materialsRequired
                        ? formatNumber(filing.stats.materialsRequired)
                        : "0"}
                    </LabelField>
                    <LabelField label="EDGAR Date" sx={{ textAlign: "center" }}>
                      {filing?.edgarFilings?.length > 0 ? (
                        <EdgarDateTableCell
                          edgarFilings={filing.edgarFilings}
                          issuer={filing.issuer}
                        />
                      ) : (
                        "-"
                      )}
                    </LabelField>
                  </Box>
                  <FormSection title="Filing Details">
                    <BaseDetails
                      additionalRecordDatesError={additionalRecordDatesError}
                      formikBag={formikBag}
                      isDraft={operationsStatus === OperationsStatus.Draft}
                    />
                    <Box sx={{ mt: "-4px" }}>
                      <ContactDetails
                        formikBag={formikBag}
                        isDraft={operationsStatus === OperationsStatus.Draft}
                      />
                    </Box>
                  </FormSection>
                </>
              )}
              {currentTab === "meeting" && (
                <FormSection title="Meeting Details">
                  <MeetingDetails
                    formikBag={formikBag}
                    isDraft={operationsStatus === OperationsStatus.Draft}
                  />
                </FormSection>
              )}
              {currentTab === "materials" && (
                <MaterialsDetails
                  filing={filing}
                  formikBag={formikBag}
                  hasPaper={!!stats.materialsRequired}
                  onDocumentPreviewClick={(doc) => {
                    onPreviewChange({
                      id: String(doc.id),
                      name: doc.name,
                      preview: doc.attachment,
                      type: DisplayType.Document,
                    });
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                backgroundColor: "white",
                border: "1px solid #e5e5e5",
                height: "64px",
                display: formikBag.dirty ? "flex" : "none",
                alignItems: "center",
                justifyContent: "space-between",
                px: "24px",
                mx: "-1px",
              }}
            >
              <Button
                onClick={() => formikBag.resetForm()}
                size="small"
                variant="outlined"
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                size="small"
                type="submit"
                disabled={!formikBag.dirty}
                loading={isSaving}
                onClick={() => {
                  if (formikBag.errors.meetingFormats) {
                    setBadRequest("Error in meeting formats.");
                  }
                  setErrorBanner(!formikBag.isValid);
                }}
              >
                Save
              </LoadingButton>
            </Box>
            <DirtyFormDialog />
          </Form>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
