import * as Yup from "yup";
import { CorporateActionType } from "./types";

export const createValidationSchema = Yup.object().shape(
  {
    securities: Yup.array().required("CUSIP is required."),
    issuer: Yup.object().required("Issuer is required."),
    announcementDate: Yup.string().required("Announcement date is required."),
    electionCutoffDate: Yup.string().required(
      "Election cutoff date is required."
    ),
    dtcExpirationDate: Yup.string().required(
      "DTC expiration date is required."
    ),
    type: Yup.string().required("Type is required."),
    electionTitle: Yup.string().required("Election title is required."),
    electionDetails: Yup.string().required("Election details are required."),
    offererParty: Yup.string().when("type", {
      is: (value: CorporateActionType) =>
        value === "OfferToPurchaseThirdPartyOffer",
      then: Yup.string().required("offerer party is required."),
      otherwise: Yup.string().notRequired(),
    }),
    minimumQualifyingQuantity: Yup.string().when("type", {
      is: (value: CorporateActionType) => value === "OddLotOffer",
      then: Yup.string().required("Minimum qualifying quantity is required."),
      otherwise: Yup.string().notRequired(),
    }),
    maximumQualifyingQuantity: Yup.string().when("type", {
      is: (value: CorporateActionType) => value === "OddLotOffer",
      then: Yup.string().required("Maximum qualifying quantity is required."),
      otherwise: Yup.string().notRequired(),
    }),
    price: Yup.string().when("type", {
      is: (value: CorporateActionType) => value === "OddLotOffer",
      then: Yup.string().required("Price is required."),
      otherwise: Yup.string().notRequired(),
    }),
    frequency: Yup.string().when("type", {
      is: (value: CorporateActionType) => value === "DividendOption",
      then: Yup.string().required("Frequency is required."),
      otherwise: Yup.string().notRequired(),
    }),
    grossDividend: Yup.string().when("type", {
      is: (value: CorporateActionType) => value === "DividendOption",
      then: Yup.string().required("Gross dividend is required."),
      otherwise: Yup.string().notRequired(),
    }),
    minimumBidPrice: Yup.string().when(
      ["type", "bidIncrement", "maximumBidPrice"],
      {
        is: (
          type: CorporateActionType,
          bidIncrement: string,
          maximumBidPrice: string
        ) =>
          (type === "OfferToPurchaseBuyBack" ||
            type === "OfferToPurchaseThirdPartyOffer") &&
          (bidIncrement || maximumBidPrice)
            ? true
            : false,
        then: Yup.string().required("Minimum bid price is required."),
        otherwise: Yup.string().notRequired(),
      }
    ),
    maximumBidPrice: Yup.string().when(
      ["type", "bidIncrement", "minimumBidPrice"],
      {
        is: (
          type: CorporateActionType,
          bidIncrement: string,
          minimumBidPrice: string
        ) =>
          (type === "OfferToPurchaseBuyBack" ||
            type === "OfferToPurchaseThirdPartyOffer") &&
          (bidIncrement || minimumBidPrice)
            ? true
            : false,
        then: Yup.string().required("Maximum bid price is required."),
        otherwise: Yup.string().notRequired(),
      }
    ),
  },
  [["maximumBidPrice", "minimumBidPrice"]]
);

export const editValidationSchema = createValidationSchema.shape({
  outTurn: Yup.string().when("type", {
    is: (value: CorporateActionType) => value === "RightsIssue",
    then: Yup.string().required("Out Turn is required."),
    otherwise: Yup.string().notRequired(),
  }),
  tradeSecurity: Yup.string().when("type", {
    is: (value: CorporateActionType) => value === "RightsIssue",
    then: Yup.string().required("Trade Security is required."),
    otherwise: Yup.string().notRequired(),
  }),
});
