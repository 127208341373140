import get from "lodash.get";
import { useSearchParams } from "react-router-dom";
import { COLORS } from "@asayinc/component-library";
import { ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import {
  ProspectusDocument,
  ProspectusPackage,
} from "../../api/adapters/nexus/types";

import { capitalize } from "../../utils/capitalize";
import { Accordion } from "../atoms/Accordion";
import { AccordionSummary } from "../atoms/AccordionSummary";
import { Chip } from "../atoms/Chip";
import { ProspectusPackageActions } from "../ProspectusPackageActions";
import { ProspectusDocumentsAccordionDetails } from "./ProspectusDocumentsAccordionDetails";
import { Box } from "../atoms/Box";
import { Label } from "../Label";
import { formatDate } from "src/utils/format-date";

interface IProspectusDocumentsAccordionProps {
  isReadOnly?: boolean;
  onDocumentPreviewClick?: (doc: ProspectusDocument) => void;
  onProspectusPackageUpdate: () => void;
  prospectusPackage: ProspectusPackage;
}

export function ProspectusDocumentsAccordion({
  isReadOnly = false,
  onDocumentPreviewClick,
  onProspectusPackageUpdate,
  prospectusPackage,
}: IProspectusDocumentsAccordionProps) {
  const { documents, expectedEndDate, effectiveStartDate, id, status } =
    prospectusPackage;
  const [searchParams] = useSearchParams();
  const targetPackage = get(searchParams, "targetPackage", null);

  const isTargetPackage = () => {
    if (targetPackage && !Array.isArray(targetPackage)) {
      return id === targetPackage;
    }
    return false;
  };

  const [isOpen, setIsOpen] = useState(isTargetPackage());
  const splitDate = effectiveStartDate.split("-");
  const endDate = expectedEndDate ? expectedEndDate.split("-") : "";
  const isAfterExpectedEndDate = expectedEndDate
    ? new Date(expectedEndDate + "T00:00:00") < new Date()
    : false;

  const chipColor =
    status === "pending" ? COLORS.LIGHT_YELLOW : COLORS.LIGHT_GREEN;
  const summaryColor = isAfterExpectedEndDate
    ? COLORS.P_MED_LOW_EMPHASIS
    : COLORS.P_HIGH_EMPHASIS;

  return (
    <Accordion
      expanded={isOpen}
      sx={{
        boxShadow: "none",
        paddingRight: -2,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          alignItems: "center",
          color: summaryColor,
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            fontWeight: 500,
          }}
        >
          Package {splitDate[0]} - {endDate[0]} ({documents.length})
          <Chip
            label={
              isAfterExpectedEndDate
                ? capitalize("expired")
                : capitalize(status)
            }
            size="small"
            sx={{
              backgroundColor: isAfterExpectedEndDate
                ? COLORS.LIGHT_RED
                : chipColor,
              ml: 5,
            }}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ mr: 5 }}>
            <Label>Effective start date</Label>
            <Box sx={{ fontSize: ".8rem " }}>
              {formatDate(new Date(effectiveStartDate + "T00:00:00"))}
            </Box>
          </Box>
          <Box sx={{ mr: 5 }}>
            <Label>Expected end date</Label>
            {expectedEndDate && (
              <Box sx={{ fontSize: ".8rem " }}>
                {formatDate(new Date(expectedEndDate + "T00:00:00"))}
              </Box>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <ProspectusDocumentsAccordionDetails
        isReadOnly={isReadOnly}
        onDocumentPreviewClick={onDocumentPreviewClick}
        onProspectusPackageUpdate={onProspectusPackageUpdate}
        prospectusPackage={prospectusPackage}
      />
      {!isReadOnly && (
        <ProspectusPackageActions
          isTargetPackage={isTargetPackage()}
          prospectusPackage={prospectusPackage}
          onProspectusPackageUpdate={onProspectusPackageUpdate}
        />
      )}
    </Accordion>
  );
}
