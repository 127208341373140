import { Box } from "src/components/atoms/Box";
import { AdditionalMailingStatusBadge } from "src/components/molecules/AdditionalMailingStatusBadge";
import { AdditionalMailing } from "src/types/additionalMailing";
import {
  ADDITIONAL_MAILING_AUDIENCE_TO_LABEL,
  ADDITIONAL_MAILING_TYPE_TO_LABEL,
} from "./utils";

interface IAdditionalMailingRowProps {
  isInFocus: boolean;
  mailing: AdditionalMailing;
  onClick: (mailing: AdditionalMailing) => void;
}

export function AdditionalMailingRow({
  isInFocus,
  mailing,
  onClick,
}: IAdditionalMailingRowProps) {
  const { audience, dateReceived, type, operationsStatus } = mailing;
  const handleOnClick = () => {
    onClick(mailing);
  };

  return (
    <Box
      onClick={handleOnClick}
      sx={{
        p: 3,
        my: 2,
        alignItems: "center",
        backgroundColor: isInFocus ? "#f8f8f8" : "none",
        border: "1px solid #e5e5e5",
        cursor: "pointer",
        display: "flex",
        "&: hover": {
          backgroundColor: "#f8f8f8",
        },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box component="b">{ADDITIONAL_MAILING_TYPE_TO_LABEL[type]}</Box>
        <Box>Received on: {dateReceived}</Box>
        <Box>{ADDITIONAL_MAILING_AUDIENCE_TO_LABEL[audience]}</Box>
      </Box>
      <Box sx={{ pr: 2 }}>
        <AdditionalMailingStatusBadge status={operationsStatus} />
      </Box>
    </Box>
  );
}
