import { useSearchParams } from "react-router-dom";

import { useVoteTransmissions } from "src/api/adapters/nexus/hooks/useVoteTransmissions";
import { Box } from "src/components/atoms/Box";
import { LoadingState } from "src/components/LoadingState";
import { AdditionalMailingStatusBadge } from "src/components/molecules/AdditionalMailingStatusBadge";
import { SplitPane } from "src/components/molecules/SplitPane";
import { FilingDetails } from "../types";
import { VoteTransmissionDetailPanel } from "./VoteTransmissionDetailPanel";

interface IVoteTransmissionsProps {
  filing: FilingDetails;
}

export function VoteTransmissions({ filing }: IVoteTransmissionsProps) {
  const { globalFilingId: proxyEventId } = filing;
  const { isLoading, isError, voteTransmissions } =
    useVoteTransmissions(proxyEventId);
  const [searchParams, setSearchParams] = useSearchParams();
  const vtId = searchParams.get("voteTransmission");
  const voteTransmission = voteTransmissions?.find((vt) => vt.id === vtId);

  if (isLoading) {
    return <LoadingState />;
  }

  if (isError || !voteTransmissions) {
    return <Box>Uh-oh we encountered an error</Box>;
  }

  const renderPrimaryPanel = () => {
    return (
      <Box
        data-testid="voteTransmissions"
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          px: 4,
        }}
      >
        <Box
          sx={{
            py: 3,
            mx: -4,
            pl: 8,
            pr: 4,
            mb: 4,
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #e5e5e5",
            fontWeight: 500,
            height: "48px",
          }}
        >
          Vote Transmissions
        </Box>
        <Box sx={{ overflowY: "auto", height: "calc(100vh - 16rem)" }}>
          {voteTransmissions.map((vt) => {
            const { id, operationsStatus, noboEvent } = vt;
            const { solicitor } = noboEvent;

            const handleOnClick = () => {
              searchParams.set("voteTransmission", id);
              setSearchParams(searchParams);
            };

            return (
              <Box
                key={id}
                onClick={handleOnClick}
                sx={{
                  p: 3,
                  my: 2,
                  alignItems: "center",
                  backgroundColor:
                    id === voteTransmission?.id ? "#f8f8f8" : "none",
                  border: "1px solid #e5e5e5",
                  cursor: "pointer",
                  display: "flex",
                  "&: hover": {
                    backgroundColor: "#f8f8f8",
                  },
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box component="b">Vote Transmission</Box>
                  <Box>{solicitor.name}</Box>
                </Box>
                <Box sx={{ pr: 2 }}>
                  <AdditionalMailingStatusBadge status={operationsStatus} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  return (
    <SplitPane
      primaryPane={renderPrimaryPanel()}
      primaryWidth={25}
      secondaryPane={
        <VoteTransmissionDetailPanel voteTransmission={voteTransmission} />
      }
      secondarySx={{ display: "flex" }}
    />
  );
}
