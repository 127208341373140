import { COLORS } from "@asayinc/component-library";
import { SxProps } from "@mui/material";
import { OperationsStatus } from "src/types/additionalMailing";

import { Chip } from "../atoms/Chip";

interface IAdditionalMailingStatusBadgeProps {
  status: OperationsStatus;
  sx?: SxProps;
}

export function AdditionalMailingStatusBadge({
  status,
  sx,
}: IAdditionalMailingStatusBadgeProps) {
  const getBorderColor = () => {
    switch (status) {
      case "pending":
        return COLORS.MUTED_ORANGE;
      case "approved":
        return COLORS.MUTED_GREEN;
      case "closed":
        return COLORS.MUTED_RED;
    }
  };

  const getLabel = () => {
    switch (status) {
      case "pending":
        return "Pending";
      case "approved":
        return "Approved";
      case "closed":
        return "Deleted";
    }
  };

  return (
    <Chip
      label={getLabel()}
      variant="outlined"
      size="small"
      sx={{ border: `1px solid ${getBorderColor()}`, ...sx }}
    />
  );
}
