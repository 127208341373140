import { SkipButton } from "./SkipButton";
import { formatNumber } from "../../utils/format-number";

type PaginatedButtonsProps = {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
};

const PaginationButtons = ({
  onPageChange,
  currentPage,
  totalPages,
}: PaginatedButtonsProps): JSX.Element => {
  return (
    <div className="flex items-center font-medium text-xs uppercase">
      <SkipButton
        buttonType="skip all left"
        disabled={currentPage === 0}
        className="mr-1"
        onClick={() => {
          onPageChange(0);
        }}
      />
      <SkipButton
        buttonType="skip left"
        disabled={currentPage === 0}
        onClick={() => {
          onPageChange(currentPage - 1);
        }}
      />
      <div className="mx-2 text-secondary-text">
        {formatNumber(currentPage + 1)} of {formatNumber(totalPages)}
      </div>
      <SkipButton
        buttonType="skip right"
        disabled={currentPage === totalPages - 1}
        className="mr-1"
        onClick={() => {
          onPageChange(currentPage + 1);
        }}
      />
      <SkipButton
        buttonType="skip all right"
        disabled={currentPage === totalPages - 1}
        onClick={() => {
          onPageChange(totalPages - 1);
        }}
      />
    </div>
  );
};

export { PaginationButtons };
