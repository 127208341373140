import { MenuItem, TextField } from "@mui/material";
import { useState } from "react";

import { Dialog } from "src/components/atoms/dialog/Dialog";
import { AdditionalMailingEmailPreviewPane } from "src/components/EmailPreviewPane/AdditionalMailingEmailPreviewPane";
import { AdditionalMailingBroker } from "src/types/additionalMailing";

interface IPreviewAdditionalMailingDialogProps {
  additionalMailingId: string;
  eligibleBrokers: AdditionalMailingBroker[];
  isOpen: boolean;
  proxyEventId: string;
  onClose: () => void;
}

export function PreviewAdditionalMailingDialog({
  additionalMailingId,
  eligibleBrokers,
  isOpen,
  proxyEventId,
  onClose,
}: IPreviewAdditionalMailingDialogProps) {
  const [brokerId, setBrokerId] = useState<string | null>(null);
  const currentBroker = eligibleBrokers.find(
    (broker) => broker.id === brokerId
  );

  const handleBrokerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrokerId(e.target.value);
  };

  const handleOnClose = () => {
    onClose();
    setBrokerId(null);
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={handleOnClose}
      title="Preview Email"
    >
      <TextField
        sx={{ my: 2 }}
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Broker"
        onChange={handleBrokerChange}
        size="small"
        select={true}
        value={brokerId}
      >
        {eligibleBrokers.map((broker) => {
          return (
            <MenuItem key={broker.id} value={broker.id}>
              {broker.name}
            </MenuItem>
          );
        })}
      </TextField>
      <AdditionalMailingEmailPreviewPane
        additionalMailingId={additionalMailingId}
        broker={currentBroker}
        proxyEventId={proxyEventId}
      />
    </Dialog>
  );
}
