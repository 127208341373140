// TBD move file to better location

/**
 * Api props
 */
interface ApiProps {
  filingId: string;
  broker: string;
}

/**
 * Generate communcation preview api url
 * @param filingId - filing id for communication api
 * @param broker - broker for communication api
 * @returns {string}
 */
export const getCommunicationApi = ({ filingId, broker }: ApiProps): string =>
  `/filings/${filingId}/communication-preview/${broker}/`;

/**
 * Generate email preview template api url
 * @param filingId - filing id for email template api
 * @param broker - broker for email template api
 * @returns {string}
 */
export const getTemplateApi = ({ filingId, broker }: ApiProps): string =>
  `/filings/${filingId}/preview/${broker}/`;
