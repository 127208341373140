import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

interface IPreviewViewSwitcherProps {
  isVoting: boolean;
  onChange: (view: string) => void;
  selectedView: string;
}

function PreviewViewSwitcher({
  isVoting,
  onChange,
  selectedView,
}: IPreviewViewSwitcherProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option: string) => {
    onChange(option);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <List sx={{ padding: 0 }}>
        <ListItem
          aria-haspopup="true"
          dense={true}
          onClick={handleClickListItem}
          sx={{ justifyContent: "flex-end" }}
        >
          <ListItemText
            sx={{ textTransform: "capitalize" }}
            primary={selectedView}
          />
          <ListItemIcon sx={{ minWidth: 0 }}>
            <ArrowDropDown />
          </ListItemIcon>
        </ListItem>
      </List>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          dense={true}
          key="email"
          selected={"email" === selectedView}
          onClick={() => handleMenuItemClick("email")}
          sx={{ textTransform: "capitalize" }}
        >
          Email
        </MenuItem>
        {isVoting && (
          <MenuItem
            dense={true}
            key="ballot"
            selected={"ballot" === selectedView}
            onClick={() => handleMenuItemClick("ballot")}
            sx={{ textTransform: "capitalize" }}
          >
            Ballot
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export { PreviewViewSwitcher };
