import { OperationsStatus } from "../../features/Proxy/types";
import { Dialog } from "../atoms/dialog/Dialog";

interface IUpdateStatusDialogProps {
  currentStatus: OperationsStatus;
  isOpen: boolean;
  isPrimaryLoading: boolean;
  onConfirmUpdate: (targetStatus: OperationsStatus) => void;
  onRequestClose: () => void;
}

const STATUS_TO_UPDATE_ACTION: { [key in OperationsStatus]: OperationsStatus } =
  {
    [OperationsStatus.Draft]: OperationsStatus.New,
    [OperationsStatus.New]: OperationsStatus.ReadyForReview,
    [OperationsStatus.Active]: OperationsStatus.ReadyForReview,
    [OperationsStatus.MaterialsRequested]: OperationsStatus.ReadyForReview,
    [OperationsStatus.ReadyForReview]: OperationsStatus.Approved,
    [OperationsStatus.Closed]: OperationsStatus.Closed,
    [OperationsStatus.Approved]: OperationsStatus.Approved,
  };

export function UpdateStatusDialog({
  currentStatus,
  isOpen,
  isPrimaryLoading,
  onConfirmUpdate,
  onRequestClose,
}: IUpdateStatusDialogProps) {
  const getTitle = () => {
    switch (currentStatus) {
      case OperationsStatus.Draft:
        return "Confirm Filing";
      case OperationsStatus.New:
      case OperationsStatus.Active:
      case OperationsStatus.MaterialsRequested:
        return "Complete Filing";
      case OperationsStatus.ReadyForReview:
        return "Approve Filing";
      default:
        return "";
    }
  };

  const getDescription = () => {
    switch (currentStatus) {
      case OperationsStatus.Draft:
        return "Are you sure you want to confirm this filing? This filing will be added to Mission Control and we will look for positions with this record date and these CUSIPs";
      case OperationsStatus.New:
      case OperationsStatus.Active:
      case OperationsStatus.MaterialsRequested:
        return "Would you like to mark this filing ready for review?";
      case OperationsStatus.ReadyForReview:
        return "Are you sure you would like to approve this filing? Approved emails will be sent to shareholders.";
      default:
        return "";
    }
  };

  const getPrimaryButtonText = () => {
    switch (currentStatus) {
      case OperationsStatus.Draft:
        return "Confirm";
      case OperationsStatus.New:
      case OperationsStatus.Active:
      case OperationsStatus.MaterialsRequested:
        return "Complete";
      case OperationsStatus.ReadyForReview:
        return "Approve";
      default:
        return "";
    }
  };

  return (
    <Dialog
      fullWidth
      onClose={onRequestClose}
      open={isOpen}
      isPrimaryLoading={isPrimaryLoading}
      primaryButtonText={getPrimaryButtonText()}
      secondaryButtonText="Cancel"
      onPrimaryClick={() => {
        const targetStatus = STATUS_TO_UPDATE_ACTION[currentStatus];
        if (targetStatus) {
          onConfirmUpdate(targetStatus);
        }
      }}
      onSecondaryClick={onRequestClose}
      title={getTitle()}
    >
      {getDescription()}
    </Dialog>
  );
}
