import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { proxy } from "../../api/adapters/proxy";
import { CompanyContact } from "../../features/Proxy/types";
import useSWR from "swr";

type Props = {
  onClear: () => void;
  onSelect: (solicitor: Company) => void;
  value: Company;
};

type Companies = {
  count: number;
  next: string;
  previous: null;
  results: Company[];
};

type Company = {
  id: number;
  name: string;
  address: null | string;
  companyContacts: CompanyContact[];
  defaultTabulator?: DefaultTabulator | null;
  type: CompanyType;
};

export interface Contact {
  id: number;
  name: string;
  email: string;
  phone: null | string;
}

type DefaultTabulator = {
  id: number;
  name: string;
  address: string;
  companyContacts: CompanyContact[];
};

type CompanyType = "Solicitor" | "Tabulator";

const fetcher = (url: string) => proxy.get<Companies>(url);

function SolicitorTypeahead({ onClear, onSelect, value }: Props): JSX.Element {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { data, error } = useSWR(`/companies/?search=${searchTerm}`, fetcher);

  const options = data
    ? data.data.results.filter((result) => result.type === "Solicitor")
    : [];

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterSelectedOptions
      inputValue={searchTerm}
      loading={!data && !error}
      ChipProps={{ size: "small" }}
      noOptionsText="No solicitors"
      onChange={(_, changeValue, reason) => {
        if (reason === "clear" || reason === "createOption") {
          onClear();
        }

        if (reason === "selectOption" && changeValue) {
          onSelect(changeValue);
        }
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === "clear") {
          onClear();
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      value={value || null}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Solicitor"
          size="small"
          placeholder="Start typing solicitor..."
          required={true}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}

export { SolicitorTypeahead };
