import { TextField } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";

import { CorporateActionType, CreateCorporateActionForm } from "src/types/vca";
import {
  ExchangeOfferConditions,
  ConsentSolicitationConditions,
  DividendOptionConditions,
  DividendReinvestmentPlanConditions,
  MergerConsiderationElectionConditions,
  OfferToPurchaseConditions,
  OfferToPurchaseAuctionConditions,
  RightsOfferConditions,
  BuybackConditions,
  BuybackAuctionConditions,
  OddLotOfferConditions,
} from "./Conditions";
import { RightsOfferOverSubscriptionConditions } from "./Conditions/RightsOfferOversubscriptionConditions";
import { FormSection } from "src/components/molecules/FormSection";

interface IOfferDetailsProps {
  form: UseFormReturn<CreateCorporateActionForm>;
  type: CorporateActionType;
}

export const OfferDetails = ({ form, type }: IOfferDetailsProps) => {
  const { control } = form;

  const renderConditions = () => {
    if (type) {
      switch (type as CorporateActionType) {
        case "consent_solicitation":
          return <ConsentSolicitationConditions form={form} />;
        case "dividend_option":
          return <DividendOptionConditions form={form} />;
        case "dividend_reinvestment_plan":
          return <DividendReinvestmentPlanConditions form={form} />;
        case "exchange_offer":
          return <ExchangeOfferConditions form={form} />;
        case "merger_consideration_election":
          return <MergerConsiderationElectionConditions form={form} />;
        case "odd_lot_offer":
          return <OddLotOfferConditions form={form} />;
        case "offer_to_purchase":
          return <OfferToPurchaseConditions form={form} />;
        case "offer_to_purchase_auction":
          return <OfferToPurchaseAuctionConditions form={form} />;
        case "rights_offer":
          return <RightsOfferConditions form={form} />;
        case "rights_offer_oversubscription":
          return <RightsOfferOverSubscriptionConditions form={form} />;
        case "buyback":
          return <BuybackConditions form={form} />;
        case "buyback_auction":
          return <BuybackAuctionConditions form={form} />;
        default:
          return;
      }
    }
  };

  return (
    <FormSection title="Offer Details" isCollapsible={false}>
      {renderConditions()}
      <Controller
        control={control}
        name="offer.title"
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              InputLabelProps={{ shrink: true }}
              sx={{ my: 2 }}
              error={!!fieldState.error}
              fullWidth={true}
              label="Offer Title*"
              size="small"
            />
          );
        }}
      />
      <Controller
        control={control}
        name="offer.detail"
        rules={{
          required:
            type === "informational" ? false : "Offer Detail is required.",
        }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              sx={{ my: 2 }}
              InputLabelProps={{ shrink: true }}
              error={!!fieldState.error}
              fullWidth={true}
              label={`Offer Detail${type !== "informational" ? "*" : ""}`}
              rows={2}
              multiline
              size="small"
            />
          );
        }}
      />
    </FormSection>
  );
};
