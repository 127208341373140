import { AxiosRequestConfig } from "axios";
import useSWR from "swr";

import { PaginatedResponse } from "src/types/PaginatedResponse";
import { nexus } from "..";
import { IQueryParams } from "../../types";
import { CorporateActionEvent } from "src/types/vca";

type ListType = "new" | "in_progress" | "open" | "historical";

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) =>
  nexus.get<PaginatedResponse<CorporateActionEvent>>(url, { params });

export function useCorporateActionEvents(
  listType: ListType,
  { limit, ordering, offset }: IQueryParams
) {
  const url = `/corporate-action-events/?tab=${listType}&limit=${limit}&ordering=${ordering}&offset=${offset}`;
  const { data, error } = useSWR(url, fetcher);

  return {
    count: data?.data?.count,
    isError: error,
    isLoading: !error && !data,
    corporateActionEvents: data?.data?.results,
  };
}
