import Cookies from "js-cookie";

const removeAuthToken = (): void => {
  const domainParts = window.location.hostname.split(".");
  const topDomain = domainParts.slice(-2).join(".");
  const domain =
    process.env.NODE_ENV === "production"
      ? topDomain
      : window.location.hostname;

  Cookies.remove("authToken", { path: "/", domain });
  return;
};

export { removeAuthToken };
