import { CircularProgress } from "@mui/material";
import {
  EmailPreviewState,
  CommunicationPreviewState,
} from "../../../types/api";
import { Box } from "src/components/atoms/Box";

interface Props {
  communication: CommunicationPreviewState;
  template: EmailPreviewState;
  selectedView: string;
}

function PreviewPane({
  template,
  communication,
  selectedView,
}: Props): JSX.Element {
  // fetching || idle
  const loading = !template || !communication;

  return (
    <Box
      sx={{
        gridArea: "previewPane",
        borderRadius: "4px",
        background: "#f8f8f8",
        margin: "0 24px",
        boxShadow:
          "0 5px 5px -3px #00000033, 0 3px 14px 2px #0000001e, 0 8px 10px 1px #00000023",
        overflowY: "auto",
        overflowX: "hidden",
        height: "calc(100vh - 17rem)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "40px",
          background: "#fff",
          overflow: "hidden",
        }}
      >
        {selectedView === "email" && template && template.status === 200 && (
          <Box component="p" sx={{ marginLeft: "14px", fontSize: "14px" }}>
            <Box component="span" sx={{ fontWeight: "500" }}>
              Subject:
            </Box>{" "}
            {template.data.subject}
          </Box>
        )}
        {selectedView === "ballot" &&
          communication &&
          communication.status === 200 && (
            <Box component="p" sx={{ marginLeft: "24px", fontSize: "14px" }}>
              {communication.data.sampleBallotUrl}
            </Box>
          )}
      </Box>
      {selectedView === "email" && template && template.status === 200 && (
        <div dangerouslySetInnerHTML={{ __html: template.data.body }} />
      )}
      {selectedView === "ballot" &&
        communication &&
        communication.status === 200 && (
          <iframe
            style={{
              height: "100%",
              width: "100%",
            }}
            src={communication.data.sampleBallotUrl}
          />
        )}

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export { PreviewPane };

// Leaving this here incase we want to add these back,
// but functionally there was no use for these "buttons"

// type BrowserButtonColor = "red" | "yellow" | "green";

// export const BrowserButton = styled.div<{ color: BrowserButtonColor }>`
//   background: ${({ color }) => {
//     switch (color) {
//       case "red":
//         return "#fd3b57";
//       case "yellow":
//         return "#ffc206";
//       case "green":
//         return "#12d43a";
//     }
//   }};
//   margin-right: 6px;
//   height: 8px;
//   width: 8px;
//   border-radius: 50%;

//   &:last-of-type {
//     margin-right: 0;
//   }
// `;
