import { AdditionalMailingBroker } from "src/types/additionalMailing";

// Flatten EligibleBrokers returned from eligble-brokers api
// Each broker has a childBrokers field and we want to roll up
// all brokers to the same level to a flat list
export function flattenEligibleBrokers(
  eligibleBrokers: AdditionalMailingBroker[]
): AdditionalMailingBroker[] {
  let flattenedList: AdditionalMailingBroker[] = [];
  eligibleBrokers.forEach((broker) => {
    flattenedList.push(broker);
    if (broker.childBrokers.length > 0) {
      // .concat returns a new array so we need to reassign flattenedList
      flattenedList = flattenedList.concat(
        flattenEligibleBrokers(broker.childBrokers)
      );
    }
  });
  return flattenedList;
}
