import {
  Autocomplete,
  MenuItem,
  SxProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import React from "react";
import { Controller, UseFormReturn, useController } from "react-hook-form";

import { Box } from "../atoms/Box";
import { CusipField } from "../atoms/CusipField";
import {
  CreateCorporateActionForm as CreateCorporateActionFormType,
  Security,
} from "src/types/vca";
import {
  getCorporateActionTypeOptions,
  getInitialOfferConditions,
} from "src/features/vca/utils";
import { OfferDetails } from "./OfferDetails/OfferDetails";
import { CorporateActionType } from "src/types/vca";
import { TIMEZONES } from "src/constants/timezones";
import { formatTimezoneLabel } from "src/utils/format-timezone-label";

interface ICreateCorporateActionDialogProps {
  form: UseFormReturn<CreateCorporateActionFormType>;
  sx?: SxProps;
}

export const CreateCorporateActionForm = ({
  form,
  sx,
}: ICreateCorporateActionDialogProps) => {
  const { clearErrors, control, setValue, watch } = form;
  const {
    field: typeField,
    fieldState: { error: typeError },
  } = useController({
    name: "type",
    control,
    rules: { required: true },
  });
  const securities = watch("securities");
  const type = watch("type");

  // Side Effect: When updating the type we want to reset all the
  // offer fields since each type has a different set of offer fields.
  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = typeField;
    const { value } = e.target;
    const title = getCorporateActionTypeOptions().find(
      (option) => option.value === value
    );
    onChange(e);
    setValue("offer", {
      title: title?.label || "",
      detail: "",
      conditions: getInitialOfferConditions(value as CorporateActionType),
    });
    clearErrors();
  };

  return (
    <form id="create-vca-form">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box sx={sx}>
          <Controller
            control={control}
            name="securities"
            rules={{ required: "Please select at least 1 security." }}
            render={({ fieldState }) => {
              return (
                <CusipField
                  hasError={!!fieldState.error}
                  label="CUSIP*"
                  setSecurities={(securities: Security[]) =>
                    setValue("securities", securities, { shouldDirty: true })
                  }
                  values={securities}
                />
              );
            }}
          />
          <TextField
            sx={{ my: 2 }}
            InputLabelProps={{ shrink: true }}
            disabled={true}
            fullWidth={true}
            label="Issuer"
            size="small"
            value={securities.length > 0 ? securities[0].issuer?.name : ""}
          />
          <Box sx={{ display: "flex" }}>
            <Controller
              name="announcementDate"
              control={control}
              defaultValue={null}
              rules={{ required: "Announcement Date is required." }}
              render={({ field, fieldState }) => {
                return (
                  <DatePicker
                    value={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    renderInput={(props: TextFieldProps) => {
                      return (
                        <TextField
                          {...props}
                          InputLabelProps={{ shrink: true }}
                          error={!!fieldState.error}
                          id="announcementDate"
                          label="Announcement Date*"
                          size="small"
                          variant="outlined"
                          fullWidth
                          sx={{ my: 2, mr: 3 }}
                        />
                      );
                    }}
                  />
                );
              }}
            />
            <Controller
              name="recordDate"
              control={control}
              defaultValue={null}
              rules={{
                required:
                  type !== "informational"
                    ? false
                    : "Record Date is required for informational filings.",
              }}
              render={({ field, fieldState }) => {
                return (
                  <DatePicker
                    value={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    renderInput={(props: TextFieldProps) => {
                      return (
                        <TextField
                          {...props}
                          sx={{ my: 2 }}
                          error={!!fieldState.error}
                          InputLabelProps={{ shrink: true }}
                          id="recordDate"
                          label={`Record Date${
                            type === "informational" ? "*" : ""
                          }`}
                          size="small"
                          variant="outlined"
                          fullWidth
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Controller
              name="electionCutoff"
              control={control}
              defaultValue={null}
              rules={{
                required:
                  type === "informational"
                    ? false
                    : "Election Cutoff is required.",
              }}
              render={({ field, fieldState }) => {
                return (
                  <DateTimePicker
                    {...field}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ my: 2, mr: 2 }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label="Election Cutoff"
                        error={!!fieldState.error}
                        size="small"
                        data-testid="Election Cutoff"
                        required={type !== "informational"}
                      />
                    )}
                  />
                );
              }}
            />
            <Controller
              name="electionCutoffTimezone"
              control={control}
              defaultValue={undefined}
              rules={{
                required:
                  type === "informational"
                    ? false
                    : "Election Cutoff Timezone is required.",
              }}
              render={({ field, fieldState }) => {
                return (
                  <Autocomplete
                    {...field}
                    id="election-cutoff-timezone"
                    options={TIMEZONES}
                    fullWidth
                    getOptionLabel={formatTimezoneLabel}
                    onChange={(_, newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Election Cutoff Timezone"
                        size="small"
                        error={!!fieldState.error}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ my: 2 }}
                        required={type !== "informational"}
                      />
                    )}
                  />
                );
              }}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Controller
              name="dtcExpiration"
              control={control}
              defaultValue={null}
              rules={{ required: "DTC Expiration Date is required." }}
              render={({ field, fieldState }) => {
                return (
                  <DateTimePicker
                    {...field}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ my: 2, mr: 2 }}
                        error={!!fieldState.error}
                        required
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label="DTC Expiration"
                        size="small"
                        data-testid="DTC Expiration"
                      />
                    )}
                  />
                );
              }}
            />
            <Controller
              name="dtcExpirationTimezone"
              control={control}
              defaultValue={undefined}
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                return (
                  <Autocomplete
                    {...field}
                    id="dtc-expiration-timezone"
                    options={TIMEZONES}
                    fullWidth
                    getOptionLabel={formatTimezoneLabel}
                    onChange={(_, newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!fieldState.error}
                        label="DTC Expiration Timezone"
                        size="small"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ my: 2 }}
                      />
                    )}
                  />
                );
              }}
            />
          </Box>
          <TextField
            {...typeField}
            sx={{ my: 2 }}
            InputLabelProps={{ shrink: true }}
            error={!!typeError}
            fullWidth
            label="VCA Type*"
            onChange={handleTypeChange}
            size="small"
            select={true}
          >
            {getCorporateActionTypeOptions().map((option) => {
              return (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
        {type && <OfferDetails type={type} form={form} />}
      </LocalizationProvider>
    </form>
  );
};
