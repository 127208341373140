import { DocumentType } from "./types";

export const getLabel = (value: DocumentType): string | null => {
  const filtered = documentTypeOptions.filter(
    (option) => option.value === value
  );
  if (filtered.length) {
    return filtered[0]["label"];
  } else return null;
};

export const documentTypeOptions: {
  value: DocumentType | "";
  label: string;
}[] = [
  {
    value: DocumentType.ProxyStatement,
    label: "Proxy Statement",
  },
  {
    value: DocumentType.CombinedDocument,
    label: "Combined Document",
  },
  {
    value: DocumentType.TenK,
    label: "Form 10-K",
  },
  {
    value: DocumentType.AnnualReport,
    label: "Annual Report",
  },
  {
    value: DocumentType.SemiAnnualReport,
    label: "Semi-Annual Report",
  },
  {
    value: DocumentType.ProxyCard,
    label: "Proxy Card",
  },
  {
    value: DocumentType.InformationStatement,
    label: "Information Statement",
  },
  {
    value: DocumentType.ShareholderLetter,
    label: "Shareholder Letter",
  },
  {
    value: DocumentType.ConsentSolicitation,
    label: "Consent Solicitation",
  },
  {
    value: DocumentType.StatutoryProspectus,
    label: "Statutory Prospectus",
  },
  {
    value: DocumentType.SummaryProspectus,
    label: "Summary Prospectus",
  },
  {
    value: DocumentType.StatementOfAdditionalInformation,
    label: "Statement of Additional Information",
  },
  {
    value: DocumentType.Sticker,
    label: "Sticker",
  },
];
