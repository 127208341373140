import axios from "axios";
import { toast } from "react-toastify";

import { createTestVCAEmail } from "src/api/adapters";
import { Broker } from "src/api/adapters/proxy/hooks/useCorporateActionBrokers";
import { useEmailPreview } from "src/api/adapters/proxy/hooks/useEmailPreview";
import { EmailPreviewPane } from "./EmailPreviewPane";

interface ISayVcaEmailPreviewPaneProps {
  broker: Broker | null;
  vcaId: string;
}

export function SayVcaEmailPreviewPane({
  broker,
  vcaId,
}: ISayVcaEmailPreviewPaneProps) {
  const {
    preview,
    isLoading,
    isError: isEmailError,
  } = useEmailPreview(vcaId, broker);

  async function handleSendTestEmail(emails: string[]) {
    if (broker) {
      try {
        await createTestVCAEmail(vcaId, broker.slug, {
          emails,
        });
      } catch (error) {
        if (axios.isAxiosError(error) && error?.response?.data) {
          toast.error(error.response.data);
        } else {
          toast.error(`An error occurred when trying to send a test email.`);
        }
      }
      toast.success("Test email sent!");
    }
  }

  return (
    <EmailPreviewPane
      brokerName={broker?.name}
      isLoading={isLoading}
      hasError={isEmailError}
      onSendTestEmail={handleSendTestEmail}
      preview={preview}
    />
  );
}
