import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Formik,
  FormikProps,
  Field,
  FieldProps,
  FormikActions,
  Form,
} from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { login } from "../../api/adapters/sso";
import { setAuthToken } from "../../utils/setToken";
import { Box, Button, TextField, Typography } from "@mui/material";
import { getCanLogIn } from "../../components/PrivateRoute/utils";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

interface LoginFormValues {
  email: string;
  password: string;
}

const loginSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
});

const handleSubmit = async (email: string, password: string) => {
  return await login({ email, password });
};

type UserTraits = {
  name: string;
  email: string;
  username: string;
};

const identifyUser = ({ id, traits }: { id: string; traits: UserTraits }) => {
  window.analytics.identify(id, traits);
};

interface LocationStateType {
  from: { pathname: string };
}

export const LogIn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const canLogIn = getCanLogIn();
    // If user is already logged in redirect to
    // proxy page (This is the "home" page for now)
    if (canLogIn) {
      navigate("/proxy");
    }
  }, []);

  return (
    <Box
      height="100%"
      minHeight="100vh"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="24px"
    >
      <Typography variant="h5" align="center" style={{ fontWeight: 500 }}>
        Mission Control{" "}
        <span role="img" aria-label="rocket">
          🚀
        </span>
      </Typography>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginSchema}
        onSubmit={(
          values: LoginFormValues,
          actions: FormikActions<LoginFormValues>
        ) =>
          handleSubmit(values.email, values.password)
            .then((response) => {
              actions.setSubmitting(false);
              actions.resetForm();
              const { token, user } = response.data;
              const canLogIn = getCanLogIn(token);

              if (canLogIn) {
                setAuthToken(token);
                identifyUser({
                  id: user.pk,
                  traits: {
                    email: user.email,
                    username: user.username,
                    name: `${user.firstName} ${user.lastName}`,
                  },
                });
                const locationState = location.state as LocationStateType;
                navigate(locationState?.from || "/proxy");
              }
            })
            .catch(() => {
              actions.setSubmitting(false);
              actions.resetForm();

              return toast.error(
                "Unable to login. Please check your credentials and try again."
              );
            })
        }
        render={({ isValid, isSubmitting }: FormikProps<LoginFormValues>) => (
          <Form style={{ marginTop: "24px" }}>
            <Field
              name="email"
              render={({ field }: FieldProps<LoginFormValues>) => (
                <TextField
                  required={true}
                  type="text"
                  {...field}
                  placeholder="email@saytechnologies.com"
                  fullWidth
                />
              )}
            />

            <Field
              name="password"
              render={({ field }: FieldProps<LoginFormValues>) => (
                <TextField
                  type="password"
                  required={true}
                  {...field}
                  placeholder="superdupersecret"
                  fullWidth
                  style={{ marginTop: "16px" }}
                />
              )}
            />

            <Button
              disabled={!isValid || isSubmitting}
              type="submit"
              color="primary"
              variant="contained"
              style={{ marginTop: "16px" }}
              fullWidth
            >
              {isSubmitting ? "Loading... ⏳" : "Log In"}
            </Button>
          </Form>
        )}
      />
    </Box>
  );
};
