import { Security } from "src/features/Proxy/types";

// Since the security on documents only return the ids
// We need to find the appropriate security objects from these ids
// This assumes the securityIds on the documents are always a subset
// of the securities on a filing
export function getSecuritiesFromIds(
  securities: Security[],
  ids: number[]
): Security[] {
  const documentSecurities: Security[] = [];
  ids.forEach((id) => {
    const found = securities.find((security) => security.id === id);
    if (found) {
      documentSecurities.push(found);
    }
  });
  return documentSecurities;
}
