import Popover from "@mui/material/Popover";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { toast } from "react-toastify";
import { useState } from "react";

type CusipTagListProps = {
  cusips: string[];
};

const CusipTagList = ({ cusips }: CusipTagListProps): JSX.Element | null => {
  const [cusipPopover, setCusipPopover] = useState<null | HTMLElement>(null);
  const handleCusipClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation(); // isolates click to popover when used on task lists
    event.preventDefault();
    setCusipPopover(event.currentTarget);
  };
  const handleCusipClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setCusipPopover(null);
  };
  const totalCusips = cusips.length;
  const firstCusip = cusips[0];
  const remainingCusipsLength = cusips.length - 1;

  if (totalCusips === 0) {
    return null;
  }

  return (
    <div>
      <Chip
        label={firstCusip}
        onClick={(e) => {
          navigator.clipboard.writeText(firstCusip);
          toast.info("Copied CUSIP", { autoClose: 1000 });
          e.stopPropagation();
        }}
        size="small"
        sx={{ cursor: "pointer" }}
      />
      {totalCusips > 1 && (
        <>
          <Button
            size="small"
            onClick={(e) => handleCusipClick(e)}
            style={{
              fontSize: "12px",
              marginLeft: "4px",
              padding: 0,
              minWidth: "36px",
            }}
          >
            {` + ${remainingCusipsLength}`}
            <div style={{ opacity: 0.5, maxWidth: "12px" }}>
              <ArrowDropDownIcon />
            </div>
          </Button>
          <Popover
            PaperProps={{
              style: { paddingTop: 3, paddingBottom: 3, minWidth: "160px" },
            }}
            open={Boolean(cusipPopover)}
            anchorEl={cusipPopover}
            onClose={handleCusipClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <ul>
              {cusips.map((cusip: string) => (
                <li
                  className="py-2 hover:bg-light-gray cursor-pointer text-center"
                  onClick={(e) => {
                    navigator.clipboard.writeText(cusip);
                    toast.info("Copied CUSIP", { autoClose: 1000 });
                    handleCusipClose(e);
                  }}
                  key={cusip}
                >
                  {cusip}
                </li>
              ))}
            </ul>
          </Popover>
        </>
      )}
    </div>
  );
};

export { CusipTagList };
