import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

import { Box } from "src/components/atoms/Box";
import { Button } from "src/components/atoms/Button";
import {
  AdditionalMailing,
  OperationsStatus,
} from "src/types/additionalMailing";
import { Dialog } from "src/components/atoms/dialog/Dialog";
import { updateAdditionalMailingStatus } from "src/api/adapters";
import { EmptyCard } from "src/components/EmptyCard";
import { capitalize } from "src/utils/capitalize";
import { AdditionalMailingDocumentList } from "src/components/organisms/AdditionalMailingDocumentList";
import { PreviewAdditionalMailingDialog } from "../PreviewAdditionalMailingDialog";
import { useAdditionalMailingEligibleBrokers } from "src/api/adapters/nexus/hooks/useAdditionalMailingEligibleBrokers";
import { AdditionalMailingKeyDetailsSection } from "./AdditionalMailingKeyDetailsSection";
import { AdditionalMailingBrokerStatsSection } from "./AdditionalMailingBrokerStatsSection";
import { flattenEligibleBrokers } from "./utils";
import { AdditionalMailingDialog } from "../AdditionalMailingDialog";

interface IAdditionalMailingDetailPanelProps {
  additionalMailing: AdditionalMailing | null;
  onAdditionalMailingUpdate: () => void;
  proxyEventId: string;
}

export function AdditionalMailingDetailPanel({
  additionalMailing,
  onAdditionalMailingUpdate,
  proxyEventId,
}: IAdditionalMailingDetailPanelProps) {
  if (!additionalMailing) {
    return (
      <EmptyCard
        body="Click on an additional mailing to get started."
        title="Additional Mailings"
        sx={{ p: 6 }}
      />
    );
  }

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [isEmailPreviewOpen, setIsEmailPreviewOpen] = useState(false);
  const { eligibleBrokers, isLoading, isError } =
    useAdditionalMailingEligibleBrokers(proxyEventId, additionalMailing.id);
  const isPreviewEnabled =
    !isLoading && !isError && eligibleBrokers && eligibleBrokers?.length > 0;
  const allBrokers = flattenEligibleBrokers(eligibleBrokers || []);

  // We only show Delete when the additional mailing is in a pending state
  // We can not change status from approved -> closed
  // "Delete" is updating the status from pending -> closed
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => setMenuAnchorEl(event.currentTarget);

  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleApproveAdditionalMailing = () => {
    handleUpdateAdditionalMailingStatus("approved");
  };

  const handleCloseAdditionalMailing = () => {
    handleUpdateAdditionalMailingStatus("closed");
  };

  const handleUpdateAdditionalMailingStatus = async (
    operationsStatus: OperationsStatus
  ) => {
    if (additionalMailing) {
      try {
        setIsUpdatingStatus(true);
        await updateAdditionalMailingStatus(
          proxyEventId,
          additionalMailing.id,
          {
            operationsStatus,
          }
        );
      } catch (e) {
        if (
          axios.isAxiosError(e) &&
          e?.response?.data?.nonFieldErrors?.length > 0
        ) {
          const errorMessage = e?.response?.data?.nonFieldErrors[0];
          toast.error(capitalize(errorMessage));
        } else {
          toast.error(
            "An error occured when trying to update the additional mailing's status."
          );
        }
      } finally {
        onAdditionalMailingUpdate();
        setIsUpdatingStatus(false);
        // Closing both dialogs since we have one handler for both
        setIsDeleteDialogOpen(false);
        setIsApproveDialogOpen(false);
      }
    }
  };

  const handleOpenApproveDialog = () => {
    setIsApproveDialogOpen(true);
  };

  const handleOpenUpdateDialog = () => {
    setIsEditDialogOpen(true);
  };

  const handleOpenPreviewDialog = () => {
    if (isPreviewEnabled) {
      setIsEmailPreviewOpen(true);
    }
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleCloseApproveDialog = () => {
    setIsApproveDialogOpen(false);
  };

  const handleCloseUpdateDialog = () => {
    setIsEditDialogOpen(false);
  };

  const handleClosePreviewDialog = () => {
    setIsEmailPreviewOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, px: 4 }}>
      <Box
        data-testid="additional_mailing_details"
        sx={{
          py: 3,
          px: 4,
          mx: -4,
          mb: 4,
          alignItems: "center",
          display: "flex",
          borderBottom: "1px solid #e5e5e5",
          height: "48px",
        }}
      >
        <Box component="b" sx={{ flexGrow: 1 }}>
          Additional Mailing Details
        </Box>
        {additionalMailing.operationsStatus === "pending" && (
          <>
            <Button
              onClick={handleOpenApproveDialog}
              disabled={!isPreviewEnabled}
              size="small"
              sx={{ mr: 2 }}
              variant="contained"
            >
              Approve
            </Button>
            <IconButton onClick={handleMenuClick}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleDeleteClick}>
                Delete Additional Mailing
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
      <AdditionalMailingKeyDetailsSection
        additionalMailing={additionalMailing}
        isPreviewEnabled={isPreviewEnabled || false}
        onEditClick={handleOpenUpdateDialog}
        onPreviewClick={handleOpenPreviewDialog}
      />
      {additionalMailing.operationsStatus !== "pending" && (
        <AdditionalMailingBrokerStatsSection
          eligibleBrokers={allBrokers || []}
        />
      )}
      <AdditionalMailingDocumentList
        additionalMailing={additionalMailing}
        onAdditionalMailingUpdate={onAdditionalMailingUpdate}
        proxyEventId={proxyEventId}
      />
      <AdditionalMailingDialog
        additionalMailing={additionalMailing}
        onClose={handleCloseUpdateDialog}
        onUpdate={onAdditionalMailingUpdate}
        open={isEditDialogOpen}
        proxyEventId={proxyEventId}
      />
      <PreviewAdditionalMailingDialog
        additionalMailingId={additionalMailing.id}
        eligibleBrokers={allBrokers || []}
        isOpen={isEmailPreviewOpen}
        onClose={handleClosePreviewDialog}
        proxyEventId={proxyEventId}
      />
      <Dialog
        isPrimaryLoading={isUpdatingStatus}
        open={isApproveDialogOpen}
        onClose={handleCloseApproveDialog}
        onSecondaryClick={handleCloseApproveDialog}
        onPrimaryClick={handleApproveAdditionalMailing}
        secondaryButtonText="Cancel"
        primaryButtonText="Approve"
        primaryButtonColor="primary"
        title="Approve Additional Mailing"
      >
        Are you sure you want to apporve this additional mailing?
      </Dialog>
      <Dialog
        isPrimaryLoading={isUpdatingStatus}
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onSecondaryClick={handleCloseDeleteDialog}
        onPrimaryClick={handleCloseAdditionalMailing}
        secondaryButtonText="Cancel"
        primaryButtonText="Delete"
        primaryButtonColor="error"
        title="Delete Additional Mailing"
      >
        Are you sure you want to delete this additional mailing?
      </Dialog>
    </Box>
  );
}
