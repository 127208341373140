const documentTypeMap = {
  amendment_to_offer_to_purchase: "Amendment to Offer to Purchase",
  client_letter: "Client Letter",
  letter_of_transmittal: "Letter of Transmittal",
  merger_proposal: "Merger Proposal",
  notice_19a: "Notice 19A",
  notice_of_guaranteed_delivery: "Notice of Guaranteed Delivery",
  offering_circular: "Offering Circular",
  offer_to_purchase: "Offer to Purchase",
  other: "Other",
  press_release: "Press Release",
  schedule_14d_9: "Schedule 14D 9",
  schedule_to: "Schedule To",
  shareholder_letter: "Shareholder Letter",
};

export default documentTypeMap;
