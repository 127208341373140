import { CorporateActionEvent } from "src/types/vca";

export const getFormDefaultValues = (
  corporateActionEvent: CorporateActionEvent
) => {
  return {
    announcementDate: corporateActionEvent.announcementDate
      ? new Date(corporateActionEvent.announcementDate + "T00:00:00")
      : null,
    dtcExpiration: corporateActionEvent.dtcExpiration,
    dtcExpirationTimezone: corporateActionEvent.dtcExpirationTimezone,
    electionCutoff: corporateActionEvent.electionCutoff
      ? corporateActionEvent.electionCutoff
      : null,
    electionCutoffTimezone: corporateActionEvent.electionCutoffTimezone,
    recordDate: corporateActionEvent.recordDate
      ? new Date(corporateActionEvent.recordDate + "T00:00:00")
      : null,
    operationsStatus: corporateActionEvent.operationsStatus,
    offer: corporateActionEvent.offer,
    securities: corporateActionEvent.securities,
    type: corporateActionEvent.type,
  };
};
