import React from "react";

const Input = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
): JSX.Element => {
  return (
    <input
      className={`border border-light-gray rounded outline-none h-8 px-2 focus:border-primary-text w-full disabled:bg-very-light-gray disabled:cursor-not-allowed ${props.className}`}
      {...props}
    />
  );
};

export { Input };
