import { Box } from "src/components/atoms/Box";
import { VoteTransmission } from "src/types/voteTransmission";
import { EmptyCard } from "src/components/EmptyCard";
import { VoteTransmissionKeyDetailsSection } from "./VoteTransmissionKeyDetailsSection";
import { VoteTransmissionFiles } from "./VoteTransmissionFiles";

interface IVoteTransmissionDetailPanelProps {
  voteTransmission: VoteTransmission | undefined;
}

export function VoteTransmissionDetailPanel({
  voteTransmission,
}: IVoteTransmissionDetailPanelProps) {
  if (!voteTransmission) {
    return (
      <EmptyCard
        body="Click on a vote transmission to get started."
        title="Vote Transmissions"
        sx={{ p: 6 }}
      />
    );
  }

  return (
    <Box sx={{ flexGrow: 1, px: 4 }}>
      <Box
        data-testid="vote_transmission_details"
        sx={{
          py: 3,
          px: 4,
          mx: -4,
          mb: 4,
          alignItems: "center",
          display: "flex",
          borderBottom: "1px solid #e5e5e5",
          height: "48px",
        }}
      >
        <Box component="b" sx={{ flexGrow: 1 }}>
          Vote Transmission Details
        </Box>
      </Box>
      <VoteTransmissionKeyDetailsSection voteTransmission={voteTransmission} />
      <VoteTransmissionFiles voteTransmission={voteTransmission} />
    </Box>
  );
}
