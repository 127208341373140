import { AxiosError, AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import { responseInterface } from "swr";

import { SectionTitle } from "../SectionTitle";
import { pluralize } from "../../../utils/pluralize";
import { FilingsResponse } from "../Activity";
import { Label } from "../../../components/Label";
import { ItemRow } from "../ItemRow";
import { CusipTagList } from "../../../components/CusipTagList";
import { formatDate } from "../../../utils/format-date";
import { humanize } from "../../../utils/humanize";
import { StatusLabel } from "../StatusLabel";

type FilingsListProps = {
  cache: responseInterface<AxiosResponse<FilingsResponse>, AxiosError>;
};

const FilingsList = ({ cache }: FilingsListProps): JSX.Element | null => {
  const filings = cache.data ? cache.data.data : [];
  const count = filings.length;

  if (count > 0)
    return (
      <div className="mt-8 first:mt-0">
        <SectionTitle>
          {count}{" "}
          {pluralize({
            singular: "filing",
            plural: "filings",
            count,
          })}
        </SectionTitle>

        <div className="grid gap-4 grid-cols-7 border-t border-light-gray p-4">
          <Label>Status</Label>
          <Label>Cusip</Label>
          <Label>Issuer Name</Label>
          <Label>Edgar</Label>
          <Label>Filing Type</Label>
          <Label>Record Date</Label>
          <Label>Meeting Date</Label>
        </div>

        <div>
          {filings.map((filing) => (
            <Link
              to={`/proxy/${filing.id}`}
              key={filing.id}
              className="group mt-2 first:mt-0 block hover:no-underline hover:text-primary-text"
            >
              <ItemRow className="grid gap-4 grid-cols-7 group-hover:shadow-lg transition duration-150 ease-in-out">
                <StatusLabel status={filing.operationsStatus} />
                <CusipTagList cusips={filing.cusips} />
                <p>
                  <span className="font-medium">{filing.tickers[0]}</span>{" "}
                  {filing.issuerName}
                </p>
                <p>
                  {filing.edgarDate && filing.edgarUrl ? (
                    <a
                      href={filing.edgarUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue hover:underline"
                      onClick={(event) => event.stopPropagation()}
                    >
                      {formatDate(new Date(filing.edgarDate))}
                    </a>
                  ) : (
                    <span className="text-gray">n/a</span>
                  )}
                </p>
                <p className="capitalize">{humanize(filing.type)}</p>
                <p>
                  {filing.recordDate ? (
                    formatDate(new Date(filing.recordDate))
                  ) : (
                    <span className="text-gray">n/a</span>
                  )}
                </p>
                <p>
                  {filing.meetingDate ? (
                    formatDate(new Date(filing.meetingDate))
                  ) : (
                    <span className="text-gray">n/a</span>
                  )}
                </p>
              </ItemRow>
            </Link>
          ))}
        </div>
      </div>
    );

  return null;
};

export { FilingsList };
