import { formatNumber } from "../../utils/format-number";

type PaginationPageSizeProps = {
  count: number;
  pageSize: number;
  onChange: (pageSize: number) => void;
};

const PaginationPageSize = ({
  count,
  pageSize,
  onChange,
}: PaginationPageSizeProps): JSX.Element => {
  return (
    <div className="flex items-center uppercase text-xs font-medium text-secondary-text">
      Show:{" "}
      <select
        className="border border-light-gray text-secondary-text text-xs font-medium appearance-none rounded px-1 text-center mx-1 bg-transparent"
        style={{ paddingTop: "0.125rem", paddingBottom: "0.125rem" }}
        onChange={(event) => onChange(Number(event.currentTarget.value))}
        value={pageSize}
      >
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
      </select>{" "}
      of {formatNumber(count)}
    </div>
  );
};

export { PaginationPageSize };
