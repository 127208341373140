import axios from "axios";
import { format } from "date-fns";
import { FormikProps } from "formik";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";

import { Document, FilingDetails, FilingDetailsFormValues } from "../types";
import { FilingDatePicker } from "./FilingDatePicker";
import { SayDocumentRow } from "src/components/organisms/SayDocumentRow";
import { Dropzone } from "src/components/atoms/Dropzone";
import {
  SayDocumentDialog,
  SayDocumentForm,
} from "src/components/SayDocumentDialog/SayDocumentDialog";
import { useProxy } from "src/api/adapters/proxy/hooks/useProxy";
import { createAttachment, uploadAttachment } from "src/api/adapters";
import { Box } from "src/components/atoms/Box";

interface IMaterialsDetailsProps {
  filing: FilingDetails;
  formikBag: FormikProps<FilingDetailsFormValues>;
  hasPaper: boolean;
  onDocumentPreviewClick: (doc: Document) => void;
}

export const MaterialsDetails = ({
  filing,
  formikBag,
  hasPaper,
  onDocumentPreviewClick,
}: IMaterialsDetailsProps) => {
  const { mutate } = useProxy(String(filing.id));
  const [documentToCreate, setDocumentToCreate] = useState<File | null>(null);

  const handleAddDocument = (files: File[]) => {
    setDocumentToCreate(files[0]);
  };

  const handleCloseCreateDialog = () => {
    setDocumentToCreate(null);
  };

  const handleCreateDocument = async (values: SayDocumentForm) => {
    const { effectiveDate, name, securities, shouldDistribute, type } = values;
    try {
      const formData = new FormData();
      formData.append(
        "file",
        documentToCreate as File,
        (documentToCreate as File).name
      );

      const attachmentPayload = {
        filing: filing.id,
        securities: securities.map((security) => security.id),
        name,
        type,
        shouldDistributeInNextMailing: shouldDistribute,
        effectiveDate: effectiveDate
          ? moment(effectiveDate).format("yyyy-MM-DD")
          : format(new Date(), "yyyy-MM-dd"),
      };
      const attachmentsResponse = await createAttachment(attachmentPayload);
      await uploadAttachment(attachmentsResponse.data.id, formData);
      toast.success("Upload complete!");
      mutate();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error);
      } else {
        toast.error(
          "An unexpected error occurred. Unable to create new document."
        );
      }
    } finally {
      setDocumentToCreate(null);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div className="grid grid-cols-2 gap-6">
        <FilingDatePicker
          label="Digital Date"
          name="digitalMaterialsReceivedDate"
          formikBag={formikBag}
        />
        {hasPaper && (
          <FilingDatePicker
            label="Paper Date"
            name="paperMaterialsReceivedDate"
            formikBag={formikBag}
          />
        )}
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
          paddingTop: "12px",
        }}
      >
        {filing.documents.map((document: Document) => {
          const handlePreviewDocument = () => {
            onDocumentPreviewClick(document);
          };
          return (
            <SayDocumentRow
              key={document.id}
              document={document}
              filing={filing}
              onDocumentPreviewClick={handlePreviewDocument}
            />
          );
        })}
        <Dropzone multiple={false} onDrop={handleAddDocument} pdfOnly={true} />
        <SayDocumentDialog
          filing={filing}
          isOpen={!!documentToCreate}
          onClose={handleCloseCreateDialog}
          onCreateUpdate={handleCreateDocument}
        />
      </Box>
    </Box>
  );
};
