import DOMPurify from "dompurify";
import React, { useState } from "react";

import { Box } from "src/components/atoms/Box";
import { Button } from "src/components/atoms/Button";
import { Input } from "src/components/Input";
import { validateEmailAddress } from "src/utils/validate-email-address";
import { EmptyCard } from "../EmptyCard";
import { ErrorCard } from "../ErrorCard";
import { LoadingCard } from "../LoadingCard/LoadingCard";

type Preview = {
  body: string;
  subject: string;
};

interface IEmailPreviewPaneProps {
  brokerName?: string;
  isLoading: boolean;
  hasError: boolean;
  onSendTestEmail: (emails: string[]) => void;
  preview: Preview;
}

export const EmailPreviewPane = ({
  brokerName,
  isLoading,
  hasError,
  onSendTestEmail,
  preview,
}: IEmailPreviewPaneProps) => {
  const [testEmailAddresses, setTestEmailAddresses] = useState<string[]>([]);

  function validateAllEmailAddresses() {
    return testEmailAddresses.every(validateEmailAddress);
  }

  if (hasError) {
    return (
      <ErrorCard
        title="Email Preview"
        body="Error when trying to load template."
      />
    );
  }

  if (!brokerName) {
    return (
      <EmptyCard
        title="Email Preview"
        body="Select a Broker to preview email."
      />
    );
  }

  if (isLoading) {
    return <LoadingCard />;
  }

  const handleSendTestEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSendTestEmail(testEmailAddresses);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTestEmailAddresses(e.currentTarget.value.split(","));
  };

  return (
    <Box
      sx={{
        border: "1px solid #e5e5e5",
        backgroundColor: "#f8f8f8",
        flexGrow: 1,
      }}
    >
      <form
        onSubmit={handleSendTestEmail}
        className="flex items-center px-2 py-3"
      >
        <Input
          data-testid="test-email-input"
          placeholder="Send test email (separate emails by comma)"
          onChange={handleEmailChange}
          value={testEmailAddresses.join(",")}
        />
        <Button
          color="primary"
          disabled={!testEmailAddresses.length || !validateAllEmailAddresses()}
          data-testid="sent-test-email-button"
          size="small"
          type="submit"
        >
          Send
        </Button>
      </form>
      <Box
        sx={{
          backgroundColor: "#313130",
          color: "#f8f8f8",
          fontSize: ".875rem",
          fontWeight: 500,
          height: "2rem",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {brokerName}
      </Box>
      <Box
        component="p"
        sx={{
          height: "2rem",
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          fontSize: ".875rem",
          px: 2,
          whiteSpace: "nowrap",
          borderBottom: "1px solid #e5e5e5",
        }}
      >
        <Box component="b" sx={{ mr: 1 }}>
          Subject:
        </Box>
        {preview.subject}
      </Box>
      <Box
        sx={{
          height: "75vh",
          overflow: "auto",
        }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(preview.body),
        }}
      />
    </Box>
  );
};
