import { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import { FormikActions, FormikValues } from "formik";
import { createFilterOptions } from "@mui/material/Autocomplete";
import useSWR from "swr";
import { FilterOptionsState } from "@mui/material/useAutocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { cusipReqLength } from "../../../constants/values";

import { proxy } from "../../../api/adapters/proxy";
import { Security } from "../../../features/Proxy/types";
import { toast } from "react-toastify";

type AutoCompleteFilter = (
  options: Security[],
  state: FilterOptionsState<Security>
) => unknown[];

type Props = {
  setFieldValue: FormikActions<FormikValues>["setFieldValue"];
  values: Security[] | [];
  issuerId: string | null;
  field: string;
  optionLabelField: "cusip";
  label: string;
};

const filter: AutoCompleteFilter = createFilterOptions();

const fetcher = (url: string) => proxy.get<Security[]>(url);

/**
 * Typeahead for dynamically pulling cusip options from API
 * @param setFieldValue - formik set field
 * @param values - array of currently selected values
 * @param field - field within value object for display
 * @param issuerId - ID of active issuer, for filtering of cusip options
 * @param label - input label
 * @param optionLabelField - field to be used for display of typeahead option
 * @returns FC
 */
const DynamicCusipTypeahead = ({
  setFieldValue,
  values,
  field,
  issuerId,
  label,
  optionLabelField,
}: Props): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState(""); // What user types
  const [query, setQuery] = useState(""); // Debounced updated value for API
  const debounceSetQuery = useCallback(debounce(setQuery, 200), []);
  const { data, error } = useSWR(query, fetcher, {
    revalidateOnFocus: false,
  }); // use query instead of searchTerm for debounce
  const currentValue = values || [];
  const loading = !data && !error;

  // Copying the cusip inside the chip to user's clipboard
  const handleCusipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.target as HTMLElement;
    navigator.clipboard.writeText(element.innerText);
    toast.info("Copied CUSIP", { autoClose: 1000 });
    event.stopPropagation();
  };

  /**
   * Every time the search term changes, update the query on debounce
   */
  useEffect(() => {
    const queryTemplate = `/issuers/cusip-typeahead/?cusip={{searchTerm}}${
      issuerId !== null ? `&issuer_id=${issuerId}` : ""
    }`;
    debounceSetQuery(
      queryTemplate.replace("{{searchTerm}}", encodeURIComponent(searchTerm))
    );
  }, [searchTerm, debounceSetQuery, issuerId]);

  /**
   * Show add new option if item does not currently exist,
   * api is not loading, contains at least 9 characters
   * and is only capital letters and numbers
   * @param options - list of current options
   * @returns boolean
   */
  const showAddNew = (options: Security[]) =>
    searchTerm.length === cusipReqLength &&
    options.length === 0 &&
    !loading &&
    searchTerm.match(/^[0-9A-Z]+$/);

  // parse securities to extract needed data
  const dynamicOptions = data ? data.data : [];
  return (
    <Autocomplete
      multiple
      limitTags={3}
      options={dynamicOptions}
      value={currentValue}
      loading={loading}
      inputValue={searchTerm}
      getOptionLabel={(option) =>
        option.addNew
          ? `Add "${option[optionLabelField]}"`
          : option[optionLabelField]
      }
      filterOptions={(options, params) => {
        const filtered = filter(options, params) as Security[];
        // Suggest the creation of a new value
        if (showAddNew(filtered)) {
          filtered.push({
            id: NaN,
            [optionLabelField]: params.inputValue,
            addNew: true,
          });
        }

        return filtered;
      }}
      isOptionEqualToValue={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value) || !value.id
      }
      filterSelectedOptions
      ChipProps={{ onClick: handleCusipClick, size: "small" }}
      onChange={(_, changeValue) => {
        if (data) {
          if (changeValue.length > 0) {
            const latestValue = changeValue[changeValue.length - 1];
            if (latestValue.addNew) {
              delete latestValue.addNew;
              // call api to add
            } else if (!issuerId) {
              // get related issuer if its not a net new cusip
              const selectedIssuer = latestValue.issuer;
              selectedIssuer &&
                setFieldValue("issuer", {
                  companyName: selectedIssuer.companyName,
                  contactEmail: selectedIssuer.contactEmail,
                  id: selectedIssuer.id,
                  globalIssuerId: selectedIssuer.globalIssuerId,
                });
            }
          } else {
            // manually clear issuer if values are deleted until empty
            setFieldValue("issuer", {
              companyName: "",
              contactEmail: "",
              id: null,
              globalIssuerId: null,
            });
          }
        }
        return setFieldValue(field, changeValue);
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === "clear") {
          setFieldValue(field, []);
          setFieldValue("issuer", {
            companyName: "",
            contactEmail: "",
            id: null,
            globalIssuerId: null,
          });
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export { DynamicCusipTypeahead };
