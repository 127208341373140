import { createContext, ReactNode, useReducer, useContext } from "react";
import { deleteFiling } from "../../api/adapters/proxy";

type Action =
  | { type: "SELECT_FILING"; filing: number; path: string }
  | { type: "DESELECT_FILING"; filing: number }
  | { type: "SELECT_ALL_FILINGS"; filings: number[]; path: string }
  | { type: "DESELECT_ALL_FILINGS" }
  | { type: "ADD_FINISHED_FILING"; filing: number }
  | { type: "RESET_BATCH" }
  | { type: "OPEN_MESSAGE"; message: string }
  | { type: "CLOSE_MESSAGE" };

type Dispatch = (action: Action) => void;

interface State {
  taskListPath: string;
  filings: number[];
  finishedFilings: number[];
  message: string;
}

interface BatchFilingsProviderProps {
  children: ReactNode;
}

const BatchFilingsStateContext = createContext<State | undefined>(undefined);
const BatchFilingsDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

const initialState = {
  taskListPath: "",
  filings: [],
  finishedFilings: [],
  message: "",
};

function batchFilingsReducer(state: State, action: Action) {
  switch (action.type) {
    case "SELECT_FILING": {
      if (state.taskListPath === action.path) {
        return {
          ...state,
          filings: [...state.filings, action.filing],
          taskListPath: action.path,
        };
      } else {
        return {
          filings: [action.filing],
          taskListPath: action.path,
          finishedFilings: [],
          message: "",
        };
      }
    }
    case "DESELECT_FILING":
      return {
        ...state,
        filings: state.filings.filter((filing) => filing !== action.filing),
      };
    case "SELECT_ALL_FILINGS": {
      if (state.taskListPath === action.path) {
        return {
          ...state,
          filings: action.filings,
          taskListPath: action.path,
        };
      } else {
        return {
          filings: action.filings,
          taskListPath: action.path,
          finishedFilings: [],
          message: "",
        };
      }
    }
    case "DESELECT_ALL_FILINGS":
      return {
        filings: [],
        taskListPath: "",
        finishedFilings: [],
        message: "",
      };
    case "ADD_FINISHED_FILING":
      return {
        ...state,
        finishedFilings: state.finishedFilings.includes(action.filing)
          ? state.finishedFilings
          : [...state.finishedFilings, action.filing],
      };
    case "RESET_BATCH":
      return initialState;
    case "OPEN_MESSAGE":
      return {
        ...state,
        message: action.message,
      };
    case "CLOSE_MESSAGE":
      return {
        ...state,
        message: "",
      };
  }
}

function BatchFilingsProvider({
  children,
}: BatchFilingsProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(batchFilingsReducer, initialState);

  return (
    <BatchFilingsStateContext.Provider value={state}>
      <BatchFilingsDispatchContext.Provider value={dispatch}>
        {children}
      </BatchFilingsDispatchContext.Provider>
    </BatchFilingsStateContext.Provider>
  );
}

function useBatchFilingsState(): State {
  const context = useContext(BatchFilingsStateContext);
  if (context === undefined) {
    throw new Error(
      "useBatchFilingsState must be used within a BatchFilingsProvider"
    );
  }
  return context;
}

function useBatchFilingsDispatch(): Dispatch {
  const context = useContext(BatchFilingsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useBatchFilingsDispatch must be used within a BatchFilingsProvider"
    );
  }
  return context;
}

function deleteFilings(
  dispatch: Dispatch,
  filings: number[],
  mutate: () => void
): void {
  Promise.all(filings.map((filing) => deleteFiling(String(filing))))
    .then(function () {
      dispatch({ type: "DESELECT_ALL_FILINGS" });
      mutate();
    })
    .catch(function (error) {
      console.error(error);
    });
}

export {
  BatchFilingsProvider,
  useBatchFilingsState,
  useBatchFilingsDispatch,
  deleteFilings,
};
