/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikProps } from "formik";
import { FilingDetailsFormValues, IFilingCreateValues } from "../types";
import { TextField, TextFieldProps } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { Moment } from "moment";

type FormikBag =
  | FormikProps<FilingDetailsFormValues>
  | FormikProps<IFilingCreateValues>;

type DateProps = {
  formikBag: FormikBag;
  name: string;
  label: string;
  required?: boolean;
};

const INPUT_FORMAT = "MM/DD/YYYY";
const DASH_FORMAT = "yyyy-MM-dd";

export const FilingDatePicker = ({
  name,
  label,
  required = false,
  formikBag,
}: DateProps): JSX.Element => {
  const getValueFromName = () => {
    // we have to check for a . here because Formik supports nested values
    //
    // looking these values up with . isn't valid JS, so we have to lookup
    // using brackets instead
    if (name.includes(".")) {
      const valueParts = name.split(".");
      // ignoring this line of TS temporarily until we can come up with a fix
      // for the index signatrure lookups
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return formikBag.values[valueParts[0] as keyof typeof formikBag.values][
        valueParts[1]
      ];
    } else {
      return formikBag.values[name as keyof typeof formikBag.values] as string;
    }
  };

  return (
    <DatePicker
      value={getValueFromName()}
      renderInput={(props: TextFieldProps) => (
        <TextField
          {...props}
          name={name}
          fullWidth
          size="small"
          variant="outlined"
          required={required}
          label={label}
          InputLabelProps={{ shrink: true }}
          error={
            formikBag.touched[name as keyof typeof formikBag.touched] &&
            Boolean(formikBag.errors[name as keyof typeof formikBag.errors])
          }
          helperText={
            formikBag.touched[name as keyof typeof formikBag.touched] &&
            formikBag.errors[name as keyof typeof formikBag.errors]
          }
        />
      )}
      inputFormat={INPUT_FORMAT}
      onChange={(selectedDate: Moment | null) => {
        const formatDate = (date: Moment) => {
          if (!date) return new Date().toLocaleString();
          // Get the timezone from browser using native methods
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const dateTmp = Date.parse(date.toLocaleString());
          const localDate = zonedTimeToUtc(dateTmp, timezone);
          return format(localDate, DASH_FORMAT);
        };

        if (selectedDate === null) {
          formikBag.setFieldError(name, "");
          formikBag.setFieldValue(name, null);
        }
        if (selectedDate && selectedDate.isValid()) {
          formikBag.setFieldError(name, "");
          formikBag.setFieldValue(name, formatDate(selectedDate));
        }
      }}
    />
  );
};
