import { Edit, DeleteOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Box } from "src/components/atoms/Box";

interface Props {
  isVisible: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

function BatchActionButtons({
  isVisible,
  onEdit,
  onDelete,
}: Props): JSX.Element {
  return (
    <Box
      sx={{
        display: isVisible ? "flex" : "none",
        alignItems: "center",
        position: "absolute",
        left: 0,
        top: "8px",
        background: "#fff",
        zIndex: 1,
      }}
    >
      <IconButton onClick={onEdit}>
        <Edit />
      </IconButton>
      <IconButton onClick={onDelete}>
        <DeleteOutline />
      </IconButton>
    </Box>
  );
}

export { BatchActionButtons };
