import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

function getCanLogIn(jwt?: string): boolean {
  jwt = jwt || Cookies.get("authToken");

  if (jwt) {
    const decodedJWT: { groups: string[] } = jwtDecode(jwt);
    const canLogIn: boolean =
      (decodedJWT.groups.includes("proxy_staff") ||
        decodedJWT.groups.includes("proxy_admins")) &&
      decodedJWT.groups.includes("hub_admin");

    return canLogIn;
  }

  return false;
}

export { getCanLogIn };
