import useSWR from "swr";
import { proxy } from "..";
import { BrokersResponseBroker } from "../../../../features/Proxy/types";

const fetcher = (url: string) => {
  return proxy.get<[BrokersResponseBroker]>(url);
};

export function useBrokers(id: string) {
  const url = `/filings/${id}/brokers/`;
  const { data, error, mutate } = useSWR(url, fetcher);

  return {
    isError: error,
    isLoading: !data && !error,
    brokers: data?.data,
    mutate,
  };
}
