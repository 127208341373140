import { TextField } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";

import { CreateCorporateActionForm } from "src/types/vca";

interface IConsentSolicitationConditionsProps {
  form: UseFormReturn<CreateCorporateActionForm>;
}

export const ConsentSolicitationConditions = ({
  form,
}: IConsentSolicitationConditionsProps) => {
  const { control } = form;
  return (
    <Controller
      control={control}
      name="offer.conditions.payment"
      rules={{
        min: {
          value: 0,
          message: "Payment cannot be a negative number",
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            sx={{ mb: 2 }}
            InputProps={{ inputProps: { min: 0 } }}
            InputLabelProps={{ shrink: true }}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            fullWidth={true}
            label="Consent Payment"
            size="small"
          />
        );
      }}
    />
  );
};
