import { IconButton, Menu, MenuItem, Stack, TextField } from "@mui/material";
import { ArrowDropDown, MoreHoriz } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useNobo } from "src/api/adapters/nexus/hooks/useNobo";
import { Avatar } from "src/components/atoms/Avatar";
import { Box } from "src/components/atoms/Box";
import { LoadingState } from "src/components/LoadingState";
import { FormSection } from "src/components/molecules/FormSection";
import { LabelField } from "src/components/molecules/LabelField";
import { NoboForm } from "src/components/NoboForm/NoboForm";
import { PageWrapper } from "src/components/PageWrapper";
import { PageHeader } from "src/components/molecules/PageHeader";
import { OperationsStatus } from "src/types/nobo";
import { Button } from "src/components/atoms/Button";
import { Dialog } from "src/components/atoms/dialog/Dialog";
import { updateNoboRequestStatus } from "src/api/adapters";
import { capitalize } from "src/utils/capitalize";
import { NoboStatusBadge } from "src/components/molecules/NoboStatusBadge";
import { NoboDialog } from "src/components/NoboDialog/NoboDialog";

export function NoboDetails() {
  const { id } = useParams() as { id: string };
  const { nobo, isLoading, mutate } = useNobo(id);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const form = useForm<NoboForm>({
    defaultValues: {
      solicitor: undefined,
      dateReceived: null,
      frontLink: undefined,
      securities: [],
      recordDate: null,
      notes: undefined,
    },
  });
  const { reset } = form;

  useEffect(() => {
    if (nobo) {
      reset({
        solicitor: nobo.solicitor,
        dateReceived: nobo.dateReceived,
        frontLink: nobo.frontLink,
        securities: nobo.securities,
        recordDate: nobo.recordDate,
        notes: nobo.notes,
      });
    }
  }, [nobo]);

  if (isLoading || !nobo) {
    return <LoadingState />;
  }

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => setMenuAnchorEl(event.currentTarget);

  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleOpenApproveDialog = () => {
    setIsApproveDialogOpen(true);
  };

  const handleOpenEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setIsDeleteDialogOpen(true);
  };

  const handleCloseApproveDialog = () => {
    setIsApproveDialogOpen(false);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const handleLocationMenuClose = () => {
    setAnchorEl(null);
  };

  const handleApproveVoteTransmission = () =>
    updateNoboOperationsStatus("approved");

  const handleCloseVoteTransmission = () =>
    updateNoboOperationsStatus("closed");

  const updateNoboOperationsStatus = async (
    operationsStatus: OperationsStatus
  ) => {
    try {
      setIsUpdating(true);
      await updateNoboRequestStatus(nobo.id, { operationsStatus });
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        e?.response?.data?.nonFieldErrors?.length > 0
      ) {
        const errorMessage = e?.response?.data?.nonFieldErrors[0];
        toast.error(capitalize(errorMessage));
      } else {
        toast.error(
          "An error occured when trying to update NOBO request's status."
        );
      }
    } finally {
      mutate();
      setIsUpdating(false);
      setIsDeleteDialogOpen(false);
      setIsApproveDialogOpen(false);
    }
  };

  const { noboEventNoboFileExportJobs, noboEventStats } = nobo;
  // Since Export Jobs are sorted dsc order on creation date
  // we can just get the first one to show the latest export job
  const latestExportJob = noboEventNoboFileExportJobs[0];
  const locations =
    latestExportJob?.noboFiles?.map((file) => `${file.s3Key}`) || [];

  const handleLocationClick = (location: string) => {
    navigator.clipboard.writeText(location);
    toast.info("Copied s3 url", { autoClose: 1000 });
  };

  // Open the location menu when we click on down chevron
  const handleMoreLocationClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const locationValue =
    locations.length > 0 ? (
      <Box
        sx={{
          color: "#1853b4",
          cursor: "pointer",
          display: "flex",
        }}
        onClick={() => handleLocationClick(locations[0])}
      >
        {locations[0].length > 10
          ? `...${locations[0].slice(-10)}`
          : locations[0]}
        {locations.length > 1 && (
          <Box
            component="span"
            sx={{
              fontSize: "12px",
              ml: "8px",
              p: 0,
              minWidth: "36px",
              color: "black",
              opacity: 0.5,
            }}
            onClick={handleMoreLocationClick}
          >
            {`+ ${locations.length - 1}`}
            <ArrowDropDown />
          </Box>
        )}
      </Box>
    ) : (
      "-"
    );

  return (
    <>
      <PageHeader
        icon={<Avatar name="no" />}
        title={`NOBO Request: ${nobo.securities[0]?.issuer?.name || ""}`}
        details={`Date Received: ${nobo.dateReceived}`}
      >
        <Stack flexGrow={1} alignItems="center" flexDirection="row">
          <Box sx={{ ml: 4, flexGrow: 1 }}>
            <NoboStatusBadge status={nobo.operationsStatus} />
          </Box>
          {nobo.operationsStatus === "new" && (
            <Button
              onClick={handleOpenApproveDialog}
              size="small"
              sx={{ mr: 2 }}
              variant="contained"
            >
              Approve
            </Button>
          )}
          <IconButton onClick={handleMenuClick}>
            <MoreHoriz />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleDeleteClick}>Delete NOBO request</MenuItem>
          </Menu>
        </Stack>
      </PageHeader>
      <PageWrapper sx={{ mx: "auto", width: "772px" }}>
        <FormSection
          sx={{ mt: 4 }}
          isCollapsible={false}
          title="Latest Export Job Details"
        >
          <Box
            data-testid="latest_export_job_details"
            sx={{
              display: "flex",
              mt: 4,
              mb: 6,
              p: 4,
              justifyContent: "space-between",
              border: "1px solid #e5e5e5",
              backgroundColor: "#f8f8f8",
            }}
          >
            <LabelField
              label="Positions"
              sx={{ width: "25%" }}
              value={noboEventStats?.totalPositions || "-"}
            />
            <LabelField
              label="Total shares"
              sx={{ width: "25%" }}
              value={noboEventStats?.totalShares || "-"}
            />
            <LabelField
              label="Delivered at"
              sx={{ width: "25%" }}
              value={
                latestExportJob
                  ? moment(latestExportJob.deliveredAt).format("yyyy-MM-DD")
                  : "-"
              }
            />
            <LabelField
              label="Location"
              sx={{ width: "25%" }}
              value={locationValue}
            />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleLocationMenuClose}
            >
              {locations.map((location, index) => {
                const handleLocationClick = () => {
                  navigator.clipboard.writeText(location);
                  toast.info("Copied location s3 url", { autoClose: 1000 });
                };
                return (
                  <MenuItem
                    dense={true}
                    key={`location-${index}`}
                    onClick={handleLocationClick}
                  >
                    {location.length > 10
                      ? `...${location.slice(-10)}`
                      : location}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        </FormSection>
        <FormSection
          onEditClick={
            nobo.operationsStatus === "new" ? handleOpenEditDialog : undefined
          }
          isCollapsible={false}
          title="NOBO Details"
        >
          <Box
            data-testid="nobo_details"
            sx={{
              mt: 4,
              p: 4,
              border: "1px solid #e5e5e5",
              backgroundColor: "#f8f8f8",
            }}
          >
            <LabelField
              label="Cusip"
              value={nobo.securities.map((s) => s.cusip).join(",") || "-"}
            />
            <LabelField
              label="Solicitor"
              sx={{ mt: 3 }}
              value={nobo.solicitor.name || "-"}
            />
            <LabelField
              label="Front Link"
              sx={{ mt: 3 }}
              value={nobo.frontLink || "-"}
            />
            <LabelField
              label="Date Received"
              sx={{ mt: 3 }}
              value={moment(nobo.dateReceived).format("yyyy-MM-DD")}
            />
            <LabelField
              label="Record Date"
              sx={{ mt: 3 }}
              value={moment(nobo.recordDate).format("yyyy-MM-DD")}
            />
          </Box>
        </FormSection>
        <FormSection title="Others" isCollapsible={false}>
          <TextField
            disabled
            sx={{ my: 2 }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            rows={3}
            multiline
            label="Notes*"
            size="small"
            value={nobo.notes}
          />
        </FormSection>
      </PageWrapper>
      <NoboDialog
        isOpen={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        onUpdate={mutate}
        nobo={nobo}
      />
      <Dialog
        isPrimaryLoading={isUpdating}
        open={isApproveDialogOpen}
        onClose={handleCloseApproveDialog}
        onSecondaryClick={handleCloseApproveDialog}
        onPrimaryClick={handleApproveVoteTransmission}
        secondaryButtonText="Cancel"
        primaryButtonText="Approve"
        primaryButtonColor="primary"
        title="Approve NOBO Request"
      >
        Are you sure you want to approve this NOBO request?
      </Dialog>
      <Dialog
        isPrimaryLoading={isUpdating}
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onSecondaryClick={handleCloseDeleteDialog}
        onPrimaryClick={handleCloseVoteTransmission}
        secondaryButtonText="Cancel"
        primaryButtonText="Delete"
        primaryButtonColor="error"
        title="Delete NOBO Request"
      >
        Are you sure you want to delete this NOBO request?
      </Dialog>
    </>
  );
}
