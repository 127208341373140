import { useState } from "react";
import { Outlet } from "react-router-dom";

import { SideNavigationBar } from "../SideNavigationBar";
import { Header } from "../Header";
import { Box } from "../atoms/Box";

const AppLayout = (): JSX.Element => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const handleToggleNav = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <Box
      sx={{
        background: "#ffffff",
        display: "grid",
        gridTemplateColumns: isNavCollapsed ? "5rem 1fr" : "12.5rem 1fr",
      }}
    >
      <SideNavigationBar
        isCollapsed={isNavCollapsed}
        onToggleNav={handleToggleNav}
      />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          minWidth: "75rem",
        }}
      >
        <Header />
        <Outlet />
      </Box>
    </Box>
  );
};

export { AppLayout };
