type TickerAvatarProps = {
  tickers: string[];
};

const TickerAvatar = ({ tickers }: TickerAvatarProps): JSX.Element => {
  const isMultiple = tickers.length > 1;
  const isZero = tickers.length === 0;

  if (isZero) {
    return (
      <div className="bg-primary-text h-8 w-8 rounded-md text-xxxs flex items-center justify-center text-white font-medium"></div>
    );
  }

  if (isMultiple) {
    return (
      <div className="w-8 h-8 border border-primary-text rounded-md bg-white relative ticker_avatar__multiple overflow-hidden"></div>
    );
  }

  return (
    <div className="bg-primary-text h-8 w-8 rounded-md text-xxxs flex items-center justify-center text-white font-medium">
      {tickers[0]}
    </div>
  );
};

export { TickerAvatar };
