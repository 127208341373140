import { Issuer } from "src/features/IssuerDetails/Invoicing";
import { Box } from "../atoms/Box";
import { FormSection } from "../molecules/FormSection";
import { LabelField } from "../molecules/LabelField";

interface IIssuerInvocingDetailsProps {
  issuer: Issuer;
  onContactEdit?: () => void;
}

export function IssuerInvocingDetails({
  issuer,
  onContactEdit,
}: IIssuerInvocingDetailsProps) {
  const { invoicingAddress } = issuer;
  return (
    <>
      <FormSection
        title="Contact Details"
        isCollapsible={false}
        onEditClick={onContactEdit}
        data-testid="contact_details"
      >
        <Box
          sx={{
            backgroundColor: "#f8f8f8",
            py: 3,
            px: 4,
            mt: 3,
            border: "1px solid #e5e5e5",
          }}
        >
          <LabelField label="Email" sx={{ mb: 2 }}>
            {invoicingAddress?.email}
          </LabelField>
          <LabelField label="Notes" sx={{ mb: 2 }}>
            {invoicingAddress?.notes}
          </LabelField>
          <LabelField label="Address 1" sx={{ mb: 2 }}>
            {invoicingAddress?.addressLine1}
          </LabelField>
          <LabelField label="Address 2" sx={{ mb: 2 }}>
            {invoicingAddress?.addressLine2}
          </LabelField>
          <LabelField label="City" sx={{ mb: 2 }}>
            {invoicingAddress?.city}
          </LabelField>
          <LabelField label="State" sx={{ mb: 2 }}>
            {invoicingAddress?.state}
          </LabelField>
          <LabelField label="Postal Code" sx={{ mb: 2 }}>
            {invoicingAddress?.postalCode}
          </LabelField>
          <LabelField label="Country" sx={{ mb: 2 }}>
            {invoicingAddress?.country}
          </LabelField>
        </Box>
      </FormSection>
    </>
  );
}
