import { useEffect } from "react";
import { To, useNavigate } from "react-router-dom";

interface IRedirectProps {
  to: To;
}

export function Redirect({ to }: IRedirectProps) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
}
