import { decamelize } from "humps";
import { checkAndAppendHttps } from "../../../../../../../src/utils/check-and-append-https";
import {
  IApiMeetingFormat,
  IFormCanAttendMeeting,
  IFormMeetingFormatObj,
  IMeetingFormatType,
} from "../../../../types";
/**
 * convert form meetingFormat data to the format the api needs
 */
export const getMeetingFormatPayload = (
  meetingFormats: IFormMeetingFormatObj,
  canAttendMeetingObj: IFormCanAttendMeeting
): IApiMeetingFormat[] => {
  // If canAttendMeeting is "no", only send this single meetingFormat
  if (canAttendMeetingObj.enabled === "no") {
    return [
      {
        format: "private",
        value: canAttendMeetingObj.value,
        canVote: false,
      },
    ];
  }
  // If canAttendMeeting is not "no", add all enabled meeting formats to an array
  const formats = Object.entries(meetingFormats).reduce((acc, curr) => {
    if (curr[1].enabled) {
      acc.push({
        format: decamelize(curr[0]) as IMeetingFormatType,
        canVote: curr[1].canVote === "yes" ? true : false,
        value:
          decamelize(curr[0]) === "virtual"
            ? checkAndAppendHttps(curr[1].value)
            : curr[1].value,
      });
    }
    return acc;
  }, [] as IApiMeetingFormat[]);

  return formats;
};
