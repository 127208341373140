import React from "react";
import useSWR from "swr";
import { CircularProgress } from "@mui/material";
// components
import { BrokerLogo } from "./BrokerLogo";
// api
import { getTemplateApi } from "./endpointGenerators";
//constants
import { swrDisablePolling } from "../../../constants/configs";
import { Box } from "src/components/atoms/Box";
import { COLORS } from "@asayinc/component-library";

interface Props {
  logo: string;
  name: string;
  slug: string;
  filingId: string;
  onClick: (slug: string) => void;
  selected: boolean;
}

function Thumbnail({ logo, name, slug, filingId, onClick, selected }: Props) {
  const templateApi = getTemplateApi({
    filingId,
    broker: slug,
  });
  // retrieve email template data
  const { data: template } = useSWR(templateApi, swrDisablePolling);

  const renderPreview = () => {
    switch (slug) {
      case "acorns":
        return (
          <Box
            sx={{
              height: "100px",
              overflow: "hidden",
              background: "#fff",
              width: "100%",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: selected ? COLORS.SAY_COBALT : "#e5e5e5",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              "& > div:nth-of-type(1), & > div:nth-of-type(2)": {
                zoom: "0.5",
                transform: "translateY(0px)",
              },
            }}
            dangerouslySetInnerHTML={{ __html: template.data.body }}
          />
        );
      case "square":
        return (
          <Box
            sx={{
              height: "100px",
              overflow: "hidden",
              background: "#fff",
              width: "100%",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: selected ? COLORS.SAY_COBALT : "#e5e5e5",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              "& > div:nth-of-type(1), & > div:nth-of-type(2)": {
                zoom: "0.5",
                transform: "translateY(-90px)",
              },
            }}
            dangerouslySetInnerHTML={{ __html: template.data.body }}
          />
        );
      case "robinhood":
        return (
          <Box
            sx={{
              height: "100px",
              overflow: "hidden",
              background: "#fff",
              width: "100%",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: selected ? COLORS.SAY_COBALT : "#e5e5e5",
              borderRadius: "4px",
            }}
          >
            <Box
              dangerouslySetInnerHTML={{ __html: template.data.body }}
              sx={{
                transform: "scale(0.35) translate(-80px, -133px)",
                transformOrigin: "top left",
                width: "650px",
              }}
            />
          </Box>
        );
      default:
        return (
          <Box
            sx={{
              height: "100px",
              overflow: "hidden",
              background: "#fff",
              width: "100%",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: selected ? COLORS.SAY_COBALT : "#e5e5e5",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              "& > div:nth-of-type(1), & > div:nth-of-type(2)": {
                zoom: "0.5",
                transform: "translateY(-120px)",
              },
            }}
            dangerouslySetInnerHTML={{ __html: template.data.body }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridColumnGap: "8px",
        gridTemplateColumns: "24px 1fr",
        padding: "8px 8px 8px 24px",
        background: selected ? COLORS.ICE_BLUE : "#fff",
        cursor: "pointer",
      }}
      onClick={() => onClick(slug)}
    >
      <BrokerLogo logo={logo} name={name} />
      <Box sx={{ overflow: "hidden" }}>
        {!template && (
          <Box
            sx={{
              height: "100px",
              overflow: "hidden",
              background: "#fff",
              width: "100%",
              border: "1px solid #e5e5e5",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {template && template.status === 200 && renderPreview()}
        <Box
          component="p"
          sx={{
            fontSize: "12px",
            fontWeight: "500",
            marginTop: "4px",
          }}
        >
          {name}
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(Thumbnail);
