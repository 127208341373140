/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export type EventNames =
  | "Create Filing Clicked"
  | "Save Filing Clicked"
  | "Create Filing Unsuccessful"
  | "Edit Filing Clicked"
  | "Save Filing Clicked"
  | "Save Only Clicked"
  | "Edgar Link Clicked"
  | "Link to Solicitor Clicked"
  | "Mark Requested Clicked"
  | "Review Email Clicked"
  | "Approve Clicked"
  | "Send Test Email Clicked"
  | "Mark Ready for Review Unsuccessful"
  | "Create Fund Dialog Clicked"
  | "All Funds Tab Clicked"
  | "Missing Delivery Document Tab Clicked"
  | "Edit Document Clicked"
  | "Delete Document Clicked"
  | "Save Fund Clicked"
  | "Save Fund Unsuccessful"
  | "Save Company Clicked"
  | "Save Company Unsuccessful"
  | "Save Meeting Results Clicked"
  | "Save Meeting Results Submit"
  | "Edgar Clicked"
  | "Save Meeting Results Unsuccessful";

export const userIdEvent = (
  eventName: EventNames,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any,
  includeName?: boolean
): void => {
  const token = Cookies.get("authToken");

  if (token) {
    const { user_id, username } = jwt_decode(token);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const spreadOptions: any = {
      ...options,
      userId: user_id,
    };

    if (includeName) {
      spreadOptions.username = username;
    }

    window.analytics.track(eventName, { ...spreadOptions });
  }
};
