import { OperationsStatus as AdditionalMailingOperationsStatus } from "src/types/additionalMailing";
import { OperationsStatus as VcaOperationsStatus } from "src/types/vca";
import { OperationsStatus as NoboOperationsStatus } from "src/types/nobo";
import { createAdapter } from "../adapterFactory";
import {
  CreateVCAPayload,
  ICreateAdditionalMailingForm,
  ICreateNoboRequest,
  ICreateProspectusPackage,
  ICreateVoteTransmissionRequest,
  IUpdateProspectusDocumentRequest,
  IUpdateProspectusRequest,
  UpdateVCADocumentPayload,
} from "./types";

const nexus = createAdapter({
  baseURL: `${process.env.REACT_APP_NEXUS_URL}/mission-control/v1/`,
});

const nexusFormData = createAdapter({
  baseURL: `${process.env.REACT_APP_NEXUS_URL}/mission-control/v1`,
  isMultipartRequest: true,
  transformRequest: false,
});

function createProspectusPackage(payload: ICreateProspectusPackage) {
  const url = `prospectus-packages/`;
  return nexus.post(url, payload);
}

function createVoluntaryCorporateActionFiling(payload: CreateVCAPayload) {
  const url = `corporate-action-events/`;
  return nexus.post(url, payload);
}

function sendTestAdditionalMailingEmailWarrenG(
  proxyEventId: string,
  additionalMailingId: string,
  brokerId: string,
  payload: { emails: string[] }
) {
  const url = `proxy-events/${proxyEventId}/additional-mailings/${additionalMailingId}/preview/${brokerId}/`;
  return nexus.post(url, payload);
}

function sendTestVCAEmailWarrenG(
  vcaId: string,
  brokerId: string,
  payload: { emails: string[] }
) {
  const url = `corporate-action-events/${vcaId}/preview/${brokerId}/`;
  return nexus.post(url, payload);
}

function createVoluntaryCorporateActionDocuments(
  id: string,
  payload: FormData
) {
  const url = `corporate-action-events/${id}/documents/`;
  return nexusFormData.post(url, payload);
}

function createAdditionalMailing(
  id: string,
  payload: ICreateAdditionalMailingForm
) {
  const url = `proxy-events/${id}/additional-mailings/`;
  return nexus.post(url, payload);
}

function createAdditionalMailingDocument(
  proxyEventId: string,
  additionalMailingId: string,
  payload: FormData
) {
  const url = `proxy-events/${proxyEventId}/additional-mailings/${additionalMailingId}/documents/`;
  return nexusFormData.post(url, payload);
}

function createUpdateVoteTransmission(
  proxyEventId: string,
  voteTransmissionId: string | undefined,
  payload: ICreateVoteTransmissionRequest
) {
  const url = `proxy-events/${proxyEventId}/vote-transmissions/${
    voteTransmissionId ? voteTransmissionId + "/" : ""
  }`;
  return nexus.post(url, payload);
}

function createUpdateNoboRequest(
  noboId: string | undefined,
  payload: ICreateNoboRequest
) {
  const url = `nobo-events/${noboId ? noboId + "/" : ""}`;
  return nexus.post(url, payload);
}

function updateNoboRequestStatus(
  noboId: string,
  payload: { operationsStatus: NoboOperationsStatus }
) {
  const url = `nobo-events/${noboId}/operations-status/`;
  return nexus.post(url, payload);
}

function updateVoteTransmissionStatus(
  proxyEventId: string,
  voteTransmissionId: string,
  payload: { operationsStatus: AdditionalMailingOperationsStatus }
) {
  const url = `/proxy-events/${proxyEventId}/vote-transmissions/${voteTransmissionId}/operations-status/`;
  return nexus.post(url, payload);
}

function updateAdditionalMailing(
  proxyId: string,
  additionalMailingId: string,
  payload: ICreateAdditionalMailingForm
) {
  const url = `/proxy-events/${proxyId}/additional-mailings/${additionalMailingId}/`;
  return nexus.patch(url, payload);
}

function updateAdditionalMailingDocument(
  proxyEventId: string,
  additionalMailingId: string,
  documentId: string,
  payload: { name: string; type: string }
) {
  const url = `proxy-events/${proxyEventId}/additional-mailings/${additionalMailingId}/documents/${documentId}/`;
  return nexus.patch(url, payload);
}

function updateAdditionalMailingStatus(
  proxyId: string,
  additionalMailingId: string,
  payload: { operationsStatus: AdditionalMailingOperationsStatus }
) {
  const url = `/proxy-events/${proxyId}/additional-mailings/${additionalMailingId}/operations-status/`;
  return nexus.post(url, payload);
}

function updateVoluntaryCorporateActionFiling(
  id: string,
  payload: CreateVCAPayload
) {
  const url = `corporate-action-events/${id}/`;
  return nexus.patch(url, payload);
}

function updateVoluntaryCorporateActionDocument(
  eventId: string,
  documentId: string,
  payload: UpdateVCADocumentPayload
) {
  const url = `corporate-action-events/${eventId}/documents/${documentId}/`;
  return nexus.patch(url, payload);
}

function updateVoluntaryCorporateActionStatus(
  eventId: string,
  payload: { operationsStatus: VcaOperationsStatus }
) {
  const url = `corporate-action-events/${eventId}/operations-status/`;
  return nexus.post(url, payload);
}

function approveProspectusPackage(id: string) {
  const url = `prospectus-packages/${id}/approve/`;
  return nexus.get(url);
}

function deleteProspectusDocument(prospectusId: string, documentId: string) {
  const url = `prospectus-packages/${prospectusId}/documents/${documentId}`;
  return nexus.delete(url);
}

function deleteAdditionalMailingDocument(
  proxyEventId: string,
  additionalMailingId: string,
  documentId: string
) {
  const url = `proxy-events/${proxyEventId}/additional-mailings/${additionalMailingId}/documents/${documentId}/`;
  return nexus.delete(url);
}

function deleteVoluntaryCorporateActionDocument(
  eventId: string,
  documentId: string
) {
  const url = `corporate-action-events/${eventId}/documents/${documentId}/`;
  return nexus.delete(url);
}

function updateProspectusNexus(
  prospectusPackageId: string,
  payload: IUpdateProspectusRequest,
  isConfirmed = false
) {
  let url = `prospectus-packages/${prospectusPackageId}/`;
  if (isConfirmed) {
    url = url + "?confirm=True";
  }
  return nexus.patch(url, payload);
}

function updateProspectusDocumentNexus(
  prospectusId: string,
  documentId: string,
  payload: IUpdateProspectusDocumentRequest
) {
  const url = `prospectus-packages/${prospectusId}/documents/${documentId}/`;
  return nexus.patch(url, payload);
}

function uploadProspectusDocuments(propsectusId: string, payload: FormData) {
  const url = `/prospectus-packages/${propsectusId}/documents/`;
  return nexusFormData.post(url, payload);
}

export {
  approveProspectusPackage,
  createAdditionalMailing,
  createAdditionalMailingDocument,
  createProspectusPackage,
  createUpdateNoboRequest,
  createUpdateVoteTransmission,
  sendTestAdditionalMailingEmailWarrenG,
  sendTestVCAEmailWarrenG,
  createVoluntaryCorporateActionFiling,
  createVoluntaryCorporateActionDocuments,
  updateVoluntaryCorporateActionFiling,
  updateVoluntaryCorporateActionDocument,
  deleteAdditionalMailingDocument,
  deleteProspectusDocument,
  deleteVoluntaryCorporateActionDocument,
  updateAdditionalMailing,
  updateAdditionalMailingStatus,
  updateAdditionalMailingDocument,
  updateNoboRequestStatus,
  updateProspectusNexus,
  updateProspectusDocumentNexus,
  uploadProspectusDocuments,
  updateVoluntaryCorporateActionStatus,
  updateVoteTransmissionStatus,
  nexus,
};
