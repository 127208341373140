import { MouseEvent } from "react";
import { Dialog as MUIDialog, DialogProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { DialogActions } from "./DialogActions";
import { DialogContent } from "./DialogContent";
import { DialogTitle } from "./DialogTitle";
import { ButtonColors } from "../Button";

interface IDialogProps extends DialogProps {
  isPrimaryDisabled?: boolean;
  isPrimaryLoading?: boolean;
  isSecondaryDisabled?: boolean;
  isSecondaryLoading?: boolean;
  onSecondaryClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  onPrimaryClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  primaryButtonColor?: ButtonColors;
  primaryButtonText?: string;
  secondaryButtonColor?: ButtonColors;
  secondaryButtonText?: string;
  title?: string;
}

export function Dialog({
  children,
  isPrimaryDisabled,
  isPrimaryLoading,
  isSecondaryDisabled,
  isSecondaryLoading,
  primaryButtonColor = "primary",
  primaryButtonText,
  secondaryButtonColor = "primary",
  secondaryButtonText,
  onSecondaryClick,
  onPrimaryClick,
  title,
  ...props
}: IDialogProps) {
  return (
    <MUIDialog {...props}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      {(onPrimaryClick || onSecondaryClick) && (
        <DialogActions>
          {onSecondaryClick && (
            <LoadingButton
              color={secondaryButtonColor}
              disabled={isSecondaryDisabled}
              loading={isSecondaryLoading}
              onClick={onSecondaryClick}
              size="small"
              variant="outlined"
            >
              {secondaryButtonText}
            </LoadingButton>
          )}
          {onPrimaryClick && (
            <LoadingButton
              color={primaryButtonColor}
              disabled={isPrimaryDisabled}
              loading={isPrimaryLoading}
              onClick={onPrimaryClick}
              size="small"
              type="button"
              variant="contained"
            >
              {primaryButtonText}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </MUIDialog>
  );
}
