import { Box } from "src/components/atoms/Box";
import { FormSection } from "src/components/molecules/FormSection";
import { LabelField } from "src/components/molecules/LabelField";
import { VoteTransmission } from "src/types/voteTransmission";
import { formatDate } from "src/utils/format-date";
import { formatNumber } from "src/utils/format-number";

interface IVoteTransmissionFilesProps {
  voteTransmission: VoteTransmission;
}

export function VoteTransmissionFiles({
  voteTransmission,
}: IVoteTransmissionFilesProps) {
  const { voteTransmissionEventVoteResultsFileImportJobs } = voteTransmission;

  if (voteTransmissionEventVoteResultsFileImportJobs.length === 0) {
    return null;
  }

  return (
    <FormSection isCollapsible={false} title="Vote Transmission Files">
      {voteTransmissionEventVoteResultsFileImportJobs.map((job) => {
        const { id, s3Key, received, totalCount } = job;
        return (
          <Box
            data-testid="key_details"
            key={id}
            sx={{
              display: "flex",
              mt: 4,
              p: 4,
              border: "1px solid #e5e5e5",
              backgroundColor: "#f8f8f8",
              flexDirection: "column",
            }}
          >
            <LabelField label="File Name">{s3Key}</LabelField>
            <LabelField label="Received Date" sx={{ mt: 4 }}>
              {formatDate(new Date(received))}
            </LabelField>
            <LabelField label="Votes" sx={{ mt: 4 }}>
              {formatNumber(totalCount)}
            </LabelField>
          </Box>
        );
      })}
    </FormSection>
  );
}
