import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useTasks } from "./useTasks";
import { Box } from "src/components/atoms/Box";
import { Tabs } from "src/components/atoms/Tabs";
import { formatNumber } from "src/utils/format-number";
import { getTabFromUrl } from "src/utils/get-tab-from-url";
import { PageHeader } from "src/components/molecules/PageHeader";
import { Avatar } from "src/components/atoms/Avatar";

const TaskListLayout = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { newTasks, inProgressTasks, openTasks } = useTasks();
  const newCount = newTasks.data ? newTasks.data.data.count : 0;
  const inProgressCount = inProgressTasks.data
    ? inProgressTasks.data.data.count
    : 0;
  const openCount = openTasks.data ? openTasks.data.data.count : 0;

  function onTabChange(value: string) {
    navigate(`/corporate-actions/tasks/${value}`);
  }

  return (
    <>
      <PageHeader
        icon={<Avatar name="ca" />}
        title="Voluntary Corporate Actions"
      />
      <Box id="VcaTabs" sx={{ pl: 6, borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          onChange={onTabChange}
          value={getTabFromUrl(location)}
          tabs={[
            {
              label: `New (${formatNumber(newCount)})`,
              value: "new",
              testId: "VcaNewTab",
            },
            {
              label: `In Progress (${formatNumber(inProgressCount)})`,
              value: "in-progress",
              testId: "VcaInProgressTab",
            },
            {
              label: `Open (${formatNumber(openCount)})`,
              value: "open",
              testId: "VcaOpenTab",
            },
          ]}
        />
      </Box>
      <Outlet />
    </>
  );
};

export { TaskListLayout };
