import { ReactNode } from "react";
import { AccordionSummary as MUIAccordionSummary } from "@mui/material";
import { SxProps } from "@mui/system";
import { IBaseProps } from "../../types/BaseProps";

interface IAccordionSummaryProps extends IBaseProps {
  expandIcon?: ReactNode;
  onClick?: () => void;
  sx?: SxProps;
}

export function AccordionSummary({
  children,
  expandIcon,
  onClick,
  sx,
}: IAccordionSummaryProps) {
  return (
    <MUIAccordionSummary expandIcon={expandIcon} onClick={onClick} sx={sx}>
      {children}
    </MUIAccordionSummary>
  );
}
