import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  useProxyWarningState,
  useProxyWarningDispatch,
} from "../../features/Proxy/proxy-warning-context";

export const DirtyFormDialog = (): JSX.Element | null => {
  const { isDialogOpen, handlers } = useProxyWarningState();
  const dispatch = useProxyWarningDispatch();
  if (handlers) {
    return (
      <div>
        <Dialog
          fullWidth
          open={isDialogOpen}
          onClose={() => {
            handlers.reject();
            dispatch({ type: "CANCEL_STAY" });
          }}
          sx={{
            position: "absolute",
            overflow: "hidden",
            [`& .${dialogClasses["container"]}`]: {
              maxHeight: "90vh",
              overflow: "hidden",
            },
          }}
          BackdropProps={{
            sx: {
              position: "absolute",
              backgroundColor: "rgba(0,0,0,.8)",
              width: "100vw",
              height: "100vh",
            },
          }}
          style={{ position: "absolute" }}
        >
          <DialogTitle>Are you sure you want to leave this page?</DialogTitle>

          <DialogContent
            sx={{
              "& > *": {
                margin: "12px 0",
              },
            }}
          >
            <p>
              It looks like you have unsaved edits. If you leave this page your
              changes will be lost.
            </p>
            <p>Save before you go!</p>
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={() => {
                handlers.reject();
                dispatch({ type: "CANCEL_STAY" });
              }}
            >
              Stay
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                handlers.resolve();
                dispatch({ type: "CONFIRM_LEAVE" });
              }}
            >
              Leave
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return null;
};
