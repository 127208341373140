import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useTasks } from "./useTasks";
import { SimpleSnackbar } from "../../components/Snackbar";
import {
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from "./batch-filings-context";
import { Box } from "src/components/atoms/Box";
import { Tabs } from "src/components/atoms/Tabs";
import { formatNumber } from "src/utils/format-number";
import { getTabFromUrl } from "src/utils/get-tab-from-url";
import { PageHeader } from "src/components/molecules/PageHeader";
import { Avatar } from "src/components/atoms/Avatar";

const TaskListLayout = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { drafts, ready, review, open } = useTasks();
  const draftsCount = drafts.data ? drafts.data.data.count : 0;
  const readyCount = ready.data ? ready.data.data.count : 0;
  const reviewCount = review.data ? review.data.data.count : 0;
  const openCount = open.data ? open.data.data.count : 0;
  const batchFilings = useBatchFilingsState();
  const dispatch = useBatchFilingsDispatch();

  function onTabChange(value: string) {
    navigate(`/proxy/tasks/${value}`);
  }

  const handleCloseSnackbar = (
    event: Event | React.SyntheticEvent<unknown, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "CLOSE_MESSAGE" });
    dispatch({ type: "RESET_BATCH" });
  };
  return (
    <>
      <PageHeader icon={<Avatar name="px" />} title="Proxy" />
      <Box sx={{ pl: 6, borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          onChange={onTabChange}
          value={getTabFromUrl(location)}
          tabs={[
            {
              label: `Drafts (${formatNumber(draftsCount)})`,
              value: "drafts",
              testId: "ProxyDraftTab",
            },
            {
              label: `Ready (${formatNumber(readyCount)})`,
              value: "ready",
              testId: "ProxyReadyTab",
            },
            {
              label: `Review (${formatNumber(reviewCount)})`,
              value: "review",
              testId: "ProxyReviewTab",
            },
            {
              label: `Open (${formatNumber(openCount)})`,
              value: "open",
              testId: "ProxyOpenTab",
            },
          ]}
        />
      </Box>
      <Outlet />
      <SimpleSnackbar
        open={batchFilings.message.length > 0}
        message={batchFilings.message}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export { TaskListLayout };
