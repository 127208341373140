import { SxProps } from "@mui/material";
import React from "react";
import { Box } from "../atoms/Box";

type PageWrapperProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

const PageWrapper = ({ children, sx }: PageWrapperProps): JSX.Element => (
  <Box sx={{ px: 8, ...sx }}>{children}</Box>
);

export { PageWrapper };
