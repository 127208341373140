import { FilingType } from "src/features/Proxy/types";

// Filing Type to template link mapping
export const FILING_TYPE_TO_LINK: { [filingType in FilingType]: string } = {
  [FilingType.FundReport]:
    "https://docs.google.com/spreadsheets/d/1lQCaNSfHXDGLaN0xzYDWq0HM4RzDq-Lmlf3mxZrP9zQ",
  [FilingType.FundMeeting]:
    "https://docs.google.com/spreadsheets/d/1nvAOWWX5drY7DR6TpRPrH2jBuB28eRpzpg2MTJaOBH0",
  [FilingType.FirmMeeting]:
    "https://docs.google.com/spreadsheets/d/1VFy4eXN9TUsXDyHnpvdDDEMWsEiSrMnp1xUDjXzMXEU",
  [FilingType.FirmInformationStatement]:
    "https://docs.google.com/spreadsheets/d/1YoPWGHqiJ6RQ8IrV26kh7hb0n861FaF9m3MZJlECAxk",
  [FilingType.FirmConsentSolicitation]:
    "https://docs.google.com/spreadsheets/d/1Qd8EJhIThf9jHBjXBqBsIdgLGQdjAyk75Hqr7fYXIGc",
  [FilingType.MeetingContest]:
    "https://docs.google.com/spreadsheets/d/112Wnd7szmIGN_doBF70ovTDHj_S080SNnDCY0mUv7u4",
  [FilingType.FundInformationStatement]:
    "https://docs.google.com/spreadsheets/d/1TLA1UnsZUi-T54SX2YT2xD-XVdbP2JoFsytcr7tR1f8",
};
