import { Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { SecurityProspectusPackage } from "../../api/adapters/nexus/types";
import { FilingDetails } from "./types";

interface ITradeAlertBanner {
  filing: FilingDetails;
  tradeAlert: SecurityProspectusPackage;
}

export function TradeAlertBanner({ filing, tradeAlert }: ITradeAlertBanner) {
  const {
    security: { issuerName, ticker },
    tradeStats: { recentTrades },
    prospectusPackage: { expectedEndDate },
  } = tradeAlert;
  const {
    issuer: { edgarLandingPage },
  } = filing;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "12px 24px",
        fontSize: ".75rem",
      }}
    >
      <ErrorOutlineIcon sx={{ fill: "#d33f33", marginRight: "12px" }} />
      <span>
        <strong>Trade Alert: </strong>
        {" " +
          `${
            recentTrades || 0
          } shareholders purchased (${ticker}) ${issuerName}. `}
        {!!expectedEndDate && `Approve filing by ${expectedEndDate}. `}
        {edgarLandingPage && (
          <a
            className="text-blue cursor-pointer"
            href={"//" + edgarLandingPage}
            target="_blank"
            rel="noreferrer"
          >
            View fund information
          </a>
        )}
      </span>
    </Box>
  );
}
