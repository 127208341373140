import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingState } from "../../../components/LoadingState";
import { useTasks, OrderingKey } from "../useTasks";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
} from "@mui/material";
import { useTableOrdering } from "../useTableOrdering";
import { BatchActionButtons } from "../BatchActionButtons";
import {
  deleteFilings,
  useBatchFilingsDispatch,
  useBatchFilingsState,
} from "../batch-filings-context";
import { ReadyTableRow } from "./ReadyTableRow";
import { SimpleSnackbar } from "../../../components/Snackbar";
import { checkIfFilingHasTradeAlert } from "../checkIfFilingHasTradeAlert";
import { PageWrapper } from "src/components/PageWrapper";
import { TableHeader } from "src/components/TableHeader";
import { table, tableHeadCell } from "../styles/tableStyles";
import { TableSortLabel } from "src/components/TableSortLabel";

const Ready = (): JSX.Element | null => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    ready: { data, error, mutate },
    readyQuery: query,
    handleUpdateQuery,
    readyProxyEvents: proxyEvents,
  } = useTasks();
  const batchFilings = useBatchFilingsState();
  const dispatch = useBatchFilingsDispatch();
  const { getIsActive, direction } = useTableOrdering(query.ordering);
  const [batchActionButtonsVisible, setBatchActionButtonsVisible] =
    useState(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessageMessage] =
    useState<React.ReactNode>([]);
  const handleOrderingClick = useCallback(
    (orderingKey: OrderingKey) => {
      switch (direction) {
        case "desc":
          return handleUpdateQuery("ready", "ordering", orderingKey);
        case "asc":
        default:
          return handleUpdateQuery("ready", "ordering", `-${orderingKey}`);
      }
    },
    [query]
  );

  function handleSelectAll(event: React.ChangeEvent<HTMLInputElement>) {
    if (!data) return;

    if (event.target.checked) {
      const allFilingIds = data.data.results.map((filing) => filing.id);
      dispatch({
        type: "SELECT_ALL_FILINGS",
        filings: allFilingIds,
        path: location.pathname,
      });
      return;
    }

    dispatch({ type: "DESELECT_ALL_FILINGS" });
  }

  function handleBatchDeleteFilings() {
    deleteFilings(dispatch, batchFilings.filings, mutate);
  }

  function handleBatchEditFilings() {
    navigate(`/proxy/${batchFilings.filings[0]}`);
  }

  const handleSnackbar = (message?: React.ReactNode) => {
    if (message) setSnackbarMessageMessage(message);
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (
    event: Event | React.SyntheticEvent<unknown, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessageMessage("");
    setOpenSnackbar(false);
  };

  if ((!error && !data) || !data?.data?.results)
    return (
      <Box p={4}>
        <LoadingState />
      </Box>
    );

  if (error) return <Box p={4}>Error...</Box>;

  return (
    <PageWrapper>
      <TableHeader
        count={data.data.count}
        page={query.page}
        pageSize={query.pageSize}
        itemType="filing"
        onPageChange={(page: number) =>
          handleUpdateQuery("ready", "page", page)
        }
        onPageSizeChange={(pageSize: number) =>
          handleUpdateQuery("ready", "pageSize", pageSize)
        }
      />
      <Box sx={{ overflowX: "auto" }}>
        <Table sx={table}>
          <TableHead
            onMouseEnter={() => setBatchActionButtonsVisible(true)}
            onMouseLeave={() => setBatchActionButtonsVisible(false)}
          >
            <TableRow>
              <TableCell
                padding="checkbox"
                sx={{
                  position: "sticky",
                  left: 0,
                  background: "white",
                  zIndex: 10,
                }}
              >
                <Checkbox
                  onChange={handleSelectAll}
                  checked={
                    batchFilings.filings.length > 0 &&
                    batchFilings.filings.length === data.data.results.length
                  }
                  indeterminate={
                    batchFilings.filings.length > 0 &&
                    batchFilings.filings.length < data.data.results.length
                  }
                />
              </TableCell>
              <TableCell
                sx={tableHeadCell}
                style={{
                  width: "300px",
                  left: 47,
                  position: "sticky",
                  zIndex: 10,
                }}
              >
                <BatchActionButtons
                  isVisible={
                    batchActionButtonsVisible &&
                    Boolean(batchFilings.filings.length)
                  }
                  onDelete={handleBatchDeleteFilings}
                  onEdit={handleBatchEditFilings}
                />
                <TableSortLabel
                  active={getIsActive("issuer__company_name")}
                  direction={direction}
                  onClick={() => handleOrderingClick("issuer__company_name")}
                >
                  Issuer
                </TableSortLabel>
              </TableCell>
              <TableCell sx={tableHeadCell} id="CUSIP">
                CUSIP
              </TableCell>
              <TableCell sx={tableHeadCell}>
                <TableSortLabel
                  active={getIsActive("polymorphic_ctype")}
                  direction={direction}
                  onClick={() => handleOrderingClick("polymorphic_ctype")}
                >
                  Event
                </TableSortLabel>
              </TableCell>
              <TableCell sx={tableHeadCell}>
                <TableSortLabel
                  active={getIsActive("latest_edgar_filing_date")}
                  direction={direction}
                  onClick={() =>
                    handleOrderingClick("latest_edgar_filing_date")
                  }
                >
                  EDGAR
                </TableSortLabel>
              </TableCell>
              <TableCell sx={tableHeadCell}>
                <TableSortLabel
                  active={getIsActive("first_record_date")}
                  direction={direction}
                  onClick={() => handleOrderingClick("first_record_date")}
                >
                  Record
                </TableSortLabel>
              </TableCell>
              <TableCell sx={tableHeadCell}>
                <TableSortLabel
                  active={getIsActive(
                    "stats__estimated_digital_materials_expected_date"
                  )}
                  direction={direction}
                  onClick={() =>
                    handleOrderingClick(
                      "stats__estimated_digital_materials_expected_date"
                    )
                  }
                >
                  Expected
                </TableSortLabel>
              </TableCell>
              <TableCell sx={tableHeadCell}>Solicitor</TableCell>
              <TableCell sx={tableHeadCell}>
                <TableSortLabel
                  active={getIsActive("meeting_date")}
                  direction={direction}
                  onClick={() => handleOrderingClick("meeting_date")}
                >
                  Meeting
                </TableSortLabel>
              </TableCell>
              <TableCell sx={tableHeadCell} style={{ maxWidth: "30px" }}>
                Mat.
              </TableCell>
              <TableCell sx={tableHeadCell}>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data.results.map((filing) => (
              <ReadyTableRow
                key={filing.id}
                filing={filing}
                onSetSnackbar={handleSnackbar}
                hasTradeAlert={
                  proxyEvents?.data?.data?.results
                    ? checkIfFilingHasTradeAlert(
                        filing.globalFilingId,
                        proxyEvents.data.data.results
                      )
                    : false
                }
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <SimpleSnackbar
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </PageWrapper>
  );
};

export { Ready };
