import { MenuItem, TextField } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";

import { frequencyOptions } from "src/features/CorporateActions/select-options";
import { CreateCorporateActionForm } from "src/types/vca";

interface IDividendOptionConditionsProps {
  form: UseFormReturn<CreateCorporateActionForm>;
}

export const DividendOptionConditions = ({
  form,
}: IDividendOptionConditionsProps) => {
  const { control } = form;
  return (
    <>
      <Controller
        control={control}
        name="offer.conditions.frequency"
        rules={{ required: "Frequency is required." }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              sx={{ mb: 2 }}
              InputLabelProps={{ shrink: true }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              fullWidth
              label="Frequency*"
              size="small"
              select={true}
            >
              {frequencyOptions.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          );
        }}
      />
      <Controller
        control={control}
        name="offer.conditions.dividend"
        rules={{ required: "Gross Dividend is required." }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              sx={{ my: 2 }}
              InputLabelProps={{ shrink: true }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              fullWidth={true}
              label="Gross Dividend*"
              size="small"
            />
          );
        }}
      />
    </>
  );
};
