import React from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Checkbox,
} from "@mui/material";
import { Formik, Field, FormikActions, FormikValues } from "formik";
import * as Yup from "yup";
import { CompanyContact } from "../../features/Proxy/types";
import { Company } from "../../features/Proxy/DetailsForm/Contact";
import { createContact, createCompanyContact } from "../../api/adapters/proxy";

type AddContactFormValues = {
  name: string;
  email: string;
  isDefaultForRole: boolean;
};

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  role: string;
  company: Company;
  setFieldValue: FormikActions<FormikValues>["setFieldValue"];
  fieldName: string;
  values: CompanyContact[] | [];
};

export const AddCompanyContactDialog = ({
  isOpen,
  onClose,
  role,
  company,
  fieldName,
  setFieldValue,
  values,
}: DialogProps): JSX.Element => {
  const handleCreateNewContact = async (newContact: AddContactFormValues) => {
    try {
      const contactRequestData = {
        name: newContact.name,
        email: newContact.email,
      };
      const contactResponse = await createContact(contactRequestData);
      const companyContactRequestData = {
        contactId: contactResponse.data.id,
        companyId: company.id,
        role,
        isDefaultForRole: newContact.isDefaultForRole,
      };
      const companyContactResponse = await createCompanyContact(
        companyContactRequestData
      );
      if (role === "tabulator_reply_to") {
        setFieldValue("tabulator", {
          ...company,
          companyContacts: [
            ...company.companyContacts,
            companyContactResponse.data,
          ],
        });
      } else {
        setFieldValue("solicitor", {
          ...company,
          companyContacts: [
            ...company.companyContacts,
            companyContactResponse.data,
          ],
        });
      }
      setFieldValue(fieldName, [...values, companyContactResponse.data]);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div data-testid="AddCompanyContactDialog">
      <Dialog fullWidth open={isOpen} onClose={onClose}>
        <DialogTitle>Create New Contact</DialogTitle>
        <Formik
          initialValues={{
            name: "",
            email: "",
            isDefaultForRole: false,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            email: Yup.string()
              .email("Must be a valid email.")
              .required("Valid email is required"),
          })}
          onSubmit={handleCreateNewContact}
          render={({
            setFieldValue,
            submitForm,
            isValid,
            values,
            handleChange,
          }) => (
            <form onSubmit={() => submitForm()}>
              <DialogContent data-testid="DialogBody">
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Field
                      required
                      name="name"
                      type="text"
                      component={TextField}
                      fullWidth
                      size="small"
                      label="Name"
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setFieldValue("name", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      required
                      name="email"
                      type="text"
                      fullWidth
                      component={TextField}
                      size="small"
                      label="Email"
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setFieldValue("email", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isDefaultForRole"
                          checked={values.isDefaultForRole}
                          onChange={handleChange}
                          indeterminate={false}
                        />
                      }
                      label="Is Default For Role?"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions data-testid="DialogButtons">
                <Button onClick={onClose} type="button">
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={!isValid}>
                  Create
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </div>
  );
};
