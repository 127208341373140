type Pluralize = {
  singular: string;
  plural: string;
  count: number;
};

const pluralize = ({ singular, plural, count }: Pluralize): string => {
  if (count === 1) {
    return `${singular}`;
  } else {
    return `${plural}`;
  }
};

export { pluralize };
