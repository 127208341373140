import { CorporateActionType, OfferConditions } from "src/types/vca";

export const CORPORATE_ACTION_EVENT_TYPE_LABEL: {
  [key in CorporateActionType]: string;
} = {
  consent_solicitation: "Consent Solicitation",
  dividend_option: "Dividend Option",
  dividend_reinvestment_plan: "Dividend Reinvestment Plan",
  exchange_offer: "Exchange Offer",
  merger_consideration_election: "Merger with Election",
  odd_lot_offer: "Odd Lot Offer",
  buyback: "Buyback",
  buyback_auction: "Buyback",
  offer_to_purchase: "Offer to Purchase",
  offer_to_purchase_auction: "Offer to Purchase",
  rights_offer: "Rights Offer",
  rights_offer_oversubscription: "Rights Offer",
  informational: "Informational",
};

export function getCorporateActionTypeOptions() {
  return [
    {
      value: "exchange_offer",
      label: "Exchange Offer",
    },
    {
      value: "odd_lot_offer",
      label: "Odd Lot Offer",
    },
    {
      value: "buyback",
      label: "Buyback",
    },
    {
      value: "buyback_auction",
      label: "Buyback Auction",
    },
    {
      value: "offer_to_purchase",
      label: "Offer to Purchase",
    },
    {
      value: "offer_to_purchase_auction",
      label: "Offer to Purchase Auction",
    },
    {
      value: "merger_consideration_election",
      label: "Merger with Election",
    },
    {
      value: "rights_offer",
      label: "Rights Offer",
    },
    {
      value: "rights_offer_oversubscription",
      label: "Rights Offer Oversubscription",
    },
    {
      value: "informational",
      label: "Informational",
    },
    // Notes: We are not supporting these corporate action types
    // in V1 rollout of VCA w/ warrenG
    // {
    //   value: "dividend_option",
    //   label: "Dividend Option",
    // },
    // {
    //   value: "dividend_reinvestment_plan",
    //   label: "Dividend Reinvestment Plan",
    // },
    // {
    //   value: "consent_solicitation",
    //   label: "Consent Solicitation",
    // },
  ];
}

export function getInitialOfferConditions(
  type: CorporateActionType
): OfferConditions {
  switch (type) {
    case "buyback":
      return {
        contraCusip: null,
        price: null,
      };
    case "buyback_auction":
      return {
        specifiedContraCusip: null,
        unspecifiedContraCusip: null,
        bidIncrement: null,
        maximumBidPrice: null,
        minimumBidPrice: null,
      };
    case "offer_to_purchase":
      return {
        contraCusip: null,
        price: null,
        offererParty: null,
      };
    case "offer_to_purchase_auction":
      return {
        specifiedContraCusip: null,
        unspecifiedContraCusip: null,
        bidIncrement: null,
        maximumBidPrice: null,
        minimumBidPrice: null,
        offererParty: null,
      };
    case "rights_offer":
    case "rights_offer_oversubscription":
      return {
        contraCusip: null,
        multiple: null,
        price: null,
      };
    case "dividend_option":
      return {
        frequency: null,
        dividend: null,
      };
    case "dividend_reinvestment_plan":
      return {
        price: null,
      };
    case "consent_solicitation":
      return {
        payment: null,
      };
    case "merger_consideration_election":
      return {
        payment: null,
        stockContraCusip: null,
        cashContraCusip: null,
        mixedContraCusip: null,
      };
    case "exchange_offer":
      return {
        contraCusip: null,
      };
  }
  return {};
}
