import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useCorporateActionEvents } from "src/api/adapters/nexus/hooks/useCorporateActionEvents";
import { AlertIcon } from "src/components/AlertIcon";
import { CusipTagList } from "src/components/CusipTagList";
import { LoadingState } from "src/components/LoadingState";
import { PageWrapper } from "src/components/PageWrapper";
import { Table } from "src/components/Table";
import { TableBodyCell } from "src/components/TableBodyCell";
import { TableBodyRow } from "src/components/TableBodyRow";
import { TableHeadCell } from "src/components/TableHeadCell";
import { TableHeader } from "src/components/TableHeader";
import { TickerAvatar } from "src/components/TickerAvatar";
import { CORPORATE_ACTION_EVENT_TYPE_LABEL } from "src/features/vca/utils";
import { Security } from "src/types/vca";
import { formatDate } from "src/utils/format-date";
import { getPageOffset } from "src/utils/get-page-offset";
import { notEmpty } from "src/utils/not-empty";

export function InProgress() {
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState({
    limit: 25,
    offset: 0,
    ordering: "deadline",
    page: 0,
  });
  const { count, corporateActionEvents, isError, isLoading } =
    useCorporateActionEvents("in_progress", queryParams);
  const handleOrderingClick = useCallback(
    (ordering: string) => {
      setQueryParams({ ...queryParams, ordering });
    },
    [queryParams]
  );

  if (isLoading) {
    return <LoadingState />;
  }

  if (isError || !corporateActionEvents)
    return <PageWrapper>Error...</PageWrapper>;

  const { limit, ordering, page } = queryParams;

  function handleTableRowClick(path: string) {
    return function (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
      if (e.metaKey) {
        window.open(path, "_blank");
        return;
      }
      navigate(path);
    };
  }

  return (
    <PageWrapper>
      <TableHeader
        count={count || 0}
        page={page}
        pageSize={limit}
        itemType="filing"
        onPageChange={(page) =>
          setQueryParams({
            ...queryParams,
            page,
            offset: getPageOffset({ page, pageSize: queryParams.limit }),
          })
        }
        onPageSizeChange={(limit) => setQueryParams({ ...queryParams, limit })}
      />
      <Table>
        <thead className="bg-white">
          <tr>
            <TableHeadCell name="Ticker" />
            <TableHeadCell name="Issuer" />
            <TableHeadCell name="CUSIP" />
            <TableHeadCell
              name="Type"
              orderingKey="type"
              currentOrdering={ordering}
              onOrderingClick={handleOrderingClick}
            />
            <TableHeadCell
              name="Announce Date"
              orderingKey="announcement_date"
              currentOrdering={ordering}
              onOrderingClick={handleOrderingClick}
            />
            <TableHeadCell
              name="DTC Expiration"
              orderingKey="dtc_expiration_date"
              currentOrdering={ordering}
              onOrderingClick={handleOrderingClick}
            />
          </tr>
        </thead>
        <tbody>
          {corporateActionEvents.map((result) => (
            <TableBodyRow
              key={result.id}
              onClick={handleTableRowClick(`/vca/${result.id}`)}
            >
              <TableBodyCell>
                <TickerAvatar
                  tickers={result.securities
                    .map((security: Security) => security.ticker)
                    .filter(notEmpty)}
                />
              </TableBodyCell>
              <TableBodyCell>
                <Link
                  className="text-blue hover:underline hover:text-blue"
                  to={`/issuers/${result.securities[0].id}`}
                  target="_blank"
                  onClick={(event) => event.stopPropagation()}
                >
                  {result.securities[0]?.issuer?.name || "-"}
                </Link>
              </TableBodyCell>
              <TableBodyCell>
                <CusipTagList
                  cusips={result.securities
                    .map((security: Security) => security.cusip)
                    .filter(notEmpty)}
                />
              </TableBodyCell>
              <TableBodyCell>
                {CORPORATE_ACTION_EVENT_TYPE_LABEL[result.type]}
              </TableBodyCell>
              <TableBodyCell>
                {result.announcementDate
                  ? formatDate(new Date(result.announcementDate))
                  : "--"}
              </TableBodyCell>
              <TableBodyCell>
                <div className="text-red">
                  {result.dtcExpiration ? (
                    formatDate(new Date(result.dtcExpiration))
                  ) : (
                    <div className="flex items-center">
                      -- <AlertIcon className="ml-2" />
                    </div>
                  )}
                </div>
              </TableBodyCell>
            </TableBodyRow>
          ))}
        </tbody>
      </Table>
    </PageWrapper>
  );
}
