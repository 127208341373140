import { COLORS } from "@asayinc/component-library";
import { SxProps } from "@mui/material";

import { OperationsStatus } from "src/features/Proxy/types";
import { Chip } from "../atoms/Chip";

interface IProxyStatusBadgeProps {
  filingStatus: OperationsStatus;
  sx?: SxProps;
}

export function ProxyStatusBadge({ filingStatus, sx }: IProxyStatusBadgeProps) {
  const mapStatusToLabel = new Map([
    [OperationsStatus.Draft, "DRAFT"],
    [OperationsStatus.New, "NEW"],
    [OperationsStatus.Active, "ACTIVE"],
    [OperationsStatus.MaterialsRequested, "REQUESTED"],
    [OperationsStatus.ReadyForReview, "COMPLETE"],
    [OperationsStatus.Approved, "APPROVED"],
    [OperationsStatus.Closed, "CLOSED"],
  ]);

  const getBorderColor = () => {
    switch (filingStatus) {
      case OperationsStatus.Draft:
      case OperationsStatus.New:
        return "rgba(0, 0, 0, 0.9)";
      case OperationsStatus.Active:
        return COLORS.BRIGHT_GREEN;
      case OperationsStatus.MaterialsRequested:
        return "#1853B4";
      case OperationsStatus.ReadyForReview:
        return "#EE8B35";
      case OperationsStatus.Approved:
        return COLORS.BRIGHT_GREEN;
      case OperationsStatus.Closed:
        return "rgba(0, 0, 0, 0.9)";
    }
  };

  return (
    <Chip
      data-testid="filingStatus"
      label={mapStatusToLabel.get(filingStatus) || ""}
      variant="outlined"
      size="small"
      sx={{ border: `1px solid ${getBorderColor()}`, ...sx }}
    />
  );
}
