import useSWR from "swr";
import {
  Routes,
  Route,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { hub } from "../../api/adapters/hub";
import { PageWrapper } from "../../components/PageWrapper";
import { LoadingState } from "../../components/LoadingState";
import { Details } from "./Details";
import { Activity } from "./Activity";
import { Invoicing } from "./Invoicing";
import { Prospectus } from "./Prospectus";
import { ErrorCard } from "../../components/ErrorCard";
import { getTabFromUrl } from "src/utils/get-tab-from-url";
import { Tabs } from "src/components/atoms/Tabs";
import { PageHeader } from "src/components/molecules/PageHeader";
import { ReactComponent as IssuerIcon } from "src/assets/icons/issuer-icon.svg";

type Response = {
  id: string;
  name: string;
  issuerType: null | IssuerType;
  cik: null | string;
  fundOwner: null | string;
  securities: Security[];
};

export type Security = {
  id: string;
  name: string;
  securityType: null | SecurityType;
  cusip: string;
  ticker: null | string;
};

export type IssuerType = "corporation" | "investment_trust" | "government";
export type SecurityType = "etf" | "fund" | "adr" | "etn" | "bond" | "stock";

const issuerFetcher = (url: string) => hub.get<Response>(url);

function Issuer(): JSX.Element | null {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { data, error } = useSWR(`/admin/issuers/${id}/`, issuerFetcher);

  if (!error && !data)
    return (
      <PageWrapper>
        <LoadingState />
      </PageWrapper>
    );

  if (error)
    return (
      <PageWrapper>
        <ErrorCard
          title="Unable to load this issuer."
          body="Something went wrong when attempting to load this issuer. Please contact support for help with this issue."
        />
      </PageWrapper>
    );

  if (!data) return null;

  const getTabs = () => {
    if (data.data.issuerType === "corporation") {
      return [
        {
          label: `Issuer Details`,
          value: "",
          testId: "IssuerDetailsTab",
        },
        {
          label: `Activity`,
          value: "activity",
          testId: "IssuerActivityTab",
        },
        {
          label: `Invoicing`,
          value: "invoicing",
          testId: "IssuerInvoicingTab",
        },
      ];
    } else {
      return [
        {
          label: `Issuer Details`,
          value: "",
          testId: "IssuerDetailsTab",
        },
        {
          label: `Prospectus Documents`,
          value: "prospectus",
          testId: "IssuerProspectusTab",
        },
        {
          label: `Activity`,
          value: "activity",
          testId: "IssuerActivityTab",
        },
        {
          label: `Invoicing`,
          value: "invoicing",
          testId: "IssuerInvoicingTab",
        },
      ];
    }
  };

  function onTabChange(value: string) {
    navigate(`/issuers/${id}/${value}`);
  }

  return (
    <div id="issuer-body">
      <PageHeader icon={<IssuerIcon />} title={data?.data.name || ""} />
      <Tabs
        sx={{ px: 6, mb: 4, borderBottom: "1px solid #e5e5e5" }}
        onChange={onTabChange}
        value={getTabFromUrl(location)}
        tabs={getTabs()}
      />
      <Routes>
        <Route path="prospectus" element={<Prospectus />} />
        <Route path="activity" element={<Activity />} />
        <Route path="invoicing" element={<Invoicing />} />
        <Route path="/" element={<Details {...data.data} />} />
      </Routes>
    </div>
  );
}

export { Issuer };
