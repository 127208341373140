import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { createVoluntaryCorporateActionFiling } from "src/api/adapters";
import { CreateVCAPayload } from "src/api/adapters/nexus/types";
import { CreateCorporateActionForm as CreateCorporateActionFormType } from "src/types/vca";
import { capitalize } from "src/utils/capitalize";
import { Dialog } from "../atoms/dialog/Dialog";
import { CreateCorporateActionForm } from "../CreateCorporateActionForm/CreateCorporateActionForm";

interface IVcaCreationDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function VcaCreationDialog({
  isOpen,
  onClose,
}: IVcaCreationDialogProps) {
  const navigate = useNavigate();
  const form = useForm<CreateCorporateActionFormType>({
    defaultValues: {
      announcementDate: null,
      dtcExpiration: null,
      dtcExpirationTimezone: null,
      electionCutoff: null,
      electionCutoffTimezone: null,
      recordDate: null,
      operationsStatus: "new",
      offer: {
        title: "",
        detail: "",
        conditions: {},
      },
      securities: [],
      type: null,
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = form;

  useEffect(() => {
    if (!isOpen) {
      reset({
        announcementDate: null,
        dtcExpiration: null,
        dtcExpirationTimezone: null,
        electionCutoff: null,
        electionCutoffTimezone: null,
        recordDate: null,
        operationsStatus: "new",
        offer: {
          title: "",
          detail: "",
          conditions: {},
        },
        securities: [],
        type: null,
      });
    }
  }, [isOpen]);

  const handleOnSubmit: SubmitHandler<CreateCorporateActionFormType> = async (
    values
  ) => {
    if (values.type) {
      const payload: CreateVCAPayload = {
        type: values.type,
        announcementDate: moment(values.announcementDate).format("yyyy-MM-DD"),
        electionCutoff: values.electionCutoff
          ? moment(values.electionCutoff).format("YYYY-MM-DDTHH:mm:ss")
          : null,
        electionCutoffTimezone: values.electionCutoffTimezone || null,
        dtcExpiration: moment(values.dtcExpiration).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        dtcExpirationTimezone: values.dtcExpirationTimezone || "",
        recordDate: values.recordDate
          ? moment(values.recordDate).format("yyyy-MM-DD")
          : null,
        securityIds: values.securities.map((security) => security.id),
        offer: values.offer,
        brokerIds: [],
      };

      try {
        const vca = await createVoluntaryCorporateActionFiling(payload);
        const {
          data: { id },
        } = vca;
        navigate(`/vca/${id}`);
      } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.data?.nonFieldErrors) {
          const errorMessage = e.response.data.nonFieldErrors[0];
          toast.error(capitalize(errorMessage));
        } else {
          toast.error(
            "An error has occured when trying to create the vca filing. Please try creating the filing again."
          );
        }
      } finally {
        onClose();
      }
    }
  };

  return (
    <Dialog
      fullWidth
      isPrimaryLoading={isSubmitting}
      onClose={onClose}
      open={isOpen}
      onPrimaryClick={handleSubmit(handleOnSubmit)}
      onSecondaryClick={onClose}
      primaryButtonText="Create"
      secondaryButtonText="Cancel"
      title="Create Voluntary Corporate Action"
    >
      <CreateCorporateActionForm form={form} sx={{ my: 2 }} />
    </Dialog>
  );
}
