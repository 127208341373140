import { useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { updateProspectusNexus } from "../../api/adapters/nexus";
import { Dialog } from "../atoms/dialog/Dialog";
import { ProspectusPackage } from "../../api/adapters/nexus/types";

interface IDismissProspectusPackageDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
  prospectusPackage: ProspectusPackage;
}

export function DismissProspectusPackageDialog({
  isOpen,
  onClose,
  onUpdate,
  prospectusPackage,
}: IDismissProspectusPackageDialogProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id, expectedEndDate, security } = prospectusPackage;

  const handleDismiss = async () => {
    const endDate = expectedEndDate ? new Date(expectedEndDate) : new Date();
    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const newExpectedEndDate = tomorrowDate > endDate ? tomorrowDate : endDate;
    try {
      setIsLoading(true);
      await updateProspectusNexus(id, {
        expectedEndDate: format(newExpectedEndDate, "yyyy-MM-dd"),
        securityId: security.id,
      });
    } catch {
      toast.error(
        "An unknown error occurred. Please ensure your selections are correct.",
        {
          autoClose: false,
        }
      );
    } finally {
      setIsLoading(false);
      onUpdate();
      navigate("/prospectuses");
      onClose();
    }
  };

  return (
    <Dialog
      isPrimaryLoading={isLoading}
      onSecondaryClick={onClose}
      onPrimaryClick={handleDismiss}
      primaryButtonText="Dismiss"
      secondaryButtonText="Cancel"
      open={isOpen}
      onClose={onClose}
      title="Dismiss Trade Alert"
    >
      <p className="mb-4">Are you sure you want to dismiss this trade alert?</p>
    </Dialog>
  );
}
