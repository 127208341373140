import { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import { FormikActions, FormikValues } from "formik";
import { createFilterOptions } from "@mui/material/Autocomplete";
import useSWR from "swr";
import { FilterOptionsState } from "@mui/material/useAutocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { proxy } from "../../../api/adapters/proxy";
import { Issuer } from "../../../types/common";

type AddNew = {
  addNew?: boolean;
};

type Option = Issuer & AddNew;

type AutoCompleteFilter = (
  options: Option[],
  state: FilterOptionsState<Option>
) => unknown[];

type Props = {
  setFieldValue: FormikActions<FormikValues>["setFieldValue"];
  value: Issuer | null;
  field: string;
  optionLabelField: "companyName";
  label: string;
  disable: boolean;
};

const filter: AutoCompleteFilter = createFilterOptions();

const fetcher = (url: string) => proxy.get<Issuer[]>(url);

/**
 * Typeahead for dynamically pulling issuer options from API
 * @param setFieldValue - formik set field
 * @param value - object of current selected value
 * @param field - field within value object for display
 * @param disable - should input be disabled
 * @param label - input label
 * @param optionLabelField - field to be used for display of typeahead option
 * @returns FC
 */
const DynamicIssuerTypeahead = ({
  setFieldValue,
  value,
  field,
  label,
  optionLabelField,
  disable,
}: Props): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState(""); // What user types
  const [query, setQuery] = useState(""); // Debounced updated value for API
  const debounceSetQuery = useCallback(debounce(setQuery, 200), []);
  const { data, error } = useSWR(query, fetcher, {
    revalidateOnFocus: false,
  }); // use query instead of searchTerm for debounce
  const currentValue = value || null;
  const loading = !data && !error;

  /**
   * Every time the search term changes, update the query on debounce
   */
  useEffect(() => {
    const queryTemplate = `/issuer-typeahead/?company_name={{searchTerm}}`;
    debounceSetQuery(
      queryTemplate.replace("{{searchTerm}}", encodeURIComponent(searchTerm))
    );
  }, [searchTerm, debounceSetQuery]);

  /**
   * Show add new option if item does not currently exist and api is not loading
   * @param options - list of current options
   * @returns boolean
   */
  const showAddNew = (options: Option[]) =>
    searchTerm.length > 2 &&
    !options.some((itm) => itm && itm.companyName === searchTerm) &&
    !loading;

  const dynamicOptions = data ? data.data : [];

  return (
    <Autocomplete
      limitTags={3}
      disabled={disable}
      options={dynamicOptions}
      value={currentValue}
      loading={loading}
      inputValue={searchTerm}
      getOptionLabel={(option: Option) =>
        option.addNew
          ? `Add "${option[optionLabelField]}"`
          : option[optionLabelField]
      }
      filterOptions={(options, params) => {
        const filtered = filter(options, params) as Option[];
        // Display option to add new
        if (showAddNew(filtered)) {
          filtered.push({
            id: NaN,
            [optionLabelField]: params.inputValue,
            addNew: true,
          });
        }

        return filtered;
      }}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id || disable || !value.id;
      }}
      ChipProps={{ size: "small" }}
      onChange={(_, changeValue) => {
        if (data) {
          if (changeValue) {
            if (changeValue.addNew) {
              delete changeValue.addNew;
            }
          } else {
            // manually clear issuer if values are deleted until empty
            setFieldValue("issuer", {
              companyName: "",
              contactEmail: "",
              id: null,
            });
          }
        }
        return setFieldValue(field, changeValue);
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === "clear") {
          setFieldValue(field, []);
          setFieldValue("issuer", {
            companyName: "",
            contactEmail: "",
            id: null,
          });
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={true}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export { DynamicIssuerTypeahead };
