import { AxiosRequestConfig } from "axios";
import useSWR from "swr";

import { PaginatedResponse } from "src/types/PaginatedResponse";
import { nexus } from "..";
import { IQueryParams } from "../../types";
import { VoteTransmission } from "src/types/voteTransmission";

const fetcher = (url: string, params: AxiosRequestConfig<IQueryParams>) =>
  nexus.get<PaginatedResponse<VoteTransmission>>(url, { params });

export function useVoteTransmissions(id: string | undefined) {
  const url = `/proxy-events/${id}/vote-transmission-events/?ordering=-date_received`;
  const { data, error, mutate } = useSWR(id ? url : null, fetcher);
  return {
    count: data?.data?.count,
    isError: error,
    isLoading: !error && !data,
    mutate,
    voteTransmissions: data?.data?.results,
  };
}
