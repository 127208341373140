import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { FormikErrors } from "formik";
import React from "react";
import { toast } from "react-toastify";
import useSWR from "swr";

import { proxy } from "../../api/adapters/proxy";
import { Cusip } from "../../features/Proxy/types";
import { Issuer } from "../../types/common";

type Props = {
  issuerId?: string | null;
  value: { id: number; cusip: string; name: string }[];
  onSetCusips: (
    cusips: {
      id: number;
      cusip: string;
      name: string;
    }[]
  ) => void;
  onSetIssuer?: (issuer: {
    companyName: string;
    contactEmail: string | null;
    id: number | null;
    globalIssuerId: string | null;
  }) => void;
  fieldError?: (FormikErrors<Cusip> | undefined)[];
};

type Item = {
  id: number;
  cusip: string;
  name: string;
  issuer: Issuer;
};

const fetcher = (url: string) => proxy.get<Item[]>(url);

function CusipTypeahead({
  issuerId,
  onSetCusips,
  value,
  onSetIssuer,
  fieldError,
}: Props): JSX.Element {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { data, error } = useSWR(
    `/issuers/cusip-typeahead/?cusip=${searchTerm ? searchTerm : ""}${
      issuerId !== null ? `&issuer_id=${issuerId ? issuerId : ""}` : ""
    }`,
    fetcher
  );

  // Copying the cusip inside the chip to user's clipboard
  const handleCusipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.target as HTMLElement;
    navigator.clipboard.writeText(element.innerText);
    toast.info("Copied CUSIP", { autoClose: 1000 });
    event.stopPropagation();
  };

  const options = data
    ? data.data.map((d) => ({
        id: d.id,
        cusip: d.cusip,
        name: d.name,
      }))
    : [];

  const getIssuer = (cusip: string) =>
    data ? data.data.filter((d) => d.cusip === cusip)[0].issuer : null;

  return (
    <Autocomplete
      multiple
      options={[...value, ...options]}
      getOptionLabel={(option) => option.cusip}
      isOptionEqualToValue={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      filterSelectedOptions
      inputValue={searchTerm}
      loading={!data && !error}
      value={value}
      ChipProps={{
        onClick: handleCusipClick,
        size: "small",
      }}
      noOptionsText="No CUSIPs"
      onChange={(_, changeValue) => {
        // onSetIssuer only passed when creating a new filing, helps set issuer field from scratch
        if (data && onSetIssuer) {
          if (changeValue.length > 0) {
            // get first cusip in list and retrieve related issuer
            const selectedIssuer = getIssuer(changeValue[0].cusip);
            selectedIssuer &&
              onSetIssuer({
                companyName: selectedIssuer.companyName,
                contactEmail: selectedIssuer.contactEmail || null,
                id: selectedIssuer.id,
                globalIssuerId: selectedIssuer.globalIssuerId || null,
              });
          } else {
            // manually clear issuer if values are deleted until empty
            onSetIssuer({
              companyName: "",
              contactEmail: "",
              id: null,
              globalIssuerId: null,
            });
          }
        }
        return onSetCusips(changeValue);
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === "clear") {
          onSetCusips([]);
          onSetIssuer &&
            onSetIssuer({
              companyName: "",
              contactEmail: "",
              id: null,
              globalIssuerId: null,
            });
        } else {
          setSearchTerm(newInputValue);
        }
      }}
      renderInput={(params) => (
        <div id="CusipTypeAhead">
          <TextField
            {...params}
            error={Boolean(fieldError?.length)}
            helperText={fieldError}
            size="small"
            required={true}
            label="CUSIP"
            placeholder="Start typing CUSIP..."
          />
        </div>
      )}
    />
  );
}

export { CusipTypeahead };
